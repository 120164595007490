import React, { memo } from 'react'
import { Route, Navigate, Routes } from 'react-router-dom'
import loadable from '@loadable/component'

const Dashboard = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/Dashboard'))
const Settings = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/AccountSettings'))

export const SettingsRouter = memo(() => {
	return (
		<Routes>
			<Route index element={<Navigate to="/settings/profile" />} />
			<Route path=":section" element={<Settings />} />
		</Routes>
	)
})

export const DashboardRouter = memo(() => {
	return (
		<Routes>
			<Route index element={<Dashboard />} />
		</Routes>
	)
})
