/* **************************************
LIBRARIES
************************************** */

import { combineReducers } from 'redux'

/* ******************************
 * PROJECT DEPENDENCIES
 ***************************** */

import * as types from '../constants'

function data(state = {}, action = {}) {
	switch (action.type) {
		case types.LOAD_ADMIN_EVENTS_SUCCESS:
			if (action.payload.entities && action.payload.entities.events) {
				return Object.assign({}, state, action.payload.entities.events)
			}

		default:
			if (action.entities && action.entities.events) {
				return Object.assign({}, state, action.entities.events)
			}
			return state
	}
}

const publishing = (state = {}, action) => {
	switch (action.type) {
		case types.UPLOAD_PUBLISHED_MEDIA_REQUEST:
			return Object.assign({}, state, {
				isLoading: true,
				showProgress: true,
				percentage: 0,
			})

		case types.UPLOAD_PUBLISHED_MEDIA_PROGRESS:
			if (action.payload.percentage) {
				return Object.assign({}, state, {
					isLoading: true,
					showProgress: action.payload.percentage == 100 ? false : true,
					percentage: action.payload.percentage,
				})
			}

		case types.UPLOAD_PUBLISHED_MEDIA_SUCCESS:
			if (action.payload.percentage) {
				return Object.assign({}, state, {
					isLoading: false,
					showProgress: false,
					percentage: 0,
					editMode: false,
					data: action.payload.data,
				})
			}

		case types.UPLOAD_PUBLISHED_MEDIA_FAILURE:
			if (action.payload.percentage) {
				return Object.assign({}, state, {
					isLoading: false,
					showProgress: false,
					percentage: 0,
					statusType: action.payload.statusType,
					statusMessage: action.payload.message,
				})
			}

		default:
			return state
	}
}

const packaging = (state = {}, action) => {
	switch (action.type) {
		case types.COMPRESS_VIDDAY_PACKAGE_REQUEST:
			return Object.assign({}, state, {
				isLoading: true,
				showProgress: true,
			})

		case types.COMPRESS_VIDDAY_PACKAGE_SUCCESS:
			if (action.payload.percentage) {
				return Object.assign({}, state, {
					isLoading: false,
					showProgress: false,
				})
			}

		case types.COMPRESS_VIDDAY_PACKAGE_FAILURE:
			if (action.payload.percentage) {
				return Object.assign({}, state, {
					isLoading: false,
					showProgress: false,
				})
			}
		default:
			return state
	}
}

const medias = (state = {}, action) => {
	switch (action.type) {
		case types.LOAD_ADMIN_EVENTS_SUCCESS:
			if (action.payload.entities && action.payload.entities.medias) {
				return Object.assign({}, state, action.payload.entities.medias)
			}

		default:
			if (action.entities && action.entities.medias) {
				return Object.assign({}, state, action.entities.medias)
			}
			return state
	}
}

const recipients = (state = {}, action) => {
	switch (action.type) {
		case types.LOAD_ADMIN_EVENTS_SUCCESS:
			if (action.payload.entities && action.payload.entities.recipients) {
				return Object.assign({}, state, action.payload.entities.recipients)
			}

		default:
			if (action.entities && action.entities.recipients) {
				return Object.assign({}, state, action.entities.recipients)
			}
			return state
	}
}

export function eventRenderJobs(state = {}, action = {}) {
	switch (action.type) {
		case types.LOAD_ADMIN_RENDER_JOBS_SUCCESS:
			if (action.payload && action.payload.entities && action.payload.entities.event_render_jobs) {
				return Object.assign({}, state, action.payload.entities.event_render_jobs)
			}
			break
		case types.ADD_ADMIN_RENDER_JOB_SUCCESS:
			// console.log("UPDATE PAYLOAD", action.payload)
			if (action.payload && action.payload.data) {
				let renderJobId = action.payload.data.id
				state[renderJobId] = action.payload.data
				return Object.assign({}, state)
			}
			break
		case types.UPDATE_ADMIN_RENDER_JOB_SUCCESS:
			// console.log("UPDATE PAYLOAD", action.payload)
			if (action.payload && action.payload.data) {
				let renderJobId = action.payload.data.id
				state[renderJobId] = action.payload.data
				return Object.assign({}, state)
			}
			break
		case types.DELETE_ADMIN_RENDER_JOB_SUCCESS:
			if (action.payload && action.payload.id) {
				let renderJobId = action.payload.id
				delete state[renderJobId]
				return Object.assign({}, state)
			}
			break
		default:
			return state
	}
}

const reducer = combineReducers({ publishing, data, packaging, medias, recipients })

export default reducer
