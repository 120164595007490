import * as React from 'react'
import { Box, Slider, SliderTrack, SliderFilledTrack, SliderThumb } from '@chakra-ui/react'

const containerStyles = {
	float: 'left',
	display: 'block',
	w: '80px',
	pos: 'relative',
	ml: '5px',
	mt: '9px',
}

const VolumeSlider = (props) => {
	return (
		<Box sx={containerStyles} w="80px">
			<Slider aria-label="slider-ex-1" defaultValue={props.volume} onChange={props.onChange}>
				<SliderTrack>
					<SliderFilledTrack />
				</SliderTrack>
				<SliderThumb />
			</Slider>
		</Box>
	)
}

export default VolumeSlider
