/* ******************************
 * LIBRARIES
 ***************************** */

import { RSAA, getJSON } from 'redux-api-middleware'

/* ******************************
 * PROJECT DEPENDENCIES
 ***************************** */

import * as types from '../constants'
import { retrieveURL } from '@vidday/utils'

/* ******************************
 * FETCH ONE PROMOTION FROM PROMOCODE
 ***************************** */

export const fetchPromotion = (data) => ({
	[RSAA]: {
		types: [
			types.LOAD_PROMOTION_REQUEST,
			{
				type: types.LOAD_PROMOTION_SUCCESS,
				// payload: (action, state, res) => {
				//     return getJSON(res).then((json) => normalize(json.data, promotionListSchema ));
				// }
				payload: (action, state, res) => {
					// grab attempted login email from login form state
					//const attemptedEmail = state.form.login_form.values.email || state.form.register_form.values.email
					return getJSON(res).then((json) => {
						if (res.status === 200) {
							return { valid: true, code: json.data, message: json.message }
						} else {
							return { valid: false, code: {}, message: json.message }
						}
					})
				},
			},
			{
				type: types.LOAD_PROMOTION_FAILURE,
				payload: (action, state, res) => {
					return getJSON(res).then((json) => {
						return { valid: false, code: {}, message: json.message }
					})
				},
			},
		],

		//types: [types.LOAD_PROMOTION_REQUEST, types.LOAD_PROMOTION_SUCCESS, types.LOAD_PROMOTION_FAILURE],

		endpoint: `${retrieveURL()}/api/v2/promo-codes/check/${data.code}?occasion=${data.occasion}`,
		credentials: 'include',
		method: 'GET',
	},
})

export function removePromotion() {
	return {
		type: types.REMOVE_PROMOTION_SUCCESS,
		payload: { valid: false, code: {}, message: '' },
	}
}
