import React from 'react'
import { showReportDialog, lastEventId } from '@sentry/react'
import HandleArbitrary from '../HandleArbitrary'

const SentryDialogFallback = (props) => {
	const error = props.error
	const eventId = lastEventId()

	showReportDialog({
		eventId: eventId,
		title: 'Something went wrong',
		subtitle:
			"We're here to help — tell us what happened below and then reach out to Customer Care for help by using our chat support in the bottom corner.",
		subtitle2: '', // only shows up on Desktop view (not mobile)
	})

	return <HandleArbitrary />
}

export default SentryDialogFallback
