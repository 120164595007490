import { createSlice } from '@reduxjs/toolkit'
import { combineFormState } from './formState'

const initialState = {}

/** Reducer for the Register form */
const rootSlice = createSlice(
	combineFormState('RESET_PASSWORD', {
		name: 'forms.ResetPassword',
		initialState,
		// reducers: {
		// 	updateEmail: (state, action) => {
		// 		state.data.email = action.payload
		// 	},
		// },
	})
)

// Export Form Reducer
export const reducer = rootSlice.reducer

// Export our actions
export const { reset } = rootSlice.actions
