import { createSlice } from '@reduxjs/toolkit'
import { combineFormState } from './formState'

const initialState = {
	data: {
		// Step 1
		occasion: '',
		occasionOther: '',
		years: null,
		// Step 2
		recipients: [],
		// Step 3
		//occasionDate: null,
		deadline: null,
		timezone: null,
		isPersonal: false,
		// Step 4
		// allowCashCollection: false,
		// cashCollectionGroup:'creator',
	},
}

const rootSlice = createSlice(
	combineFormState('ADD_EVENT', {
		name: 'forms.NewVidday',
		initialState,
		reducers: {
			updateRecipients: (state, action) => {
				state.data.recipients = action.payload
			},
			updateOccasion: (state, action) => {
				state.data.occasion = action.payload
			},
			updateOccasionOther: (state, action) => {
				state.data.occasionOther = action.payload
			},
			updateYears: (state, action) => {
				state.data.years = action.payload
			},
			updateDeadline: (state, action) => {
				state.data.deadline = action.payload
			},
			updateTimezone: (state, action) => {
				state.data.timezone = action.payload
			},
			updateIsPersonal: (state, action) => {
				state.data.isPersonal = action.payload
			},
			// occasionDate deprecate 24/11/01 - use deadline
			//updateOccasionDate: (state, action) => {
			//	state.data.occasionDate = action.payload
			//},
			// updateAllowCashCollection: (state, action) => {
			// 	state.data.allowCashCollection = action.payload
			// },
		},
	})
)

// Export Form Reducer
export const reducer = rootSlice.reducer

// Export our actions
export const {
	reset,
	updateOccasion,
	updateOccasionOther,
	updateYears,
	updateRecipients,
	updateDeadline,
	updateTimezone,
	updateIsPersonal,
	//updateOccasionDate,
	//updateAllowCashCollection,
} = rootSlice.actions
