/* ******************************
 * LIBRARIES
 ***************************** */

import { RSAA, getJSON } from 'redux-api-middleware'
import { normalize } from 'normalizr'

/* ******************************
 * PROJECT DEPENDENCIES
 ***************************** */

import * as types from '../constants'
import { adminEventListSchema, renderJobListSchema } from '../middlewares/normalizr'
import { retrieveURL } from '@vidday/utils'
import qs from 'qs'
import { putEvent } from '../../app/events'
import { addFlashMessage } from '../../app/behaviors/flash-messages'

/* ******************************
 * FETCH ALL EVENTS
 ***************************** */

export const fetchEvents = (params) => ({
	[RSAA]: {
		types: [
			types.LOAD_ADMIN_EVENTS_REQUEST,
			{
				type: types.LOAD_ADMIN_EVENTS_SUCCESS,
				payload: (action, state, res) => {
					return getJSON(res).then((json) => normalize(json.data, adminEventListSchema))
				},
			},
			types.LOAD_ADMIN_EVENTS_FAILURE,
		],
		endpoint: retrieveURL() + `/api/admin/events${params ? `?${qs.stringify(params)}` : ``}`,
		credentials: 'include',
		method: 'GET',
	},
})

/* ******************************
 * UPDATE AN EVENT (data should have all 'Data' defined) update action from universal actions
 ***************************** */

export const updateEvent = function (event, silent) {
	return function (dispatch, state) {
		delete event.publishedMedia //remove this from event object
		event.uuid = event.uuid
			? event.uuid
			: state().event && state().event.data && state().event.data.uuid
			? state().event.data.uuid
			: null
		event.id = event.id
			? event.id
			: state().event && state().event.event && state().event.event.id
			? state().event.event.id
			: null
		dispatch(putEvent(event)).then((response) => {
			if (!silent) {
				dispatch(addFlashMessage('success', 'Event updated successfully.'))
				dispatch(fetchEvents())
			}
		})
	}
}

/* ******************************
 * MergeEvents - Merge events and their data/media into one uuid
 ***************************** */
export function mergeEvents(data) {
	return (dispatch, state) => {
		dispatch({
			[RSAA]: {
				types: [
					types.MERGE_EVENTS_REQUEST,
					{
						type: types.MERGE_EVENTS_SUCCESS,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								dispatch(addFlashMessage('success', json.message))
								return json
							})
						},
					},
					{
						type: types.MERGE_EVENTS_FAILURE,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								dispatch(addFlashMessage('error', json.message))
								return json
							})
						},
					},
				],
				endpoint: retrieveURL() + '/api/v2/events/merge',
				method: 'POST',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(data),
			},
		})
	}
}

/* ******************************
 * MergeEvents - Merge events and their data/media into one uuid
 ***************************** */
export function changeEventCreator(data) {
	return (dispatch, state) => {
		dispatch({
			[RSAA]: {
				types: [
					types.CHANGE_EVENT_CREATOR_REQUEST,
					{
						type: types.CHANGE_EVENT_CREATOR_SUCCESS,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								dispatch(addFlashMessage('success', json.message))
								return json
							})
						},
					},
					{
						type: types.CHANGE_EVENT_CREATOR_FAILURE,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								dispatch(addFlashMessage('error', json.message))
								return json
							})
						},
					},
				],
				endpoint: retrieveURL() + '/api/events/changeeventcreator',
				method: 'POST',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(data),
			},
		})
	}
}

/////////////////////////////// AUTOMATION ////////////////////////////////////////////

/* ******************************
 * FETCH ALL RENDER JOBS
 ***************************** */

export const fetchRenderJobs = () => ({
	[RSAA]: {
		types: [
			types.LOAD_ADMIN_RENDER_JOBS_REQUEST,
			{
				type: types.LOAD_ADMIN_RENDER_JOBS_SUCCESS,
				payload: (action, state, res) => {
					return getJSON(res).then((json) => normalize(json.data, renderJobListSchema))
				},
			},
			types.LOAD_ADMIN_RENDER_JOBS_FAILURE,
		],
		endpoint: retrieveURL() + '/api/admin/renderjobs',
		credentials: 'include',
		method: 'GET',
	},
})

/* ******************************
 * CREATE A RENDER_JOB
 ***************************** */
export function addRenderJob(data) {
	return (dispatch, state) => {
		dispatch({
			[RSAA]: {
				types: [
					types.ADD_ADMIN_RENDER_JOB_REQUEST,
					{
						type: types.ADD_ADMIN_RENDER_JOB_SUCCESS,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								dispatch(addFlashMessage('info', json.message))
								return json
							})
						},
					},
					{
						type: types.ADD_ADMIN_RENDER_JOB_FAILURE,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								dispatch(addFlashMessage('error', json.message))
								return json
							})
						},
					},
				],
				endpoint: retrieveURL() + '/api/admin/renderjobs',
				method: 'POST',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(data),
			},
		})
	}
}

/* ******************************
 * UPDATE A RENDER_JOB
 ***************************** */

export const updateRenderJob = (data) => ({
	[RSAA]: {
		types: [
			types.UPDATE_ADMIN_RENDER_JOB_REQUEST,
			types.UPDATE_ADMIN_RENDER_JOB_SUCCESS,
			types.UPDATE_ADMIN_RENDER_JOB_FAILURE,
		],
		endpoint: retrieveURL() + '/api/admin/renderjobs/' + data.id,
		method: 'PUT',
		credentials: 'include',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(data),
	},
})

/* ******************************
 * DELETE A RENDER_JOB
 ***************************** */

export const deleteRenderJob = (id) => ({
	[RSAA]: {
		types: [
			types.DELETE_ADMIN_RENDER_JOB_REQUEST,
			types.DELETE_ADMIN_RENDER_JOB_SUCCESS,
			types.DELETE_ADMIN_RENDER_JOB_FAILURE,
		],
		endpoint: retrieveURL() + '/api/admin/renderjobs/' + id,
		method: 'DELETE',
		credentials: 'include',
		headers: { 'Content-Type': 'application/json' },
	},
})
