import React, { createContext, useCallback, useContext } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { fetchPromotion, removePromotion } from '../../api/app/promotions'
import { updateCart } from '../../api/checkout/actions'

/** Build Current Checkout Context */
const CheckoutContext = createContext()

/** Define Current Checkout Context Provider  */
const CheckoutProvider = ({ children }) => {
	/** Retrieve Dispatch */
	const dispatch = useDispatch()

	/** Retrieve our redux state corresponding to checkout */
	const checkout = useSelector((s) => s.checkout, shallowEqual)

	/** Load the promotion entered through the checkout */
	const promotionState = checkout.promotion
	/**
	 * Handle fetch promo code submitted by user
	 */
	const fetchPromoCode = useCallback((data) => {
		dispatch(fetchPromotion(data))
	}, [])

	/**
	 * Handle removing of promo code submitted by user
	 */
	const removePromoCode = useCallback(() => {
		return new Promise((resolve) => {
			dispatch(removePromotion())
			/** Reset in the cart too */
			dispatch(updateCart({ promoCode: null }))
			// Resolve the Promise when both dispatch actions are completed
			resolve()
		})
	}, [])

	/** END HACK */
	const state = {
		checkout: checkout,
		/** Load the cart of the user */
		cart: checkout.cart,
		/** Load the checkout event data */
		event: checkout.event,
		/** Get checkout location */
		location: checkout.location.location,
		/** Get checkout products */
		productsArray: checkout.products.data,
		/** Load checkout promotion state with attached actions*/
		promotion: { promotionState, fetchPromoCode, removePromoCode },
		/** Retrieve active checkout currency */
		currency: checkout.currency.data,
		/** retrieve details about the calculation of the cart */
		cartCalculations: checkout.calculations,
	}

	return <CheckoutContext.Provider value={state}>{children}</CheckoutContext.Provider>
}

export default CheckoutProvider

export const useCheckoutContext = () => useContext(CheckoutContext)
