import { createSlice } from '@reduxjs/toolkit'
import { combineFormState } from './formState'

const initialState = {
	data: {
		email: '',
		firstName: '',
		lastName: '',
	},
}

/** Reducer for the Register form */
const rootSlice = createSlice(
	combineFormState(
		'POST_RSVP',
		{
			name: 'forms.RSVP',
			initialState,
			reducers: {},
		},
		true
	)
)

// Export Form Reducer
export const reducer = rootSlice.reducer

// Export our actions
export const { reset } = rootSlice.actions
