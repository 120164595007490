import PropTypes from 'prop-types'
import forEach from 'lodash/forEach'

/* **************************************
 *  Import Models
 * ************************************ */

import Announcement from './Announcement'
import PromoCode from './PromoCode'
import User from './User'
import UserPreferences from './UserPreferences'
import UserProfile from './UserProfile'
import Event from './Event'
import EventRecipient from './EventRecipient'
import EventMedia from './EventMedia'
import EventInvoice from './EventInvoice'
import EventPublishedMedia from './EventPublishedMedia'
import EventRenderJob from './EventRenderJob'
import RakutenAction from './RakutenAction'

/* **************************************
 *  Define models to be exported
 * ************************************ */

/** Collection of models */
let Models = {
	Announcement,
	PromoCode,
	User,
	UserPreferences,
	UserProfile,
	Event,
	EventRecipient,
	EventMedia,
	EventInvoice,
	EventPublishedMedia,
	EventRenderJob,
	RakutenAction,
}
/** Final collection after being generated */
let output = {}

let errorScope = 'Schema error : '

/* **************************************
 *  Base schema, which all models inherit
 * ************************************ */

const propTypes = {
	id: PropTypes.string,
	updatedAt: PropTypes.string, //instanceOf(Date),
	createdAt: PropTypes.string, //.instanceOf(Date),
}

const defaultProps = {
	updatedAt: null,
	createdAt: null,
}

/* **************************************
 *  Process Schemas
 * ************************************ */

forEach(Models, (el, i) => {
	/** Obtain the key/model name */
	let modelName = i

	/** Merge propTypes */
	output[modelName] = {
		/** Merge global propTypes and model's propTypes */
		propTypes: Object.assign(el.propTypes, propTypes),
		/** Merge global defaultProps and model's defaultProps */
		defaultProps: Object.assign(el.defaultProps, defaultProps),
		/**
		 * Define a merge method handler that allow us to merge the return
		 * default values with another custom object, for 'on-the-fly' customization
		 * @function
		 * @name merge
		 * @param {string} val - To find which key to be used for the merge ('default', 'type').
		 * @param {string} obj - Object to be merged with the key above.
		 */
		merge(val, obj) {
			if (val === 'default') {
				this.defaultProps = Object.assign(this.defaultProps, obj)
				return this.defaultProps
			} else if (val === 'type') {
				this.propTypes = Object.assign(this.propTypes, obj)
				return this.defaultProps
			} else {
				throw new Error(
					errorScope +
						'the merge() handler requires either "default" or "type" as the first argument, followed by your overwrite object as second argument.'
				)
			}
		},
	}
})

/* **************************************
 *  Define Accessor
 * ************************************ */

/**
 * Define Accessor
 * @function
 * @name get
 * @param {string} model - Name of the model/document to retrieve.
 */
const get = (model = undefined) => {
	if (model && output[model]) {
		return output[model]
	} else {
		throw (
			errorScope +
			'You need to specify a valid model name in order to access defaultProps and propTypes of a Document.'
		)
	}
}

/* **************************************
 *  Export Models Getter as default
 * ************************************ */

export default get
