import React, { useEffect } from 'react'
import {
	LiveChatWidget as ReactLiveChatWidget,
	EventHandlerPayload,
	useWidgetIsReady,
	useWidgetCustomerData,
} from '@livechat/widget-react'
import useLiveChatMeta from './hooks/useLiveChatMeta'

const LiveChat = () => {
	useLiveChatMeta()

	const customerData = useWidgetCustomerData()

	function handleNewEvent(event) {
		// console.log('LiveChatWidget.onNewEvent', event)
	}

	const onReady = (initialData) => {
		// Chat Widget is ready
		var state = initialData.state
		var customerData = initialData.customerData
	}

	const onCustomerStatusChanged = (data) => {
		switch (data.status) {
			case 'queued':
				// customer is in queue
				break
			case 'chatting':
				// customer is currently chatting
				break
			case 'invited':
				// customer received an invitation but didn't start the chat
				break
			case 'browsing':
				// customer is in idle state, not queued, not chatting, and didn't receive an invitation
				break
		}
	}

	useEffect(() => {}, [customerData])

	return (
		<ReactLiveChatWidget
			license="11930697"
			visibility="minimized"
			onNewEvent={handleNewEvent}
			onReady={onReady}
			onCustomerStatusChanged={onCustomerStatusChanged}
		/>
	)
}

export default LiveChat
