export default {
	// We're using defaults to not differenciate mobile sizes
	'sm': '30em', // 480px
	'md': '48em', // 768px
	'lg': '62em', // 992px
	'xl': '80em', // 1280px
	'1xl': '90em', // 1440px
	'2xl': '96em', // 1536px
	'3xl': '120em', // 1920px
}
