import React from 'react'
import Icon from './Icon'

var format = require('format-duration')
import { chakra } from '@chakra-ui/react'

export const baseControlStyles = {
	'p': '0',
	'border': '0',
	'outline': '0',
	'backgroundColor': 'transparent',
	'fontSize': '1rem',
	'textAlign': 'center',
	'color': 'white',
	'--connected-color': '#3eddfa',
	'--disconnected-color': 'white',
	'fontFamily': 'Helvetica, sans-serif',
	'h': '100%',
	'width': '28px',
	'd': 'flex',
	'alignItems': 'center',
	'marginLeft': '2px',
	'cursor': 'pointer',
	'userSelect': 'none',
	'_disabled': {
		'backgroundColor': 'transparent',
		'#ic_play_arrow_24px': {
			fill: '#828d99',
		},
	},
	'.rvt-icon': {
		display: 'block',
	},
}

export const labelControlStyles = {
	'w': 'auto',
	'textAlign': 'center',
	'fontFamily': 'Helvetica, sans-serif',
	'fontSize': '13px',
	'paddingLeft': '0',
	'paddingRight': '0',
	'&:hover': {
		boxShadow: 'none',
	},
}

// const StyledBaseControl = styled.button`
// 	padding: 0;
// 	border: 0;
// 	outline: 0;
// 	background-color: transparent;
// 	font-size: 16px;
// 	text-align: center;
// 	color: white;
// 	--connected-color: #3eddfa;
// 	--disconnected-color: white;
// 	font-family: Helvetica, sans-serif;
// 	height: 100%;
// 	width: 28px;
// 	display: flex;
// 	align-items: center;
// 	margin-left: 2px;
// 	cursor: pointer;
// 	user-select: none;

// 	&[disabled] {
// 		background-color: transparent;
// 		#ic_play_arrow_24px {
// 			fill: #828d99;
// 		}
// 	}

// 	.rvt-icon {
// 		display: block;
// 	}

// 	&.label {
// 		// width: 50px;
// 		width: auto;
// 		text-align: center;
// 		// margin-left: 7px;
// 		// margin-right: 7px;
// 		font-family: Helvetica, sans-serif;
// 		font-size: 13px;
// 		padding-left: 0;
// 		padding-right: 0;
// 		&:hover {
// 			box-shadow: none;
// 		}
// 	}
// `

/* Duration Label */
const DurationLabel = ({ playerDataDuration, timeRange, currentTime }) => {
	// let clippedDuration = timeRange.end - timeRange.start
	let clippedDuration = timeRange.end - (currentTime || timeRange.start)
	// wait to load player actual data duration instead of showing media state duration value
	let formatedDuration = playerDataDuration ? format(Number(clippedDuration) * 1000) : '0:00'

	return (
		<chakra.button
			sx={{ ...baseControlStyles, ...labelControlStyles }}
			title="Remaining video duration"
			type="button"
			float="right"
			display="inline-grid"
			textAlign="center">
			{formatedDuration || 0.0}
		</chakra.button>
		// <StyledBaseControl
		// 	type="button"
		// 	title="Remaining video duration"
		// 	className="label"
		// 	style={{
		// 		float: 'right',
		// 		display: 'inline-grid',
		// 		textAlign: 'center',
		// 	}}>
		// 	{formatedDuration || 0.0}
		// </StyledBaseControl>
	)
}

/* Play / Pause Button */
const PlayPauseControl = ({ onPlayPauseClick, playing, canPlay }) => {
	return (
		<chakra.button
			sx={baseControlStyles}
			type="button"
			title={playing ? 'Pause' : 'Play'}
			onClickCapture={onPlayPauseClick}
			float="left"
			disabled={!canPlay}>
			<Icon name={playing ? 'pause' : 'play'} />
		</chakra.button>
		// <StyledBaseControl
		// 	type="button"
		// 	title={playing ? 'Pause' : 'Play'}
		// 	onClickCapture={onPlayPauseClick}
		// 	style={{ float: 'left' }}
		// 	disabled={!canPlay}>
		// 	<Icon name={playing ? 'pause' : 'play'} />
		// </StyledBaseControl>
	)
}

/* Volume Button */
const VolumeControl = ({ onMuteUnmuteClick, muted }) => {
	return (
		<chakra.button
			sx={baseControlStyles}
			type="button"
			title={muted ? 'Unmute' : 'Mute'}
			onClick={onMuteUnmuteClick}
			float="left">
			<Icon name={muted ? 'volumeMuted' : 'volumeNotMuted'} />
		</chakra.button>
		// <StyledBaseControl
		// 	type="button"
		// 	title={muted ? 'Unmute' : 'Mute'}
		// 	onClick={onMuteUnmuteClick}
		// 	style={{ float: 'left' }}>
		// 	<Icon name={muted ? 'volumeMuted' : 'volumeNotMuted'} />
		// </StyledBaseControl>
	)
}

const VideoRepeatControl = ({ onRepeatOnOffClick, loop, castConnected }) => {
	return (
		<chakra.button
			sx={baseControlStyles}
			type="button"
			title={loop ? 'Exit loop video' : 'Loop video'}
			onClick={onRepeatOnOffClick}
			float="right"
			paddingLeft="4px"
			opacity={castConnected ? '0.3' : '1'}
			disabled={castConnected}>
			<Icon name={loop ? 'repeatOff' : 'repeat'} />
		</chakra.button>
		// <StyledBaseControl
		// 	type="button"
		// 	title={loop ? 'Exit loop video' : 'Loop video'}
		// 	onClick={onRepeatOnOffClick}
		// 	style={
		// 		castConnected
		// 			? { float: 'right', paddingLeft: '4px', opacity: '0.3' }
		// 			: { float: 'right', paddingLeft: '4px' }
		// 	}
		// 	disabled={castConnected}>
		// 	<Icon name={loop ? 'repeatOff' : 'repeat'} />
		// </StyledBaseControl>
	)
}

const FullScreenControl = ({ onfullScreenOnOffClick, fullScreen }) => {
	return (
		<chakra.button
			sx={baseControlStyles}
			type="button"
			title={fullScreen ? 'Full screen off' : 'Full screen'}
			onClick={onfullScreenOnOffClick}
			float="right"
			paddingLeft="2px">
			<Icon name={fullScreen ? 'fullScreenOff' : 'fullScreen'} />
		</chakra.button>
		// <StyledBaseControl
		// 	type="button"
		// 	title={fullScreen ? 'Full screen off' : 'Full screen'}
		// 	onClick={onfullScreenOnOffClick}
		// 	style={{ float: 'right', paddingLeft: '2px' }}>
		// 	<Icon name={fullScreen ? 'fullScreenOff' : 'fullScreen'} />
		// </StyledBaseControl>
	)
}

// const StyledControls = styled.div`
// 	margin-top: 10px;
// 	text-align: center;
// 	.seconds {
// 		font-size: 12px;
// 		line-height: 36px;
// 		margin-left: 10px;
// 		display: inline-block;
// 		overflow: hidden;
// 		color: #aaa;
// 	}
// `

export { PlayPauseControl, VolumeControl, VideoRepeatControl, FullScreenControl, DurationLabel }
