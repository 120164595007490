/* **************************************
LIBRARIES
************************************** */

import * as types from '../constants'

function users(state = {}, action = {}) {
	switch (action.type) {
		case types.LOAD_ADMIN_USERS_SUCCESS:
			if (action.payload && action.payload.entities && action.payload.entities.users) {
				return action.payload.entities.users //Object.assign({}, state, action.payload.entities.users);
			}

		default:
			return state
	}
}

export default users
