import { useState, useEffect } from 'react'
import { getBrowserAgent } from '@vidday/utils'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { browserName, osName } from 'react-device-detect'

const useBrowserAgent = (initialValue = null) => {
	// Host value of the current User OS
	const [browserAgent, setBrowserAgent] = useState(initialValue)
	const dispatch = useDispatch()
	const browserAgentRedux = useSelector((s) => s.environment.browserAgent, shallowEqual)

	// Update OS when mounting component
	useEffect(() => {
		setBrowserAgent(browserName)
		if (browserName != browserAgentRedux) {
			dispatch({ type: 'SET_BROWSER_AGENT', payload: `${browserName} - ${osName}` })
		}
	}, [browserName, osName])

	return browserAgent
}

export default useBrowserAgent
