import { useColorMode } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const Button = {
	baseStyle: {
		borderRadius: 'full',
		fontWeight: 'semibold', // Normally, it is "semibold"
		// bg: 'link',
		// _hover: {
		// 	bg: 'blue.700',
		// },
	},
	sizes: {
		xlg: {
			fontSize: '19px',
			h: 16,
			minW: 16,
			px: 8,
		},
		lg: {
			fontSize: '18px',
			h: '3.25rem',
		},
		md: {
			fontSize: '16px',
			h: '3rem',
		},
		sm: {
			fontSize: '14px',
			h: '2.75rem',
		},
		xs: {
			fontSize: '12px',
			h: 9,
		},
	},
	variants: {
		solid: {},
		outline: (p) => {
			return {
				color: p.colorScheme ? `${p.colorScheme}.600` : p.color ? p.color : 'link',
				borderColor: p.colorScheme ? `${p.colorScheme}.600` : 'link',
			}
		},
		ghost: {
			color: 'link',
			minWidth: '0',
			backgroundColor: 'transparent',
		},
		link: {
			fontWeight: 'bold',
			color: 'link',
		},
		invert: {
			bg: 'white',
			color: 'link',
		},
		cashContributionButton: (p) => {
			let baseStyles = {
				border: '1px solid var(--vidday-colors-link)',
				borderRadius: 'full',
				h: '32px',
				minW: '78px',
				outline: '0',
				fontWeight: '600',
				color: 'link',
				backgroundColor: 'white',
				fontSize: '0.875rem',
				_hover: {
					cursor: 'pointer',
				},
			}

			if (p.isLighten) {
				baseStyles = {
					...baseStyles,
					...{
						borderColor: 'gray.400',
						color: 'gray.400',
						backgroundColor: 'white',
						_hover: {
							cursor: 'pointer',
							borderColor: 'link',
							color: 'link',
						},
					},
				}
			}

			if (p.isActive) {
				baseStyles = {
					...baseStyles,
					...{
						borderColor: 'link',
						color: '#FFFFFF',
						backgroundColor: 'link',
						_hover: {
							cursor: 'default',
						},
					},
				}
			}

			return baseStyles
		},
		/**
		 * IconButton with light blue bg
		 */
		iconCta: (props) => {
			return {
				width: '2.5rem',
				height: '2.5rem',
				backgroundColor: mode('blueAlpha.200', 'whiteAlpha.200')(props),
				_hover: {
					backgroundColor: mode('blueAlpha.300', 'whiteAlpha.300')(props),
				},
				svg: {
					color: mode('link', 'white')(props),
					fill: mode('link', 'white')(props),
				},
			}
		},

		/**
		 * IconButton with white bg
		 */
		iconWhite: () => {
			return {
				'width': '2.5rem',
				'height': '2.5rem',

				'backgroundColor': 'whiteAlpha.800',
				'&:hover': {
					backgroundColor: 'white',
				},
				'svg': {
					fill: 'link',
				},
			}
		},
		/**
		 * IconButton with light border, used for menu
		 */
		iconMenu: () => {
			return {
				'width': '2.5rem',
				'backgroundColor': 'white',
				'boxShadow': 'base',
				'&:hover': {
					backgroundColor: 'rgba(205, 231, 255, 0.8)',
				},
				'svg': {
					fill: 'link',
				},
			}
		},

		/**
		 * IconButton with light border, used for menu
		 */
		pageItem: (p) => {
			return {
				'width': '2rem',
				'maxW': '2rem',
				'minW': '2rem',
				'height': '2rem',
				'backgroundColor': p.isActive ? 'link' : 'transparent',
				'boxShadow': p.isActive ? 'base' : 'none',
				'color': p.isActive ? 'white' : '#47596A',
				'&:hover': {
					backgroundColor: p.isActive ? 'link' : 'rgba(205, 231, 255, 0.8)',
				},
			}
		},
		playButton: () => {
			return {
				backgroundColor: 'white',
				color: 'link',
			}
		},

		impersonateButton: () => {
			return {
				backgroundColor: 'white',
				color: 'pink.500',
				px: '0.75rem',
			}
		},

		pageArrow: (p) => {
			return {
				'width': '2rem',
				'maxW': '2rem',
				'minW': '2rem',
				'height': '2rem',
				'borderRadius': '25px',
				'lineHeight': '0.5rem',
				'backgroundColor': p.theme == 'dark' ? 'whiteAlpha.300' : 'gray.100',
				'&:hover': {
					backgroundColor: p.theme == 'dark' ? 'whiteAlpha.400' : 'blackAlpha.300',
				},
				'svg': {
					verticalAlign: 'middle',
					fill: p.theme == 'dark' ? 'white' : 'gray.600',
					w: '1rem',
					h: '1rem',
				},
			}
		},
		control: (props) => {
			return {
				display: 'flex',
				flexDirection: 'column',
				borderRadius: 'md',
				backgroundColor: mode(props.bg || 'gray.100', props.bg || 'whiteAlpha.200')(props),
				color: mode(props.color || 'link', props.color || 'whiteAlpha.600')(props),
				fontSize: '0.725rem',
				// px:'0.5rem',
				// fontWeight:'bold',
				h: '60px',
				w: ['48%', '5.25rem'],
				minW: ['4.5rem', '5.25rem'],

				svg: {
					fill: mode(props.color || 'link', props.color || 'black.Alpha.700')(props),
				},
				_hover: {
					backgroundColor: mode(props.bg ? 'whiteAlpha.400' : 'gray.200', 'whiteAlpha.400')(props),
					color: mode(props.color || 'link', props.color || 'white')(props),
					svg: {
						fill: mode(props.color || 'link', props.color || 'white')(props),
					},
				},
			}
		},
		mediaButton: (p) => {
			const color =
				p.colorScheme && p.colorScheme == 'red'
					? {
							'backgroundColor': 'pink.500',
							'svg': {
								fill: 'white',
								path: {
									fill: 'white',
								},
							},
							'&:hover svg': {
								fill: 'link',
								path: {
									fill: 'link',
								},
							},
							// eslint-disable-next-line no-mixed-spaces-and-tabs
					  }
					: {
							svg: {
								fill: 'link',
							},
					  }

			return {
				mb: '0.5rem',
				w: '4rem',
				h: '4rem',
				boxShadow: 'base',
				_hover: {
					backgroundColor: 'gray.100',
				},
				...color,
			}
		},
		mediaGalleryButton: (p) => {
			const red =
				p.colorScheme && p.colorScheme == 'red'
					? {
							'color': 'pink.500',
							'svg': {
								fill: 'pink.500',
							},
							'&:hover svg': {
								fill: 'link',
							},
							// eslint-disable-next-line no-mixed-spaces-and-tabs
					  }
					: {}

			return {
				backgroundColor: 'gray.100',
				borderRadius: 'md',
				h: '2rem',
				maxH: '2rem',
				// boxShadow: 'base',
				fontSize: '0.875rem',
				color: 'link',
				px: ['0.5rem', '1rem'],
				_hover: {
					backgroundColor: 'gray.200',
				},
				// color: 'link',
				svg: {
					w: '20px',
					fill: 'link',
				},
				...red,
			}
		},
		download: (p) => {
			const red =
				p.colorScheme && p.colorScheme == 'red'
					? {
							'color': 'pink.500',
							'svg': {
								fill: 'pink.500',
							},
							'&:hover svg': {
								fill: 'link',
							},
							// eslint-disable-next-line no-mixed-spaces-and-tabs
					  }
					: {}

			return {
				backgroundColor: 'gray.100',
				borderRadius: 'md',
				h: '3rem',
				maxH: '3rem',
				fontSize: '0.875rem',
				color: 'link',
				px: '1rem',
				_hover: {
					cursor: 'pointer',
					backgroundColor: 'gray.200',
				},
				svg: {
					w: '20px',
					fill: 'link',
				},
				...red,
			}
		},
		solidWhite: () => {
			return {
				backgroundColor: 'white',
				color: 'link',
			}
		},
	},

	defaultProps: {
		colorScheme: 'blue',
	},
}

export default Button
