const Divider = {
	baseStyle: {
		borderColor: 'gray.300',
	},
	variants: {
		dashed: {
			borderStyle: 'dashed',
		},
	},
	defaultProps: {},
}

export default Divider
