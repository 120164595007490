export default {
	hide: -1,
	auto: 'auto',
	base: 0,
	docked: 10,
	dropdown: 1000,
	sticky: 1100,
	banner: 1200,
	overlay: 1300,
	modal: 2147483640, // in front of Live Chat //2147483639
	popover: 1500,
	skipLink: 1600,
	toast: 2147483641, // in front of Modal //2147483640
	tooltip: 1800,
}
