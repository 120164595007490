/* **************************************
 *  LIBRARIES
 * ************************************ */

import { combineReducers } from 'redux'
import findIndex from 'lodash/findIndex'

/* **************************************
 *  PROJECT DEPENDENCIES
 * ************************************ */

import * as types from '../../constants'
import { TRACKS } from '../../media/constants'

const initialState = {
	isFetching: true,
}

// Events
const events = (state = { ...initialState, entities: [] }, action = {}) => {
	const updatedAddMedia = () => {
		let updatedEventObjectId = action?.payload?.data?.eventId
		let updatedMediaObject = action?.payload?.data
		let media = state.entities[updatedEventObjectId]?.media

		if (!media) media = []

		if (
			action?.payload?.data?.eventId &&
			updatedMediaObject?.isCoverPhoto != true &&
			updatedMediaObject?.isThemeCover != true
		) {
			const index = media?.findIndex((el) => el.id == updatedMediaObject.id)
			/** If the media isn't here yet, and is not a cover photo */
			if (index == -1) {
				/** We're adding to collection */
				media.push(updatedMediaObject)
			} else if (index != -1 && media) {
				/** If the media already exist, and is not a cover photo */
				/** We're merging to collection */
				let existingData = media[index]
				media[index] = { ...existingData, ...updatedMediaObject }
			}
		}
		return media
	}

	const deleteMedia = () => {
		let deletedEventObjectId = action?.payload?.data?.eventId
		let updatedMediaObject = action?.payload?.data
		let media = state.entities[deletedEventObjectId]?.media

		if (!media) media = []

		if (action?.payload?.data.eventId && updatedMediaObject?.isCoverPhoto != true) {
			const mediaIndex = media?.findIndex((el) => el.id == updatedMediaObject.id)
			if (mediaIndex !== -1) {
				media.splice(mediaIndex, 1)
			}
		}

		return media
	}

	switch (action.type) {
		case types.LOAD_EVENTS_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
				entities: [],
			})

		case types.LOAD_EVENTS_SUCCESS:
			var payload = { ...action.payload }
			delete payload.entities
			delete payload.result
			return Object.assign({}, state, {
				isFetching: false,
				...payload,
				entities: action.payload.entities?.events ? action.payload.entities?.events : [],
			})

		// Load in new event
		case types.ADD_EVENT_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				entities: Object.assign({}, state.entities, action.payload.entities?.events),
			})

		// Update existing event
		case types.UPDATE_EVENT_SUCCESS: {
			let updatedEventObjectId = action?.payload?.data?.id

			let currentObject = state.entities[updatedEventObjectId]

			let updatedEventObject = {
				...currentObject,
				...action?.payload?.data,
			}

			return {
				...state,
				entities: {
					...state.entities,
					[updatedEventObjectId]: updatedEventObject,
				},
			}
		}

		// Update existing event Media
		case types.SOCKET_UPDATE_MEDIA:
		case types.PUT_EVENT_MEDIA_SUCCESS:
		case types.POST_EVENT_MEDIA_SUCCESS: {
			let updatedEventObjectId = action?.payload?.data?.eventId

			return {
				...state,
				entities: {
					...state.entities,
					[updatedEventObjectId]: {
						...state.entities[updatedEventObjectId],
						media: updatedAddMedia(),
					},
				},
			}
		}

		// Update existing event Media
		case types.DELETE_MEDIA_SUCCESS: {
			let updatedEventObjectId = action?.payload?.data?.eventId

			return {
				...state,
				entities: {
					...state.entities,
					[updatedEventObjectId]: {
						...state.entities[updatedEventObjectId],
						media: deleteMedia(),
					},
				},
			}
		}

		default:
			return Object.assign({}, state)
	}
}

// Invoices
const invoices = (state = { ...initialState, entities: [] }, action = {}) => {
	switch (action.type) {
		case types.LOAD_INVOICES_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
			})

		case types.LOAD_INVOICES_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				...action.payload,
				entities: action.payload.entities?.invoices ? action.payload.entities?.invoices : [],
			})

		case types.LOAD_EVENTS_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				entities: action.payload.entities?.invoices ? action.payload.entities?.invoices : state.entities || [],
			})

		default:
			return Object.assign({}, state)
	}
}

// Recipients
const recipients = (state = { ...initialState, entities: [] }, action = {}) => {
	switch (action.type) {
		case types.LOAD_EVENTS_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
			})

		case types.LOAD_EVENTS_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				...action.payload,
				entities: action.payload.entities?.recipients ? action.payload.entities?.recipients : state.entities,
			})

		case types.ADD_EVENT_SUCCESS:
			if (action.payload.entities && action.payload.entities?.recipients) {
				return Object.assign({}, state, {
					isFetching: false,
					entities: Object.assign({}, state.entities, action.payload.entities?.recipients),
				})
			} else {
				return Object.assign({}, state)
			}

		default:
			return Object.assign({}, state)
	}
}

// Media
const medias = (state = { ...initialState, entities: [] }, action = {}) => {
	switch (action.type) {
		case types.LOAD_USER_MEDIAS_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
			})

		case types.LOAD_USER_MEDIAS_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				entities: action.payload.entities.medias ? action.payload.entities?.medias : [],
			})

		default:
			return Object.assign({}, state)
	}
}

// Announcements
const announcements = (state = { ...initialState, entities: [] }, action = {}) => {
	switch (action.type) {
		case types.LOAD_ACTIVE_ANNOUNCEMENTS_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
			})

		case types.LOAD_ACTIVE_ANNOUNCEMENTS_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				entities: action.payload.entities?.announcements ? action.payload.entities?.announcements : [],
			})

		default:
			return Object.assign({}, state)
	}
}

// Promo Codes
const promotions = (state = { ...initialState, valid: false, code: {}, message: '' }, action = {}) => {
	switch (action.type) {
		case types.LOAD_PROMOTION_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
			})

		case types.LOAD_PROMOTION_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				...action.payload,
			})
		case types.LOAD_PROMOTION_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				...action.payload,
			})
		case types.REMOVE_PROMOTION_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				...action.payload,
			})

		default:
			return state
	}
}

// User Preferences
const preferences = (state = { ...initialState }, action = {}) => {
	switch (
		action.type // Preferences gets returned through the user fetch request
	) {
		case types.LOAD_PREFERENCES_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
			})

		case types.LOAD_PREFERENCES_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				...action.payload.data,
			})
		// Profile gets returned through the user fetch request
		case types.UPDATE_PREFERENCES_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				...action.payload.data,
			})

		default:
			return Object.assign({}, state, {
				isFetching: false,
				...state,
			})
	}
}

// Custom track uploads
const tracks = (state = { ...initialState, entities: [] }, action = {}) => {
	switch (action.type) {
		case TRACKS.FETCH_REQUESTED:
			return Object.assign({}, state, {
				isFetching: true,
				entities: [],
			})

		case TRACKS.FETCH_SUCCEEDED:
			return Object.assign({}, state, {
				isFetching: false,
				...action.payload,
			})

		case TRACKS.FETCH_FAILED:
			return Object.assign({}, state, {
				isFetching: false,
				...action.payload,
			})

		case TRACKS.POST_SUCCEEDED:
			return Object.assign({}, state, {
				isFetching: false,
				entities: [...state.entities, action.payload],
			})

		case TRACKS.DELETE_SUCCEEDED:
			const index = findIndex(state.entities, { id: action.payload.id })
			if (index >= 0) {
				return Object.assign({}, state, {
					isFetching: false,
					entities: [...state.entities.slice(0, index), ...state.entities.slice(index + 1)],
				})
			}

		default:
			return state
	}
}

const reducer = combineReducers({
	tracks,
	events,
	invoices,
	medias,
	recipients,
	preferences,
	announcements,
	promotions,
})

export default reducer
