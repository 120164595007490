const Modal = {
	parts: ['overlay', 'dialogContainer', 'dialog', 'header', 'closeButton', 'body', 'footer'],
	baseStyle: {
		overlay: {
			zIndex: 2147483640,
			bg: 'blackAlpha.700',
			backdropFilter: 'blur(10px)',
		},
		dialogContainer: {
			zIndex: 2147483640,
		},
		dialog: {
			borderRadius: '0.5rem',
			p: '2rem',
			mx: ['0', '1rem', 'auto'],
		},
		header: {},
		closeButton: {
			zIndex: 2147483640,
			right: '1rem',
			top: '1rem',
			color: 'gray.900',
		},
		body: {
			p: '0',
		},
		footer: {
			pt: '2rem',
			pb: '0',
		},
	},
	sizes: {},
	variants: {
		confetti: {
			dialog: {
				_before: {
					position: 'absolute',
					content: "''",
					display: 'block',
					top: '-70px',
					left: '50%',
					transform: 'translate(-50%, 0)',
					width: '100%',
					height: '70px',
					background: 'url(/assets/images/bg/modal-confetti.svg) no-repeat center',
				},
				_after: {
					position: 'absolute',
					content: "''",
					display: 'block',
					bottom: '-70px',
					left: '50%',
					transform: 'translate(-50%, 0) rotate(180deg)',
					width: '100%',
					height: '70px',
					background: 'url(/assets/images/bg/modal-confetti.svg) no-repeat center',
				},
			},
		},
	},
	defaultProps: {
		size: 'lg',
	},
}

export default Modal
