import React, { createContext, useContext, useState, useEffect } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { useAuthContext } from './AuthProvider'
import { useEventContext } from './EventProvider'
import { history } from '../../history'

/** Build Current Event Context */
const RoleContext = createContext()

const determineRole = ({ isOwner, isAdmin, isImpersonated, privateKey, event, location }) => {
	let role = 'public'

	if (privateKey && event && privateKey == event.privateKey) {
		//&& privateKey == event.privateKey
		role = 'recipient'
	} else if (location && location.state && location.state.presentationRole == 'public') {
		/** This need to happen before checking for admin otherwise cannot set public first! */
		role = 'public'
	} else if ((isAdmin && isImpersonated) || isOwner) {
		role = 'creator'
	} else {
		role = 'public'
	}
	return role
}

/** Define Current Event Context Provider  */
const RoleProvider = ({ value, children }) => {
	const { isAuthenticated, isAdmin, isImpersonated } = useAuthContext()
	const { event, isOwner } = useEventContext()

	// const [eventPrivateKey] = useState(event?.privateKey)

	/**
	 * Retrieve private key from current location
	 * */
	const location = useLocation()
	const [searchQuery] = useSearchParams()
	const privateKey = searchQuery.get('v')

	const [role, setRole] = useState(determineRole({ isAdmin, isOwner, isImpersonated, privateKey, event, location }))
	useEffect(() => {
		setRole(determineRole({ isAdmin, isOwner, isImpersonated, privateKey, event, location }))
	}, [isAdmin, isImpersonated, event, isOwner, privateKey])

	/**
	 * Handler to set the role manyally,
	 * useful for admin trying to check a view
	 * for each admissible role.
	 * This method can only be called as an admin,
	 * therefore, the current event will always contain
	 * the privateKey.
	 */
	const handleSetRole = (r) => {
		if (['creator', 'recipient', 'public'].includes(r)) {
			if (r == 'recipient') {
				/**
				 * Check if we have a private key available.
				 * If we don't have a private key in the search params
				 * */
				if (!privateKey) {
					if (isAuthenticated && isOwner) {
						history.push(`${location.pathname}?v=${event.privateKey}`)
					} else history.push(location.pathname)
				}
			} else if (r == 'public') {
				/**
				 * Remove the private key if showing public,
				 * and use navigation state to ensure public
				 * comes first before admin auth
				 * */
				if (privateKey) {
					history.push(location.pathname, { presentationRole: 'public' })
				}
			} else if (r == 'creator') {
				/** Remove any params if any by navigating to the pathname only */
				history.push(location.pathname)
			}

			setRole(r)
		}
	}

	let state = {
		role: role,
		setRole: isAdmin ? handleSetRole : undefined,
	}

	return <RoleContext.Provider value={state}>{children}</RoleContext.Provider>
}

export default RoleProvider

export const useRoleContext = () => useContext(RoleContext)
