import React from 'react'
import { Box } from '@chakra-ui/react'
import AnimatedLoader from './AnimatedLoader'

const getWrapperStyles = ({ size, noMargin, fullscreen }) => {
	const fullscreenStyles = fullscreen
		? {
				minHeight: 'calc(100vh - 80px)',
				minW: '100vw',
		  }
		: {}

	return {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		minH: noMargin ? 'auto' : `${size * 1.25}px`,
		transition: 'all 300ms ease-in-out 0s',
		svg: {
			margin: 'auto',
			width: `${size}px`,
			height: `${size}px`,
		},
		...fullscreenStyles,
	}
}

const FancyLoader = ({ size, noMargin, fullscreen, ...rest }) => {
	const styles = getWrapperStyles({ size, noMargin, fullscreen })

	return (
		<Box sx={styles} {...rest}>
			<AnimatedLoader />
		</Box>
	)
}

FancyLoader.defaultProps = {
	size: 120,
	fullscreen: true,
}

export default FancyLoader
