import React, { useEffect, useState, useRef, useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { updateTrimmerStateToStore } from './actions'
import Player from './components/Player'
/** Hooks */
import useEventListener from '../../../hooks/useEventListener'
import { useAuthContext } from '../../../contexts/AuthProvider'
import { Box, Spinner } from '@chakra-ui/react'

const videoEditorContainerStyles = {
	w: '100%',
	margin: 'auto',
	pos: 'relative',
	overflow: 'hidden',
	borderRadius: ['0', 'sm'],
}

const ViddayVideoPlayer = (props) => {
	/* **************************************
	 *  State of the Trimmer Component
	 * ************************************ */

	const [play, setPlay] = useState(props.play || false)
	const [playing, setPlaying] = useState(props.playing || false)
	const [mute, setMute] = useState(props.muted || false)
	const [loop, setLoop] = useState(false)
	const [hide, setHide] = useState(props.hide ? props.hide : props.hideControlsOnLoad || false)
	const [hideOverride, setHideOverride] = useState(false)
	const [timeRange, setTimeRange] = useState({
		start: props.timeRange.start || 0,
		end:
			props.timeRange.end && props.timeRange.end > props.duration
				? props.duration
				: props.timeRange.end || props.duration || 0,
	})
	const [duration, setDuration] = useState(props.duration || 0)
	const [currentTime, setCurrentTime] = useState(props.timeRange.start || 0)
	const [ready, setReady] = useState(false)
	const [fullScreen, setFullScreen] = useState(props.fullScreen || false)

	const dispatch = useDispatch()
	const { browserAgent } = useSelector((s) => s.environment, shallowEqual)
	const { isAdmin } = useAuthContext()

	useEffect(() => {
		setMute(props.muted)
	}, [props.muted])
	/* **************************************
	 *  Manage Handlers
	 * ************************************ */
	const handlePlayerReady = () => {
		// console.log('Player READY', browserAgent)
		setReady(true)
		if (!browserAgent?.includes('Safari') && props.autoplay && !play && !playing) {
			// console.log("AUTOPLAY")
			setPlay(true)
		}
	}

	const handleOnTrim = (data) => {
		setTimeRange({
			start: data.start,
			end: data.end,
		})
		setCurrentTime(data.current)
		// update Redux
		let newState = {
			timeRange: {
				start: data.start,
				end: data.end,
			},
			duration: data.duration || duration,
		}
		dispatch(updateTrimmerStateToStore(newState))
	}

	// call to hide player-controls
	const handleHideControls = () => {
		if (!props.thankYouVideo && !props.hide && !hide && playing) {
			setHide(true)
			// if (hideLock != true) {
			// }
		}
	}
	// call to LOCK hide player-controls
	const handleHideOverrideControls = () => {
		setHideOverride(false)
	}

	// call to unhide player-controls
	const handleUnhideControls = () => {
		if (!props.hide) {
			setHide(false)
		}
	}

	// call to LOCK unhide player-controls
	const handleUnhideOverrideControls = () => {
		setHideOverride(true)
	}

	// call to toggle hide/unhide
	const handleHideUnhideControls = () => {
		setHide(!hide)
	}

	// call to toggle play/pause
	const handlePlayPauseVideo = () => {
		setPlay(!play)
	}

	// Delay the hide boolean
	const delayHideControls = () => {
		var timer
		if (hide == false) {
			timer = setTimeout(() => {
				setHide(true)
			}, 2000)
		} else {
			clearTimeout(timer)
		}
	}
	// call to show player-controls on mouse movement while video is playing
	const handleMouseMove = () => {
		if (play) {
			handleUnhideControls()
			delayHideControls()
		}
	}

	// call to toggle mute/unmute
	const handleMuteUnmuteVideo = () => {
		setMute(!mute)
	}

	// call to toggle mute
	const handleMuteVideo = () => {
		setMute(true)
	}

	// call to toggle unmute
	const handleUnmuteVideo = () => {
		mute && setMute(false)
	}

	// call to toggle video-repeat on/off
	const handleVideoLoop = () => {
		setLoop(!loop)
	}

	// call to toggle full-screen on/off
	const handleFullScreenChange = (isFullscreen) => {
		setFullScreen(isFullscreen)
	}

	// call to pause
	const handlePlayerPause = () => {
		setPlay(false)
	}

	// call to play
	const handlePlayerPlay = () => {
		setPlay(true)
	}

	// is the video playing
	const handlePlayerPlaying = (playing) => {
		setPlaying(playing)
	}

	const handlePlayerEnded = () => {
		handlePlayerPause()
	}

	const handlePlayerDuration = (duration) => {
		// Set the end if it's not yet set.
		// OR timerange END can not exceed duration so make sure of that
		if (timeRange.end == 0 /*|| timeRange.end > duration*/) {
			handleOnTrim({ start: timeRange.start, end: duration, duration })
		}
		setDuration(duration)
	}

	const handleTimeUpdate = (newCurrentTime) => {
		setCurrentTime(newCurrentTime)
	}

	const handleCurrentPositionTimeChange = (newCurrentTime) => {
		setCurrentTime(newCurrentTime)
	}

	const keymap = useRef()
	// KeyBoard events
	const handleKeyDown = (event) => {
		keymap.current = { ...keymap.current, [event.code]: true }

		//console.log("DOWNS",keymap.current)
		var SPACE_KEY = 'Space' // play/pause toggle
		var RIGHT_ARROW_KEY = 'ArrowRight' // jump forward 3 sec
		var LEFT_ARROW_KEY = 'ArrowLeft' // jump backwards 3 sec
		var UP_ARROW_KEY = 'ArrowUp' // jump to end
		var DOWN_ARROW_KEY = 'ArrowDown' // jump to start
		var M_KEY = 'KeyM' // mute toggle
		var F_KEY = 'KeyF' // fullscreen
		var META_LEFT = 'MetaLeft'
		var META_RIGHT = 'MetaRight'

		// Prevent default for hot keys
		if (
			event.code == SPACE_KEY ||
			event.code == RIGHT_ARROW_KEY ||
			event.code == LEFT_ARROW_KEY ||
			event.code == UP_ARROW_KEY ||
			event.code == DOWN_ARROW_KEY
		) {
			event.preventDefault()
		}

		// Play Pause Toggle
		if (keymap.current?.[SPACE_KEY]) {
			handlePlayPauseVideo(keymap.current)
		}
		// Play Pause Toggle Skip Ahead
		if (keymap.current?.[RIGHT_ARROW_KEY] && (keymap.current?.[META_LEFT] || keymap.current?.[META_RIGHT])) {
			let FF = currentTime + 2.5
			if (FF > timeRange.end) FF = timeRange.end
			handleCurrentPositionTimeChange(FF)
		}
		// Play Pause Toggle Skip Back
		if (keymap.current?.[LEFT_ARROW_KEY] && (keymap.current?.[META_LEFT] || keymap.current?.[META_RIGHT])) {
			let RW = currentTime - 2.5
			if (RW < timeRange.start) RW = timeRange.start
			handleCurrentPositionTimeChange(RW)
		}
		// Play Pause Toggle Skip to End
		if (keymap.current?.[UP_ARROW_KEY]) {
			let END = timeRange.end - 1
			handleCurrentPositionTimeChange(END)
		}
		// Play Pause Toggle Skip to Start
		if (keymap.current?.[DOWN_ARROW_KEY]) {
			let START = timeRange.start + 0.00000000000001
			handleCurrentPositionTimeChange(START)
		}

		if (keymap.current?.[M_KEY]) {
			if (props.onMuteAudio) {
				props.onMuteAudio()
			}
		}

		if (keymap.current?.[F_KEY]) {
			setFullScreen(true)
		}
	}

	const handleKeyUp = (event) => {
		let obj = keymap.current
		if (obj) delete obj[event.code]
		keymap.current = obj
		if (event.code == ('MetaLeft' || 'MetaRight')) keymap.current = {} // Clear KEYMAP
		//console.log("UPS",keymap.current)
	}

	// Set up listeners
	if (typeof window !== 'undefined') {
		useEventListener('keydown', handleKeyDown, !props.thankYouVideo && window)
		useEventListener('keyup', handleKeyUp, window)
	}

	// Load Google Cast library
	useEffect(() => {
		if (document.getElementById('castScript') === null) {
			var script = document.createElement('script')
			script.id = 'castScript'
			script.src = `https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1`
			script.async = true
			document.body.appendChild(script)
		}
	}, [])

	useEffect(() => {
		if (ready && play && duration > 0) {
			// Reset Player to start position
			if (currentTime >= timeRange.end) {
				setCurrentTime(timeRange.start) // set new time to seekto
				if (!playing) {
					setPlay(true)
				}
			}
		}
	}, [play])

	useEffect(() => {
		if (playing && !isAdmin) {
			handleHideOverrideControls()
			delayHideControls()
		}
		if (!playing /*&& !props.hideControlsOnLoad*/) {
			handleUnhideOverrideControls()
			handleUnhideControls()
		}
	}, [playing])

	if (props.sources[0].src != null) {
		return (
			<>
				{props.nativePlayer ? (
					<video
						ref={props.videoRef}
						id="video"
						preload="auto"
						controlsList={['nodownload']}
						width={'100%'}
						autoPlay={props.thankYouVideo || false}
						playsInline
						controls={true}
						poster={props.posterImage}
						onEnded={props.thankYouVideo && props.onEnded}
						onError={(error) => console.error(error)}
						onContextMenu={(e) => {
							e.preventDefault()
						}}>
						<source src={props.sources[0].src} type={props.sources[0].type} />
					</video>
				) : (
					<Box
						sx={videoEditorContainerStyles}
						maxW={props.fullWidth ? `100%` : `720px`}
						pos={props.position}
						id="videoContainer">
						<Box backgroundColor={fullScreen ? '#000' : '#f6f6f8'}>
							<Player
								nativeControls={props.nativeControls}
								css={props.css}
								poster={props.hidePoster ? '' : props.posterImage}
								sources={props.sources}
								key={props.key}
								removeFadeIn={props.removeFadeIn}
								showTrimmer={props.showTrimmer}
								browserAgent={browserAgent}
								currentRotation={props.currentRotation}
								onEnded={props.onEnded}
								height={props.height}
								volume={props.volume}
								maxHeight={props.maxHeight}
								videoRef={props.videoRef}
								hideLoader={props.hideLoader || false}
								hideVolume={props.hideVolume || false}
								hideLoop={props.hideLoop || false}
								hidePlayIcon={props.hidePlayIcon || false}
								hidePlayIconOnLoad={props.hidePlayIconOnLoad || false}
								hideFullScreen={props.hideFullScreen || false}
								playsinline={true}
								play={play}
								playing={playing}
								canPlay={ready}
								muted={mute}
								timeRange={timeRange}
								setTimeRange={setTimeRange}
								currentTime={currentTime}
								duration={duration}
								setDuration={setDuration}
								loop={loop}
								fullScreen={fullScreen}
								onPlayerReady={handlePlayerReady}
								onPlayerPlay={handlePlayerPlay}
								onPlayerPause={handlePlayerPause}
								onPlayerPlaying={handlePlayerPlaying}
								// onPlayerSeeking={handleTimeUpdate}
								onPlayerTimeUpdate={handleTimeUpdate}
								onPlayerDuration={handlePlayerDuration}
								onPlayerEnded={handlePlayerEnded}
								onCurrentPositionTimeChange={handleCurrentPositionTimeChange}
								hide={hide}
								hideOverride={hideOverride}
								onHideControls={handleHideControls}
								onHideOverrideControls={handleHideOverrideControls}
								onUnhideControls={handleUnhideControls}
								onUnhideOverrideControls={handleUnhideOverrideControls}
								onHideUnhideControls={handleHideUnhideControls}
								// onMouseMove={handleMouseMove}
								onMuteUnmuteClick={handleMuteUnmuteVideo}
								onMuteVideo={handleMuteVideo}
								onUnmuteVideo={handleUnmuteVideo}
								onPlayPauseClick={handlePlayPauseVideo}
								onRepeatOnOffClick={handleVideoLoop}
								onPlayerFullScreenChange={handleFullScreenChange}
								onTrim={handleOnTrim}
								thankYouVideo={props.thankYouVideo || false}
							/>
						</Box>
					</Box>
				)}
			</>
		)
	} else {
		;<Spinner color="blue.600" />
	}
}

ViddayVideoPlayer.defaultProps = {
	currentRotation: null,
	timeRange: { start: null, end: null },
	ready: false,
}

export default ViddayVideoPlayer
