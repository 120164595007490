import { call, put, takeEvery } from 'redux-saga/effects'
import requests from './requests'
import { RSVP, MAIL, AUTH, MEDIA, PUBLISHED_MEDIA } from './constants'

/** Saga to handle creation of RSVP for a user */
function* handlePostRSVP(action) {
	let muteAlert = action.payload.muteAlert || false
	delete action.payload.muteAlert
	try {
		var user = {
			email: action.payload.deliveryEmail,
			profile: {
				firstName: action.payload.firstName,
				sms: action.payload.deliverySms,
			},
		}
		const response = yield call(requests.postRSVP, action.payload)
		yield put({ type: RSVP.POST_SUCCEEDED, payload: { data: { ...response.data.data, user: user } } })
		if (!muteAlert) {
			yield put({
				type: 'ADD_FLASH_MESSAGE',
				status: 'success',
				message: action.payload.notifyInvite
					? `Invitation sent to ${action.payload.deliveryEmail || action.payload.deliverySms}`
					: 'Added to participant list',
			})
		}
	} catch (e) {
		if (!muteAlert) {
			yield put({
				type: 'ADD_FLASH_MESSAGE',
				status: 'error',
				message: e.message,
			})
		}
		yield put({ type: RSVP.POST_FAILED, payload: e })
	}
}

export function* watchPostRSVP() {
	// while(true){
	yield takeEvery(RSVP.POST_REQUESTED, handlePostRSVP)
	// }
}

/** Saga to handle creation of RSVP for a user */
function* handleUpdateRSVP(action) {
	try {
		const response = yield call(requests.updateRSVP, action.payload)
		yield put({ type: RSVP.UPDATE_SUCCEEDED, payload: { data: response.data.data } })
	} catch (e) {
		yield put({ type: RSVP.UPDATE_FAILED, payload: e })
	}
}
export function* watchUpdateRSVP() {
	// while(true){
	yield takeEvery(RSVP.UPDATE_REQUESTED, handleUpdateRSVP)
	// }
}

/** MAILERS */

/** Saga to handle sending of Invitation to a user */
function* handleSendInvite(action) {
	try {
		const response = yield call(requests.postInvite, action.payload)
		yield put({ type: RSVP.POST_INVITE_SUCCEEDED, payload: { data: response.data.data } })
		yield put({ type: 'ADD_FLASH_MESSAGE', status: 'success', message: 'Invite successfully resent' })
	} catch (e) {
		yield put({ type: RSVP.POST_INVITE_FAILED, payload: e })
		yield put({ type: 'ADD_FLASH_MESSAGE', status: 'error', message: 'Invite failed to resend' })
	}
}
export function* watchSendInvite() {
	// while(true){
	yield takeEvery(RSVP.POST_INVITE_REQUESTED, handleSendInvite)
	// }
}

/** Saga to handle sending of Published email to a user */
function* handleSendPublishedNotice(action) {
	try {
		const response = yield call(requests.postPublishedEmail, action.payload)
		yield put({ type: MAIL.POST_PUBLISHED_SUCCEEDED, payload: { data: response.data.data } })
		yield put({ type: 'ADD_FLASH_MESSAGE', status: 'success', message: 'Email successfully sent' })
	} catch (e) {
		yield put({ type: MAIL.POST_PUBLISHED_FAILED, payload: e })
		yield put({ type: 'ADD_FLASH_MESSAGE', status: 'error', message: 'Email failed to send' })
	}
}
export function* watchSendPublishedNotice() {
	// while(true){
	yield takeEvery(MAIL.POST_PUBLISHED_REQUESTED, handleSendPublishedNotice)
	// }
}

/** Saga to handle verify email address */
function* handleVerifySingleEmail(action) {
	try {
		const response = yield call(requests.verifySingleEmail, action.payload)
		yield put({ type: MAIL.POST_VERIFY_SUCCEEDED, payload: { data: response.data.data } })
		return response.data.data
	} catch (e) {
		yield put({ type: MAIL.POST_VERIFY_FAILED, payload: e })
	}
}
export function* verifySingleEmail() {
	yield takeEvery(MAIL.POST_VERIFY_REQUESTED, handleVerifySingleEmail)
}

/** Saga to handle change primary email response for a user */
function* handleUpdatePrimaryEmailRequest(action) {
	try {
		const response = yield call(requests.updatePrimaryEmailAddress, action.payload)
		yield put({ type: AUTH.PUT_CHANGE_PRIMARY_EMAIL_SUCCEDED, payload: { data: response.data.data } })
		yield put({
			type: 'ADD_FLASH_MESSAGE',
			status: 'success',
			message: `Verification link has been sent to your emailId: ${response.data.data.email}.`,
		})
	} catch (e) {
		yield put({ type: AUTH.PUT_CHANGE_PRIMARY_EMAIL_FAILED, payload: e })
		yield put({
			type: 'ADD_FLASH_MESSAGE',
			status: 'error',
			message: e.response.data.message,
		})
	}
}
export function* watchUpdatePrimaryEmailRequest() {
	yield takeEvery(AUTH.PUT_CHANGE_PRIMARY_EMAIL_REQUESTED, handleUpdatePrimaryEmailRequest)
}
