import React, { createContext, useContext } from 'react'
import { useSelector, shallowEqual, useStore as useReduxStore } from 'react-redux'
import { useParams } from 'react-router'
import { useAuthContext } from './AuthProvider'

/** Build Current Event Context */
const EventContext = createContext()

// const useIsOwner = (event, userId) => {
// 	const [isOwner, setIsOwner] = useState()

// 	console.log(event, event.creatorId, userId, event.creatorId == userId, "RECEIVED IN USEISOWNER", isOwner)

// 	useEffect(() => {
// 		if (userId == event.creatorId) setIsOwner(true)
// 		// else setIsOwner(false)
// 	}, [userId, event])

// 	return isOwner
// }

/** Define Current Event Context Provider  */
const EventProvider = ({ children }) => {
	/** Set initial state rendered by the context */
	// Get the redux store (important for SSR)
	const store = useReduxStore()
	const { uuid } = useParams()
	const userContext = useAuthContext()
	const { userId, role } = userContext

	// console.log('DEVTOOLS => STORE: ', store)
	// Get initial redux state
	const reduxState = store.getState()
	// console.log('DEVTOOLS => STORE => STATE (redux): ', reduxState)
	const event = useSelector((s) => s.event.event, shallowEqual)
	const themeCover = useSelector((s) => s.event.themeCover, shallowEqual)
	// console.log('DEVTOOLS => STORE => Event (redux): ', reduxState)

	const isFetching = useSelector((s) => s.event.isFetching, shallowEqual)

	/** Do not use state here so this is updated directly */
	const isOwner = event.creatorId === userId

	/** HACK DUE TO IMPROPER REDUX STRUCTURE */
	const hackedEvent = event && event.uuid == -1 ? null : event

	/** END HACK */
	const s = {
		event: hackedEvent,
		isOwner,
		isFetching,
		themeCover,
	}

	return <EventContext.Provider value={s}>{children}</EventContext.Provider>
}

export default EventProvider

export const useEventContext = () => useContext(EventContext)
