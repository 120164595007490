import React, { memo } from 'react'
import { useEventContext } from '../contexts/EventProvider'
import loadable from '@loadable/component'
import { Routes, Route, useParams, Navigate } from 'react-router-dom'
import PresentationMode from '../ui/molecules/PresentationMode'
import FancyLoader from '../ui/atoms/FancyLoader'
// import MetaTagsEvent from '../ui/organisms/MetaTagsEvent'
import HandleTemporaryUserParams from '../ui/pages/public/atoms/HandleTemporaryUserParams'
import PublicSocketListeners from '../contexts/PublicSocketListeners'

const Archived = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/public/Archived'))
const Download = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/public/Published/Download'))
const Publishing = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/public/Publishing'))
const Active = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/public/Active'))
// const Marketplace = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/Marketplace'))
const Published = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/public/Published'))
const Keepsake = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/Keepsake'))
const KeepsakeDVD = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/Keepsake/DVD'))
const KeepsakeUSB = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/Keepsake/USB'))
const KeepsakeVideoBook = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/Keepsake/VideoBook'))
const Redeem = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/public/Redeem'))
const UploadThankYouVideo = loadable(() =>
	import(/* webpackPrefetch: true */ '../ui/pages/public/Published/UploadThankYou')
)
const AddRecipient = loadable(() =>
	import(/* webpackPrefetch: true */ '../ui/pages/public/Published/UploadThankYou/AddRecipient')
)

const RenderPublicByStatus = () => {
	const { event } = useEventContext()
	const status = event.status

	let View

	switch (status) {
		case 'active':
			View = <Active />
			break

		case 'abandoned':
		case 'archived':
			View = <Archived />
			break

		// case 'restoring':
		// 	View = <Archived />//<ArchivedRestoring event={event} />
		// 	break

		case 'publishing':
			View = <Publishing />
			break

		// case 'abandoned':
		// 	View = <Abandoned />
		// 	break

		// case 'archived':
		// 	View = <Archived />
		// 	break

		// case 'publishing':
		// 	View = isImpersonated ? <Event event={event} /> : <Publishing />
		// 	break

		case 'published':
			View = <Published />
			break

		default:
			View = <FancyLoader />
	}

	return View
}

const PublicEventRouter = memo(() => {
	return (
		<PresentationMode>
			{/* <MetaTagsEvent /> */}
			<HandleTemporaryUserParams />
			<Routes>
				<Route index element={<RenderPublicByStatus />} />
				<Route path="keepsake/usb" element={<KeepsakeUSB />} />
				<Route path="keepsake/dvd" element={<KeepsakeDVD />} />
				<Route path="keepsake/video-book" element={<KeepsakeVideoBook />} />
				<Route path="keepsake" element={<Keepsake />} />
				<Route path="redeem" element={<Redeem />} />
				<Route path="upload-thankyou" element={<UploadThankYouVideo />} />
				<Route path="upload-thankyou/recipient" element={<AddRecipient />} />
				<Route path="download" element={<Download />} />
				<Route path="*" element={<RenderPublicByStatus />} />
			</Routes>
		</PresentationMode>
	)
})
PublicEventRouter.displayName = 'PublicEventRouter'

/**
 * Route Component to protect access to the Private Event Routes
 * This include all routes under /event/:uuid *
 * @returns
 */
const PublicEventRoute = () => {
	/** Retrieve the event from the state */
	const { event, isOwner, isFetching } = useEventContext()

	const { uuid } = useParams()

	/**
	 * Because of the way the routes are nested,
	 * we can always expect to have an event
	 * and a UUID here available.
	 * */
	if (event && uuid) {
		/**
		 * If we're not fetching an event and the
		 * authed user is the owner of the event,
		 * renders our Event component.
		 */
		if (!isFetching) {
			/** Determine the role */
			if (['active', 'archived', 'abandoned', 'publishing', 'published'].includes(event.status)) {
				//
				return (
					<PublicSocketListeners>
						<PublicEventRouter />
					</PublicSocketListeners>
				)
			} else {
				/**
				 * Otherwise, redirect to the public event page.
				 */
				return <Navigate to={`/${uuid}`} replace />
			}
		}
	}

	return <FancyLoader />
}

export default PublicEventRoute
