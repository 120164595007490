const TRACKS = {
	/** Retrieve User's tracks */
	FETCH_REQUESTED: 'FETCH_TRACKS_REQUESTED',
	FETCH_SUCCEEDED: 'FETCH_TRACKS_SUCCEEDED',
	FETCH_FAILED: 'FETCH_TRACKS_FAILED',

	POST_REQUESTED: 'POST_TRACK_REQUESTED',
	POST_SUCCEEDED: 'POST_TRACK_SUCCEEDED',
	POST_FAILED: 'POST_TRACK_FAILED',

	DELETE_REQUESTED: 'DELETE_TRACK_REQUESTED',
	DELETE_SUCCEEDED: 'DELETE_TRACK_SUCCEEDED',
	DELETE_FAILED: 'DELETE_TRACK_FAILED',
}

const MEDIA = {
	POST_DUPLICATE_MEDIA_REQUESTED: 'POST_DUPLICATE_MEDIA_REQUESTED',
	POST_DUPLICATE_MEDIA_SUCCEEDED: 'POST_DUPLICATE_MEDIA_SUCCEEDED',
	POST_DUPLICATE_MEDIA_FAILED: 'POST_DUPLICATE_MEDIA_FAILED',

	POST_UPSCALE_MEDIA_REQUESTED: 'POST_UPSCALE_MEDIA_REQUESTED',
	POST_UPSCALE_MEDIA_SUCCEEDED: 'POST_UPSCALE_MEDIA_SUCCEEDED',
	POST_UPSCALE_MEDIA_FAILED: 'POST_UPSCALE_MEDIA_FAILED',

	POST_UNARCHIVE_MEDIA_REQUESTED: 'POST_UNARCHIVE_MEDIA_REQUESTED',
	POST_UNARCHIVE_MEDIA_SUCCEEDED: 'POST_UNARCHIVE_MEDIA_SUCCEEDED',
	POST_UNARCHIVE_MEDIA_FAILED: 'POST_UNARCHIVE_MEDIA_FAILED',

	POST_RESTORE_ALL_MEDIA_REQUESTED: 'POST_RESTORE_ALL_MEDIA_REQUESTED',
	POST_RESTORE_ALL_MEDIA_SUCCEEDED: 'POST_RESTORE_ALL_MEDIA_SUCCEEDED',
	POST_RESTORE_ALL_MEDIA_FAILED: 'POST_RESTORE_ALL_MEDIA_FAILED',
}

const PUBLISHED_MEDIA = {
	PUT_RESTORE_PUBLISHED_MEDIA_REQUESTED: 'PUT_RESTORE_PUBLISHED_MEDIA_REQUESTED',
	PUT_RESTORE_PUBLISHED_MEDIA_SUCCEEDED: 'PUT_RESTORE_PUBLISHED_MEDIA_SUCCEEDED',
	PUT_RESTORE_PUBLISHED_MEDIA_FAILED: 'PUT_RESTORE_PUBLISHED_MEDIA_FAILED',
}

export { TRACKS, MEDIA, PUBLISHED_MEDIA }
