import { createSlice } from '@reduxjs/toolkit'
import { PRODUCT } from '../../api/checkout/constants'
/**
 * This reducer correspond to the products available throughout the checkout module.
 * These are not the products contained in the cart, but rather all available options.
 */

const initialState = {
	/** Processing status */
	isFetching: false,
	/** Product requests status */
	status: null, // or error, success
	statusMessage: '',
	/** Cart data */
	data: [],
}

const ProductsSlice = createSlice({
	initialState: initialState,
	name: 'products',
	extraReducers: {
		[PRODUCT.FETCH_REQUESTED]: (state, action) => {
			state.isFetching = true
		},
		[PRODUCT.FETCH_SUCCEEDED]: (state, action) => {
			state.isFetching = false
			/* Map Stripe metadata */
			state.data = action.payload.data.map((product) => {
				// Map 'metadata.group' (String) to 'group' (String)
				product.group = product.metadata.group
				// Map 'metadata.slug' (String) to 'slug' (String)
				product.slug = product.metadata.slug
				// Map 'metadata.duration' (String) to 'duration' (Number)
				if (product.metadata?.duration) {
					product.duration = Number(product.metadata.duration)
				}
				// Map 'pricing.unit_amount' to 'pricing.price'
				product.pricing.price = product.pricing.unit_amount
				return product
			})
		},
		[PRODUCT.FETCH_FAILED]: (state, action) => {
			state.isFetching = false
			state.data = []
			state.status = 'error'
			state.statusMessage = 'An error happened while retrieving products...'
		},
	},
	reducers: {},
})

export default ProductsSlice.reducer

// export const {
// 	setPaymentCharge,
// } = CheckoutSlice.actions
