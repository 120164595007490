const RSVP = {
	POST_REQUESTED: 'POST_RSVP_REQUESTED',
	POST_SUCCEEDED: 'POST_RSVP_SUCCEEDED',
	POST_FAILED: 'POST_RSVP_FAILED',

	UPDATE_REQUESTED: 'UPDATE_RSVP_REQUESTED',
	UPDATE_SUCCEEDED: 'UPDATE_RSVP_SUCCEEDED',
	UPDATE_FAILED: 'UPDATE_RSVP_FAILED',

	POST_INVITE_REQUESTED: 'POST_RSVP_INVITE_REQUESTED',
	POST_INVITE_SUCCEEDED: 'POST_RSVP_INVITE_SUCCEEDED',
	POST_INVITE_FAILED: 'POST_RSVP_INVITE_FAILED',

	POST_REMINDER_REQUESTED: 'POST_RSVP_REMINDER_REQUESTED',
	POST_REMINDER_SUCCEEDED: 'POST_RSVP_REMINDER_SUCCEEDED',
	POST_REMINDER_FAILED: 'POST_RSVP_REMINDER_FAILED',

	SET_FILTER: 'SET_RSVP_FILTER',
}

const MAIL = {
	POST_PUBLISHED_REQUESTED: 'POST_PUBLISHED_REQUESTED',
	POST_PUBLISHED_SUCCEEDED: 'POST_PUBLISHED_SUCCEEDED',
	POST_PUBLISHED_FAILED: 'POST_PUBLISHED_FAILED',

	POST_VERIFY_REQUESTED: 'POST_VERIFY_REQUESTED',
	POST_VERIFY_SUCCEEDED: 'POST_VERIFY_SUCCEEDED',
	POST_VERIFY_FAILED: 'POST_VERIFY_FAILED',
}

const TEMPORARY_USER = {
	SET: 'SET_TEMP_USER',
}

const AUTH = {
	PUT_CHANGE_PRIMARY_EMAIL_REQUESTED: 'PUT_CHANGE_PRIMARY_EMAIL_REQUESTED',
	PUT_CHANGE_PRIMARY_EMAIL_SUCCEDED: 'PUT_CHANGE_PRIMARY_EMAIL_SUCCEDED',
	PUT_CHANGE_PRIMARY_EMAIL_FAILED: 'PUT_CHANGE_PRIMARY_EMAIL_FAILED',
}

export { RSVP, MAIL, TEMPORARY_USER, AUTH }
