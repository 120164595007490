import React from 'react'
import { useColorMode, Button, LightMode, VStack } from '@chakra-ui/react'
import { __DEV__ } from '@chakra-ui/utils'
import { useRoleContext } from '../../../contexts/RoleProvider'
import { DarkMode, GlobalStyle } from '@chakra-ui/react'
import { LightModeRounded, DarkModeRounded } from '../../atoms/Icon'

const PresentationMode = ({ children }) => {
	const { colorMode, toggleColorMode, setColorMode } = useColorMode()
	const { role, setRole } = useRoleContext()

	return (
		<DarkMode>
			<GlobalStyle /> {/* Needed to set global styles, like body bg, to dark during ssr */}
			{children}
			{(__DEV__ || setRole != undefined) && (
				<LightMode>
					<Button
						zIndex="999"
						pos="fixed"
						left="1rem"
						bottom="1rem"
						size="sm"
						variant="mediaGalleryButton"
						onClick={toggleColorMode}
						backgroundColor={colorMode == 'dark' ? 'rgba(255,255,255,0.1)' : 'inherit'}
						boxShadow="md">
						{colorMode == 'light' ? (
							<DarkModeRounded color="inherit" />
						) : (
							<LightModeRounded color="#FFAF2F !important" />
						)}
					</Button>

					{setRole != undefined && (
						<VStack
							zIndex="999"
							spacing="0.5rem"
							pos="fixed"
							left="1rem"
							bottom="4rem"
							p="0.5rem"
							bg="white"
							borderRadius="base"
							boxShadow="md">
							<Button
								size="sm"
								variant="mediaGalleryButton"
								onClick={() => setRole('public')}
								backgroundColor={colorMode == 'dark' ? 'rgba(255,255,255,0.1)' : 'inherit'}>
								Public
							</Button>
							<Button
								size="sm"
								variant="mediaGalleryButton"
								onClick={() => setRole('recipient')}
								backgroundColor={colorMode == 'dark' ? 'rgba(255,255,255,0.1)' : 'inherit'}>
								Recipient
							</Button>
							<Button
								size="sm"
								variant="mediaGalleryButton"
								onClick={() => setRole('creator')}
								backgroundColor={colorMode == 'dark' ? 'rgba(255,255,255,0.1)' : 'inherit'}>
								Creator
							</Button>
						</VStack>
					)}
				</LightMode>
			)}
		</DarkMode>
	)
}

export default PresentationMode
