/* ******************************
 * ADMIN CONSTANTS
 ***************************** */

// Load admin events
export const LOAD_ADMIN_EVENTS_REQUEST = 'LOAD_ADMIN_EVENTS_REQUEST'
export const LOAD_ADMIN_EVENTS_SUCCESS = 'LOAD_ADMIN_EVENTS_SUCCESS'
export const LOAD_ADMIN_EVENTS_FAILURE = 'LOAD_ADMIN_EVENTS_FAILURE'

// Load all invoices
export const LOAD_ADMIN_INVOICES_REQUEST = 'LOAD_ADMIN_INVOICES_REQUEST'
export const LOAD_ADMIN_INVOICES_SUCCESS = 'LOAD_ADMIN_INVOICES_SUCCESS'
export const LOAD_ADMIN_INVOICES_FAILURE = 'LOAD_ADMIN_INVOICES_FAILURE'

// Load all users
export const LOAD_ADMIN_USERS_REQUEST = 'LOAD_ADMIN_USERS_REQUEST'
export const LOAD_ADMIN_USERS_SUCCESS = 'LOAD_ADMIN_USERS_SUCCESS'
export const LOAD_ADMIN_USERS_FAILURE = 'LOAD_ADMIN_USERS_FAILURE'

// Update Single User
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'

// Change user role
export const CHANGE_ROLE_REQUEST = 'CHANGE_ROLE_REQUEST'
export const CHANGE_ROLE_SUCCESS = 'CHANGE_ROLE_SUCCESS'
export const CHANGE_ROLE_FAILURE = 'CHANGE_ROLE_FAILURE'

// Publish / Update Event Status
export const UPLOAD_PUBLISHED_MEDIA_REQUEST = 'UPLOAD_PUBLISHED_MEDIA_REQUEST'
export const UPLOAD_PUBLISHED_MEDIA_SUCCESS = 'UPLOAD_PUBLISHED_MEDIA_SUCCESS'
export const UPLOAD_PUBLISHED_MEDIA_FAILURE = 'UPLOAD_PUBLISHED_MEDIA_FAILURE'

// Publish a Render Job
export const PUBLISH_RENDER_JOB_REQUEST = 'PUBLISH_RENDER_JOB_REQUEST'
export const PUBLISH_RENDER_JOB_SUCCESS = 'PUBLISH_RENDER_JOB_SUCCESS'
export const PUBLISH_RENDER_JOB_FAILURE = 'PUBLISH_RENDER_JOB_FAILURE'

export const UPLOAD_PUBLISHED_MEDIA_PROGRESS = 'UPLOAD_PUBLISHED_MEDIA_PROGRESS'

export const POST_MAIL_PUBLISHED_REQUEST = 'POST_MAIL_PUBLISHED_REQUEST'
export const POST_MAIL_PUBLISHED_SUCCESS = 'POST_MAIL_PUBLISHED_SUCCESS'
export const POST_MAIL_PUBLISHED_FAILURE = 'POST_MAIL_PUBLISHED_FAILURE'

export const UPDATE_EVENT_REQUEST = 'UPDATE_EVENT_REQUEST'
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS'
export const UPDATE_EVENT_FAILURE = 'UPDATE_EVENT_FAILURE'

// Impersonate User
export const IMPERSONATE_USER_REQUEST = 'IMPERSONATE_USER_REQUEST'
export const IMPERSONATE_USER_SUCCESS = 'IMPERSONATE_USER_SUCCESS'
export const IMPERSONATE_USER_FAILURE = 'IMPERSONATE_USER_FAILURE'

// Stop impersonating User
export const UNMASK_REQUEST = 'UNMASK_REQUEST'
export const UNMASK_SUCCESS = 'UNMASK_SUCCESS'
export const UNMASK_FAILURE = 'UNMASK_FAILURE'

// Load promotional Codes
export const LOAD_ADMIN_PROMOTIONS_REQUEST = 'LOAD_ADMIN_PROMOTIONS_REQUEST'
export const LOAD_ADMIN_PROMOTIONS_SUCCESS = 'LOAD_ADMIN_PROMOTIONS_SUCCESS'
export const LOAD_ADMIN_PROMOTIONS_FAILURE = 'LOAD_ADMIN_PROMOTIONS_FAILURE'
// Add promotional Codes
export const ADD_ADMIN_PROMOTION_REQUEST = 'ADD_ADMIN_PROMOTION_REQUEST'
export const ADD_ADMIN_PROMOTION_SUCCESS = 'ADD_ADMIN_PROMOTION_SUCCESS'
export const ADD_ADMIN_PROMOTION_FAILURE = 'ADD_ADMIN_PROMOTION_FAILURE'
// Update promotional Codes
export const UPDATE_ADMIN_PROMOTION_REQUEST = 'UPDATE_ADMIN_PROMOTION_REQUEST'
export const UPDATE_ADMIN_PROMOTION_SUCCESS = 'UPDATE_ADMIN_PROMOTION_SUCCESS'
export const UPDATE_ADMIN_PROMOTION_FAILURE = 'UPDATE_ADMIN_PROMOTION_FAILURE'
// Delete promotional Codes
export const DELETE_ADMIN_PROMOTION_REQUEST = 'DELETE_ADMIN_PROMOTION_REQUEST'
export const DELETE_ADMIN_PROMOTION_SUCCESS = 'DELETE_ADMIN_PROMOTION_SUCCESS'
export const DELETE_ADMIN_PROMOTION_FAILURE = 'DELETE_ADMIN_PROMOTION_FAILURE'

// Load announcements
export const LOAD_ADMIN_ANNOUNCEMENTS_REQUEST = 'LOAD_ADMIN_ANNOUNCEMENTS_REQUEST'
export const LOAD_ADMIN_ANNOUNCEMENTS_SUCCESS = 'LOAD_ADMIN_ANNOUNCEMENTS_SUCCESS'
export const LOAD_ADMIN_ANNOUNCEMENTS_FAILURE = 'LOAD_ADMIN_ANNOUNCEMENTS_FAILURE'
// Add announcement
export const ADD_ADMIN_ANNOUNCEMENT_REQUEST = 'ADD_ADMIN_ANNOUNCEMENT_REQUEST'
export const ADD_ADMIN_ANNOUNCEMENT_SUCCESS = 'ADD_ADMIN_ANNOUNCEMENT_SUCCESS'
export const ADD_ADMIN_ANNOUNCEMENT_FAILURE = 'ADD_ADMIN_ANNOUNCEMENT_FAILURE'
// Update announcement
export const UPDATE_ADMIN_ANNOUNCEMENT_REQUEST = 'UPDATE_ADMIN_ANNOUNCEMENT_REQUEST'
export const UPDATE_ADMIN_ANNOUNCEMENT_SUCCESS = 'UPDATE_ADMIN_ANNOUNCEMENT_SUCCESS'
export const UPDATE_ADMIN_ANNOUNCEMENT_FAILURE = 'UPDATE_ADMIN_ANNOUNCEMENT_FAILURE'
// Delete announcement
export const DELETE_ADMIN_ANNOUNCEMENT_REQUEST = 'DELETE_ADMIN_ANNOUNCEMENT_REQUEST'
export const DELETE_ADMIN_ANNOUNCEMENT_SUCCESS = 'DELETE_ADMIN_ANNOUNCEMENT_SUCCESS'
export const DELETE_ADMIN_ANNOUNCEMENT_FAILURE = 'DELETE_ADMIN_ANNOUNCEMENT_FAILURE'

// Load event render jobs
export const LOAD_ADMIN_RENDER_JOBS_REQUEST = 'LOAD_ADMIN_RENDER_JOBS_REQUEST'
export const LOAD_ADMIN_RENDER_JOBS_SUCCESS = 'LOAD_ADMIN_RENDER_JOBS_SUCCESS'
export const LOAD_ADMIN_RENDER_JOBS_FAILURE = 'LOAD_ADMIN_RENDER_JOBS_FAILURE'

// Compress Vidday Media Package
export const COMPRESS_VIDDAY_PACKAGE_REQUEST = 'COMPRESS_VIDDAY_PACKAGE_REQUEST'
export const COMPRESS_VIDDAY_PACKAGE_SUCCESS = 'COMPRESS_VIDDAY_PACKAGE_SUCCESS'
export const COMPRESS_VIDDAY_PACKAGE_FAILURE = 'COMPRESS_VIDDAY_PACKAGE_FAILURE'
export const COMPRESS_VIDDAY_PACKAGE_PROGRESS = 'COMPRESS_VIDDAY_PACKAGE_PROGRESS'

// Add event render job
export const ADD_ADMIN_RENDER_JOB_REQUEST = 'ADD_ADMIN_RENDER_JOB_REQUEST'
export const ADD_ADMIN_RENDER_JOB_SUCCESS = 'ADD_ADMIN_RENDER_JOB_SUCCESS'
export const ADD_ADMIN_RENDER_JOB_FAILURE = 'ADD_ADMIN_RENDER_JOB_FAILURE'
// Update event render job
export const UPDATE_ADMIN_RENDER_JOB_REQUEST = 'UPDATE_ADMIN_RENDER_JOB_REQUEST'
export const UPDATE_ADMIN_RENDER_JOB_SUCCESS = 'UPDATE_ADMIN_RENDER_JOB_SUCCESS'
export const UPDATE_ADMIN_RENDER_JOB_FAILURE = 'UPDATE_ADMIN_RENDER_JOB_FAILURE'
// Update event render job
export const DELETE_ADMIN_RENDER_JOB_REQUEST = 'DELETE_ADMIN_RENDER_JOB_REQUEST'
export const DELETE_ADMIN_RENDER_JOB_SUCCESS = 'DELETE_ADMIN_RENDER_JOB_SUCCESS'
export const DELETE_ADMIN_RENDER_JOB_FAILURE = 'DELETE_ADMIN_RENDER_JOB_FAILURE'

/* **************************************
 *  MANAGEMENT TOOLS - To manage duplicate events and accounts
 * ************************************ */

export const MERGE_DUPLICATE_ACCOUNT_REQUEST = 'MERGE_DUPLICATE_ACCOUNT_REQUEST'
export const MERGE_DUPLICATE_ACCOUNT_SUCCESS = 'MERGE_DUPLICATE_ACCOUNT_SUCCESS'
export const MERGE_DUPLICATE_ACCOUNT_FAILURE = 'MERGE_DUPLICATE_ACCOUNT_FAILURE'

export const MERGE_EVENTS_REQUEST = 'MERGE_EVENTS_REQUEST'
export const MERGE_EVENTS_SUCCESS = 'MERGE_EVENTS_SUCCESS'
export const MERGE_EVENTS_FAILURE = 'MERGE_EVENTS_FAILURE'

export const CHANGE_EVENT_CREATOR_REQUEST = 'CHANGE_EVENT_CREATOR_REQUEST'
export const CHANGE_EVENT_CREATOR_SUCCESS = 'CHANGE_EVENT_CREATOR_SUCCESS'
export const CHANGE_EVENT_CREATOR_FAILURE = 'CHANGE_EVENT_CREATOR_FAILURE'

export const FIX_DUPLICATE_PUBLISHED_MEDIA_REQUEST = 'FIX_DUPLICATE_PUBLISHED_MEDIA_REQUEST'
export const FIX_DUPLICATE_PUBLISHED_MEDIA_SUCCESS = 'FIX_DUPLICATE_PUBLISHED_MEDIA_SUCCESS'
export const FIX_DUPLICATE_PUBLISHED_MEDIA_FAILURE = 'FIX_DUPLICATE_PUBLISHED_MEDIA_FAILURE'

export const UNSUBSCRIBE_USER_REQUEST = 'UNSUBSCRIBE_USER_REQUEST'
export const UNSUBSCRIBE_USER_SUCCESS = 'UNSUBSCRIBE_USER_SUCCESS'
export const UNSUBSCRIBE_USER_FAILURE = 'UNSUBSCRIBE_USER_FAILURE'
