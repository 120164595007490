import { useEffect, useState } from 'react'

const useCompatibilityHook = () => {
	const [isCompatible, setIsCompatible] = useState(true)
	/** Check if the browser is compatible */
	useEffect(() => {
		// This is true when using internet explore 6 - 11
		let isIE = false || !!document.documentMode
		if (isIE) {
			setIsCompatible(false)
		}
	}, [])

	return isCompatible
}

export default useCompatibilityHook
