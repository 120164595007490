/* ******************************
 * PROJECT DEPENDENCIES
 ***************************** */

import * as types from './constants'

/* ******************************
 * Update trimming period of the video being worked on
 ***************************** */

export function updateTrimmingPeriod(data) {
	return {
		type: types.UPDATE_TRIMMING_PERIOD,
		data: data,
	}
}

export function updateTrimmerStateToStore(data) {
	return {
		type: types.UPDATE_TRIMMER_STATE_TO_STORE,
		data: data,
	}
}

export function resetVideoTrimmer() {
	return {
		type: types.RESET_VIDEO_TRIMMER,
	}
}

export function updateVideoRotation(data) {
	return {
		type: types.UPDATE_VIDEO_ROTATION,
		data: data,
	}
}

export function resetVideoRotation() {
	return {
		type: types.RESET_VIDEO_ROTATION,
	}
}
