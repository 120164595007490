import { createSlice } from '@reduxjs/toolkit'
import { CART } from '../../api/checkout/constants'

const initialState = {
	/** Processing status */
	isFetching: true,
	/** Is the coupon valid */
	isValid: false,
	/** Cart status */
	status: null, // or error, success
	statusMessage: '',

	/** Cart data */
	data: null,
	/** Keep an original copy of the coupon initially retrieved */
	dataServer: null,
}

const PromotionSlice = createSlice({
	initialState: initialState,
	name: 'promotion',
	extraReducers: {
		[CART.FETCH_SUCCEEDED]: (state, action) => {
			if (action.payload.data?.promotion) {
				state.isFetching = false
				state.isValid = true
				state.status = 'success'
				state.statusMessage = 'Hooray! Found a valid code'
				state.data = action.payload.data.promotion
				// Keep this line as reference to the original coupon applied and retrieved directly from server
				state.dataServer = action.payload.data.promotion
			}
		},
		['RESET_PROMOTION']: (state, action) => {
			state = initialState
		},
		['RESET_PROMOTION_STATUS']: (state, action) => {
			state.isFetching = false
			state.isValid = false
			state.status = null
			state.statusMessage = ''
		},
		['LOAD_PROMOTION_REQUEST']: (state, action) => {
			state.isFetching = true
		},
		['LOAD_PROMOTION_SUCCESS']: (state, action) => {
			state.isFetching = false
			state.isValid = action.payload.valid
			state.data = action.payload.code
			state.dataServer = action.payload.code
			state.status = action.payload.valid ? 'success' : 'error'
			state.statusMessage = action.payload.message
		},
		['LOAD_PROMOTION_FAILURE']: (state, action) => {
			state.isFetching = false
			state.data = null
			state.isValid = false
			state.status = 'error'
			state.statusMessage = action.payload.message
		},
		['REMOVE_PROMOTION_SUCCESS']: (state, action) => {
			state.isFetching = false
			state.data = null
			state.dataServer = null
		},
	},
	reducers: {},
})

export default PromotionSlice.reducer

// export const {
// 	setPaymentCharge,
// } = CheckoutSlice.actions
