const baseStyle = {
	container: {
		padding: '0.5rem !important',
		borderRadius: 'md',
		bg: 'white',
		_hover: {
			backgroundColor: 'gray.50',
		},
	},
	contentWrapper: {
		justifyContent: 'space-between',
		w: 'full',
		alignItems: 'center',
	},
	readNotice: {
		backgroundColor: 'link',
		w: '12px',
		h: '12px',
		minW: '12px',
		minH: '12px',
		borderRadius: 'full',
	},
	icon: {
		borderRadius: 'full',
		borderColor: 'cyan.300',
		borderWidth: '1px',
		bg: 'blueAlpha.100',
		w: '3.375rem',
		minW: '3.375rem',
		h: '3.375rem',
		minH: '3.375rem',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	image: {
		borderRadius: 'full',
		minW: '3.375rem',
		minH: '3.375rem',
	},
}

export default {
	baseStyle,
	parts: ['container', 'contentWrapper', 'readNotice', 'icon', 'image'],
}
