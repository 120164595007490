import { createSlice } from '@reduxjs/toolkit'
import { CART, COUNTRY } from '../../api/checkout/constants'

const defaultCurrency = { code: 'USD', symbol: '$' }

const initialState = {
	/** Processing status */
	isFetching: false,
	/** currency fetching status */
	status: null, // or error, success
	statusMessage: '',
	/** Currency data */
	data: defaultCurrency,
	/** Store available currencies here */
	currencies: null,
}

// Update currency when cart updates
const CurrencySlice = createSlice({
	initialState: initialState,
	name: 'currency',
	extraReducers: {
		// Handle cart loads
		[CART.FETCH_REQUESTED]: (state, action) => {
			state.isFetching = true
		},
		[CART.FETCH_SUCCEEDED]: (state, action) => {
			state.isFetching = false
			state.data =
				action.payload.data.currencyCode && state.currencies && Object.keys(state.currencies).length > 0
					? state.currencies[action.payload.data.currencyCode]
					: {
							code: action.payload.data.currencyCode
								? action.payload.data.currencyCode
								: defaultCurrency.code,
							symbol: defaultCurrency.symbol,
					  }
			state.data.code =
				action.payload.data && action.payload.data.currencyCode
					? action.payload.data.currencyCode
					: defaultCurrency.code // fallback if above fials
			state.status = 'success'
			state.statusMessage = `Active checkout currency set to ${state.data.code}`
		},
		[CART.FETCH_FAILED]: (state, action) => {
			state.isFetching = false
			state.data = defaultCurrency
			state.status = 'error'
			state.statusMessage = `Failed retrieving the cart... using default currency ${state.data.code}`
		},
		// Handle cart updates
		[CART.UPDATE_REQUESTED]: (state, action) => {
			state.isFetching = true
		},
		[CART.UPDATE_SUCCEEDED]: (state, action) => {
			state.isFetching = false
			state.data =
				action.payload.data.currencyCode && state.currencies && Object.keys(state.currencies).length > 0
					? state.currencies[action.payload.data.currencyCode]
					: {
							code: action.payload.data.currencyCode
								? action.payload.data.currencyCode
								: defaultCurrency.code,
							symbol: defaultCurrency.symbol,
					  }
			state.data.code = action.payload.data.currencyCode ? action.payload.data.currencyCode : defaultCurrency.code // fallback if above fials
			state.status = 'success'
			state.statusMessage = `Active checkout currency set to ${state.data.code}`
		},
		[CART.UPDATE_FAILED]: (state, action) => {
			state.isFetching = false
			state.data = defaultCurrency
			state.status = 'error'
			state.statusMessage = `Failed retrieving the cart... using default currency ${state.data.code}`
		},
		// handle when countries change too
		[COUNTRY.FETCH_ALL_REQUESTED]: (state, action) => {
			state.isFetching = true
		},
		[COUNTRY.FETCH_ALL_SUCCEEDED]: (state, action) => {
			state.isFetching = false
			if (action.payload.data?.entities?.currencies) {
				state.currencies = action.payload.data.entities.currencies
			}
		},
		[COUNTRY.FETCH_ALL_FAILED]: (state, action) => {
			state.isFetching = false
		},
	},
	reducers: {},
})

export default CurrencySlice.reducer
