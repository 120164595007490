/** Import our instance config */
// import instance from '../../../../api/config'

import axios from 'axios'
import qs from 'qs'
import config from '../../../client/config.client'

/** API config instance */
const instance = axios.create({
	withCredentials: true,
	baseURL: config.api.baseUrl,
})

/**
 * Fetch notifications
 */
const fetchNotifications = async (params) => {
	const response = await instance.get(`/v2/notifications${params ? `?${qs.stringify(params)}` : ''}`)
	return response
}

/**
 * Mark a notification as seen
 */
const markNotificationAsSeen = async (id) => {
	const response = await instance.post(`/v2/notifications/${id}/seen`)
	return response
}

/**
 * Mark a notification as read
 */
const markNotificationAsRead = async (id) => {
	const response = await instance.post(`/v2/notifications/${id}/read`)
	return response
}

/**
 * Mark a notification as unread
 */
const markNotificationAsUnread = async (id) => {
	const response = await instance.post(`/v2/notifications/${id}/unread`)
	return response
}

/**
 * Mark a notification as unread
 */
const markNotificationAsDismissed = async (id) => {
	const response = await instance.post(`/v2/notifications/${id}/dismiss`)
	return response
}

const requests = {
	fetchNotifications,
	markNotificationAsSeen,
	markNotificationAsRead,
	markNotificationAsUnread,
	markNotificationAsDismissed,
}

export default requests
