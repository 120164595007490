import React from 'react'
import PropTypes from 'prop-types'
import {
	RepeatRounded,
	RepeatOnRounded,
	PlayArrowRounded,
	PauseRounded,
	VolumeUpRounded,
	VolumeOffRounded,
	FullscreenRounded,
	FullscreenExitRounded,
} from '../../../atoms/Icon'

const MuteOff = () => <VolumeOffRounded />

const MuteOn = () => <VolumeUpRounded />

const BackgroundMusicOff = ({ className }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="19.988" height="19.994" viewBox="0 0 19.988 19.994">
		<path
			id="Union_5"
			data-name="Union 5"
			d="M21721.652,17252.068a3.79,3.79,0,0,1-1.043.146,3.189,3.189,0,0,1-3.369-2.967,2.645,2.645,0,0,1,.326-1.268l-4.826-4.826v8.875a3.4,3.4,0,1,1-3.369-2.961,3.7,3.7,0,0,1,2.148.676v-7.812l-3.3-3.3,1.412-1.406.311.3,16.047,16.059-1.412,1.412Zm-.717-5.77a3.637,3.637,0,0,1,1.822.67v-7.541l-6.969,1.725-3.3-3.3,11.5-2.846v14.246c0,.033-.006.066-.006.1Z"
			transform="translate(-21706.002 -17235.002)"
			fill="#6c7a88"
		/>
	</svg>
)

const BackgroundMusicOn = ({ className }) => (
	<svg
		id="Group_8932"
		data-name="Group 8932"
		xmlns="http://www.w3.org/2000/svg"
		width="17.99"
		height="20"
		viewBox="0 0 17.99 20">
		<path
			id="Path_8746"
			data-name="Path 8746"
			d="M5.52,3.085V14.75a3.651,3.651,0,0,0-2.147-.68A3.193,3.193,0,0,0,0,17.036,3.192,3.192,0,0,0,3.373,20a3.192,3.192,0,0,0,3.373-2.964V6.907L16.763,4.429v7.54a3.651,3.651,0,0,0-2.147-.68,3.192,3.192,0,0,0-3.373,2.964,3.192,3.192,0,0,0,3.373,2.964,3.192,3.192,0,0,0,3.373-2.964V0Zm0,0"
			fill="#0187ff"
		/>
	</svg>
)

const Rotate = ({ className }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="21" height="18" viewBox="0 0 21 18">
		<path
			id="ic_settings_backup_restore_24px"
			d="M9,3a9,9,0,0,1,9,9h3l-4,4-4-4h3a7,7,0,1,0-2.94,5.7l1.42,1.44A9,9,0,1,1,9,3Z"
			transform="translate(0 -3)"
			fill="#0187ff"
		/>
	</svg>
)

const Download = ({ className }) => (
	<svg className={className} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" />
		<path d="M0 0h24v24H0z" fill="none" />
	</svg>
)

const Music = ({ className }) => (
	<svg width="32" height="32" className={className} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M15 6H3v2h12V6zm0 4H3v2h12v-2zM3 16h8v-2H3v2zM17 6v8.18c-.31-.11-.65-.18-1-.18-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3V8h3V6h-5z" />
	</svg>
)

const Play = () => <PlayArrowRounded />

const Pause = () => <PauseRounded />

const Replay = ({ className }) => (
	<svg className={className} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M12 5V1L7 6l5 5V7c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6H4c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8z" />
	</svg>
)

const Repeat = () => <RepeatRounded />

const RepeatOff = () => <RepeatOnRounded />

const FullScreen = () => <FullscreenRounded />

const FullScreenOff = () => <FullscreenExitRounded />

const Icon = (props) => {
	let El = Download
	switch (props.name) {
		case 'music':
			El = Music
			break
		case 'play':
			El = Play
			break
		case 'pause':
			El = Pause
			break
		case 'replay':
			El = Replay
			break

		case 'volumeMuted':
			El = MuteOff
			break
		case 'volumeNotMuted':
			El = MuteOn
			break

		case 'repeat':
			El = Repeat
			break
		case 'repeatOff':
			El = RepeatOff
			break
		case 'fullScreen':
			El = FullScreen
			break
		case 'fullScreenOff':
			El = FullScreenOff
			break

		default:
			El = Download
			break
	}
	return <El className={`rat-icon-${props.name} ${props.className}`.trim()} />
}

Icon.propTypes = {
	name: PropTypes.string,
}

export default Icon
