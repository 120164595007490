import PropTypes from 'prop-types'

/* **************************************
 *  Event Recipient
 * ************************************ */

const propTypes = {
	firstName: PropTypes.string,
	lastName: PropTypes.string,
}

const defaultProps = {}

const EventRecipient = { propTypes, defaultProps }

export default EventRecipient
