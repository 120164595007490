import { createSlice } from '@reduxjs/toolkit'
import { CART } from '../../api/checkout/constants'

const initialState = {
	/** Processing status */
	isFetching: true,
	/** currency fetching status */
	status: null, // or error, success
	statusMessage: '',
	/** Payment Intent data (from request) */
	data: null,
	/** If we are processing the payment */
	isProcessing: false,
	/** Status of the payment */
	paymentError: '',
	/** Has the payment been successful ? */
	succeeded: false,
	/** payment Token */
	paymentToken: '',
	/** Client secret */
	clientSecret: '',
	/** Payment Intent Id */
	intentId: '',
	/** final Intent */
	finalIntent: '',
}

const updatePaymentIntent = (state, action) => {
	if (action.payload.data && action.payload.data.paymentIntent) {
		state.intentId = action.payload.data.paymentIntentId
		state.clientSecret = action.payload.data.paymentIntent.client_secret
		state.finalIntent = action.payload.data.paymentIntent
	}
}

// Update currency when fetching/retrieving a new country
const PaymentSlice = createSlice({
	initialState: initialState,
	name: 'payment',
	extraReducers: {
		[CART.FETCH_FAILED]: (state, action) => {
			state.intentId = ''
			state.clientSecret = ''
			state.finalIntent = ''
		},
		[CART.FETCH_SUCCEEDED]: updatePaymentIntent,
		[CART.UPDATE_SUCCEEDED]: updatePaymentIntent,
		[CART.ADD_ITEM_SUCCEEDED]: updatePaymentIntent,
		[CART.REMOVE_ITEM_SUCCEEDED]: updatePaymentIntent,

		// ['stripe/PaymentIntentSuccess']: (state, action) => {
		// 	state.clientSecret = action.payload.clientSecret
		// 	state.intentId = action.payload.intentId
		// },
		// ['stripe/UpdatePaymentIntentSuccess']: (state, action) => {
		// 	state.clientSecret = action.payload.clientSecret
		// },
		// ['checkout/postFreeIntentRequest']: (state, action) => {
		// 	// state.form.isSubmitting = true
		// },
		// ['checkout/postFreeIntentFailure']: (state, action) => {
		// 	// state.form.isSubmitting = false
		// },
		// ['checkout/postFreeIntentSuccess']: (state, action) => {
		// 	// state.form.isSubmitting = false
		// 	state.payment.finalIntent = action.payload.data
		// },
	},
	reducers: {
		/** Set values */
		setValues: (state, action) => {
			Object.keys(action.payload).forEach((key, i) => {
				state[key] = action.payload[key]
			})
		},
	},
})

export default PaymentSlice.reducer

export const { setValues } = PaymentSlice.actions
