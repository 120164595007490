import { combineReducers } from 'redux'

/** Retrieve all individual logics */
import cartReducer from './cart'
import locationReducer from './location'
import promotionReducer from './promotion'
import productsReducer from './products'
import currencyReducer from './currency'
import eventReducer from './event'
import calculationsReducer from './calculations'
import paymentReducer from './payment'

const combinedReducer = combineReducers({
	cart: cartReducer,
	location: locationReducer,
	promotion: promotionReducer,
	products: productsReducer,
	currency: currencyReducer,
	event: eventReducer,
	calculations: calculationsReducer,
	payment: paymentReducer,
})

const rootReducer = (state, action) => {
	/** This reset the full cart when dispatched */
	if (action.type === 'checkout/reset') {
		state = {}
	}
	return combinedReducer(state, action)
}

export default rootReducer
