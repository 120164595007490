import { createSlice } from '@reduxjs/toolkit'
import { CART } from '../../api/checkout/constants'

const initialState = {
	isLoading: true,
	subTotal: 0,
	total: 0,
	appliedDiscount: 0,
	republishDiscount: 0,
	occasionDiscount: 0,
	subscriptionDiscount: 0,
	taxesTotal: 0,
	/** Calculated taxes */
	taxes: {},
	/** tax jar object, from cart response */
	taxation: null,
}

/** Common reducer for all updates of the cart */
let handleUpdateTaxes = (state, action) => {
	const { data } = action.payload
	/** If we have provided taxation as null, set it to null */
	if (data.taxation && data.taxation == null) {
		state.taxation = null
	} else if (data.taxation) {
		/** If we did provide taxation in the request payload, replace existing objects */
		state.taxation = data.taxation
	}
	/** Update totals received from server */
	if (data.totals) {
		state.subTotal = data.totals.subTotal
		state.total = data.totals.total
		state.appliedDiscount = data.totals.appliedDiscount
		state.subscriptionDiscount = data.totals.subscriptionDiscount
		state.taxesTotal = data.totals.taxesTotal
		state.taxes = data.totals.taxes
	}

	let videoItem = data.lineItems.find((el, i) => el.group == 'video')
	if (videoItem) {
		state.republishDiscount = videoItem.isRepub ? videoItem.republishDiscount : 0
		state.occasionDiscount = videoItem.occasionDiscount ? videoItem.occasionDiscount : 0
	}
}

const CalculationSlice = createSlice({
	initialState: initialState,
	name: 'calculations',
	extraReducers: {
		[CART.FETCH_SUCCEEDED]: handleUpdateTaxes,
		[CART.UPDATE_SUCCEEDED]: handleUpdateTaxes,
		[CART.ADD_ITEM_SUCCEEDED]: handleUpdateTaxes,
		[CART.REMOVE_ITEM_SUCCEEDED]: handleUpdateTaxes,
	},
	reducers: {
		/** Set sub-Total */
		setValues: (state, action) => {
			Object.keys(action.payload).forEach((key, i) => {
				state[key] = action.payload[key]
			})
		},
		/** Set sub-Total */
		setSubTotal: (state, action) => {
			state.subTotal = action.payload
		},
		/** Set Total */
		setTotal: (state, action) => {
			state.total = action.payload
		},
		setAppliedDiscount: (state, action) => {
			state.appliedDiscount = action.payload
		},
	},
})

export default CalculationSlice.reducer

export const { setValues, setTotal, setSubTotal, setAppliedDiscount } = CalculationSlice.actions
