/* ******************************
 * LIBRARIES
 ***************************** */

import { RSAA, getJSON } from 'redux-api-middleware'
import { normalize } from 'normalizr'

/* ******************************
 * PROJECT DEPENDENCIES
 ***************************** */

import * as types from '../constants'
import { retrieveURL } from '@vidday/utils'
import { invoiceListSchema } from '../middlewares/normalizr'
import { loadEvent } from '../events'

/* ******************************
 * FETCH ALL INVOICES OF AUTH USER
 ***************************** */

export const fetchInvoices = () => ({
	[RSAA]: {
		types: [
			types.LOAD_INVOICES_REQUEST,
			{
				type: types.LOAD_INVOICES_SUCCESS,
				payload: (action, state, res) => {
					return getJSON(res).then((json) => normalize(json.data, invoiceListSchema))
				},
			},
			types.LOAD_INVOICES_FAILURE,
		],
		endpoint: retrieveURL() + '/api/v2/invoices',
		credentials: 'include',
		method: 'GET',
	},
})

/* ******************************
 * CREATE AN INVOICE
 ***************************** */

export const addInvoice = (data) => ({
	[RSAA]: {
		types: [types.ADD_INVOICE_REQUEST, types.ADD_INVOICE_SUCCESS, types.ADD_INVOICE_FAILURE],
		endpoint: retrieveURL() + '/api/invoice',
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		credentials: 'include',
		body: JSON.stringify(data),
	},
})

/* ******************************
 * UPDATE AN INVOICE
 ***************************** */

export const putInvoice = (data) => ({
	[RSAA]: {
		types: [
			types.UPDATE_INVOICE_REQUEST,
			{
				type: types.UPDATE_INVOICE_SUCCESS,
				payload: (action, state, res) => {
					return res.json().then((json) => {
						return json
					})
				},
			},
			{
				type: types.UPDATE_INVOICE_FAILURE,
				payload: (action, state, res) => {
					return res.json().then((json) => {
						return { data: json.stripeError }
					})
				},
			},
		],
		endpoint: retrieveURL() + '/api/invoice',
		method: 'PUT',
		headers: { 'Content-Type': 'application/json' },
		credentials: 'include',
		body: JSON.stringify(data),
	},
})

// Post the media object to server, then reload event for new media
export const updateInvoice = (data, onPaymentCallback) => {
	return (dispatch, state) => {
		dispatch(putInvoice(data)).then((result) => {
			dispatch(fetchInvoices(state().auth.userId)).then(() => {
				dispatch(loadEvent(state().currentEvent.data.uuid))
				onPaymentCallback(result.payload.data)
			})
		})
	}
}

/* ******************************
 * DELETE AN INVOICE
 ***************************** */

export const deleteInvoice = (id) => ({
	[RSAA]: {
		types: [types.DELETE_INVOICE_REQUEST, types.DELETE_INVOICE_SUCCESS, types.DELETE_INVOICE_FAILURE],
		endpoint: retrieveURL() + '/api/invoice',
		method: 'DELETE',
		headers: { 'Content-Type': 'application/json' },
	},
})
