import PropTypes from 'prop-types'

/* **************************************
 *  USER PREFERENCES
 * ************************************ */

const propTypes = {
	userId: PropTypes.string.isRequired,
	gridSize: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge']).isRequired,
}

const defaultProps = {
	gridSize: 'medium',
}

const UserPreferences = { propTypes, defaultProps }

export default UserPreferences
