import { useWidgetIsReady } from '@livechat/widget-react'
import { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useAuthContext } from '../../../../contexts/AuthProvider'
import dayjs from 'dayjs'
import sumBy from 'lodash/sumBy'
import { useVideoDurationCalc } from '../../../../hooks/useVideoDurationCalc'
import useStandaloneDetect from '../../../../hooks/useStandaloneDetect'
import { getRecipients } from '@vidday/utils'
import useSSR from 'use-ssr'

const useEventMeta = () => {
	const appInstalled = useStandaloneDetect()
	const isReady = useWidgetIsReady()
	const { userId } = useAuthContext()
	const event = useSelector((s) => s.event.event, shallowEqual)
	const media = useSelector((s) => s.event?.media, shallowEqual)
	const recipients = useSelector((s) => s.event?.recipients, shallowEqual)
	const { durationTime } = useVideoDurationCalc(media, event?.themeTemplateId)

	useEffect(() => {
		if (isReady) {
			let eventInfo = {}

			if (event?.id) {
				let eventStatus = event.status || null
				let eventOccasion = event.occasion || null
				let isOwner = event.creatorId == userId || null
				let deadline = event.deadline ? dayjs(event.deadline).format('llll') : null
				// let timezone = event.timezone ? event.timezone : null
				let payment = event.paid ? 'Paid' : event.paid == false ? 'Trial' : null
				let mediaCount = media ? sumBy(media, (m) => (/^video/i.test(m?.mimeType) ? 1 : 0)) : 0
				let imageCount = media ? sumBy(media, (m) => (/^image/i.test(m?.mimeType) ? 1 : 0)) : 0
				let totalLength = media.length > 0 ? durationTime : 0
				let recipientsFormated = recipients ? getRecipients(recipients, true).names : null
				let themeTemplateId = event.themeTemplateId
				let backgroundMusicIds = event.backgroundMusicIds
				// let coverPhoto = event ? (event.coverPhotoId ? true : false) : false
				// this might be broken? Will review it at a later date.
				// let defaultDeadline =
				// 	event.deadline && event.createdAt
				// 		? dayjs(event.createdAt)
				// 				.add(2, 'days')
				// 				.startOf('minute')
				// 				.isSame(dayjs(event.deadline).startOf('minute'))
				// 		: null

				// let displaySize = viewport.width + 'x' + viewport.height || null

				eventInfo = {
					'Event isCreator': isOwner,
					'Event Status': eventStatus,
					'Event Link': `https://share.vidday.com/${event.uuid}`,
					'Event Occasion': eventOccasion,
					'Event Recipients': recipientsFormated,
					'Date Deadline': deadline,
					// 'Date Timezone': timezone,
					'Media # Photos': imageCount,
					'Media # Videos': mediaCount,
					'Media Length': totalLength,
					'Media Theme': themeTemplateId,
					'Media Music': backgroundMusicIds,
					'App Installed': appInstalled,
					// 'User Device': os,
					// 'User Browser': browserAgent,
					// 'Display Size': displaySize,
					// '~ Cart': `${JSON.stringify(cart, undefined, 4)}`,
					'~ Payment': payment,
					// 'Cover Photo': coverPhoto,
					// 'Default Deadline': defaultDeadline,
				}
			}
			// // Greeting Triggers - no longer need these to fire before an event is accessed.
			// // Will leave incase this changes in the future.
			// eventInfo['Has Cover Photo'] = event ? (event.coverPhotoId ? true : false) : false
			// eventInfo['Has Default Deadline'] = event
			// 	? dayjs(event.createdAt).add(2, 'd').startOf('minute').isSame(dayjs(event.deadline).startOf('minute'))
			// 	: false

			// Update LiveChat
			LiveChatWidget.call('update_session_variables', eventInfo)
		}
	}, [isReady, event, userId, media, durationTime, recipients])
}

export default useEventMeta
