import { useDispatch } from 'react-redux'
import { resetTemporaryUser } from '../../../../../api/app/auth'
import { setActivationRequiredFalse } from '../../../../../api/app/auth'
import { resendVerificationEmail } from '../../../../../api/app/auth'
import { checkAccount } from '../../../../../api/app/auth'

const useAuthHandlers = () => {
	const dispatch = useDispatch()

	const handleResendVerificationEmail = (email) => {
		dispatch(resendVerificationEmail(email))
	}

	const handleCancelVerify = () => {
		dispatch(reset('login_form'))
		dispatch(resetTemporaryUser())
		dispatch(setActivationRequiredFalse())
	}

	const handleCheckAccount = (val) => {
		var re =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

		if (val == '') {
			dispatch({ type: 'RESET_TEMPORARY_USER' })
		} else if (re.test(String(val).toLowerCase())) {
			dispatch(checkAccount(String(val).toLowerCase(), true))
		}
	}

	return {
		handleResendVerificationEmail,
		handleCancelVerify,
		handleCheckAccount,
	}
}

export default useAuthHandlers
