import React, { useEffect, useState } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { loadEvent } from '../../../../../api/app/events'
import { useLocation, Navigate, useSearchParams } from 'react-router-dom'
import HandleArbitrary from '../HandleArbitrary'
import { history } from '../../../../../history'
import { useAuthContext } from '../../../../contexts/AuthProvider'
import FancyLoader from '../../../atoms/FancyLoader'
import PropTypes from 'prop-types'

const checkIfOwner = (creatorId, userId) => {
	if (creatorId && userId) {
		if (creatorId == userId) {
			return true
		} else return false
	} else return false
}

const isOwnerOrStaff = (isAuthenticated, role, isOwner) => {
	/** If user is authenticated and a role is supplied */
	if (isAuthenticated && role) {
		/** If the role is either 'editor', 'admin' or if the user is the owner */
		if (isOwner || ['editor', 'admin'].includes(role)) {
			return true
		} else return false
	}
}

const AuthedOrUnauthed = ({ uuid }) => {
	const dispatch = useDispatch()
	const location = useLocation()
	const [searchQuery] = useSearchParams()
	const privateKey = searchQuery.get('v') //qs.parse(search, { ignoreQueryPrefix: true }).v || null

	/** EVENT STATE */
	/** Are we currently fetching an event? */
	const isFetching = useSelector((s) => s.event.isFetching, shallowEqual)
	/** Retrieve Fetching errors from the server while retrieving the current event */
	const fetchingError = useSelector((s) => s.event.fetchingError, shallowEqual)
	/** Retrieve the event in the store, but return null if uuid is -1 (This is a hack, null should be default) */
	const event = useSelector((s) => (s.event.event.uuid != -1 ? s.event.event : null), shallowEqual)

	/** USER STATE */
	const { userId, isAuthenticated, role } = useAuthContext()

	/** AUTHENTICATION STATE */
	/** Save a reference to the user being the owner of the event  */
	const [isOwner, setIsOwner] = useState(checkIfOwner(event?.creatorId, userId))
	/** Is the user authorized to access the page */
	const [authorized, setAuthorized] = useState(isOwnerOrStaff(isAuthenticated, role, isOwner))

	/** Set isOwner and authorized at once */
	useEffect(() => {
		if (event && userId) {
			if (event.creatorId) {
				const _isOwner = checkIfOwner(event.creatorId, userId)
				// console.log('trying to set ownership')
				setIsOwner(_isOwner)

				if (isAuthenticated && role) {
					// console.log('trying to set isAuthorized')
					const _isOwnerOrStaff = isOwnerOrStaff(isAuthenticated, role, _isOwner)
					setAuthorized(_isOwnerOrStaff)
				}
			}
		}
	}, [event, userId, isAuthenticated, role])

	/**
	 * If we don't have an event, let's fetch event or redirect to V2
	 * */
	useEffect(() => {
		/** First make sure we have a uuid  and it's not the default value */
		if (uuid && uuid !== -1) {
			if (!event) {
				/** Otherwise, if we have a uuid BUT NO event, let's trigger a load */
				dispatch(loadEvent(uuid, { privateKey: privateKey }))
			}

			/**
			 * Legacy: Check for UUID format
			 * */
			if (uuid.indexOf('-') == 1 && uuid.length > 8) {
				// => for format "b-123456extra" -> "b-123456"
				let fixedUuid = uuid.substring(0, 8) // trim things down to 8 chars and try the search.
				console.log('DEBUG: <AuthedOrUnauthed> [checking uuid length 8]', fixedUuid)
				history.push(`/event/${fixedUuid}`)

				// dispatch(loadEvent(fixedUuid, { privateKey: privateKey }))
			} else if (uuid.indexOf('-') == -1 && uuid.length > 6) {
				// for format "123456extra" -> "123456"
				// for supporting older 6 char uuid (without the occasion letter and -)
				let fixedUuid = uuid.substring(0, 6) // trim things down to 8 chars and try the search.
				console.log('DEBUG: <AuthedOrUnauthed> [checking uuid length 6]', fixedUuid)
				history.push(`/event/${fixedUuid}`)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [event, uuid, privateKey])

	if (!isFetching) {
		/** If we have a fetching error, render 404 */
		if (fetchingError) {
			console.error('EVENT DOES NOT EXIST!')
			return <HandleArbitrary layout="event-404" />
		}

		if (event && event.id) {
			/** Serve out the correct view */
			if (authorized) {
				return <Navigate to={`/event${location.pathname}${location.search}`} />
			} else {
				return <Navigate to={`/e${location.pathname}${location.search}`} />
			}
		}
	}

	return <FancyLoader />
}

AuthedOrUnauthed.propTypes = {
	uuid: PropTypes.string,
}

export default AuthedOrUnauthed
