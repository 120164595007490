/** Import our instance config */

import axios from 'axios'
import { reject } from './utils'
import config from '../../../client/config.client'

/** API config instance */
const instance = axios.create({
	withCredentials: true,
	baseURL: config.api.baseUrl,
})

/**
 * Retrieve the active cart for a given event.
 * Ultimately performs a POST request to determine if one exist or should be created.
 * @param {*} data
 * @returns
 */
const fetchInvoices = async (params) => {
	const response = await instance.get('/v2/stripe/completed-payments')
	return response
}

/* *******************************
CART
******************************* */

/**
 * Retrieve the active cart for a given event.
 * Ultimately performs a POST request to determine if one exist or should be created.
 * @param {*} data
 * @returns
 */
const getCart = async ({ eventId, countryCode }) => {
	const response = await instance.post('/v2/carts', { eventId: eventId, countryCode: countryCode })
	// console.error('getCart response ', response)
	return response
}

/**
 * Update a cart
 * @param {*} data
 * @returns
 */
const updateCart = async (data) => {
	const filtered = reject(data, [
		'createdAt',
		'updatedAt',
		'eventId',
		'id',
		'userId',
		'products',
		'checkoutSessionId',
		'canUsePromoCode',
		'lineItems',
		'promotion',
		'taxation',
		'totals',
		'upsells',
		'availableAddons',
	])
	// workaround for regionCode somehow being set to "" on profiles...
	if (filtered.regionCode == '') {
		filtered.regionCode = null
	}
	const response = await instance.put('/v2/carts/' + data.id, filtered)
	return response
}

/**
 * Add item to cart.
 * @param {*} data
 * @returns
 */
const addItemToCart = async (data) => {
	const filtered = reject(data, [
		'createdAt',
		'updatedAt',
		'eventId',
		'id',
		'userId',
		'products',
		'checkoutSessionId',
		'canUsePromoCode',
		'lineItems',
		'promotion',
		'taxation',
		'totals',
		'upsells',
		'availableAddons',
	])
	// workaround for regionCode somehow being set to "" on profiles...
	if (filtered.regionCode == '') {
		filtered.regionCode = null
	}
	const response = await instance.put('/v2/carts/' + data.id, filtered)
	return response
}

/**
 * Remove item From cart
 * @param {*} data
 * @returns
 */
const removeItemFromCart = async (data) => {
	const filtered = reject(data, [
		'createdAt',
		'updatedAt',
		'eventId',
		'id',
		'userId',
		'products',
		'checkoutSessionId',
		'canUsePromoCode',
		'lineItems',
		'promotion',
		'taxation',
		'totals',
		'upsells',
		'availableAddons',
	])
	// workaround for regionCode somehow being set to "" on profiles...
	if (filtered.regionCode == '') {
		filtered.regionCode = null
	}
	const response = await instance.put('/v2/carts/' + data.id, filtered)
	return response
}

/**
 * Post free cart
 * @param {*} cartId
 * @returns
 */
const postFreeCart = async (cartId) => {
	const response = await instance.post('/v2/carts/' + cartId + '/finalize', {})
	return response
}

/**
 * Post checkout session
 * @param {*} cartId
 * @returns
 */
const postCartCheckoutSession = async (data) => {
	const response = await instance.post('/v2/stripe/create-checkout-session', data)
	return response
}

/**
 * Create payment intent
 * @param {*} data
 * @returns
 */
const postPaymentIntent = async (data) => {
	const response = await instance.post('/payment-intent/', data)
	return response
}

/**
 * Update payment intent
 * @param {*} data
 * @returns
 */
const updatePaymentIntent = async (data) => {
	const response = await instance.put('/payment-intent/' + data.id, data)
	return response
}

/* *******************************
PRODUCTS
******************************* */

/**
 * Retrieve available products given the country
 * @param {*} data
 * @returns
 */
const getProducts = async (countryCode) => {
	const response = await instance.get('/v2/stripe/available-products/' + countryCode)
	return response
}

/* *******************************
Location
******************************* */

/** Get User's IP location */
const getLocation = async (eventId) => {
	const response = await axios.get('https://ipapi.co/json/?key=Cp8CCYMhrP4YruOA3bOB9inKnEUai2j2zvwyygsTmB8kybWMOs')
	return response
}

/**
 * Verify if a postal code is valid
 * takes: {postalCode:xx, countryCode:xx}
 */
const verifyPostalCode = async (params) => {
	const response = await instance.get('/v2/verify-postal-code', { params: params })
	return response
}

/**
 * Retrieve a specific country
 * @param {*} data
 * @returns
 */
const getCountry = async (countryCode) => {
	const response = await instance.get('/v2/geo-countries/' + countryCode)
	return response
}

/**
 * Retrieve all countries
 * @param {*} data
 * @returns
 */
const getAllCountries = async () => {
	const response = await instance.get('/v2/geo-countries?sortBy=name')
	return response
}

const requests = {
	getCart,
	updateCart,
	addItemToCart,
	removeItemFromCart,
	postFreeCart,
	postCartCheckoutSession,
	getLocation,
	getProducts,
	getCountry,
	postPaymentIntent,
	updatePaymentIntent,
	verifyPostalCode,
	getAllCountries,
	fetchInvoices,
}

export default requests
