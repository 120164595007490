import { mode } from '@chakra-ui/theme-tools'
const Menu = {
	baseStyle: (props) => {
		return {
			list: {
				background: mode('white', 'gray.200')(props),
			},
			item: {
				color: mode('gray.800', 'gray.800')(props),
				_hover: {
					background: mode('blackAlpha.100', 'blackAlpha.100')(props),
				},
			},
			button: {
				...props.theme.components.NavCard.baseStyle(props).cardButton,
			},
		}
	},
}

export default Menu
