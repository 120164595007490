/* ******************************
 * LIBRARIES
 ***************************** */
import { RSAA } from 'redux-api-middleware'
import { normalize } from 'normalizr'

/* ******************************
 * PROJECT DEPENDENCIES
 ***************************** */
import * as types from '../constants'
import { setAuthenticatedUser } from '../../app/auth'
import { adminUserListSchema } from '../middlewares/normalizr'
import { addFlashMessage } from '../../app/behaviors/flash-messages'
import qs from 'qs'
import { retrieveURL } from '@vidday/utils'
import { history } from '../../../history'

/* ******************************
 * FETCH ALL USERS
 ***************************** */

export function fetchUsers(params) {
	return (dispatch) => {
		dispatch({
			[RSAA]: {
				types: [
					{
						type: types.LOAD_ADMIN_USERS_REQUEST,
						payload: () => {
							dispatch(addFlashMessage('info', 'Searching Users'))
						},
					},
					{
						type: types.LOAD_ADMIN_USERS_SUCCESS,
						payload: (action, state, res) => {
							dispatch(addFlashMessage('success', 'Users Found!'))
							return res.json().then((json) => normalize(json.data, adminUserListSchema))
							//console.log(res.status,json.message)
							// if (res.status === 201) {
							//     // dispatch(addFlashMessage('info', json.message));
							// } else {
							//     // dispatch(addFlashMessage('success', json.message));\
							// }
						},
					},
					{
						type: types.LOAD_ADMIN_USERS_REQUEST,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								dispatch(addFlashMessage('error', json.message))
								return json
							})
						},
					},
				],
				endpoint: retrieveURL() + `/api/admin/users${params ? `?${qs.stringify(params)}` : ``}`,
				credentials: 'include',
				method: 'GET',
			},
		})
	}
}

/* ******************************
 * UPDATE USER DATA BY ID
 ***************************** */

export function updateUser(data) {
	return (dispatch, state) => {
		dispatch({
			[RSAA]: {
				types: [
					types.UPDATE_USER_REQUEST,
					{
						type: types.UPDATE_USER_SUCCESS,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								//console.log("post event media success ", json);
								// send flash for sucess
								dispatch(addFlashMessage('success', json.message))
								dispatch(fetchUsers())
								return json
							})
						},
					},
					{
						type: types.UPDATE_USER_FAILURE,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								// send flash for error
								dispatch(addFlashMessage('error', json.error))
								return json
							})
						},
					},
				],
				credentials: 'include',
				endpoint: `${retrieveURL()}/api/v2/users/${data.id}`,
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(data),
			},
		})
	}
}

/* ******************************
 * CHANGE A USER'S ROLE
 ***************************** */

export function changeRole(data) {
	return (dispatch, state) => {
		dispatch({
			[RSAA]: {
				types: [
					types.CHANGE_ROLE_REQUEST,
					{
						type: types.CHANGE_ROLE_SUCCESS,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								//console.log("post event media success ", json);
								// send flash for sucess
								dispatch(addFlashMessage('success', json.message))
								dispatch(fetchUsers())
								return json
							})
						},
					},
					{
						type: types.CHANGE_ROLE_FAILURE,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								// send flash for error
								const message = json.error || json.message
								dispatch(addFlashMessage('error', message))
								return json
							})
						},
					},
				],
				credentials: 'include',
				endpoint: `${retrieveURL()}/api/v2/users/${data.id}/change-role`,
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(data),
			},
		})
	}
}

/* ******************************
 * IMPERSONATE A USER, BY USERID
 ***************************** */
export function impersonateUser(userId, redirect = '/dashboard') {
	return (dispatch, state) => {
		dispatch({
			[RSAA]: {
				types: [
					types.IMPERSONATE_USER_REQUEST,
					{
						type: types.IMPERSONATE_USER_SUCCESS,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								dispatch(setAuthenticatedUser(json.data, json.message, true))
								// dispatch(addFlashMessage('success', json.message));
								history.push(redirect)
								// dispatch(push(redirect))
								//window.open(redirect, '_blank');
								return json
							})
						},
					},
					types.IMPERSONATE_USER_FAILURE,
				],
				endpoint: retrieveURL() + '/api/v2/auth/impersonate/' + userId,
				credentials: 'include',
				method: 'GET',
			},
		})
	}
}

/* ******************************
 * STOP IMPERSONATING A USER (unmask)
 ***************************** */
export function unmask(redirect = '/admin/users') {
	return (dispatch, state) => {
		dispatch({
			[RSAA]: {
				types: [
					types.UNMASK_REQUEST,
					{
						type: types.UNMASK_SUCCESS,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								dispatch(setAuthenticatedUser(json.data, json.message, false))
								history.push(redirect)
								// dispatch(push(redirect))
								return json
							})
						},
					},
					types.UNMASK_FAILURE,
				],
				endpoint: retrieveURL() + '/api/v2/auth/stop-impersonating',
				credentials: 'include',
				method: 'GET',
			},
		})
	}
}

/* ******************************
 * MergeDuplicateUser - To accounts with the same email address
 ***************************** */
export function mergeDuplicateUser(data) {
	return (dispatch, state) => {
		dispatch({
			[RSAA]: {
				types: [
					types.MERGE_DUPLICATE_ACCOUNT_REQUEST,
					{
						type: types.MERGE_DUPLICATE_ACCOUNT_SUCCESS,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								dispatch(addFlashMessage('success', json.message))
								return json
							})
						},
					},
					{
						type: types.MERGE_DUPLICATE_ACCOUNT_FAILURE,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								dispatch(addFlashMessage('error', json.message))
								return json
							})
						},
					},
				],
				endpoint: retrieveURL() + '/api/accounts/merge',
				method: 'POST',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ email: data.email }), // filter out anything else
			},
		})
	}
}

/* ******************************
 * FETCH ALL PROMOTIONS
 ***************************** */

export function unsubscribeUser(data) {
	return (dispatch, state) => {
		dispatch({
			[RSAA]: {
				types: [
					types.UNSUBSCRIBE_USER_REQUEST,
					{
						type: types.UNSUBSCRIBE_USER_SUCCESS,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								dispatch(addFlashMessage('success', json.message))
								return json
							})
						},
					},
					{
						type: types.UNSUBSCRIBE_USER_FAILURE,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								dispatch(addFlashMessage('error', json.message))
								return json
							})
						},
					},
				],
				endpoint: retrieveURL() + '/api/mail/unsubscribe',
				method: 'POST',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(data), // filter out only uuid
			},
		})
	}
}
