import React from 'react'
import { Box } from '@chakra-ui/react'

const statusStyles = {
	pos: 'absolute',
	zIndex: '9',
	textAlign: 'center',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	minH: '100%',
	cursor: 'pointer',
	fontSize: '20px',
	color: '#038c7f',
	w: '30px',
	left: '50%',
	transform: 'translateX(-81%)',
}

// const StyledRvtStatus = styled.div`
// 	width: 100%;
// 	position: absolute;
// 	z-index: 9;
// 	text-align: center;
// 	display: flex;
// 	align-items: center;
// 	min-height: 100%;
// 	cursor: pointer;
// 	font-size: 20px;
// 	color: #038c7f;
// `

const Status = ({ children }) => {
	return <Box sx={statusStyles}>{children}</Box>
}

export default Status
