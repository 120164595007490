const Card = {
	// The styles all Cards have in common
	baseStyle: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		background: 'white',
		boxShadow: 'md',
		overflow: 'hidden',
		// gap: 6,
	},
	// Two variants: rounded and smooth
	variants: {
		rounded: {
			// padding: 8,
			borderRadius: ['xl'],
		},

		main: {
			// No border radius on mobile
			borderRadius: [0, 'xl'],
		},

		smooth: {
			// padding: 6,
			borderRadius: 'base',
		},

		square: {
			borderRadius: '0',
		},

		outline: {
			background: 'transparent',
			border: '1px solid',
			borderColor: 'whiteAlpha.300',
			borderRadius: '1rem',
			backgroundClip: 'padding-box',
		},
	},
	// The default variant value
	defaultProps: {
		variant: 'rounded',
	},
}

export default Card
