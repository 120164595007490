import { createSlice } from '@reduxjs/toolkit'
import { CART } from '../../api/checkout/constants'

const initialState = {
	/** Event information */
	data: null,
	eventId: null,
	durationTime: null,
	durationSeconds: null,
	recipients: [],
	recipientsFormatted: null,
	coverPhoto: null,
	isRepublish: false, // indicates republish, or first time publish
	isFreeRepublish: false, // indicates if this republish should be free, or not
	// republishDiscount:0,
	isFree: false,
	hasSubscription: false,
	subscription: null,
}

const updateVideoItemOnCartChange = (state, action) => {
	let videoItem = action.payload.data.lineItems.find((el, i) => el.group == 'video')
	if (videoItem) {
		state.isRepublish = videoItem.isRepub ? true : false
		state.isFreeRepublish = videoItem.isFreeRepub ? true : false
		state.hasSubscription = videoItem.hasSubscription ? true : false
		state.subscription = videoItem.hasSubscription ? videoItem.subscription : null
		// state.republishDiscount = videoItem.isRepub ? videoItem.discount: 0;
	}
}

const EventSlice = createSlice({
	initialState: initialState,
	name: 'event',
	extraReducers: {
		[CART.FETCH_SUCCEEDED]: updateVideoItemOnCartChange,
		[CART.UPDATE_SUCCEEDED]: updateVideoItemOnCartChange,
		[CART.ADD_ITEM_SUCCEEDED]: updateVideoItemOnCartChange,
		[CART.REMOVE_ITEM_SUCCEEDED]: updateVideoItemOnCartChange,
	},
	reducers: {
		setEvent: (state, action) => {
			state.data = action.payload
			state.eventId = action.payload.id
		},
		setRecipients: (state, action) => {
			state.recipients = action.payload
		},
		setRecipientsFormatted: (state, action) => {
			state.recipientsFormatted = action.payload
		},
		setCoverPhoto: (state, action) => {
			state.coverPhoto = action.payload
		},
		setDurationTime: (state, action) => {
			state.durationTime = action.payload
		},
		setDurationSeconds: (state, action) => {
			state.durationSeconds = action.payload
		},
		setIsRepublish: (state, action) => {
			state.isRepublish = action.payload
		},
		setIsAllowedRepublish: (state, action) => {
			state.allowedRepublish = action.payload
		},
		setIsFree: (state, action) => {
			state.isFree = action.payload
		},
	},
})

export default EventSlice.reducer

export const {
	setEvent,
	setRecipients,
	setRecipientsFormatted,
	setCoverPhoto,
	setDurationTime,
	setDurationSeconds,
	setIsRepublish,
	setIsAllowedRepublish,
	setIsFree,
} = EventSlice.actions
