/* **************************************
 *  LIBRARIES
 * ************************************ */

import findIndex from 'lodash/findIndex'

/* **************************************
 *  PROJECT DEPENDENCIES
 * ************************************ */

import * as types from '../api/app/constants'
import { generateUID } from '@vidday/utils'

const flashMessages = (state = [], action = {}) => {
	switch (action.type) {
		case types.ADD_FLASH_MESSAGE:
			return [
				...state,
				{
					id: generateUID(),
					type: action.status,
					text: action.message,
					callToAction: action.callToAction,
				},
			]
		case types.DELETE_FLASH_MESSAGE:
			const index = findIndex(state, { id: action.id })
			if (index >= 0) {
				return [...state.slice(0, index), ...state.slice(index + 1)]
			}
			return state
		default:
			return state
	}
}

export default flashMessages
