/* **************************************
 *  IMPORT DEPENDENCIES
 * ************************************ */

/* Import Vidday Trimmer */
import ViddayVideoPlayer from './ViddayVideoPlayer'

/* Import Individual Controls */
import { PlayPauseControl, VolumeControl, DurationLabel } from './components/Controls'

/* Import Video Editing Actions */
import {
	resetVideoTrimmer,
	resetVideoRotation,
	updateTrimmingPeriod,
	updateTrimmerStateToStore,
	updateVideoRotation,
} from './actions'

/* Import Reducer */
import { default as videoReducer } from './reducer'

/* **************************************
 *  EXPORT LIBRARY
 * ************************************ */

/* Export Individual Controls */
export { PlayPauseControl, VolumeControl, DurationLabel }

/* Export Actions */
export { resetVideoTrimmer, resetVideoRotation, updateTrimmingPeriod, updateTrimmerStateToStore, updateVideoRotation }

/* Export Video Editing Reducer */
export { videoReducer }

/* Export Trimmer Component */
export default ViddayVideoPlayer
