/* ******************************
 * LIBRARIES
 ***************************** */
import { RSAA } from 'redux-api-middleware'
import randomBytes from 'randombytes'

/* ******************************
 * PROJECT DEPENDENCIES
 ***************************** */

import * as types from '../constants'
import { addFlashMessage } from '../behaviors/flash-messages'
import { retrieveURL } from '@vidday/utils'
import { TRACKS, MEDIA, PUBLISHED_MEDIA } from './constants'

/* ******************************
 * REPORT UPLOAD PROGRESS
 ***************************** */
export function uploadProgress(data) {
	return {
		type: types.UPLOAD_EVENT_MEDIA_PROGRESS,
		payload: data,
	}
}

/* ******************************
 * POST MEDIA OBJECT TO SERVER
 ***************************** */
export function postMedia(data, showAlert = true) {
	const payload = {
		eventId: data.eventId,
		uploadUuid: data.uploadUuid,
		mediaUuid: data.mediaUuid,
		mimeType: data.mimeType,
		name: data.name || undefined,
		sourceFilename: data.sourceFilename,
		sourceExt: data.sourceExt || undefined,
		key: data.key || undefined,
		contributorId: data.contributorId || undefined,
		isCoverPhoto: data.isCoverPhoto || undefined,
		isThemeCover: data.isThemeCover || undefined,
		tags: data.tags || undefined,
		medium: data.medium || undefined,
		text: data.text || undefined,
		duration: data.duration || undefined,
		location: data.location || undefined,
		thumbnail: data.thumbnail || undefined,
		// Thank You video below
		isThankYouVideo: data.isThankYouVideo || undefined,
		isInviteVideo: data.isInviteVideo || undefined,
		privateKey: data.privateKey || undefined,
		// Tus upload support
		uploadKey: data.uploadKey || undefined,
	}

	return (dispatch, getState) => {
		dispatch({
			[RSAA]: {
				types: [
					{
						type: types.POST_EVENT_MEDIA_REQUEST,
						payload: () => {
							return { data: data }
						},
					},
					{
						type: types.POST_EVENT_MEDIA_SUCCESS,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								if (showAlert && json.data.jobState != 'error') {
									dispatch(addFlashMessage('success', json.message))
								}
								if (
									state.event.event.creatorId == state?.auth?.userId &&
									json.data.jobState == 'error'
								) {
									// show error when media pre-processing has error
									dispatch(
										addFlashMessage(
											'error',
											`Could not process ${json.data.sourceFilename || ' upload'}`
										)
									)
								}
								if (json.data.jobState == 'error') {
									dispatch(
										addFlashMessage('error', `Media Service Error, can't process image. Try Again.`)
									)
								}
								return json
							})
						},
					},
					{
						type: types.POST_EVENT_MEDIA_FAILURE,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								// send flash for error
								dispatch(addFlashMessage('error', json.message))
								return json
							})
						},
					},
				],
				endpoint: retrieveURL() + '/api/v2/media',
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				credentials: 'include',
				body: JSON.stringify(payload),
			},
		})
	}
}

/* ******************************
 * ENCODE MEDIA OBJECT TO SERVER
 ***************************** */

export function encodeMedia(data) {
	const id = data.id

	return (dispatch, getState) => {
		dispatch({
			[RSAA]: {
				types: [
					{
						type: types.ENCODE_MEDIA_REQUEST,
						payload: (action, state, res) => {
							dispatch(addFlashMessage('info', 'Sent media for encode job.'))
						},
					},
					{
						type: types.ENCODE_MEDIA_SUCCESS,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								var message = json.message || 'Media encode job started.'
								console.log('[MEDIA ENCODE AND UPLOAD COMPLETE]')
								return json
							})
						},
					},
					{
						type: types.ENCODE_MEDIA_FAILURE,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								// send flash for error
								dispatch(addFlashMessage('error', json.message))
								return json
							})
						},
					},
				],
				endpoint: `${retrieveURL()}/api/v2/media/${id}/encode-media`,
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				credentials: 'include',
				body: JSON.stringify(data),
			},
		})
	}
}

/* ******************************
 * ENCODE MEDIA OBJECT TO SERVER
 ***************************** */

export function regenerateImage(data, muteAlert = false, next) {
	const id = data.id || data.data.id

	// // Workaroudn until api v2
	// // Needs to check for and inject media id (for authorization middleware to work)
	// if (!data.id && data.data.id) {
	// 	data.id = data.data.id
	// }

	return (dispatch, getState) => {
		dispatch({
			[RSAA]: {
				types: [
					{
						type: types.REGENERATE_MEDIA_REQUEST,
						payload: (action, state, res) => {
							if (!muteAlert) {
								dispatch(addFlashMessage('info', 'Regenerating image...'))
							}
							return data
						},
					},
					{
						type: types.REGENERATE_MEDIA_SUCCESS,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								if (!muteAlert) {
									dispatch(addFlashMessage('success', 'Image processing complete.'))
								}
								if (next) next()
								return json
							})
						},
					},
					{
						type: types.REGENERATE_MEDIA_FAILURE,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								if (!muteAlert) {
									dispatch(addFlashMessage('error', `Error processing image. ${json.message}`))
								}
								return json
							})
						},
					},
				],
				endpoint: `${retrieveURL()}/api/v2/media/${id}/regenerate-image`,
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				credentials: 'include',
				body: JSON.stringify(data),
			},
		})
	}
}

/* ******************************
 * Upscale Media
 ***************************** */
export function upscaleImage(id) {
	return {
		type: MEDIA.POST_UPSCALE_MEDIA_REQUESTED,
		payload: id,
	}
}

/* ******************************
 * SUBMIT PINTURA EDIT
 ***************************** */
export function pinturaEdit(data, muteAlert = false, next) {
	const id = data.id

	return (dispatch, getState) => {
		dispatch({
			[RSAA]: {
				types: [
					{
						type: types.REGENERATE_MEDIA_REQUEST,
						payload: (action, state, res) => {
							if (!muteAlert) {
								dispatch(addFlashMessage('info', 'Regenerating image...'))
							}
							return data
						},
					},
					{
						type: types.REGENERATE_MEDIA_SUCCESS,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								if (!muteAlert) {
									dispatch(addFlashMessage('success', 'Image processing complete.'))
								}
								if (next) next()
								return json
							})
						},
					},
					{
						type: types.REGENERATE_MEDIA_FAILURE,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								if (!muteAlert) {
									dispatch(addFlashMessage('error', `Error processing image. ${json.message}`))
								}
								return json
							})
						},
					},
				],
				endpoint: `${retrieveURL()}/api/v2/media/${id}/pintura`,
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				credentials: 'include',
				body: JSON.stringify(data.data),
			},
		})
	}
}

/* ******************************
 * Set Media as occupied by mediaUuid
 ***************************** */
export function setMediaToOccupied(data) {
	return {
		type: types.REGENERATE_MEDIA_REQUEST,
		payload: data,
	}
}

/* ******************************
 * UPDATE MEDIA OBJECT TO SERVER
 ***************************** */

export function updateMedia(data, showFlash) {
	const id = data.id

	// white listed fields
	const payload = {
		// general
		socketReloadPage: data.socketReloadPage,
		backgroundEligible: data.backgroundEligible,
		foregroundEligible: data.foregroundEligible,
		occupied: data.occupied,
		jobId: data.jobId,

		// text specific
		text: data.text,

		// video specific
		rotation: data.rotation,
		muteBackgroundMusic: data.muteBackgroundMusic,
		muteAudio: data.muteAudio,
		startTime: data.startTime,
		endTime: data.endTime,

		// image specific
		duration: data.duration,
	}

	console.log('PAYLOAD', payload)

	return (dispatch, getState) => {
		return dispatch({
			[RSAA]: {
				types: [
					types.PUT_EVENT_MEDIA_REQUEST,
					{
						type: types.PUT_EVENT_MEDIA_SUCCESS,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								if (showFlash) {
									dispatch(addFlashMessage('success', 'Media Updated'))
								}
								return json
							})
						},
					},
					types.PUT_EVENT_MEDIA_FAILURE,
				],
				endpoint: `${retrieveURL()}/api/v2/media/${id}`,
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				credentials: 'include',
				body: JSON.stringify(payload),
			},
		})
	}
}

/* ******************************
 * CONTRIBUTOR CLAIM EVENT MEDIA
 ***************************** */

export function claimMedia(data, showFlash) {
	return (dispatch, getState) => {
		return dispatch({
			[RSAA]: {
				types: [
					types.CLAIM_EVENT_MEDIA_REQUEST,
					{
						type: types.CLAIM_EVENT_MEDIA_SUCCESS,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								if (showFlash) {
									dispatch(addFlashMessage('success', json.message))
								}
								return json
							})
						},
					},
					types.CLAIM_EVENT_MEDIA_FAILURE,
				],
				endpoint: `${retrieveURL()}/api/v2/media/${data.id}/claim`,
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				credentials: 'include',
				body: JSON.stringify(data),
			},
		})
	}
}

/* ******************************
 * DELETE A MEDIA ITEM
 ***************************** */

export function deleteMedia(id, nonDestructive = false, muteAlert = false) {
	//console.log("DELETE ID Action:", id, nonDestructive, muteAlert)
	return (dispatch, getState) => {
		return dispatch({
			[RSAA]: {
				types: [
					{
						type: types.DELETE_MEDIA_REQUEST,
						payload: (action, state, res) => {
							if (!muteAlert) {
								dispatch(addFlashMessage('info', 'Deleting Media...'))
							}
						},
					},
					{
						type: types.DELETE_MEDIA_SUCCESS,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								if (!muteAlert && json.message) {
									dispatch(addFlashMessage('success', json.message))
								}
								return json
							})
						},
					},
					{
						type: types.DELETE_MEDIA_FAILURE,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								if (!muteAlert && json.message) {
									dispatch(addFlashMessage('error', 'Media delete failed.'))
								}
								return json
							})
						},
					},
				],
				endpoint: retrieveURL() + '/api/v2/media/' + id,
				method: 'DELETE',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ id: id, nonDestructive: nonDestructive }),
			},
		})
	}
}

/* ******************************
 * UNARCHIVE MEDIA ITEM
 ***************************** */
export const unArchiveMedia = (data) => ({
	type: MEDIA.POST_UNARCHIVE_MEDIA_REQUESTED,
	payload: data.id,
})

/* ******************************
 * DUPLICATE MEDIA ITEM
 ***************************** */
export const duplicateMedia = (data) => {
	const mediaUuid = randomBytes(16).toString('hex')
	return {
		type: MEDIA.POST_DUPLICATE_MEDIA_REQUESTED,
		payload: { data: { id: data.id, mediaUuid: mediaUuid } },
	}
}

/* ******************************
 * RESTORE MEDIA ITEM
 ***************************** */
export const restoreAllMedia = (data) => ({
	type: MEDIA.POST_RESTORE_ALL_MEDIA_REQUESTED,
	payload: data.eventId,
})

/* ******************************
 * RESTORE PUBLISHED MEDIA ITEM
 ***************************** */
export const restorePublishedMedia = (data) => ({
	type: PUBLISHED_MEDIA.PUT_RESTORE_PUBLISHED_MEDIA_REQUESTED,
	payload: data.id,
})

/* ******************************
 * MUSIC TRACK ITEMS
 ***************************** */
export const fetchTracks = (data) => ({
	type: TRACKS.FETCH_REQUESTED,
	payload: data,
})

export const postTrack = (data) => ({
	type: TRACKS.POST_REQUESTED,
	payload: data,
})

export const deleteTrack = (trackId) => ({
	type: TRACKS.DELETE_REQUESTED,
	payload: trackId,
})
