import { mode } from '@chakra-ui/theme-tools'

/** Global styles *Only* */
export default {
	global: (props) => {
		return {
			'body': {
				fontFamily: 'body',
				color: mode('gray.800', 'whiteAlpha.900')(props),
				bg: mode('light', 'gray.900')(props),
				transitionProperty: 'background-color',
				transitionDuration: 'normal',
				lineHeight: 'base',
				height: '100vh',
			},
			'*::placeholder': {
				color: mode('gray.400', 'whiteAlpha.400')(props),
			},
			'*, *::before, &::after': {
				borderColor: mode('gray.200', 'whiteAlpha.300')(props),
				wordWrap: 'break-word',
			},
			'* :focus:not([data-focus-visible-added])': {
				outline: 'none !important',
				boxShadow: 'none !important',
			},
		}
	},
}
