import { Button, Box, Container, Divider, Heading, Link, Text, VStack, Image, Spinner } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { LiveChatButton } from '../RefreshPage'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'

const backgroundStyles = {
	h: 'calc(100vh - 54px)',
	backgroundPosition: 'center 60px',
	backgroundRepeat: 'no-repeat',
	backgroundSize: '250px',
	paddingTop: '100px',
	marginTop: 'calc(-1rem)',
	color: 'white',
	backgroundColor: '#263340',
}

const PageInfoWithBG = ({ children }) => {
	return <Box sx={backgroundStyles}>{children}</Box>
}

PageInfoWithBG.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

const Error404 = ({ layout }) => {
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		let loadingTimer = setTimeout(() => setLoading(false), 4000)
		console.log('ERROR404 CALLED, are we loading?', loading)

		return () => {
			clearTimeout(loadingTimer)
		}
	}, [])

	return (
		<>
			<Helmet>
				<title>VidDay - Uh Oh, Page not found</title>
			</Helmet>
			<PageInfoWithBG className="app-content">
				<Container maxW="container.md">
					{loading && (
						<VStack mb="2rem" spacing="1rem" w="full" textAlign="center">
							<Image
								src="/assets/images/graphics/vidday_graphic_parachute.svg"
								alt="Searching..."
								maxW="300px"
							/>
							<Heading as="h2" variant="hero" size="lg">
								Searching ...
							</Heading>
							<Spinner thickness="4px" speed="0.45s" emptyColor="gray.200" color="link" size="md" />
						</VStack>
					)}

					{!loading && (
						<>
							{layout == 'event-404' ? (
								<>
									<VStack spacing="1rem" textAlign="center" w="full">
										<Image
											src="/assets/images/graphics/event-page-not-found-animated.svg"
											alt="Event 404"
											maxW="300px"
										/>
										<Heading as="h2" variant="hero" size="lg">
											Event Page not found
										</Heading>
										<Text color="white">
											There seems to be an error with the link you used as this page does not
											exist.
										</Text>
										<Text color="white">
											Contact the person who invited you or start a chat with a VidDay Customer
											Care Specialist. We&apos;re happy to help!
										</Text>
										<LiveChatButton />
									</VStack>

									<Divider my="1rem" />

									<VStack spacing="1rem" textAlign="center" w="full">
										<Text color="white">
											If you&apos;re the creator of an event,{' '}
											<Link variant="link" as="a" href="/auth/login">
												Log In
											</Link>
										</Text>
									</VStack>
								</>
							) : (
								<>
									<VStack spacing="1rem" textAlign="center" w="full">
										<Image
											src="/assets/images/graphics/404_graphic_animated.svg"
											alt="Error 404"
											maxW="300px"
										/>
										<Heading as="h2" variant="hero" size="lg">
											Page not found
										</Heading>
										<Text color="white">
											There seems to be an error with the link you used as this page does not
											exist.
										</Text>
										<Button color="white" variant="solid" as={RouterLink} to="/dashboard">
											Go to Home
										</Button>
									</VStack>

									<Divider my="1rem" />

									<VStack spacing="1rem" textAlign="center" w="full">
										<Text>
											If you need support, start a chat with a VidDay Customer Care Specialist.
											We&apos;re happy to help!
										</Text>
										<LiveChatButton />
									</VStack>
								</>
							)}
						</>
					)}
				</Container>
			</PageInfoWithBG>
		</>
	)
}

Error404.propTypes = {
	layout: PropTypes.string,
}

export default Error404
