import PropTypes from 'prop-types'

/* **************************************
 *  Event Invoice
 * ************************************ */

const propTypes = {
	receiptUrl: PropTypes.string.isRequired,
	refunded: PropTypes.bool,
	promoCode: PropTypes.string,
	amount: PropTypes.number,
	status: PropTypes.string,
	archived: PropTypes.bool,
}

const defaultProps = {
	refunded: false,
	promoCode: null,
	archived: false,
}

const EventInvoice = { propTypes, defaultProps }

export default EventInvoice
