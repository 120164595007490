import { mode, getColor } from '@chakra-ui/theme-tools'

function getBg(props) {
	const { theme, colorScheme: c } = props
	const lightBg = getColor(theme, `${c}.100`, c)
	const darkBg = getColor(theme, `${c}.100`, c) //transparentize(`${c}.200`, 0.16)(theme)
	return mode(lightBg, darkBg)(props)
}

const Alert = {
	baseStyle: () => {
		return {
			container: {},
			title: {},
			description: {},
			icon: {},
			spinner: {},
		}
	},
	variants: {
		subtle: (props) => {
			const { colorScheme: c } = props
			return {
				container: {
					bg: getBg(props),
					color: mode(`gray.700`, `gray.900`)(props),
					borderRadius: '.5rem',
				},
				icon: { color: mode(`${c}.500`, `${c}.500`)(props) }, // { color: mode(`${c}.500`, `${c}.200`)(props) },
				spinner: {
					color: mode(`${c}.500`, `${c}.200`)(props),
				},
			}
		},
		solid: (props) => {
			const { colorScheme: c } = props
			return {
				container: {
					bg: mode(`${c}.500`, `${c}.200`)(props),
					color: mode(`white`, `gray.900`)(props),
					borderRadius: '.5rem',
				},
				icon: { color: mode(`white`, `gray.900`)(props) }, // { color: mode(`${c}.500`, `${c}.200`)(props) },
				spinner: {
					color: mode(`white`, `gray.900`)(props),
				},
			}
		},
	},
}

export default Alert
