import { useMemo, useEffect, useState } from 'react'
import { getRecipients } from '@vidday/utils'
import { useSelector, shallowEqual } from 'react-redux'

/** V2 version, with default state */
export const useEventRecipients = (event) => {
	const allRecipients = useSelector((s) => s.entities.recipients.entities, shallowEqual)
	const recipients = useSelector((s) => s.event.recipients, shallowEqual)
	const temporaryEventRecipients = useSelector((s) => s.temporaryEvent?.data?.recipients, shallowEqual)
	const [eventRecipients, setEventRecipients] = useState(recipients)

	useEffect(() => {
		let recips =
			event && event?.recipients
				? Object.values(allRecipients).filter((p) => {
						return event.recipients.includes(p.id)
				  })
				: temporaryEventRecipients
				? temporaryEventRecipients
				: recipients

		setEventRecipients(recips)
	}, [event, recipients, temporaryEventRecipients, allRecipients])

	const formattedRecipients = useMemo(() => {
		return getRecipients(eventRecipients, true).names
	}, [eventRecipients])

	const formattedFirstnames = useMemo(() => {
		return getRecipients(eventRecipients, false).names
	}, [eventRecipients])
	return {
		raw: eventRecipients,
		formatted: formattedRecipients,
		firstname: formattedFirstnames,
	}
}
