import { mode } from '@chakra-ui/theme-tools'

const Text = {
	baseStyle: (props) => {
		return {
			color: mode('gray.800', 'gray.300')(props),
		}
	},
	sizes: {},
	variants: {
		title: (props) => {
			return {
				fontSize: '1.125rem',
				fontWeight: '700',
				color: mode('gray.900', 'white')(props),
			}
		},
		mediaButtonLabel: () => {
			return {
				textAlign: 'center',
				fontSize: '0.75rem',
				color: 'gray.900',
			}
		},
		mediaGalleryText: () => {
			return {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				width: '100%',
				height: '100%',
				padding: '0 4px',
				color: 'gray.900',
				fontSize: '95%',
				lineHeight: '140%',
				textOverflow: 'ellipsis',
				wordBreak: 'break-word',
				overflow: 'hidden',
			}
		},
	},
	defaultProps: {
		colorScheme: 'gray',
	},
}

export default Text
