const baseStyle = {
	coverWrapper: {
		minH: '80px',
		minW: '80px',
		height: '80px',
		width: '80px',
		overflow: 'hidden',
	},
	card: {
		flexDirection: ['column', 'column', 'row'],
		alignItems: 'center',
	},
	content: {
		direction: 'row',
		justifyContent: ['center', 'center', 'left'],
		alignItems: ['center', 'center', 'flex-start'],
		w: 'full',
		ml: [0, 0, '1rem'],
		mt: ['1rem', '1rem', 0],
	},
	figure: {
		h: ['180px', '180px', '130px'],
		w: ['full', 'full', '200px'],
		borderTopWidth: [0, 0, '1px'],
		borderBottomWidth: [0, 0, '1px'],
		borderLeftWidth: [0, 0, '1px'],
		borderRightWidth: [0, 0, '1px'],
		borderColor: ['white', 'white', '#D2DBE4'],
		borderStyle: ['hidden', 'hidden', 'solid'],
	},
	buttonWrap: {
		justifyContent: ['center', 'center', 'right'],
		alignItems: 'center',
		w: 'full',
		button: {
			marginX: ['0rem', '0rem', '1rem'],
			marginY: ['1rem', '1rem', '0rem'],
		},
	},
}

const variants = {}

const defaultProps = {}

export default {
	baseStyle,
	parts: ['themeCover', 'logoCard', 'content', 'figure', 'buttonWrap'],
	variants,
	defaultProps,
}
