import { useRef, useEffect } from 'react'
import useSSR from 'use-ssr'

function useEventListener(eventName, handler, element, opts = false) {
	// Create a ref that stores handler
	const savedHandler = useRef()

	const { isBrowser } = useSSR()

	// Update ref.current value if handler changes.
	// This allows our effect below to always get latest handler ...
	// ... without us needing to pass it in effect deps array ...
	// ... and potentially cause effect to re-run every render.
	useEffect(() => {
		savedHandler.current = handler
	}, [handler])

	useEffect(
		() => {
			// Lets make sure we are in a window client-side
			if (isBrowser) {
				// Get node element or fallback to document
				const nodeElement = element && element.addEventListener ? element : document

				// Make sure element supports addEventListener
				const isSupported = nodeElement && nodeElement.addEventListener
				if (!isSupported) return

				// Create event listener that calls handler function stored in ref
				const eventListener = (event) => savedHandler.current(event)

				// Add event listener
				nodeElement.addEventListener(eventName, eventListener, opts)

				// Remove event listener on cleanup
				return () => {
					nodeElement.removeEventListener(eventName, eventListener, opts)
				}
			}
		},
		[eventName, element, isBrowser] // Re-run if eventName or element changes
	)
}

export default useEventListener
