import { useColorMode } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const baseStyle = (props) => {
	return {
		stack: {
			w: 'full',
			cursor: 'pointer',
		},
		cover: {
			minH: '5rem',
			minW: '5rem',
			h: '5rem',
			w: '5rem',
			borderLeftRadius: 'xl',
			overflow: 'hidden',
		},
		figure: {
			maxH: ['180px', '180px', '130px'],
			maxW: ['full', 'full', '200px'],
			borderTopWidth: '1px',
			borderBottomWidth: '1px',
			borderLeftWidth: '1px',
			borderRightWidth: '1px',
			borderColor: '#D2DBE4',
			borderStyle: 'solid',
		},
		themeCover: {
			minH: '130px',
			minW: '200px',
			overflow: 'hidden',
		},
		logoCard: {
			flexDirection: 'column',
			alignItems: 'center',
		},
		card: {
			h: '5rem',
			flexDirection: 'row',
			alignItems: 'center',
		},
		content: {
			flexGrow: 1,
		},
		title: {
			fontWeight: 'bold',
			cursor: 'pointer',
			color: mode('gray.900', 'white')(props),
			fontSize: '1rem',
		},
		description: {
			marginTop: '-.25rem',
			fontSize: '0.875rem',
			cursor: 'pointer',
			color: mode('gray.800', 'gray.300')(props),
			a: {
				color: mode('gray.800', 'gray.300')(props),
			},
		},
		actionsContainer: {
			justifyContent: 'flex-end',
			display: 'flex',
			pr: '1rem',
		},
		cardOptions: {},
		cardButton: {
			borderRadius: '50%',
			width: '2.5rem',
			height: '2.5rem',
			backgroundColor: mode('blueAlpha.200', 'whiteAlpha.200')(props),
			_hover: {
				backgroundColor: mode('blueAlpha.300', 'whiteAlpha.300')(props),
			},
			svg: {
				color: mode('blue.500', 'white')(props),
				fill: mode('blue.500', 'white')(props),
			},
		},
	}
}

const variantSimple = () => {
	return {}
}

const variants = {
	simple: variantSimple,
	outline: (props) => {
		return {
			card: {
				backgroundColor: 'transparent',
				border: '1px solid',
				borderColor: 'whiteAlpha.300',
				borderRadius: '1rem',
				backgroundClip: 'padding-box',
			},
			title: {
				color: 'white',
			},
			description: {
				color: 'gray.300',
			},
		}
	},
}

const defaultProps = {
	variant: 'simple',
}

export default {
	baseStyle,
	parts: [
		'wrapper',
		'cover',
		'figure',
		'card',
		'themeCover',
		'logoCard',
		'content',
		'title',
		'description',
		'options',
		'button',
	],
	variants,
	defaultProps,
}
