import React, { createContext, useContext, useEffect, useState } from 'react'
import io from 'socket.io-client'

// Socket.io docs recommends this loose structure for using socket.io with hooks
// https://socket.io/how-to/use-with-react-hooks

// target socket.io namespace
const publicNamespace = '/public'
const usersNamespace = '/users'
const staffNamespace = '/staff'

/** create socket.io client for public namespace */
const publicSocket = io(publicNamespace, {
	addTrailingSlash: false,
	reconnectionDelay: 500, // ms (shortened from default of 1000)
	reconnectionDelayMax: 3000, // ms (shortened from default of 5000)
	timeout: 10000, // ms (should somewhat match serverside one)
	transports: ['websocket'], // set to only 'websocke' to not allow http long polling
	withCredentials: true, // we don't want any session information
})

/** create socket.io client for users namespace */
const usersSocket = io(usersNamespace, {
	addTrailingSlash: false,
	reconnectionDelay: 500, // ms (shortened from default of 1000)
	reconnectionDelayMax: 3000, // ms (shortened from default of 5000)
	timeout: 10000, // ms (should somewhat match serverside one)
	transports: ['websocket'], // set to only 'websocke' to not allow http long polling
	withCredentials: true, // include cookies (to have access to session)
})

/** create socket.io client for staff namespace */
const staffSocket = io(staffNamespace, {
	addTrailingSlash: false,
	reconnectionDelay: 500, // ms (shortened from default of 1000)
	reconnectionDelayMax: 3000, // ms (shortened from default of 5000)
	timeout: 10000, // ms (should somewhat match serverside one)
	transports: ['websocket'], // set to only 'websocke' to not allow http long polling
	withCredentials: true, // include cookies (to have access to session)
})

/** store socket.io client in SocketContext */
const SocketContext = createContext({
	public: { socket: publicSocket },
	users: { socket: usersSocket },
	staff: { socket: staffSocket },
})
const useSocketContext = () => useContext(SocketContext)

/** provider of socket.io client */
const SocketProvider = ({ children }) => {
	const [publicIsConnected, setPublicIsConnected] = useState(publicSocket.connected)
	const [usersIsConnected, setUsersIsConnected] = useState(usersSocket.connected)
	const [staffIsConnected, setStaffIsConnected] = useState(staffSocket.connected)
	const [lastPong, setLastPong] = useState(null)

	/** SETUP public "/public" NAMESPACE */
	useEffect(() => {
		publicSocket.on('connect', () => {
			console.info(`Socket ${publicSocket.id} connected to "${publicSocket.nsp}".`)
			setPublicIsConnected(true)
		})

		// handle "connect_error" events (failure to connect to socket.io server)
		publicSocket.on('connect_error', (err) => {
			console.info(`Unable to connect to "${publicSocket.nsp}" namespace : `, err.message)
		})

		// handle general "error" event, log and do nothing for now
		publicSocket.on('error', (err) => {
			console.error(`Socket.io client error : `, err.message)
		})

		// handle "disconnect" events
		publicSocket.on('disconnect', (reason) => {
			console.info(`Socket client disconnected because : "${reason}".`)
			setUsersIsConnected(false)

			// // Default behaviour is to not attempt reconnect for purposeful disconnects
			// if (reason === 'io server disconnect' || reason === 'io client disconnect') {
			// 	console.info(`Socket client attempting reconnect...`)
			// 	socket.connect()
			// }
		})

		// handle "pong" events
		publicSocket.on('pong', () => {
			const now = new Date().toISOString()
			setLastPong(now)
			console.info(`Socket ${publicSocket.id} received pong at ${now}.`)
		})

		// need to deregister all event listeners when unmounting
		return () => {
			publicSocket.off('connect')
			publicSocket.off('connect_error')
			publicSocket.off('error')
			publicSocket.off('disconnect')
			publicSocket.off('pong')
		}
	}, [])

	/** SETUP private "/users" NAMESPACE */
	useEffect(() => {
		usersSocket.on('connect', () => {
			console.info(`Socket ${usersSocket.id} connected to "${usersSocket.nsp}".`)
			setUsersIsConnected(true)
		})

		// handle "connect_error" events (failure to connect to socket.io server)
		usersSocket.on('connect_error', (err) => {
			console.info(`Unable to connect to "${usersSocket.nsp}" namespace : `, err.message)
		})

		// handle general "error" event, log and do nothing for now
		usersSocket.on('error', (err) => {
			console.error(`Socket.io client error : `, err.message)
		})

		// handle "disconnect" events
		usersSocket.on('disconnect', (reason) => {
			console.info(`Socket client disconnected because : "${reason}".`)
			setUsersIsConnected(false)

			// // Default behaviour is to not attempt reconnect for purposeful disconnects
			// if (reason === 'io server disconnect' || reason === 'io client disconnect') {
			// 	console.info(`Socket client attempting reconnect...`)
			// 	socket.connect()
			// }
		})

		// handle "pong" events
		usersSocket.on('pong', () => {
			const now = new Date().toISOString()
			setLastPong(now)
			console.info(`Socket ${usersSocket.id} received pong at ${now}.`)
		})

		// need to deregister all event listeners when unmounting
		return () => {
			usersSocket.off('connect')
			usersSocket.off('connect_error')
			usersSocket.off('error')
			usersSocket.off('disconnect')
			usersSocket.off('pong')
		}
	}, [])

	/** SETUP semi-private "/staff" NAMESPACE */
	useEffect(() => {
		staffSocket.on('connect', () => {
			console.info(`Socket ${staffSocket.id} connected to "${staffSocket.nsp}".`)
			setUsersIsConnected(true)
		})

		// handle "connect_error" events (failure to connect to socket.io server)
		staffSocket.on('connect_error', (err) => {
			console.info(`Unable to connect to "${staffSocket.nsp}" namespace : `, err.message)
		})

		// handle general "error" event, log and do nothing for now
		staffSocket.on('error', (err) => {
			console.error(`Socket.io client error : `, err.message)
		})

		// handle "disconnect" events
		staffSocket.on('disconnect', (reason) => {
			console.info(`Socket client disconnected because : "${reason}".`)
			setUsersIsConnected(false)

			// // Default behaviour is to not attempt reconnect for purposeful disconnects
			// if (reason === 'io server disconnect' || reason === 'io client disconnect') {
			// 	console.info(`Socket client attempting reconnect...`)
			// 	socket.connect()
			// }
		})

		// handle "pong" events
		staffSocket.on('pong', () => {
			const now = new Date().toISOString()
			setLastPong(now)
			console.info(`Socket ${staffSocket.id} received pong at ${now}.`)
		})

		// need to deregister all event listeners when unmounting
		return () => {
			staffSocket.off('connect')
			staffSocket.off('connect_error')
			staffSocket.off('error')
			staffSocket.off('disconnect')
			staffSocket.off('pong')
		}
	}, [])

	/** useful for debugging, leave it commented out otherwise */
	// useEffect(() => {
	// 	const interval = setInterval(() => {
	// 		socket.emit('ping')
	// 	}, 1000)
	// 	return () => clearInterval(interval)
	// }, [])

	const sockets = {
		public: {
			socket: publicSocket,
			isConnected: publicIsConnected,
		},
		users: {
			socket: usersSocket,
			isConnected: usersIsConnected,
		},
		staff: {
			socket: staffSocket,
			isConnected: staffIsConnected,
		},
	}

	return <SocketContext.Provider value={sockets}>{children}</SocketContext.Provider>
}

/** helper to manually trigger a connection attempt a socket.io client */
// Do not use this unless you know what you are doing! There will be a test ;)
const forceConnect = (socket) => {
	if (!socket.connected) {
		console.info(`Attempting connection to namespace "${socket.nsp}"`)
		socket.connect()
	} else {
		console.info(`Socket ${socket.id} is already connected to "${socket.nsp}".`)
	}
}

export default SocketProvider
export { useSocketContext, forceConnect }
