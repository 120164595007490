const NOTIFICATIONS = {
	FETCH_REQUESTED: 'NOTIFICATIONS_FETCH_REQUESTED',
	FETCH_SUCCEEDED: 'NOTIFICATIONS_FETCH_SUCCEEDED',
	FETCH_FAILED: 'NOTIFICATIONS_FETCH_FAILED',

	MARK_AS_SEEN_REQUESTED: 'NOTIFICATIONS_MARK_AS_SEEN_REQUESTED',
	MARK_AS_SEEN_SUCCEEDED: 'NOTIFICATIONS_MARK_AS_SEEN_SUCCEEDED',
	MARK_AS_SEEN_FAILED: 'NOTIFICATIONS_MARK_AS_SEEN_FAILED',

	MARK_AS_READ_REQUESTED: 'NOTIFICATIONS_MARK_AS_READ_REQUESTED',
	MARK_AS_READ_SUCCEEDED: 'NOTIFICATIONS_MARK_AS_READ_SUCCEEDED',
	MARK_AS_READ_FAILED: 'NOTIFICATIONS_MARK_AS_READ_FAILED',

	MARK_AS_UNREAD_REQUESTED: 'NOTIFICATIONS_MARK_AS_UNREAD_REQUESTED',
	MARK_AS_UNREAD_SUCCEEDED: 'NOTIFICATIONS_MARK_AS_UNREAD_SUCCEEDED',
	MARK_AS_UNREAD_FAILED: 'NOTIFICATIONS_MARK_AS_UNREAD_FAILED',

	MARK_AS_DISMISSED_REQUESTED: 'NOTIFICATIONS_MARK_AS_DISMISSED_REQUESTED',
	MARK_AS_DISMISSED_SUCCEEDED: 'NOTIFICATIONS_MARK_AS_DISMISSED_SUCCEEDED',
	MARK_AS_DISMISSED_FAILED: 'NOTIFICATIONS_MARK_AS_DISMISSED_FAILED',
}

export { NOTIFICATIONS }
