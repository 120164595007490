import { mode } from '@chakra-ui/theme-tools'

const Select = {
	variants: {
		outline: (props) => {
			return {
				field: {
					bg: mode('rgba(232,236,240,0.5)', 'gray.700')(props),
				},
				addon: {},
			}
		},
	},
}

export default Select
