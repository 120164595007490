import { createSlice } from '@reduxjs/toolkit'
import { combineFormState } from './formState'

const initialState = {
	data: {
		message: '',
		eventId: null,
		userArray: [],
	},
}

/** Reducer for the Register form */
const rootSlice = createSlice(
	combineFormState(
		'BROADCAST_MESSAGE',
		{
			name: 'forms.BroadcastMessage',
			initialState,
			reducers: {},
		},
		true
	)
)

// Export Form Reducer
export const reducer = rootSlice.reducer

// Export our actions
export const { reset } = rootSlice.actions
