import { createSlice } from '@reduxjs/toolkit'
import { INVOICES } from '../api/checkout/constants'

/**
 * This reducer correspond to the products available throughout the checkout module.
 * These are not the products contained in the cart, but rather all available options.
 */

const initialState = {
	/** Processing status */
	isFetching: false,
	status: null, // or error, success
	statusMessage: '',
	data: [],
}

const InvoicesSlice = createSlice({
	initialState: initialState,
	name: 'products',
	extraReducers: {
		[INVOICES.FETCH_REQUESTED]: (state, action) => {
			state.isFetching = true
		},
		[INVOICES.FETCH_SUCCEEDED]: (state, action) => {
			if (action.payload && action.payload.entities && action.payload.entities.invoices) {
				state.data = action.payload.entities.invoices
				state.ids = action.payload.result
				state.isFetching = false
				state.hasError = false
			}
		},
		[INVOICES.FETCH_FAILED]: (state, action) => {
			state.hasError = true
			state.isFetching = false
			state.errorMessage = 'An error occured while fetching.'
		},
	},
	reducers: {},
})

export default InvoicesSlice.reducer
