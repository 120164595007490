import { useWidgetIsReady } from '@livechat/widget-react'
import { useEffect } from 'react'
import { useAuthContext } from '../../../../contexts/AuthProvider'

const getFullName = (firstName, lastName) => {
	const first = firstName || 'Guest'
	const last = lastName || false
	return first + (last ? ` ${last}` : ``)
}

const useUserMeta = () => {
	const isReady = useWidgetIsReady()

	/** Retrieve Authed User */
	const { email, profile, userId } = useAuthContext()

	useEffect(() => {
		if (isReady) {
			if (userId && profile) {
				const fullName = getFullName(profile.firstName, profile.lastName)
				LiveChatWidget.call('set_customer_name', fullName)
			}
			if (email) {
				LiveChatWidget.call('set_customer_email', email)
			}
		}
	}, [isReady, userId, profile, email])
}

export default useUserMeta
