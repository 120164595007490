import React from 'react'
import { Box } from '@chakra-ui/react'

const draggerStyles = {
	'pos': 'absolute',
	'top': '-4px',
	'bottom': '0',
	'width': '8px',
	'background': 'white',
	'cursor': 'col-resize',
	'height': '26px',
	'borderRadius': '1px',
	// zIndex: ${(props) => props.zIndex},
	'boxShadow': '0px 0px 5px 0px rgba(0, 0, 0, 0.4)',
	'_hover': {
		background: 'white',
	},
	'& .rvt-cursor': {
		display: 'none',
	},
	'& .rvt-cursor.isCurrent': {
		display: 'block',
	},
	'&:hover .rvt-cursor': {
		display: 'block',
	},
	/* Start and End of trimming */
	'&:hover .rvt-cursor': {
		backgroundColor: 'white',
	},

	'&:hover .rvt-cursor::after': {
		borderTopColor: 'white',
	},
	/* Current time */
	'& .rvt-cursor.isCurrent': {
		cursor: 'default',
	},
	'&:hover .rvt-cursor.isCurrent': {
		backgroundColor: 'white',
	},
	'&:hover .rvt-cursor.isCurrent::after': {
		borderTopColor: 'white',
	},
}

const currentDraggerStyles = {
	width: '12px',
	// background: ${props.baseColor},
	height: '20px',
	// borderLeft: '${props.accentColor}CC solid 5px',
	// borderRight: '${props.accentColor}CC solid 5px',
	borderRadius: '0',
	boxShadow: 'none',
	top: '0',
	transform: 'translate(-6px)',
	_hover: {
		// background: '${props.baseColor}',
		filter: 'brightness(80%)',
	},
}

/** Utils method to merge base "current" dragger styles with computed values */
const getDraggerColorStyles = ({ baseColor, accentColor }) => {
	return {
		...currentDraggerStyles,
		...{
			background: baseColor,
			borderLeft: `${accentColor}CC solid 5px`,
			borderRight: `${accentColor}CC solid 5px`,
			_hover: {
				...currentDraggerStyles._hover,
				...{
					background: baseColor,
				},
			},
		},
	}
}

const StyledDragger = ({ zIndex, isCurrent, baseColor, accentColor, start, end, x, children, ...rest }) => {
	/** Combine final styles */
	const styles = isCurrent
		? { ...draggerStyles, ...getDraggerColorStyles({ baseColor, accentColor }) }
		: draggerStyles
	return (
		<Box
			// Combined styles
			sx={styles}
			// overwrite index of the element
			zIndex={zIndex}
			// Position of the element (this is computed on the fly)
			left={start ? `${x - 8}px` : end ? `${x}px` : `${x - 2}px`}
			top={!isCurrent ? `-3px` : `0px`}
			{...rest}>
			{children}
		</Box>
	)
}

// const StyledDragger = styled.div`
// 	position: absolute;
// 	top: -4px;
// 	bottom: 0;
// 	width: 8px;
// 	background: white;
// 	cursor: col-resize;
// 	height: 26px;
// 	border-radius: 1px;
// 	z-index: ${(props) => props.zIndex};
// 	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);

// 	&:hover {
// 		background: white;
// 	}

// 	${(props) =>
// 		props.isCurrent &&
// 		css`
// 			// z-index: 10000;
// 			width: 12px;
// 			background: ${props.baseColor};
// 			height: 20px;
// 			border-left: ${props.accentColor}CC solid 5px;
// 			border-right: ${props.accentColor}CC solid 5px;
// 			border-radius: 0;
// 			box-shadow: none;
// 			top: 0px;
// 			transform: translate(-6px);
// 			&:hover {
// 				background: ${props.baseColor};
// 				filter: brightness(80%);
// 			}
// 		`}

// 	.rvt-cursor {
// 		display: none;
// 	}
// 	.rvt-cursor.isCurrent {
// 		display: block;
// 	}
// 	&:hover .rvt-cursor {
// 		display: block;
// 	}

// 	/* Start and End of trimming */
// 	&:hover .rvt-cursor {
// 		background-color: white;
// 	}
// 	&:hover .rvt-cursor::after {
// 		border-top-color: white;
// 	}

// 	/* Current time */
// 	& .rvt-cursor.isCurrent {
// 		cursor: default;
// 	}
// 	&:hover .rvt-cursor.isCurrent {
// 		background-color: white;
// 	}
// 	&:hover .rvt-cursor.isCurrent::after {
// 		border-top-color: white;
// 	}
// `

const Dragger = ({
	accentColor,
	baseColor,
	onDrag,
	x,
	isCurrent = false,
	start,
	end,
	children,
	onDragging,
	zIndex,
	onPausePlayer,
	playing,
	onPlayerPlay,
}) => {
	let _screenX = null
	let _ox = null

	const handleMouseDown = (e) => {
		console.log(e, e.screenX, x, 'handleMouseDown')
		_screenX = e.screenX
		_ox = x || 0
		onPausePlayer && onPausePlayer()
		onDragging(true)
		window.addEventListener('mousemove', handleMouseMove, false)
		window.addEventListener('mouseup', handleMouseUp, false)
	}

	const handleMouseMove = (e) => {
		onDrag({
			x: e.screenX - _screenX + _ox,
		})
	}

	const handleMouseUp = () => {
		playing && onPlayerPlay()
		onDragging(false)
		window.removeEventListener('mousemove', handleMouseMove)
		window.removeEventListener('mouseup', handleMouseUp)
	}

	/// Mobile Touch Events
	const handleTouchStart = (e) => {
		_screenX = e.touches[0].screenX
		_ox = x
		onPausePlayer && onPausePlayer()
		onDragging(true)
		window.addEventListener('touchmove', handleTouchMove, false)
		window.addEventListener('touchend', handleTouchEnd, false)
	}

	const handleTouchMove = (e) => {
		onDrag({
			x: e.touches[0].screenX - _screenX + _ox,
		})
	}

	const handleTouchEnd = () => {
		playing && onPlayerPlay()
		onDragging(false)
		window.removeEventListener('touchmove', handleTouchMove)
		window.removeEventListener('touchend', handleTouchEnd)
	}

	return (
		<StyledDragger
			title={isCurrent ? 'Play Position' : start ? 'Trim Start' : 'Trim End'}
			isCurrent={isCurrent}
			accentColor={accentColor}
			baseColor={baseColor}
			onMouseDown={(e) => handleMouseDown(e)}
			onTouchStart={(e) => handleTouchStart(e)}
			zIndex={zIndex}
			start={start}
			end={end}
			x={x}>
			{children}
		</StyledDragger>

		// <StyledDragger
		// 	title={isCurrent ? 'Play Position' : start ? 'Trim Start' : 'Trim End'}
		// 	isCurrent={isCurrent}
		// 	accentColor={accentColor}
		// 	baseColor={baseColor}
		// 	onMouseDown={(e) => handleMouseDown(e)}
		// 	onTouchStart={(e) => handleTouchStart(e)}
		// 	zIndex={zIndex}
		// 	style={{
		// 		left: start ? `${x - 8}px` : end ? `${x}px` : `${x - 2}px`,
		// 		top: !isCurrent ? `-3px` : `0px`,
		// 	}}>
		// 	{children}
		// </StyledDragger>
	)
}

export default Dragger
