import PropTypes from 'prop-types'

/* **************************************
 *  USER
 * ************************************ */

const propTypes = {
	email: PropTypes.string.isRequired,
	temporaryEmail: PropTypes.string,
	/** Password should not be shown */
	// password:PropTypes.string.isRequired,
	facebookId: PropTypes.string.isRequired,
	googleId: PropTypes.string.isRequired,
	appleId: PropTypes.string.isRequired,
	activationToken: PropTypes.string,
	isActive: PropTypes.bool.isRequired,
	activatedAt: PropTypes.instanceOf(Date).isRequired,
	lastActive: PropTypes.instanceOf(Date).isRequired,
	resetPasswordToken: PropTypes.string,
	resetPasswordExpires: PropTypes.instanceOf(Date),
	role: PropTypes.oneOf(['admin', 'editor', 'user']).isRequired,
	blocked: PropTypes.bool.isRequired,
}

const defaultProps = {
	email: '',
	temporaryEmail: null,
	// password:'',
	facebookId: null,
	googleId: null,
	appleId: null,
	activationToken: null,
	isActive: false,
	activatedAt: null,
	lastActive: null,
	resetPasswordToken: null,
	resetPasswordExpires: null,
	role: 'user',
	blocked: false,
}

const User = { propTypes, defaultProps }

export default User
