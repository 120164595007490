import { createSlice } from '@reduxjs/toolkit'
import { combineFormState } from './formState'

const initialState = {
	data: {
		email: '',
	},
}

/** Reducer for the Register form */
const rootSlice = createSlice(
	combineFormState('UPDATE_AUTHED_USER_PRIMARY_EMAIL', {
		name: 'forms.UserEmail',
		initialState,
		reducers: {
			updateEmail: (state, action) => {
				state.data.email = action.payload
			},
		},
	})
)

// Export Form Reducer
export const reducer = rootSlice.reducer

// Export our actions
export const { reset, updateEmail } = rootSlice.actions
