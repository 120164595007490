import { useEffect, useState } from 'react'
import useSSR from 'use-ssr'
import useStandaloneDetect from './useStandaloneDetect'

/**
 * Hook that sends an event to gtm with the users displayMode
 * Will eventually generalize this hook.
 */

export const useAppInstalledEvent = () => {
	const appInstalled = useStandaloneDetect()

	useEffect(() => {
		let dataLayer = window.dataLayer || []

		// ensure we only push if appInstalled is false or true
		;(() => {
			if (appInstalled !== undefined) {
				dataLayer.push({ event: 'is_app_installed', appModel: { appInstalled: appInstalled } })
			}
		})()
	}, [appInstalled])
}

export default useAppInstalledEvent
