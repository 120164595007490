import { createSlice } from '@reduxjs/toolkit'
import { LOCATION, CART, COUNTRY } from '../../api/checkout/constants'

const initialState = {
	/** Processing status */
	isFetching: true,
	isFetchingCountries: true,
	/** Location status */
	status: null, // or error, success
	statusMessage: '',
	/** Location found through the user's profile */
	profileLocation: {
		country: null,
		region: null,
		currency: null,
	},
	profileLocationStatus: null, // or error, success
	profileLocationStatusMessage: '',
	profileLocationIsFetching: true,
	/** Location data retrieved through Geo-IP */
	ipLocation: null,
	ipLocationStatus: null, // or error, success
	ipLocationStatusMessage: '',
	/** Location that has been determined and used by checkout */
	location: {
		country: null,
		region: null,
		postalCode: null,
	},
	/** Postal code checking -related information */
	isCheckingPostalCode: false,
	isLocationValid: false,
	/** Save all countries here */
	countries: null,
	/** Save all regions here */
	regions: null,
}

const LocationSlice = createSlice({
	initialState: initialState,
	name: 'location',
	extraReducers: {
		[LOCATION.FETCH_REQUESTED]: (state, action) => {
			state.isFetching = true
		},
		[LOCATION.FETCH_SUCCEEDED]: (state, action) => {
			state.isFetching = false
			state.ipLocation = action.payload.data
			state.ipLocationStatus = 'success'
			state.ipLocationStatusMessage = `Loaded IP based location`
		},
		[LOCATION.FETCH_FAILED]: (state, action) => {
			state.isFetching = false
			state.ipLocation = null
			state.status = 'error'
			state.ipLocationStatus = 'error'
			state.statusMessage = `Failed to load IP based location.` //'An error happened while retrieving the location...'
			state.ipLocationStatusMessage = `Failed to load IP based location.` //'An error happened while retrieving the location...'
		},

		[LOCATION.VERIFY_POSTAL_CODE_REQUESTED]: (state, action) => {
			state.isCheckingPostalCode = true
			state.isLocationValid = null
			state.status = null
			state.statusMessage = ''
		},
		[LOCATION.VERIFY_POSTAL_CODE_FAILED]: (state, action) => {
			state.isCheckingPostalCode = false
			state.isLocationValid = false
			state.status = 'error'
			state.statusMessage = ' Issue while validating your postal code.'
		},

		[LOCATION.VERIFY_POSTAL_CODE_SUCCEEDED]: (state, action) => {
			state.isCheckingPostalCode = false
			state.isLocationValid = true
			state.status = null
			state.statusMessage = ''
		},

		['LOAD_PROFILE_SUCCESS']: (state, action) => {
			// console.log('LOAD_PROFILE_SUCCESS',action.payload.data)
			state.profileLocationIsFetching = false
			state.profileLocationStatus = 'success'
			state.profileLocationStatusMessage = 'Loaded profile location'
			state.profileLocation.country = action.payload.data.countryCode
			state.profileLocation.region = action.payload.data.regionCode
			// state.profileLocation.currency = action.payload.data.location?.currency
		},

		['LOAD_PROFILE_FAILED']: (state, action) => {
			state.profileLocationIsFetching = false
			state.profileLocationStatus = 'error'
			state.profileLocationStatusMessage = 'Failed to load profile location'
		},

		['UPDATE_PROFILE_REQUEST']: (state, action) => {
			state.profileLocationIsFetching = true
		},

		['UPDATE_PROFILE_SUCCESS']: (state, action) => {
			state.profileLocationIsFetching = false
			state.profileLocation.country = action.payload.data.countryCode
			state.profileLocation.region = action.payload.data.regionCode
			// state.profileLocation.currency = action.payload.data.location?.currency
		},

		['UPDATE_PROFILE_FAILED']: (state, action) => {
			state.profileLocationIsFetching = false
		},

		[CART.FETCH_SUCCEEDED]: (state, action) => {
			state.location.country = action.payload.data.countryCode
			state.location.region = action.payload.data.regionCode
			state.location.postalCode = action.payload.data.postalCode
		},
		[CART.UPDATE_SUCCEEDED]: (state, action) => {
			state.location.country = action.payload.data.countryCode
			state.location.region = action.payload.data.regionCode
			state.location.postalCode = action.payload.data.postalCode
		},
		[COUNTRY.FETCH_ALL_SUCCEEDED]: (state, action) => {
			if (action.payload.data?.entities && action.payload.data.entities.countries) {
				state.countries = action.payload.data.entities.countries
				state.regions = action.payload.data.entities.regions
			}
			state.isFetchingCountries = false
		},
		[COUNTRY.FETCH_ALL_FAILED]: (state, action) => {
			state.isFetchingCountries = false
		},
	},
	reducers: {
		setValues: (state, action) => {
			Object.keys(action.payload).forEach((key, i) => {
				state.location[key] = action.payload[key]
			})
		},
		setCountry: (state, action) => {
			state.location.country = action.payload
		},
		setRegion: (state, action) => {
			state.location.region = action.payload
		},
		setPostalCode: (state, action) => {
			state.location.postalCode = action.payload
		},
	},
})

export default LocationSlice.reducer

export const { setValues, setCountry, setRegion, setPostalCode } = LocationSlice.actions
