import { useWidgetIsReady } from '@livechat/widget-react'
import { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

const useCartMeta = () => {
	const isReady = useWidgetIsReady()

	const cart = useSelector((s) => s.checkout?.cart, shallowEqual)

	useEffect(() => {
		if (isReady) {
			const env = {
				'~ Cart': `${JSON.stringify(cart, undefined, 4)}`,
			}

			LiveChatWidget.call('update_session_variables', env)
		}
	}, [isReady, cart])
}

export default useCartMeta
