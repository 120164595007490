/* ******************************
 * PROJECT DEPENDENCIES
 ***************************** */

import * as types from '../constants'

function promotions(state = {}, action = {}) {
	switch (action.type) {
		case types.LOAD_ADMIN_PROMOTIONS_SUCCESS:
			if (
				action.payload &&
				action.payload.normalizr &&
				action.payload.normalizr.entities &&
				action.payload.normalizr.entities.promotions
			) {
				// Return normalizr entities AND addtional pagination info
				return Object.assign({}, state, {
					entities: action.payload.normalizr.entities.promotions,
					pages: action.payload.pages,
					count: action.payload.count,
					// ... etc ...
				})
			}

		default:
			return state
	}
}

export default promotions
