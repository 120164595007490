import PropTypes from 'prop-types'

/* **************************************
 *  PROMO CODE
 * ************************************ */

const propTypes = {
	code: PropTypes.string.isRequired,
	type: PropTypes.oneOf(['percent', 'amount', 'gratuit']).isRequired,
	value: PropTypes.number.isRequired,
	expires: PropTypes.instanceOf(Date).isRequired,
	isActive: PropTypes.bool.isRequired,
	maxCount: PropTypes.number.isRequired,
	useCount: PropTypes.number.isRequired,
}

const defaultProps = {
	code: '',
	type: '',
	value: 0,
	expires: null,
	isActive: true,
	maxCount: 0,
	useCount: 0,
}

const Announcement = { propTypes, defaultProps }

export default Announcement
