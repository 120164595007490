/** Import our instance config */

import axios from 'axios'
import qs from 'qs'
import config from '../../../../client/config.client'

/** API config instance */
const instance = axios.create({
	withCredentials: true,
	baseURL: config.api.baseUrl,
})

/**
 * Fetch all the tracks available to the current user
 * @param {*} data
 * @returns
 */
const fetchTracks = async (params) => {
	const response = await instance.get(`/v2/tracks${params ? `?${qs.stringify(params)}` : ''}`)
	return response
}

/**
 * Add a new track
 * @param {*} track
 * @returns
 */
const postTrack = async (track) => {
	const response = await instance.post('/v2/tracks', track)
	return response
}

/**
 * Delete a track
 * @param {*} trackId
 * @returns
 */
const deleteTrack = async (trackId) => {
	const response = await instance.delete(`/v2/tracks/${trackId}`)
	return response
}

/** POST duplicate media item
 * @param {*} id media id
 * @returns
 */
const duplicateMedia = async (data) => {
	const response = await instance.post(`/v2/media/${data.id}/duplicate`, data)
	return response
}

/** POST un archive media item
 * @param {*} id media id
 * @returns
 */
const unArchiveMedia = async (id) => {
	const response = await instance.put(`/v2/media/${id}/restore`)
	return response
}

/** POST un archive ALL media items
 * @param {*} id event id
 * @returns
 */
const restoreAllMedia = async (id) => {
	const response = await instance.post(`/v2/events/${id}/restore-media`)
	return response
}

/** POST Upscale media item
 * @param {*} id media id
 * @returns
 */
const upscaleImage = async (id) => {
	const response = await instance.post(`/v2/media/${id}/upscale`)
	return response
}

/** POST un archive media item
 * @param {*} id media id
 * @returns
 */
const restorePublishedMedia = async (id) => {
	const response = await instance.put(`/v2/published-media/${id}/restore`)
	return response
}

const requests = {
	// MUSIC
	fetchTracks,
	postTrack,
	deleteTrack,
	// MEDIA
	unArchiveMedia,
	restoreAllMedia,
	duplicateMedia,
	upscaleImage,
	// PUBLISHED MEDIA
	restorePublishedMedia,
}

export default requests
