const clearStyles = {
	display: 'block',
	content: '""',
	width: '100%',
	height: '100%',
	position: 'absolute',
}

const Element = {
	parts: ['element', 'badge', 'layers'],
	baseStyle: () => ({
		element: {
			'backgroundColor': 'white',
			'width': 'full',
			'height': 'full',
			'zIndex': 'auto',
			'opacity': '1',
			'borderRadius': 'md',
			'overflow': 'hidden',
			'touchAction': 'manipulation',
			'pointerEvents': 'auto !important',
			'cursor': 'pointer',
			'.dragging &': {
				pb: '0',
				width: '100%',
				height: '100%',
				boxShadow: '1px 3px 8px rgba(0, 0, 0, 0.3)',
				pointerEvents: 'auto !important',
				cursor: 'grab !important',
				transform: 'translate3d(0px, -6%, 0px) rotate(-2deg)',
				filter: 'brightness(1.15)',
				zIndex: '10',
			},
			// '&:hover &:after': clearStyles,
			// '&:hover:after': clearStyles,
			// '&:focus &:after': clearStyles,
			// '&:focus:after': clearStyles,
			// '&:active &:after': clearStyles,
			// '&:active:after': clearStyles,
		},
		badge: {
			'display': 'none',
			'.dragging &': {
				display: 'block',
				position: 'absolute',
				transform: 'rotate(2.5deg)',
				top: '-12px',
				right: '-12px',
				padding: '0.35em 0.5em',
				borderRadius: '0.3rem',
				color: 'white',
				fontSize: '0.8em',
				fontWeight: 'bold',
				backgroundColor: 'red',
				zIndex: '99',
			},
		},
		layers: {
			'display': 'none',
			'.dragging &': {
				display: 'block',
				position: 'absolute',
				content: '""',
				width: '101%',
				height: '101%',
				top: '6px',
				left: '2px',
				boxShadow: '1px 3px 8px rgba(0, 0, 0, 0.3)',
				borderRadius: '6px',
				backgroundColor: 'white',
				transform: 'translate3d(0px, -6%, 0px) rotate(-8deg)',
				_after: {
					display: 'block',
					position: 'absolute',
					content: '""',
					width: '102%',
					height: '102%',
					top: '4px',
					left: '-2px',
					boxShadow: '1px 3px 8px rgba(0, 0, 0, 0.3)',
					borderRadius: '6px',
					background: '#FFFFFF',
					transform: 'translate3d(0px, -6%, 0px) rotate(14deg)',
				},
			},
		},
	}),
	sizes: {},
	variants: {},
	defaultProps: {},
}

export default Element
