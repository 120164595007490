import React, { memo } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import IfEventExist from '../routes/EnsureEventExist'
import PublicEventRoute from '../routes/PublicEventRoute'
import loadable from '@loadable/component'
const Marketplace = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/Marketplace'))

const PublicEventRouter = memo((props) => {
	return (
		<Routes>
			<Route index element={<Navigate to="/dashboard" />} />
			<Route path=":uuid/add-ons" element={<Marketplace />} />
			<Route
				path=":uuid/*"
				element={
					/**
					 * HOC Component to ensure event exist or
					 * handle appropriate redirects or 404.
					 *
					 * If an event has been found, check for ownership priviledges.
					 */
					<IfEventExist>
						<PublicEventRoute />
					</IfEventExist>
				}
			/>
		</Routes>
	)
})

export default PublicEventRouter
