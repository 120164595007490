/* eslint-disable no-console */
/* **************************************
 *  LIBRARIES
 * ************************************ */

import React, { useEffect, useState } from 'react'
import { Routes, Route, useLocation, Navigate, useSearchParams, useNavigate } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import loadable from '@loadable/component'
import { Elements } from '@stripe/react-stripe-js'
import { Alert, AlertIcon, AlertDescription, ChakraProvider, Link, useToast } from '@chakra-ui/react'
import { Flex, ColorModeScript } from '@chakra-ui/react'
import { loadStripe } from '@stripe/stripe-js'
import { stripe as stripeConfig } from '../../client/config.client'
/**
 * Local Dependencies
 * */

import theme from '../v2/ui/theme'
import { DELETE_FLASH_MESSAGE } from '../api/app/constants'
import ProtectedRoute from '../v2/routes/ProtectedRoute'
import { SettingsRouter, DashboardRouter } from '../v2/routers/Protected'
import EventRouter from '../v2/routers/ProtectedEvent'
import PublicEventRouter from '../v2/routers/PublicEvent'
import useCompatibilityHook from '../v2/hooks/useCompatibilityHook'
import RoleProvider from '../v2/contexts/RoleProvider'
import LightMode from '../v2/ui/molecules/LightMode'
import MetaTagsEvent from '../v2/ui/organisms/MetaTagsEvent'
// import LiveChat from '../v2/ui/molecules/LiveChat' // experimental fix, do not use loadable here
import LiveChat from '../v2/ui/molecules/LiveChat'
import AuthProvider, { useAuthContext } from '../v2/contexts/AuthProvider'
import EventProvider from '../v2/contexts/EventProvider'
import AuthedOrUnauthed from '../v2/ui/pages/utils/AuthedOrUnauthed'
import CheckoutProvider from '../v2/contexts/CheckoutProvider'
import { impersonateUser, unmask } from '../api/admin/users'
import TemporaryUserProvider from '../v2/contexts/TemporaryUserProvider'
import Fonts from '../v2/ui/theme/Fonts'
import { CloseRounded } from '../v2/ui/atoms/Icon'
import Announcements from '../v2/ui/molecules/Announcements'
import { LazyMotion, domAnimation } from 'framer-motion'

/**
 * Loadable components
 * */
const Header = loadable(() => import(/* webpackPrefetch: true */ '../v2/ui/organisms/Header'))
const HandleArbitrary = loadable(() => import(/* webpackPrefetch: true */ '../v2/ui/pages/utils/HandleArbitrary'))
const Marketplace = loadable(() => import(/* webpackPrefetch: true */ '../v2/ui/pages/Marketplace'))
const TremendousCatalog = loadable(() => import(/* webpackPrefetch: true */ '../v2/ui/pages/TremendousCatalog'))
const Auth = loadable(() => import(/* webpackPrefetch: true */ '../v2/ui/pages/Auth'))
const StartVidday = loadable(() => import(/* webpackPrefetch: true */ '../v2/ui/pages/StartVidday'))
const Offline = loadable(() => import(/* webpackPrefetch: true */ '../v2/ui/molecules/Offline'), { ssr: false })

/*
 * Load stripe sdk
 */
let stripePromise = loadStripe(stripeConfig.publishableKey)
stripePromise.catch((err) => {
	console.warn('Failed to load Stripe.js')
	// setTimeout(() => {
	// 	stripePromise = loadStripe(stripeConfig.publishableKey)
	// }, 5000)
})

/**
 * Handler to determine if we should
 * match an Event or return a 404 error.
 */
const FindEventOrCatchAll = () => {
	/**
	 * Retrieve the full location to access pathname
	 * */
	const location = useLocation()
	// const {state:{ event }} = useStore()
	/**
	 * Check if we have a match for a UUID
	 * */
	const regex = /\/([a-z]-[a-zA-Z0-9]{6}|[a-zA-Z0-9]{6})/

	if (location.pathname.match(regex)) {
		let arr = location.pathname.split('/')
		let uuid = arr[1]
		return (
			<Routes>
				<Route path={`${uuid}/*`} element={<AuthedOrUnauthed uuid={uuid} />} />
			</Routes>
		)
		//<EventRouterV1 uuid={computedUUID} />
	}

	/**
	 * If we don't have an id, because
	 * there is no other routes define,
	 * we return a 404 error page.
	 * */
	return <HandleArbitrary />
}

const ToastContainer = () => {
	const toast = useToast()
	const messages = useSelector((s) => s.flashMessages, shallowEqual)
	const dispatch = useDispatch()
	useEffect(() => {
		messages.forEach((el) => {
			const cta = el.callToAction
			if (!toast.isActive(el.id)) {
				toast({
					id: el.id,
					// title: el.text,
					// status: el.type,
					duration: 9000,
					isClosable: true,
					render: ({ onClose }) => (
						<Alert role="alert" variant="solid" status={el.type} mr={cta ? '1rem' : null}>
							<AlertIcon />
							<AlertDescription>{el.text}</AlertDescription>
							{cta && (
								<Link
									variant={'link'}
									color="white"
									fontWeight="bold"
									onClick={cta?.callback}
									ml="1rem">
									{cta?.text}
								</Link>
							)}
							<CloseRounded position="absolute" right=".25rem" top=".25rem" onClick={onClose} />
						</Alert>
					),
					onCloseComplete: (e) => {
						dispatch({
							type: DELETE_FLASH_MESSAGE,
							id: el.id,
						})
					},
				})
			}
		})
	}, [messages])

	return <></>
}

const ImpersonationRedirect = () => {
	const location = useLocation()
	const [searchQuery] = useSearchParams()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { userId, isImpersonated, role } = useAuthContext()

	useEffect(() => {
		let impersonateId = searchQuery.get('impersonateUserId')

		if (impersonateId) {
			// Already impersonating, but wants to impersonate different user
			if (isImpersonated && impersonateId !== userId) {
				let redirect = location ? location.pathname : undefined
				console.info(`UNMASK and navigate to: ${redirect}`)
				// unmask and redirect
				dispatch(unmask(redirect))
			}

			// Already impersonating the given impersonation id, just remove it from url
			if (isImpersonated && impersonateId === userId) {
				// Redirect to url without the impersonation
				console.info(`don't impersonate, just navigate to: ${location.pathname}`)
				navigate(location.pathname, { replace: true })
			}

			// Not impersonating and is an admin, continue with impersonation...
			if (!isImpersonated && role == 'admin') {
				console.info(`IMPERSONATE and navigate to: ${location.pathname}`)
				// Impersonate the user
				dispatch(impersonateUser(impersonateId))
				// Redirect to url without the impersonation
				navigate(location.pathname, { replace: true })
			}
		}
	}, [searchQuery, role, isImpersonated, userId])
	return <></>
}

/**
 * Root Component for all V2 endpoints
 */
const Router = ({ ...rest }) => {
	const toast = useToast()
	const isCompatible = useCompatibilityHook()
	const location = useLocation()
	const announcements = useSelector((state) => state.entities.announcements.entities)
	const [pad, setBottomPad] = useState()
	useEffect(() => {
		if (announcements.length != 0) {
			setBottomPad('250px')
		} else {
			setBottomPad('0')
		}
	}, [announcements])

	/**
	 * Because we're currently switching between V1/V2,
	 * it's important to make sure we're closing all toasts
	 * when switching between v1/v2
	 * */
	useEffect(() => {
		return () => {
			toast.closeAll()
		}
	}, [])

	return (
		<>
			<ColorModeScript initialColorMode={theme.config.initialColorMode} />
			<Elements stripe={stripePromise}>
				<Fonts />
				<LazyMotion features={domAnimation}>
					<ChakraProvider theme={theme}>
						<AuthProvider>
							<EventProvider>
								<MetaTagsEvent />
								<RoleProvider>
									<TemporaryUserProvider>
										<CheckoutProvider>
											<ImpersonationRedirect />
											<Flex
												grow="1"
												width="full"
												display="block"
												overflow="hidden"
												paddingBottom={pad}>
												<Header />
												<Offline />
												{isCompatible && (
													<Routes>
														<Route
															path="dashboard/*"
															element={
																<LightMode>
																	<ProtectedRoute>
																		<DashboardRouter />
																	</ProtectedRoute>
																</LightMode>
															}
														/>

														<Route
															path="settings/*"
															element={
																<LightMode>
																	<ProtectedRoute>
																		<SettingsRouter />
																	</ProtectedRoute>
																</LightMode>
															}
														/>

														<Route
															path="event/*"
															element={
																<LightMode>
																	<ProtectedRoute>
																		<EventRouter />
																	</ProtectedRoute>
																</LightMode>
															}
														/>

														<Route
															path="start-a-vidday/*"
															element={
																<LightMode>
																	<StartVidday />
																</LightMode>
															}
														/>

														{/** Handle auth views seperately */}
														<Route
															path="auth/*"
															element={
																<LightMode>
																	<Auth />
																</LightMode>
															}
														/>

														{/** Set up Redirects */}
														<Route
															path="register"
															element={
																<Navigate to={`/auth/register${location.search}`} />
															}
														/>
														<Route
															path="login"
															element={<Navigate to={`/auth/login${location.search}`} />}
														/>
														<Route
															path="forgot"
															element={<Navigate to={`/auth/forgot${location.search}`} />}
														/>
														<Route
															path="reset/:token"
															element={
																<Navigate
																	to={`/auth/reset/${location.pathname
																		.split('/')
																		.pop()}${location.search}`}
																/>
															}
														/>
														<Route
															path="activate/:token"
															element={
																<Navigate
																	to={`/auth/activate/${location.pathname
																		.split('/')
																		.pop()}${location.search}`}
																/>
															}
														/>

														{/** Public event routes */}
														<Route path="e/*" element={<PublicEventRouter />} />

														{/** Marketplace / Add-ons / Gift Shop */}
														<Route
															path="add-ons"
															element={
																<LightMode>
																	<Marketplace />
																</LightMode>
															}
														/>

														{/* /** Redirect Gift card routes */}
														<Route
															path="giftcard/*"
															element={
																<LightMode>
																	<Routes>
																		<Route
																			index
																			element={
																				<Navigate to="/giftcard/catalog" />
																			}
																		/>
																		<Route
																			path="catalog"
																			element={<TremendousCatalog />}
																		/>
																	</Routes>
																</LightMode>
															}
														/>

														{/** SEO Redirect */}
														<Route
															path="gift-card-catalog"
															element={<Navigate to="/giftcard/catalog" />}
														/>

														<Route
															path="tremendous"
															element={<Navigate to="/giftcard/catalog" />}
														/>

														{/**
														 * Due to React Router V6,
														 * the following route is used to parse UUID
														 */}
														<Route path="*" element={<FindEventOrCatchAll />} />
													</Routes>
												)}
											</Flex>
											<LiveChat />
											<Announcements />
											<ToastContainer />
										</CheckoutProvider>
									</TemporaryUserProvider>
								</RoleProvider>
							</EventProvider>
						</AuthProvider>
					</ChakraProvider>
				</LazyMotion>
			</Elements>
		</>
	)
}

export default Router
