const initialState = {
	status: {
		isFetching: false,
		isVerifying: false,
		error: false,
		message: '',
	},
	verification: {},
}

export const combineFormState = (actionType = undefined, reducer = {}, v2 = false) => {
	let result = reducer

	/** Combine initial state */
	result.initialState = Object.assign(reducer.initialState || {}, initialState)

	/** Combine reducers */
	result.reducers = Object.assign(reducer.reducers || {}, {
		reset: () => result.initialState,
	})

	/** combine extraReducers */
	result.extraReducers = Object.assign(reducer.extraReducers || {}, {
		[actionType + (v2 ? '_REQUESTED' : '_REQUEST')]: (state) => {
			state.status.isFetching = true
		},
		[actionType + (v2 ? '_SUCCEEDED' : '_SUCCESS')]: (state, action) => {
			state.status.isFetching = false
			state.status.error = false
			state.status.message = action?.payload?.message || initialState?.status?.message
			// state.status.message = (action.payload && action.payload.message) || initialState.status.message || ''
			// Additional Data
			state.status = { ...state.status, ...action.payload?.status }
		},
		[actionType + (v2 ? '_FAILED' : '_FAILURE')]: (state, action) => {
			state.status.isFetching = false
			state.status.error = {}
			// state.status.message = action.payload.message
			state.status.message = (action.payload && action.payload?.message) || ''
			// Additional Data
			state.status = { ...state.status, ...action.payload?.status }
		},
	})

	/** combine extraReducers */
	result.extraReducers = Object.assign(reducer.extraReducers || {}, {
		['POST_VERIFY_REQUESTED']: (state) => {
			state.status.isVerifying = true
			state.verification = {}
		},
		['POST_VERIFY_SUCCEEDED']: (state, action) => {
			state.status.isVerifying = false
			state.verification = action.payload.data
		},
		['RESET_VERIFY_EMAIL']: (state) => {
			state.status.isVerifying = false
			state.verification = {}
		},
	})

	return result
}
