import PropTypes from 'prop-types'

/* **************************************
 *  USER PROFILE
 * ************************************ */

const propTypes = {
	userId: PropTypes.string.isRequired,
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	sms: PropTypes.string,
	location: PropTypes.shape({
		ip: PropTypes.string,
		city: PropTypes.string,
		region: PropTypes.string,
		region_code: PropTypes.string,
		country: PropTypes.string,
		country_name: PropTypes.string,
		countinent_code: PropTypes.string,
		postal: PropTypes.string,
		latitude: PropTypes.number,
		longitude: PropTypes.number,
		timezone: PropTypes.string,
		utc_offset: PropTypes.string,
		country_calling_code: PropTypes.string,
		currency: PropTypes.string,
		languages: PropTypes.string,
		asn: PropTypes.string,
		org: PropTypes.string,
	}),
	language: PropTypes.string,
	birthday: PropTypes.instanceOf(Date),
	notifications: PropTypes.shape({
		eventActivity: PropTypes.bool,
		features: PropTypes.bool,
		reminders: PropTypes.bool,
		promos: PropTypes.bool,
	}),
}

const defaultProps = {
	firstName: '',
	lastName: '',
	sms: '',
	location: {
		ip: null,
		city: '',
		region: '',
		region_code: '',
		country: '',
		country_name: '',
		countinent_code: '',
		postal: '',
		latitude: null,
		longitude: null,
		timezone: '',
		utc_offset: '',
		country_calling_code: '',
		currency: '',
		languages: '',
		asn: '',
		org: '',
	},
	language: '',
	birthday: null,
	notifications: {
		eventActivity: true,
		features: true,
		reminders: true,
		promos: false,
	},
}

const UserProfile = { propTypes, defaultProps }

export default UserProfile
