/* **************************************
 *  PROJECT DEPENDENCIES
 * ************************************ */

import * as types from '../constants'
import { AUTH } from '../../constants'

/* **************************************
 *  INITIAL STATE
 * ************************************ */

const initialState = {
	email: null,
	temporaryEmail: null,
	userId: null,
	isAuthenticated: false,
	isAuthenticating: false,
	isImpersonated: false,
	hasSubscription: false,
	statusText: null,
	statusType: null,
	isValidToken: null,
	role: null,
}

const auth = (state = initialState, action = {}) => {
	switch (action.type) {
		// Profile gets returned through the user fetch request
		case types.LOAD_PROFILE_SUCCESS:
			if (action.payload && action.payload.data) {
				return Object.assign({}, state, {
					profile: action.payload.data,
				})
			}

		// Profile gets returned through the user fetch request
		case types.UPDATE_PROFILE_SUCCESS:
			if (action.payload && action.payload.data) {
				return Object.assign({}, state, {
					profile: action.payload.data,
				})
			}
		// Update temporaryEmail through email change request
		case AUTH.PUT_CHANGE_PRIMARY_EMAIL_SUCCEDED:
			if (action.payload && action.payload.data) {
				return Object.assign({}, state, {
					temporaryEmail: action.payload.data.email,
				})
			}

		// Preferences gets returned through the user fetch request
		case types.LOAD_PREFERENCES_SUCCESS:
			if (action.payload && action.payload.data) {
				return Object.assign({}, state, {
					preferences: action.payload.data,
				})
			}

		// Profile gets returned through the user fetch request
		case types.UPDATE_PREFERENCES_SUCCESS:
			if (action.payload && action.payload.data) {
				return Object.assign({}, state, {
					preferences: action.payload.data,
				})
			}

		// Set new token received as invalid
		case types.ACTIVATE_ACCOUNT_FAILURE:
			return Object.assign({}, state, {
				isValidToken: false,
			})

		// Set new token received as invalid
		case types.FORGOT_PASSWORD_SUCCESS:
			return Object.assign({}, state, {
				resetPasswordSent: action.payload,
			})

		/* Login process should refer to this one rather that LOGIN_USER_SUCCESS */
		case types.SET_AUTHENTICATED_USER:
			return Object.assign({}, state, {
				blocked: action.user.blocked,
				isAuthenticating: false,
				isAuthenticated: true,
				isActive: action.user.isActive,
				activatedAt: action.user.activatedAt,
				createdAt: action.user.createdAt,
				userId: action.user.id,
				facebookId: action.user.facebookId,
				googleId: action.user.googleId,
				appleId: action.user.appleId,
				email: action.user.email,
				temporaryEmail: action.user.temporaryEmail,
				role: action.user.role,
				isImpersonated: action.isImpersonated || false,
				subscriptionId: action.user.subscriptionId || null,
				hasSubscription: !!action.user.subscriptionId || action.user.hasLegacyB2bSubscription,
			})

		case types.ACTIVATE_ACCOUNT_SUCCESS:
			return Object.assign({}, state, {
				statusText: action.message || 'authenticated user set',
				statusType: 'success',
				isValidToken: true,
			})

		case types.LOGIN_USER_REQUEST:
			return Object.assign({}, state, {
				isAuthenticating: true,
				statusText: null,
			})

		case types.LOGIN_USER_FAILURE:
			return Object.assign({}, state, {
				isAuthenticating: false,
				isAuthenticated: false,
				email: null,
				temporaryEmail: null,
				userId: null,
				statusText: action.payload.error,
				statusType: 'error',
				activationRequired: action.payload.activationRequired,
			})

		case 'RESET_ACTIVATION_REQUIRED_VARIABLE':
			return Object.assign({}, state, {
				activationRequired: action.payload.activationRequired,
			})

		case types.REGISTER_SUCCESS:
			return Object.assign({}, state, {
				activationRequired: action.payload.activationRequired,
				...action.payload.data,
			})

		case types.LOGOUT_SUCCESS:
			return Object.assign({}, initialState)
		// return Object.assign({}, state, {
		//   'isAuthenticated': false,
		//   'email': null,
		//   'userId': null,
		//   'isActive': false,
		//   'isValidToken': null,
		//   'statusText': 'You have been successfully logged out.',
		//   'statusType': 'success'
		// });

		default:
			return state
	}
}

export default auth
