import { useEffect, useState } from 'react'

export const useIsMediaAvailable = (mediaItem) => {
	const [isValid, setIsValid] = useState(true)
	const [res, setRes] = useState(null)

	useEffect(() => {
		const checkMediaAvailability = async () => {
			var response
			if (mediaItem.signedLocationUrl) {
				try {
					response = await fetch(mediaItem.signedLocationUrl, { method: 'HEAD' })
				} catch {
					console.log('Error fetching media [useIsMediaAvailable]')
				}
				if (response?.headers.get('x-amz-archive-status')) {
					setIsValid(false)
				} else {
					setIsValid(true)
				}
				setRes(response)
			}
		}
		if (mediaItem && !mediaItem.hasOwnProperty('inGlacierStorage')) {
			checkMediaAvailability()
		}
	}, [mediaItem])

	return {
		isAvailable: isValid,
		res: res,
	}
}
