import PropTypes from 'prop-types'

/* **************************************
 *  ANNOUNCEMENT
 * ************************************ */

const propTypes = {
	type: PropTypes.oneOf(['maintenance', 'promotion']).isRequired,
	message: PropTypes.string.isRequired,
	start: PropTypes.instanceOf(Date),
	ends: PropTypes.instanceOf(Date),
	enabled: PropTypes.bool.isRequired,
}

const defaultProps = {
	type: '',
	message: '',
	start: null,
	ends: null,
	enabled: true,
}

const Announcement = { propTypes, defaultProps }

export default Announcement
