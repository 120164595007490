/* eslint-disable no-console */
/* **************************************
 *  LIBRARIES
 * ************************************ */

import { combineReducers } from 'redux'

/* **************************************
 *  PROJECT DEPENDENCIES
 * ************************************ */

import * as types from '../../../../api/app/constants'

function uploads(state = [], action = {}) {
	switch (action.type) {
		case types.POST_EVENT_MEDIA_REQUEST: {
			// lets make sure we haven't added it already
			let media = [...state] // make a separate copy of the array
			// Find by mediaUuid and add it
			let index = media.findIndex((el) => el?.mediaUuid == action.payload?.data?.mediaUuid)
			if (index == -1) {
				media.push(action.payload.data)
				return (state = media)
			}
			return state
		}

		case types.POST_EVENT_MEDIA_SUCCESS: {
			// lets make sure we haven't added it already
			let media = [...state] // make a separate copy of the array
			// Find by mediaUuid
			let mediaIndex = media.findIndex((el) => el?.mediaUuid == action.payload?.data?.mediaUuid)
			if (mediaIndex !== -1) {
				// find the media and replace it with more data
				media.splice(mediaIndex, 1, action.payload.data)
				return (state = media)
			}
			return state
		}

		case types.PUT_EVENT_MEDIA_SUCCESS: {
			// lets make sure we have record of that media, then remove it.
			let media = [...state] // make a separate copy of the array
			let mediaIndex = media.findIndex((el) => el.id == action.payload?.data?.id)
			if (mediaIndex !== -1 && action.payload.data.contributorId) {
				// find the media and remove it
				media.splice(mediaIndex, 1)
				return (state = media)
			}
			return state
		}

		case 'CLAIM_MEDIA_EVENT_SUCCESS': {
			// lets make sure we have record of that media, then remove it.
			let media = [...state] // make a separate copy of the array
			let mediaIndex = media.findIndex((el) => el.id == action.payload?.data?.id)
			if (mediaIndex !== -1) {
				// find the media and remove it
				media.splice(mediaIndex, 1)
				return (state = media)
			}
			return state
		}

		case 'REMOVE_ERROR_MEDIA_UPLOAD': {
			// lets make sure we have record of that media, then remove it.
			let media = [...state] // make a separate copy of the array
			let mediaIndex = media.findIndex((el) => el.id == action.payload?.data?.id)
			if (mediaIndex !== -1) {
				// find the media and remove it
				media.splice(mediaIndex, 1)
				return (state = media)
			}
			return state
		}

		case 'CLEAR_UPLOADS': {
			return (state = [])
		}

		default:
			return state
	}
}

const reducer = combineReducers({ uploads })

export default reducer
