import { mode } from '@chakra-ui/theme-tools'
const Heading = {
	baseStyle: (props) => {
		return {
			color: mode('gray.900', 'white')(props),
			fontFamily: `"Nexa Heavy", sans-serif`,
			outline: '2px solid transparent',
			outlineOffset: '2px',
			fontWeight: '400',
		}
	},
	variants: {
		hero: {
			fontWeight: 'bold',
			fontFamily: `'Gazpacho', Georgia, sans-serif`,
		},
	},
	defaultProps: {
		colorScheme: 'gray',
	},
}

export default Heading
