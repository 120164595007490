import { keyframes } from '@chakra-ui/react'

const bellshake = keyframes`
	0% { transform: rotate(0); }
	4% { transform: rotate(0); }
	8% { transform: rotate(10deg);}
	12% { transform: rotate(-8deg);}
	16% { transform: rotate(8deg);}
	20% { transform: rotate(-6deg);}
	24% { transform: rotate(4deg);}
	28% { transform: rotate(-3deg);}
	32% { transform: rotate(1deg);}
	100% { transform: rotate(0); }
`

const variantShake = {
	animation: `${bellshake} 4s ease-in-out`,
	animationIterationCount: 'infinite',
	transformOrigin: 'center 2px',
}

const Navigation = {
	baseStyle: {
		menuButton: {
			zIndex: '1000',
			position: 'absolute',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			right: '86px',
			top: '12px',
			w: '54px',
			h: '54px',
			content: '""',
			outline: 'none',
			border: 'none',
			borderRadius: 'full',
			backgroundColor: 'gray.50',
			cursor: 'pointer',
			_hover: {
				backgroundColor: 'gray.200',
			},
		},
		menuButtonIcon: {
			minHeight: '1.75rem',
			maxHeight: '1.75rem',
			minWidth: '1.75rem',
			maxWidth: '1.75rem',
		},
		menuList: {
			maxW: '320px',
			minW: '320px',
			p: '0.5rem',
			boxShadow: 'base',
			borderColor: 'white',
		},
		menuContentWrapper: {
			w: 'full',
			pos: 'relative',
			maxH: '400px',
			overflowY: 'scroll',
		},
		notificationCount: {
			pos: 'absolute',
			top: '0',
			right: '0',
			backgroundColor: 'pink.500',
			borderRadius: 'full',
			color: 'white',
			fontSize: '0.625rem',
			minH: '1.125rem',
			minW: '1.125rem',
			w: 'auto',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			px: '6px',
		},
		header: {
			container: {
				p: '0.5rem',
			},
			flex: {
				alignItems: 'center',
				justifyContent: 'space-between',
			},
			stack: {
				alignItems: 'center',
				fontSize: '0.6875rem',
				textTransform: 'uppercase',
				fontWeight: 'bold',
			},
		},
	},

	variants: {
		shake: {
			menuButtonIcon: {
				...variantShake,
			},
		},
	},
}

export default Navigation

// export default {
// 	baseStyle,
// 	variants,
// 	parts: ['menuButton', 'menuList', 'menuContentWrapper', 'notificationCount', 'header'],
// }
