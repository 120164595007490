import React from 'react'
import { Global } from '@emotion/react'

const Fonts = () => (
	<Global
		styles={`
        /* latin */
        @font-face {
            font-family: 'Source Sans Pro';
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
                url(/assets/fonts/source_sans_pro_400.woff2) format('woff2');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
                U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }

        @font-face {
            font-family: 'Source Sans Pro';
            font-style: normal;
            font-weight: 600;
            font-display: swap;
            src: local('Source Sans Pro Semi Bold'), local('SourceSansPro-SemiBold'),
                url(/assets/fonts/source_sans_pro_600.woff2) format('woff2');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
                U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
        @font-face {
            font-family: 'Source Sans Pro';
            font-style: normal;
            font-weight: 700;
            font-display: swap;
            src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
                url(/assets/fonts/source_sans_pro_700.woff2) format('woff2');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
                U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
        @font-face {
            font-family: 'Source Sans Pro';
            font-style: normal;
            font-weight: 900;
            font-display: swap;
            src: local('Source Sans Pro Black'), local('SourceSansPro-Black'),
                url(/assets/fonts/source_sans_pro_900.woff2) format('woff2');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
                U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
        @font-face {
            font-family: Gazpacho;
            font-style: normal;
            font-weight: 900;
            font-display: swap;
            src: local('Gazpacho Black'), url(/assets/fonts/gazpacho_900.woff2) format('woff2');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
                U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
        @font-face {
            font-family: 'Nexa Heavy';
            src: local('Nexa Heavy'), url(/assets/fonts/nexa_heavy.woff2) format('woff2');
            font-display: swap;
            font-weight: 400;
            outline: none;
        }
    `}
	/>
)

export default Fonts
