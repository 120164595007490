import { useState, useEffect, useMemo } from 'react'
import { estimateRenderTime, totalVideoLength } from '@vidday/utils'

const calcEstRenderTime = (durationSec, final, media, minMinutesOnly) => {
	const durationRoundMin = Math.round(durationSec / 60)
	// multiplier on final and preview
	const x = final ? 3.25 : 2
	const multiplier = 1 + media?.length / (durationSec / 1.25)
	const addMultiplier = media?.length > 120 ? true : false
	const minRange = Math.floor(durationRoundMin * 80 * x)
	// calc to determine minimum time estimate on long videos
	const minRangeLongVideo = Math.floor(durationRoundMin * 85 * x)
	// calc to determine max time estimate
	const maxRange = Math.ceil(durationRoundMin * 95 * x)

	if (durationRoundMin < 6) {
		if (minMinutesOnly) {
			return final ? 15 : 5
		}
		return `${final ? 15 : 5} minutes`
	} else if (durationRoundMin < 61) {
		// Add multiplier if above 120 media items and round to the nearest 5 minutes.
		const min = addMultiplier ? Math.floor((minRange * multiplier) / 500) * 5 : Math.floor(minRange / 500) * 5
		const max = addMultiplier ? Math.ceil((maxRange * multiplier) / 500) * 5 : Math.ceil(maxRange / 500) * 5
		if (minMinutesOnly) {
			return min
		}
		return `${min} - ${max} minutes`
	} else if (durationRoundMin < 151) {
		// Add multiplier if above 120 media items and round to the nearest 5 minutes.
		const min = addMultiplier
			? Math.floor((minRangeLongVideo * multiplier) / 500) * 5
			: Math.floor(minRangeLongVideo / 500) * 5
		const max = addMultiplier ? Math.ceil((maxRange * multiplier) / 500) * 5 : Math.ceil(maxRange / 500) * 5
		if (minMinutesOnly) {
			return min
		}
		return `${min} - ${max} minutes`
	} else {
		// return 6.25 hours if final otherwise 3 hours
		if (minMinutesOnly) {
			return final ? 6.25 : 3
		}
		return `after ${final ? 6.25 : 3} hours`
	}
}

const calculateTime = (media, themeTemplateId) => {
	if (media && media.length > 0) {
		return totalVideoLength(media, true, themeTemplateId)
	} else {
		return null
	}
}

const calculateSec = (media, themeTemplateId) => {
	if (media && media.length > 0) {
		return totalVideoLength(media, false, themeTemplateId)
	} else {
		return null
	}
}

export const useVideoDurationCalc = (media, themeTemplateId, final = false) => {
	/**
	 * Update duration based on received media collection
	 * */
	const [durationTime, setDurationTime] = useState(calculateTime(media, themeTemplateId))
	const [durationSec, setDurationSec] = useState(calculateSec(media, themeTemplateId))

	useEffect(() => {
		setDurationTime(calculateTime(media, themeTemplateId))
		setDurationSec(calculateSec(media, themeTemplateId))
	}, [media, themeTemplateId])

	/*const renderTime = useMemo(() => {
		return `${estimateRenderTime({ sec: durationSec, x: 1, base: 300 }) / 60} — ${
			estimateRenderTime({ sec: durationSec, x: 2.5, base: 600 }) / 60
		} minutes`
	}, [durationSec])*/

	const renderTime = useMemo(() => {
		return calcEstRenderTime(durationSec, final, media)
	}, [durationSec])

	const renderTimeInMinutes = useMemo(() => {
		return parseInt(calcEstRenderTime(durationSec, final, media, true))
	}, [durationSec])

	return { renderTime, renderTimeInMinutes, durationSec, durationTime }
}

// export const useVideoDurationCalc = (media, themeTemplateId) => {
// 	/**
// 	 * Update duration based on received media collection
// 	 * */
// 	const [durationTime, setDurationTime] = useState(null)
// 	const [durationSec, setDurationSec] = useState(null)

// 	useEffect(() => {
// 		if (media?.length > 0) {
// 			/** Calculate the video duration */
// 			// /** Formatted time length */
// 			setDurationTime(totalVideoLength(media, true, themeTemplateId))
// 			setDurationSec(totalVideoLength(media, false, themeTemplateId))
// 		} else {
// 			setDurationTime(null)
// 			setDurationSec(null)
// 		}
// 	}, [media, themeTemplateId])

// 	const renderTime = useMemo(() => {
// 		return `${estimateRenderTime({ sec: durationSec, x: 1.75, base: 300 }) / 60} - ${
// 			estimateRenderTime({ sec: durationSec, x: 3, base: 600 }) / 60
// 		} minutes`
// 	}, [durationSec])

// 	return { renderTime, durationSec, durationTime }
// }
