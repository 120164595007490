import { mode } from '@chakra-ui/theme-tools'

const FormLabel = {
	baseStyle: (props) => {
		return {
			color: mode('gray.900', 'gray.800')(props),
			fontWeight: 700,
		}
	},
}

export default FormLabel
