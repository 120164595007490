/* ******************************
 * LIBRARIES
 ***************************** */

import { RSAA, getJSON } from 'redux-api-middleware'
import { normalize } from 'normalizr'

/* ******************************
 * PROJECT DEPENDENCIES
 ***************************** */

import * as types from '../constants'
import { retrieveURL } from '@vidday/utils'
import qs from 'qs'
import { eventSchema, eventListSchema, contributorSchema } from '../middlewares/normalizr'
import { addFlashMessage } from '../behaviors/flash-messages'
import { history } from '../../../history'

/* ******************************
 * FETCH ALL OWNED AND CONNECTED EVENTS
 ***************************** */

export const fetchEvents = (params) => ({
	[RSAA]: {
		types: [
			types.LOAD_EVENTS_REQUEST,
			{
				type: types.LOAD_EVENTS_SUCCESS,
				payload: (action, state, res) => {
					return res.json().then((json) => {
						let pagination = { ...json }
						delete pagination.data
						return { ...pagination, ...normalize(json.data, eventListSchema) }
					})
				},
			},
			types.LOAD_EVENTS_FAILURE,
		],
		endpoint: retrieveURL() + `/api/events${params ? `?${qs.stringify(params)}` : ``}`,
		credentials: 'include',
		method: 'GET',
	},
})

/* ******************************
 * FETCH ALL EVENTS CONTRIBUTED TO
 ***************************** */

export const fetchEventsContributed = () => ({
	[RSAA]: {
		types: [
			types.LOAD_EVENTS_CONTRIBUTED_REQUEST,
			{
				type: types.LOAD_EVENTS_CONTRIBUTED_SUCCESS,
				payload: (action, state, res) => {
					return getJSON(res).then((json) => normalize(json.data, contributorSchema))
				},
			},
			types.LOAD_EVENTS_CONTRIBUTED_FAILURE,
		],
		endpoint: retrieveURL() + '/api/account/events/contributed',
		credentials: 'include',
		method: 'GET',
	},
})

/* ******************************
 * FETCH AN EVENT (by uuid)
 * (the one currently being singly viewed)
 ***************************** */
export function loadEvent(uuid, opts = {}) {
	return (dispatch, state) => {
		let url = `${retrieveURL()}/api/event/${uuid}`

		// Append private key to url if it is specified in options
		if (opts.privateKey) {
			url = `${url}?v=${opts.privateKey}`
		}

		dispatch({
			[RSAA]: {
				types: [
					types.LOAD_EVENT_REQUEST,
					types.LOAD_EVENT_SUCCESS,
					{
						type: types.LOAD_EVENT_FAILURE,
						payload: (action, state, res) => {
							console.log('Loading an event through loadEvent()')
							return res.json().then((json) => {
								// send flash for error
								// dispatch(push('/404'))
								return json
							})
						},
					},
				],
				endpoint: url,
				credentials: 'include',
				method: 'GET',
			},
		})
	}
}

/* ******************************
 * FETCH AN EVENT FOR SSR (by uuid)
 * (the one currently being singly viewed)
 ***************************** */
// export const loadEventSSR = (uuid, host = '', query, cookie) => ({
// 	[RSAA]: {
// 		types: [
// 			types.LOAD_EVENT_REQUEST,
// 			types.LOAD_EVENT_SUCCESS,
// 			{
// 				type: types.LOAD_EVENT_FAILURE,
// 				payload: (action, state, res) => {
// 					return res.json().then((json) => {
// 						// send flash for error
// 						return json
// 					})
// 				},
// 			},
// 		],
// 		headers: { cookie: cookie },
// 		endpoint: `${host}/api/event/${uuid}${query}`,
// 		credentials: 'include',
// 		method: 'GET',
// 	},
// })

// Bypasses api lookup, ssr will pass the event data to use
export const loadEventSSR = (eventData) => {
	return {
		type: types.LOAD_EVENT_SUCCESS,
		payload: {
			data: eventData,
		},
	}
}

/* ******************************
 * CREATE AN EVENT
 ***************************** */

export const addEvent = (data) => ({
	[RSAA]: {
		types: [
			types.ADD_EVENT_REQUEST,
			{
				type: types.ADD_EVENT_SUCCESS,
				payload: (action, state, res) => {
					return res.json().then((json) => {
						return normalize(json.data, eventSchema)
					})
				},
			},
			types.ADD_EVENT_FAILURE,
		],
		endpoint: retrieveURL() + '/api/event',
		method: 'POST',
		credentials: 'include',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(data),
	},
})

/* ******************************
 * UPDATE AN EVENT (data should have 'uuid' defined)
 ***************************** */
export function putEvent(data) {
	const id = data.id
	const privateKey = data.privateKey
	// remove fields that aren't allowed
	delete data.id
	delete data.eventId
	delete data.uuid
	delete data.publishedMedia
	delete data.recipients
	delete data.paid
	delete data.contributorsNotified
	delete data.viaEmail
	delete data.viaSms
	delete data.privateKey
	delete data.smsCountryCode

	return {
		[RSAA]: {
			types: [types.UPDATE_EVENT_REQUEST, types.UPDATE_EVENT_SUCCESS, types.UPDATE_EVENT_FAILURE],
			endpoint: `${retrieveURL()}/api/v2/events/${id}${privateKey ? `?privateKey=${privateKey}` : ``}`,
			method: 'PUT',
			credentials: 'include',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(data),
		},
	}
}

/* ******************************
 * UPDATE AN EVENT (data should have all 'Data' defined)
 ***************************** */

export const updateEvent = function (event, silent) {
	return function (dispatch, state) {
		event.uuid = event.uuid
			? event.uuid
			: state().event && state().event.event && state().event.event.uuid
			? state().event.event.uuid
			: null
		event.id = event.id
			? event.id
			: state().event && state().event.event && state().event.event.id
			? state().event.event.id
			: null
		dispatch(putEvent(event)).then((response) => {
			if (!silent) {
				dispatch(addFlashMessage('success', 'Event updated'))
			}
		})
	}
}

/* ******************************
 * ARCHIVE AN EVENT (data should have status:'archived' and all 'Data' defined) redirect to dashboard
 ***************************** */

export function archiveEvent(data) {
	return (dispatch, state) => {
		dispatch(putEvent(data)).then((data) => {
			dispatch(addFlashMessage('success', 'Event archived'))
			dispatch(fetchEvents())
			history.push('/dashboard')
			// dispatch(push('/dashboard/created'))
		})
	}
}

/* ******************************
 * CREATE AN EVENT & REDIRECT TO EVENT PAGE
 ***************************** */

export const addEventAndRedirect = function (data) {
	return function (dispatch, state) {
		dispatch(addEvent(data)).then((response) => {
			// Check that we do have the payload from the response
			!response.error
				? history.push(
						`/event/${
							response.payload.entities.events[response.payload.result].uuid
						}?auth=email&addtocart=true`
				  )
				: // dispatch(
				  // 		push(
				  // 			`/v2/event/${
				  // 				response.payload.entities.events[response.payload.result].uuid
				  // 			}?auth=email&addtocart=true`
				  // 		)
				  //   )
				  null // Push to event page
		})
	}
}

/* ******************************
 * DELETE AN EVENT (Delete an Event AND its connected Media FOREVER!!)
 ***************************** */

export const deleteEvent = (id) => ({
	[RSAA]: {
		types: [types.DELETE_EVENT_REQUEST, types.DELETE_EVENT_SUCCESS, types.DELETE_EVENT_FAILURE],
		endpoint: retrieveURL() + '/api/event/' + id,
		method: 'DELETE',
		credentials: 'include',
		headers: { 'Content-Type': 'application/json' },
	},
})

/* ******************************
 * CREATE A RECIPIENT
 ***************************** */

// 'data' should have the key 'eventId' properly defined
export const addRecipient = (data) => ({
	[RSAA]: {
		types: [types.ADD_RECIPIENT_REQUEST, types.ADD_RECIPIENT_SUCCESS, types.ADD_RECIPIENT_FAILURE],
		endpoint: `${retrieveURL()}/api/v2/recipients`,
		method: 'POST',
		credentials: 'include',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(data),
	},
})

/* ******************************
 * UPDATE A RECIPIENT
 ***************************** */

export const updateRecipient = (data) => {
	const { id } = data
	delete data.id
	delete data.eventId // why is this getting included?
	delete data.confirmDeliveryEmail // why is this getting included?
	delete data.confirmDeliverySms

	return {
		[RSAA]: {
			types: [types.UPDATE_RECIPIENT_REQUEST, types.UPDATE_RECIPIENT_SUCCESS, types.UPDATE_RECIPIENT_FAILURE],
			endpoint: `${retrieveURL()}/api/v2/recipients/${id}`,
			method: 'PUT',
			credentials: 'include',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(data),
		},
	}
}

/* ******************************
 * DELETE A RECIPIENT
 ***************************** */

export const deleteRecipient = (id) => ({
	[RSAA]: {
		types: [types.DELETE_RECIPIENT_REQUEST, types.DELETE_RECIPIENT_SUCCESS, types.DELETE_RECIPIENT_FAILURE],
		endpoint: `${retrieveURL()}/api/v2/recipients/${id}`,
		method: 'DELETE',
		credentials: 'include',
		headers: { 'Content-Type': 'application/json' },
	},
})

// DELETE A RECIPIENT AND RELOAD CURRENT EVENT AUTOMATICALLY
export const deleteRecipientAndReloadEvent = function (recipientId) {
	return function (dispatch, state) {
		dispatch(deleteRecipient(recipientId)).then(() => {
			dispatch(loadEvent(state().event.event.uuid))
		})
	}
}

// MANAGE TEMPORARY EVENT NOT YET CREATED UNTIL USER ACCOUNT HAS BEEN CREATED
export function addTemporaryEvent(data) {
	return {
		type: types.ADD_TEMPORARY_EVENT,
		payload: { data: data },
	}
}

export function clearTemporaryEvent() {
	return {
		type: types.CLEAR_TEMPORARY_EVENT,
	}
}
