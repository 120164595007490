import { normalize, schema } from 'normalizr'

const currencySchema = new schema.Entity(
	'currencies',
	{},
	{
		idAttribute: (data) => data.code,
	}
)

const regionSchema = new schema.Entity(
	'regions',
	{},
	{
		idAttribute: (data) => data.code,
	}
)

const countrySchema = new schema.Entity(
	'countries',
	{
		regions: [regionSchema],
		defaultCurrency: currencySchema,
	},
	{
		idAttribute: (data) => data.alpha2Code,
	}
)
// Represent all related data coming through the fetch request when loading the user

const countryListSchema = new schema.Array(countrySchema)

export { countryListSchema }

const invoiceItemSchema = new schema.Entity(
	'invoice_items',
	{},
	{
		idAttribute: (data) => data.id,
	}
)

const invoiceItemListSchema = new schema.Array(invoiceItemSchema)

const invoiceSchema = new schema.Entity(
	'invoices',
	{},
	{
		idAttribute: (data) => data.id,
	}
)
// Represent all related data coming through the fetch request when loading the user

const invoiceListSchema = new schema.Array(invoiceSchema)

export { invoiceListSchema, invoiceItemListSchema }
