/* eslint-disable no-console */

/* **************************************
 *  LIBRARIES
 * ************************************ */

import React, { useEffect, useState } from 'react'
import { Outlet, Routes, Route } from 'react-router-dom'
import { ErrorBoundary, withProfiler, setUser, getCurrentScope } from '@sentry/react'
import { logAuthedUser } from '@vidday/utils'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
/** Actions */
import { loadUserEntities, loadAuthedUser } from '../api/app/auth'
import { fetchActiveAnnouncements } from '../api/app/announcements'

/** Router */
import Router from './Router'

/** Utils Components */
import RefreshPage from '../v2/ui/pages/utils/RefreshPage'
import SentryDialogFallback from '../v2/ui/pages/utils/SentryDialogFallback'

/** Hooks */
import useWindowSize from '../v2/hooks/useWindowSize'
import useBrowserAgent from '../v2/hooks/useBrowserAgent'
import useViddayDevtag from '../v2/hooks/useViddayDevtag'
import useStandaloneDetect from '../v2/hooks/useStandaloneDetect'

/**
 * Main Root Container
 * @param {*} props
 * @returns
 */
const Root = (props) => {
	const dispatch = useDispatch()

	/**
	 * [START] CODE FROM ROOT LAYOUT
	 * */

	/** Retrieve Authenticated user */
	const isAuthenticated = useSelector((s) => s.auth.isAuthenticated, shallowEqual)
	const userId = useSelector((s) => s.auth.userId, shallowEqual)
	const email = useSelector((s) => s.auth.email, shallowEqual)
	const createdAt = useSelector((s) => s.auth.createdAt, shallowEqual)
	const activatedAt = useSelector((s) => s.auth.activatedAt, shallowEqual)
	const isActive = useSelector((s) => s.auth.isActive, shallowEqual)
	const role = useSelector((s) => s.auth.role, shallowEqual)
	const event = useSelector((s) => s.event.event, shallowEqual)
	const user = { id: userId, email, role, isActive, activatedAt, createdAt }

	/** set redux with environment vars */
	useWindowSize()
	useBrowserAgent()
	useViddayDevtag()

	/** Checks if user viewport is standalone - has installed pwa/twa */
	let appInstalled = useStandaloneDetect()

	// const temporaryEvent = useSelector((s) => s.temporaryEvent, shallowEqual)
	/** Load entities if we have a user when component mount */
	useEffect(() => {
		if (isAuthenticated && userId !== undefined && userId !== null) {
			dispatch(loadUserEntities(userId))
			axios.get('/ua').then((res) => {
				const reflectedUserAgent = res.data
				const isPlayStoreInstall = reflectedUserAgent.includes('installed_via_play_store:true')
				logAuthedUser({
					user,
					event,
					appInstalled,
					userAgent: reflectedUserAgent,
					playStore: isPlayStoreInstall,
				})
			})
		}
		return () => {
			dispatch({
				type: 'LOAD_EVENTS_SUCCESS',
				payload: {},
			})
		}
	}, [isAuthenticated, userId, event])

	/** Set current user in Sentry sdk - for enriched logging */
	useEffect(() => {
		if (isAuthenticated && userId && email) {
			setUser({ id: userId, email: email })
		} else {
			// unauthed - clear Sentry user
			getCurrentScope().setUser(null)
		}
	}, [isAuthenticated, userId, email])

	/**
	 * [END] CODE FROM ROOT LAYOUT
	 * */

	useEffect(() => {
		/** Try to load authed user if not authed, ONLY ON MOUNT */
		if (!isAuthenticated) {
			dispatch(loadAuthedUser())
		}
		// fetch active announcements!
		dispatch(fetchActiveAnnouncements())
		/** Retrieve all countries from backend */
		// dispatch(getAllCountries())
	}, [])

	return (
		<ErrorBoundary
			fallback={({ error }) => (
				<React.Fragment>
					{error.name === 'ChunkLoadError' ? <RefreshPage /> : <SentryDialogFallback error={error} />}
				</React.Fragment>
			)}
			beforeCapture={(scope, error) => {
				scope.setTag('boundary', 'Root')
			}}>
			<Routes>
				{/* <Route path="v2/*" element={<RootV2 stripePromise={stripePromise} />} /> */}
				<Route path="*" element={<Router />} />
			</Routes>
			<Outlet />
		</ErrorBoundary>
	)
}

export default withProfiler(Root) // Attach Sentry profiler to top most component
