/* **************************************
 *  LIBRARIES
 * ************************************ */

const initialState = {
	// success: null,
	isRegistering: false,
	email: null,
	rsvp: null,
	account: null,
	pollingUserData: null,
}
/* **************************************
 *  PROJECT DEPENDENCIES
 * ************************************ */

import * as types from '../../constants'

function temporaryUser(state = initialState, action = {}) {
	switch (action.type) {
		case types.CHECK_ACCOUNT_EMAIL_REQUEST:
			return Object.assign({}, state, {
				email: action.payload,
				pollingUserData: true,
			})

		case types.CHECK_ACCOUNT_EMAIL_SUCCESS:
			return Object.assign({}, state, {
				success: action.payload.success,
				message: action.payload.message || '',
				account: action.payload.data
					? {
							...action.payload.data,
							profile: {
								...state.account?.profile,
								...action.payload.data?.profile,
							},
					  }
					: null,
				pollingUserData: false,
			})

		case types.CHECK_ACCOUNT_EMAIL_FAILURE:
			return Object.assign({}, state, {
				pollingUserData: false,
			})

		case 'SET_TEMP_USER':
			return Object.assign({}, state, {
				email: action.payload.email || null,
				rsvp: action.payload.rsvp || null,
				account: action.payload.data || null,
			})

		case types.RESET_TEMPORARY_USER:
			return initialState

		case types.REGISTER_REQUEST:
			return Object.assign({}, state, {
				isRegistering: true,
			})
		case types.REGISTER_SUCCESS:
			return Object.assign({}, state, {
				isRegistering: false,
			})
		case types.REGISTER_FAILURE:
			return Object.assign({}, state, {
				isRegistering: false,
			})
		default:
			return state
	}
}

export default temporaryUser
