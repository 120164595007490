/* ******************************
 * LIBRARIES
 ***************************** */

import { RSAA, getJSON } from 'redux-api-middleware'
import { normalize } from 'normalizr'

/* ******************************
 * PROJECT DEPENDENCIES
 ***************************** */
import * as types from '../constants'
import { announcementListSchema } from '../middlewares/normalizr'
import { retrieveURL } from '@vidday/utils'

/* ******************************
 * FETCH ALL ACTIVE ANNOUNCEMENTS
 ***************************** */

export const fetchActiveAnnouncements = () => ({
	[RSAA]: {
		types: [
			types.LOAD_ACTIVE_ANNOUNCEMENTS_REQUEST,
			{
				type: types.LOAD_ACTIVE_ANNOUNCEMENTS_SUCCESS,
				payload: (action, state, res) => {
					return getJSON(res).then((json) => normalize(json.data, announcementListSchema))
				},
			},
			types.LOAD_ACTIVE_ANNOUNCEMENTS_FAILURE,
		],
		endpoint: retrieveURL() + '/api/v2/announcements/active',
		credentials: 'include',
		method: 'GET',
	},
})
