/* **************************************
 *  LIBRARIES
 * ************************************ */

import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAuthContext } from '../../contexts/AuthProvider'
import { Box, Flex, Text, Icon } from '@chakra-ui/react'
import { ErrorRounded } from '../atoms/Icon'

const baseStylesWrapper = {
	my: 0,
	mb: ['-0.5rem', '0'],
	bottom: 0,
	position: 'fixed',
	display: 'block',
	zIndex: 1000,
	w: 'full',
	_hover: { display: 'none' },
	_active: { display: 'none' },
	_focus: { display: 'none' },
}

const baseStylesItem = {
	paddingRight: '5rem',
	pt: '1rem',
	pb: '1.5rem',
	align: 'center',
}

const baseStylesItem_promotion = {
	backgroundColor: '#6ff56fee',
	// minH: '4rem',
}

const baseStylesItem_maintenance = {
	backgroundColor: '#ffff00de',
}

const Announcements = () => {
	/** Retrieve weather the user is authenticated or not */
	const { isAuthenticated } = useAuthContext()

	/** Retrieve App's Announcements */
	const announcements = useSelector((state) => state.entities.announcements.entities)
	/** Hold a reference to items we should display */
	const [announcementsArray, setAnnouncementsArray] = useState([])

	/** I */
	useEffect(() => {
		let arr = []
		if (announcements && Object.keys(announcements).length > 0) {
			Object.keys(announcements).forEach(function (key) {
				//console.log(key, announcements[key])
				if (
					(isAuthenticated &&
						(announcements[key].type == 'maintenance' || announcements[key].type == 'promotion')) ||
					(!isAuthenticated && announcements[key].type == 'promotion')
				) {
					arr.push(announcements[key])
				}
			})

			setAnnouncementsArray(arr)
		}
	}, [isAuthenticated, announcements])

	/** Build views of announcements to be rendered by mapping each announcement */
	let view = announcementsArray.map((ann, i) => {
		let styles = baseStylesItem
		if (ann.type === 'promotion') styles = { ...baseStylesItem, ...baseStylesItem_promotion }
		if (ann.type === 'maintenance') styles = { ...baseStylesItem, ...baseStylesItem_maintenance }

		return (
			<Flex sx={styles} key={ann.id} py="0.5rem" px="1rem">
				{ann.type === 'maintenance' && <ErrorRounded mr="0.5rem" color="dark" />}
				<Text
					color="dark"
					sx={ann.type === 'promotion' ? { fontWeight: '900', fontSize: '1.125rem' } : { fontWeight: '900' }}>
					{ann.message}
				</Text>
			</Flex>
		)
	})

	/** Return our items view if we have announcements  */
	if (announcementsArray.length > 0) {
		return <Box sx={baseStylesWrapper}>{view}</Box>
	}

	/** Default return  */
	return <></>
}

Announcements.defaultProps = {}

Announcements.propTypes = {}

export default Announcements
