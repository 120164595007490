/* **************************************
 *  EVENTS
 * ************************************ */
export const SOCKET_UPDATE_MEDIA = 'SOCKET_UPDATE_MEDIA'

// SAVE EVENT TO BE CREATED TEMPORARILY
export const CLEAR_TEMPORARY_EVENT = 'CLEAR_TEMPORARY_EVENT'
export const ADD_TEMPORARY_EVENT = 'ADD_TEMPORARY_EVENT'

// LOAD COLLECTION OF EVENTS
export const LOAD_EVENTS_REQUEST = 'LOAD_EVENTS_REQUEST'
export const LOAD_EVENTS_SUCCESS = 'LOAD_EVENTS_SUCCESS'
export const LOAD_EVENTS_FAILURE = 'LOAD_EVENTS_FAILURE'

// LOAD ALL EVENTS TO WHICH THE USER MADE A CONTRIBUTION TO
export const LOAD_EVENTS_CONTRIBUTED_REQUEST = 'LOAD_EVENTS_CONTRIBUTED_REQUEST'
export const LOAD_EVENTS_CONTRIBUTED_SUCCESS = 'LOAD_EVENTS_CONTRIBUTED_SUCCESS'
export const LOAD_EVENTS_CONTRIBUTED_FAILURE = 'LOAD_EVENTS_CONTRIBUTED_FAILURE'

// LOAD AN EVENT
export const LOAD_EVENT_REQUEST = 'LOAD_EVENT_REQUEST'
export const LOAD_EVENT_SUCCESS = 'LOAD_EVENT_SUCCESS'
export const LOAD_EVENT_FAILURE = 'LOAD_EVENT_FAILURE'

export const LOAD_EVENT_SSR_REQUEST = 'LOAD_EVENT_SSR_REQUEST'
export const LOAD_EVENT_SSR_SUCCESS = 'LOAD_EVENT_SSR_SUCCESS'
export const LOAD_EVENT_SSR_FAILURE = 'LOAD_EVENT_SSR_FAILURE'

// UNLOAD EVENT DATA
export const CLEAR_CURRENT_EVENT_DATA = 'CLEAR_CURRENT_EVENT_DATA'

// CREATE AN EVENT
export const ADD_EVENT_REQUEST = 'ADD_EVENT_REQUEST'
export const ADD_EVENT_SUCCESS = 'ADD_EVENT_SUCCESS'
export const ADD_EVENT_FAILURE = 'ADD_EVENT_FAILURE'

// UPDATE AN EVENT
export const UPDATE_EVENT_REQUEST = 'UPDATE_EVENT_REQUEST'
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS'
export const UPDATE_EVENT_FAILURE = 'UPDATE_EVENT_FAILURE'

// DELETE AN EVENT
export const DELETE_EVENT_REQUEST = 'DELETE_EVENT_REQUEST'
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS'
export const DELETE_EVENT_FAILURE = 'DELETE_EVENT_FAILURE'

/* **************************************
 *  EVENT RECIPIENTS
 * ************************************ */

// ADD A NEW RECIPIENT TO AN EVENT
export const ADD_RECIPIENT_REQUEST = 'ADD_RECIPIENT_REQUEST'
export const ADD_RECIPIENT_SUCCESS = 'ADD_RECIPIENT_SUCCESS'
export const ADD_RECIPIENT_FAILURE = 'ADD_RECIPIENT_FAILURE'

// GET A RECIPIENT
export const LOAD_RECIPIENT_REQUEST = 'LOAD_RECIPIENT_REQUEST'
export const LOAD_RECIPIENT_SUCCESS = 'LOAD_RECIPIENT_SUCCESS'
export const LOAD_RECIPIENT_FAILURE = 'LOAD_RECIPIENT_FAILURE'

// UPDATE A RECIPIENT
export const UPDATE_RECIPIENT_REQUEST = 'UPDATE_RECIPIENT_REQUEST'
export const UPDATE_RECIPIENT_SUCCESS = 'UPDATE_RECIPIENT_SUCCESS'
export const UPDATE_RECIPIENT_FAILURE = 'UPDATE_RECIPIENT_FAILURE'

// DELETE A RECIPIENT
export const DELETE_RECIPIENT_REQUEST = 'DELETE_RECIPIENT_REQUEST'
export const DELETE_RECIPIENT_SUCCESS = 'DELETE_RECIPIENT_SUCCESS'
export const DELETE_RECIPIENT_FAILURE = 'DELETE_RECIPIENT_FAILURE'

/* **************************************
 *  PREFERENCES
 * ************************************ */

// LOAD AUTHED PROFILE
export const LOAD_PREFERENCES_REQUEST = 'LOAD_PREFERENCES_REQUEST'
export const LOAD_PREFERENCES_SUCCESS = 'LOAD_PREFERENCES_SUCCESS'
export const LOAD_PREFERENCES_FAILURE = 'LOAD_PREFERENCES_FAILURE'

// UPDATE AUTHED PROFILE
export const UPDATE_PREFERENCES_REQUEST = 'UPDATE_PREFERENCES_REQUEST'
export const UPDATE_PREFERENCES_SUCCESS = 'UPDATE_PREFERENCES_SUCCESS'
export const UPDATE_PREFERENCES_FAILURE = 'UPDATE_PREFERENCES_FAILURE'

/* **************************************
 *  PROFILE
 * ************************************ */

// LOAD AUTHED PROFILE
export const LOAD_PROFILE_REQUEST = 'LOAD_PROFILE_REQUEST'
export const LOAD_PROFILE_SUCCESS = 'LOAD_PROFILE_SUCCESS'
export const LOAD_PROFILE_FAILURE = 'LOAD_PROFILE_FAILURE'

// UPDATE AUTHED PROFILE
export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST'
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE'

/* **************************************
 *  INVOICES
 * ************************************ */

// LOAD COLLECTION OF INVOICES
export const LOAD_INVOICES_REQUEST = 'LOAD_INVOICES_REQUEST'
export const LOAD_INVOICES_SUCCESS = 'LOAD_INVOICES_SUCCESS'
export const LOAD_INVOICES_FAILURE = 'LOAD_INVOICES_FAILURE'

// CREATE AN INVOICE
export const ADD_INVOICE_REQUEST = 'ADD_INVOICE_REQUEST'
export const ADD_INVOICE_SUCCESS = 'ADD_INVOICE_SUCCESS'
export const ADD_INVOICE_FAILURE = 'ADD_INVOICE_FAILURE'

// UPDATE AN INVOICE
export const UPDATE_INVOICE_REQUEST = 'UPDATE_INVOICE_REQUEST'
export const UPDATE_INVOICE_SUCCESS = 'UPDATE_INVOICE_SUCCESS'
export const UPDATE_INVOICE_FAILURE = 'UPDATE_INVOICE_FAILURE'

// DELETE AN INVOICE
export const DELETE_INVOICE_REQUEST = 'DELETE_INVOICE_REQUEST'
export const DELETE_INVOICE_SUCCESS = 'DELETE_INVOICE_SUCCESS'
export const DELETE_INVOICE_FAILURE = 'DELETE_INVOICE_FAILURE'

/* **************************************
 *  PROMOTIONS
 * ************************************ */
// Load promotional Codes
export const LOAD_PROMOTION_REQUEST = 'LOAD_PROMOTION_REQUEST'
export const LOAD_PROMOTION_SUCCESS = 'LOAD_PROMOTION_SUCCESS'
export const LOAD_PROMOTION_FAILURE = 'LOAD_PROMOTION_FAILURE'

export const REMOVE_PROMOTION_SUCCESS = 'REMOVE_PROMOTION_SUCCESS'

/* **************************************
 *  FLASH MESSAGES
 * ************************************ */

export const ADD_FLASH_MESSAGE = 'ADD_FLASH_MESSAGE'
export const DELETE_FLASH_MESSAGE = 'DELETE_FLASH_MESSAGE'

/* **************************************
 *  CURRENT EVENT
 * ************************************ */

export const SET_CURRENT_MEDIA_TO_EDIT = 'SET_CURRENT_MEDIA_TO_EDIT'
export const RELOAD_CURRENT_MEDIA_TO_EDIT = 'RELOAD_CURRENT_MEDIA_TO_EDIT'
export const SET_CURRENT_EVENT_OWNER = 'SET_CURRENT_EVENT_OWNER'

/* **************************************
 *  AUTH & SESSIONS
 * ************************************ */

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE'

/* Authentication */
export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST'
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'

export const UPDATE_AUTHED_USER_REQUEST = 'UPDATE_AUTHED_USER_REQUEST'
export const UPDATE_AUTHED_USER_SUCCESS = 'UPDATE_AUTHED_USER_SUCCESS'
export const UPDATE_AUTHED_USER_FAILURE = 'UPDATE_AUTHED_USER_FAILURE'

/* Initiate password reset */
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE'

/* Complete password reset */
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE'

/* Activate user account */
export const ACTIVATE_ACCOUNT_REQUEST = 'ACTIVATE_ACCOUNT_REQUEST'
export const ACTIVATE_ACCOUNT_SUCCESS = 'ACTIVATE_ACCOUNT_SUCCESS'
export const ACTIVATE_ACCOUNT_FAILURE = 'ACTIVATE_ACCOUNT_FAILURE'

/* Check account for duplicate */
export const CHECK_ACCOUNT_EMAIL_REQUEST = 'CHECK_ACCOUNT_EMAIL_REQUEST'
export const CHECK_ACCOUNT_EMAIL_SUCCESS = 'CHECK_ACCOUNT_EMAIL_SUCCESS'
export const CHECK_ACCOUNT_EMAIL_FAILURE = 'CHECK_ACCOUNT_EMAIL_FAILURE'

/* Check account for duplicate - Except the ones linked to the current user */
export const CHECK_ACCOUNT_EMAIL_EXCEPT_OWNED_REQUEST = 'CHECK_ACCOUNT_EMAIL_EXCEPT_OWNED_REQUEST'
export const CHECK_ACCOUNT_EMAIL_EXCEPT_OWNED_SUCCESS = 'CHECK_ACCOUNT_EMAIL_EXCEPT_OWNED_SUCCESS'
export const CHECK_ACCOUNT_EMAIL_EXCEPT_OWNED_FAILURE = 'CHECK_ACCOUNT_EMAIL_EXCEPT_OWNED_FAILURE'

/* Resend verification process */
export const RESEND_VERIFICATION_REQUEST = 'RESEND_VERIFICATION_REQUEST'
export const RESEND_VERIFICATION_SUCCESS = 'RESEND_VERIFICATION_SUCCESS'
export const RESEND_VERIFICATION_FAILURE = 'RESEND_VERIFICATION_FAILURE'

export const RESET_TEMPORARY_USER = 'RESET_TEMPORARY_USER'

/* Authed User */
export const FETCH_AUTHED_USER_REQUEST = 'FETCH_AUTHED_USER_REQUEST'
export const FETCH_AUTHED_USER_FAILURE = 'FETCH_AUTHED_USER_FAILURE'
export const FETCH_AUTHED_USER_SUCCESS = 'FETCH_AUTHED_USER_SUCCESS'

// LOAD EVENTS OF THE CURRENT AUTHED USER
export const LOAD_AUTHED_EVENTS_REQUEST = 'LOAD_AUTHED_EVENTS_REQUEST'
export const LOAD_AUTHED_EVENTS_SUCCESS = 'LOAD_AUTHED_EVENTS_SUCCESS'
export const LOAD_AUTHED_EVENTS_FAILURE = 'LOAD_AUTHED_EVENTS_FAILURE'

// Used for server-side authentication
export const SET_AUTHENTICATED_USER = 'SET_AUTHENTICATED_USER'

export const REGISTER_REQUEST = 'REGISTER_REQUEST'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAILURE = 'REGISTER_FAILURE'

export const UPDATE_AUTHED_USER_PRIMARY_EMAIL_REQUEST = 'UPDATE_AUTHED_USER_PRIMARY_EMAIL_REQUEST'
export const UPDATE_AUTHED_USER_PRIMARY_EMAIL_SUCCESS = 'UPDATE_AUTHED_USER_PRIMARY_EMAIL_SUCCESS'
export const UPDATE_AUTHED_USER_PRIMARY_EMAIL_FAILURE = 'UPDATE_AUTHED_USER_PRIMARY_EMAIL_FAILURE'

/* **************************************
 *  EVENT MEDIA HANDLE
 * ************************************ */

export const LOAD_USER_MEDIAS_REQUEST = 'LOAD_USER_MEDIAS_REQUEST'
export const LOAD_USER_MEDIAS_SUCCESS = 'LOAD_USER_MEDIAS_SUCCESS'
export const LOAD_USER_MEDIAS_FAILURE = 'LOAD_USER_MEDIAS_FAILURE'

export const UPLOAD_EVENT_MEDIA_REQUEST = 'UPLOAD_EVENT_MEDIA_REQUEST'
export const UPLOAD_EVENT_MEDIA_ADDED = 'UPLOAD_EVENT_MEDIA_ADDED'
export const UPLOAD_EVENT_MEDIA_PROGRESS = 'UPLOAD_EVENT_MEDIA_PROGRESS'
export const UPLOAD_EVENT_MEDIA_SUCCESS = 'UPLOAD_EVENT_MEDIA_SUCCESS'
export const UPLOAD_EVENT_MEDIA_FAILURE = 'UPLOAD_EVENT_MEDIA_FAILURE'

export const POST_EVENT_MEDIA_REQUEST = 'POST_EVENT_MEDIA_REQUEST'
export const POST_EVENT_MEDIA_SUCCESS = 'POST_EVENT_MEDIA_SUCCESS'
export const POST_EVENT_MEDIA_FAILURE = 'POST_EVENT_MEDIA_FAILURE'

export const PUT_EVENT_MEDIA_REQUEST = 'PUT_EVENT_MEDIA_REQUEST'
export const PUT_EVENT_MEDIA_SUCCESS = 'PUT_EVENT_MEDIA_SUCCESS'
export const PUT_EVENT_MEDIA_FAILURE = 'PUT_EVENT_MEDIA_FAILURE'

export const UPDATE_EVENT_MEDIA_REQUEST = 'UPDATE_MEDIA_EVENT_REQUEST'
export const UPDATE_EVENT_MEDIA_SUCCESS = 'UPDATE_MEDIA_EVENT_SUCCESS'
export const UPDATE_EVENT_MEDIA_FAILURE = 'UPDATE_MEDIA_EVENT_FAILURE'

export const CLAIM_EVENT_MEDIA_REQUEST = 'CLAIM_MEDIA_EVENT_REQUEST'
export const CLAIM_EVENT_MEDIA_SUCCESS = 'CLAIM_MEDIA_EVENT_SUCCESS'
export const CLAIM_EVENT_MEDIA_FAILURE = 'CLAIM_MEDIA_EVENT_FAILURE'

export const UPDATE_MEDIA_REQUEST = 'UPDATE_MEDIA_REQUEST'
export const UPDATE_MEDIA_SUCCESS = 'UPDATE_MEDIA_SUCCESS'
export const UPDATE_MEDIA_FAILURE = 'UPDATE_MEDIA_FAILURE'

export const UPDATE_MEDIA_SORTING_REQUEST = 'UPDATE_MEDIA_SORTING_REQUEST'
export const UPDATE_MEDIA_SORTING_SUCCESS = 'UPDATE_MEDIA_SORTING_SUCCESS'
export const UPDATE_MEDIA_SORTING_FAILURE = 'UPDATE_MEDIA_SORTING_FAILURE'

export const ENCODE_MEDIA_REQUEST = 'ENCODE_MEDIA_REQUEST'
export const ENCODE_MEDIA_SUCCESS = 'ENCODE_MEDIA_SUCCESS'
export const ENCODE_MEDIA_FAILURE = 'ENCODE_MEDIA_FAILURE'

export const REGENERATE_MEDIA_REQUEST = 'REGENERATE_MEDIA_REQUEST'
export const REGENERATE_MEDIA_SUCCESS = 'REGENERATE_MEDIA_SUCCESS'
export const REGENERATE_MEDIA_FAILURE = 'REGENERATE_MEDIA_FAILURE'

export const DELETE_MEDIA_REQUEST = 'DELETE_MEDIA_REQUEST'
export const DELETE_MEDIA_SUCCESS = 'DELETE_MEDIA_SUCCESS'
export const DELETE_MEDIA_FAILURE = 'DELETE_MEDIA_FAILURE'

export const EDIT_VIDEO_REQUEST = 'EDIT_VIDEO_REQUEST'
export const EDIT_VIDEO_SUCCESS = 'EDIT_VIDEO_SUCCESS'
export const EDIT_VIDEO_FAILURE = 'EDIT_VIDEO_FAILURE'

/* **************************************
 *  PAYMENT PROCESSING
 * ************************************ */

export const POST_EVENT_PAYMENT_REQUEST = 'POST_EVENT_PAYMENT_REQUEST'
export const POST_EVENT_PAYMENT_UPDATE = 'POST_EVENT_PAYMENT_UPDATE'

/* **************************************
 *  SITE-WIDE ANNOUNCEMENTS (& MAINTENANCE)
 * ************************************ */
export const LOAD_ACTIVE_ANNOUNCEMENTS_REQUEST = 'LOAD_ACTIVE_ANNOUNCEMENTS_REQUEST'
export const LOAD_ACTIVE_ANNOUNCEMENTS_SUCCESS = 'LOAD_ACTIVE_ANNOUNCEMENTS_SUCCESS'
export const LOAD_ACTIVE_ANNOUNCEMENTS_FAILURE = 'LOAD_ACTIVE_ANNOUNCEMENTS_FAILURE'

/* **************************************
 *  MAILERS
 * ************************************ */

export const POST_MAIL_RECIPIENT_PRIVATE_PAGE_REQUEST = 'POST_MAIL_RECIPIENT_PRIVATE_PAGE_REQUEST'
export const POST_MAIL_RECIPIENT_PRIVATE_PAGE_SUCCESS = 'POST_MAIL_RECIPIENT_PRIVATE_PAGE_SUCCESS'
export const POST_MAIL_RECIPIENT_PRIVATE_PAGE_FAILURE = 'POST_MAIL_RECIPIENT_PRIVATE_PAGE_FAILURE'

export const POST_MAIL_CONTRIBUTORS_REQUEST = 'POST_MAIL_CONTRIBUTORS_REQUEST'
export const POST_MAIL_CONTRIBUTORS_SUCCESS = 'POST_MAIL_CONTRIBUTORS_SUCCESS'
export const POST_MAIL_CONTRIBUTORS_FAILURE = 'POST_MAIL_CONTRIBUTORS_FAILURE'

export const POST_MAIL_BROADCAST_MESSAGE_REQUEST = 'POST_MAIL_BROADCAST_MESSAGE_REQUEST'
export const POST_MAIL_BROADCAST_MESSAGE_SUCCESS = 'POST_MAIL_BROADCAST_MESSAGE_SUCCESS'
export const POST_MAIL_BROADCAST_MESSAGE_FAILURE = 'POST_MAIL_BROADCAST_MESSAGE_FAILURE'

export const MAIL_SEND_SUCCESS = 'MAIL_SEND_SUCCESS'
export const MAIL_SEND_FAILURE = 'MAIL_SEND_FAILURE'
