/* **************************************
 *  LIBRARIES
 * ************************************ */

import { combineReducers } from 'redux'
import { videoReducer } from '../v2/ui/organisms/VideoPlayer'

/* **************************************
 *  LOAD REDUCERS
 * ************************************ */

/** Modules */
import { reducer as auth } from '../api/app/auth'
/** Behaviors */
import { reducer as temporaryEvent } from '../api/app/behaviors/temporary-event'
import { reducer as temporaryUser } from '../api/app/behaviors/temporary-user'
import { reducer as flashMessages } from '../api/app/behaviors/flash-messages'
import { reducer as entities } from '../api/app/behaviors/entities'
import uploader from '../v2/ui/organisms/Uppy/reducer'
import notificationsReducer from '../api/notifications/reducers'
/** Administration Area */
import { reducer as adminUsers } from '../api/admin/users'
import { reducer as adminPromotions } from '../api/admin/promotions'
import { reducer as adminAnnouncements } from '../api/admin/announcements'
import { reducer as adminEvents, reducerRenderJobs } from '../api/admin/events'

// Hook implementation
import formReducers from './forms'
import eventReducer from './event'
/** OLD CHECKOUT REDUCER */
import checkoutReducer from './checkout'

/* **************************************
 *  BUILD FINAL APPLICATION STATES
 * ************************************ */
import invoicesReducer from './invoices'
import flashMessageReducer from './flashMessages'
import environmentReducer from './environment'

/** Administration Area */
const adminReducer = combineReducers({
	users: adminUsers,
	events: adminEvents,
	promotions: adminPromotions,
	announcements: adminAnnouncements,
	eventRenderJobs: reducerRenderJobs,
})

/** Vidday App */
const createRootReducer = () =>
	combineReducers({
		/** Define Reducers Hooks for forms */
		forms: formReducers,
		/* ******** MODULES ******** */
		// Current Authed User
		auth: auth,
		/* ******** BEHAVIORS ******** */
		flashMessages: flashMessages,
		// All Authed Events and Media
		entities: entities,
		// Store a temporary event during creation without being signed in
		temporaryEvent: temporaryEvent,
		// Polls for existing user and returns user data
		temporaryUser: temporaryUser,
		// Uploads
		uploader: uploader,
		// Video Edition REDUCERS
		videoEditing: videoReducer,
		/* ******** VIDDAY SUPER ADMIN ******** */
		admin: adminReducer,
		// Current event being visited
		event: eventReducer,
		// Cart and totals
		checkout: checkoutReducer,
		invoices: invoicesReducer,
		environment: environmentReducer,
		messages: flashMessageReducer,
		notifications: notificationsReducer,
	})

export default createRootReducer
