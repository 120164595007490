import PropTypes from 'prop-types'

/* **************************************
 *  Rakuten Action
 * ************************************ */

const propTypes = {
	actionType: PropTypes.oneOf(['lead', 'purchase']).isRequired,
	actionS3Key: PropTypes.string.isRequired,
	actionReceipt: PropTypes.string.isRequired,
	actionDate: PropTypes.instanceOf(Date).isRequired,
}

const defaultProps = {
	actionType: null,
}

const RakutenAction = { propTypes, defaultProps }

export default RakutenAction
