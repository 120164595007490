import PropTypes from 'prop-types'

/* **************************************
 *  Event Render Job
 * ************************************ */

const propTypes = {
	createdBy: PropTypes.string.isRequired,
	jobState: PropTypes.string,
	outputKey: PropTypes.string,
	signedOutputUrl: PropTypes.string,
	signedThumbnailUrl: PropTypes.string,
	archive: PropTypes.bool,
	preview: PropTypes.bool,
	retries: PropTypes.number,
}

const defaultProps = {
	createdBy: null,
	jobState: null,
	outputKey: null,
	signedOutputUrl: null,
	signedThumbnailUrl: null,
	archive: false,
	preview: false,
	retries: 0,
}

const EventRenderJob = { propTypes, defaultProps }

export default EventRenderJob
