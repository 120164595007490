import React from 'react'
import { Helmet } from 'react-helmet'
import { VStack, Text, Button, Container, Heading, Image, Divider } from '@chakra-ui/react'

export const ReloadBrowserButton = () => (
	<Button
		variant="solid"
		onClick={() => {
			if (window && typeof window != 'undefined') {
				return (window.location.href = `${window.location.pathname}?t=${new Date().getTime()}`)
			}
			return false
		}}>
		Refresh This Page
	</Button>
)

export const LiveChatButton = () => (
	<Button
		variant="solid"
		onClick={() => {
			if (window && window?.LC_API?.open_chat_window) {
				return window.LC_API.open_chat_window()
			}
			return false
		}}>
		Start a Live Chat
	</Button>
)

const RefreshPage = () => {
	return (
		<>
			<Helmet>
				<title>VidDay - Refresh Needed</title>
			</Helmet>
			<Container maxW="container.md">
				<VStack spacing="1rem" textAlign="center" w="full">
					<Image
						src="/assets/images/graphics/refresh-page-animated.svg"
						w="300px"
						h="300px"
						alt="VidDay - Refresh Needed"
					/>
					<Heading as="h3" variant="hero" size="lg">
						Out with the old, in with the new.
					</Heading>
					<Text>This page is out of date, please refresh to see the latest.</Text>
					<ReloadBrowserButton />
				</VStack>
				<Divider my="2rem" />
				<VStack spacing="1rem" textAlign="center" w="full">
					<Text>
						If you need support, start a chat with a VidDay Customer Care Specialist. We&apos;re happy to
						help!
					</Text>
					<LiveChatButton />
				</VStack>
			</Container>
		</>
	)
}

export default RefreshPage
