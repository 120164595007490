const Container = {
	baseStyle: {},
	variants: {
		main: () => {
			return {
				pb: '1rem',
				px: [0, '1rem'],
			}
		},
		invitationReview: {
			bg: "var(--vidday-colors-dark) url('/assets/images/bg/submission-page-background-pattern.png')",
			backgroundSize: '50%',
			backgroundRepeat: 'repeat-x',
			p: '0',
			m: '0',
		},
		marketplace: () => {
			return {
				py: '1rem',
				px: '0',
				maxW: '1024px',
			}
		},
	},
	defaultProps: {},
}

export default Container
