import { createSlice } from '@reduxjs/toolkit'
import { combineFormState } from './formState'

const initialState = {
	data: {
		email: '',
	},
}

/** This reducer allows us to retrieve the email address stored by the login form so it can be retrieved by the register form if the user does not exist. */

const rootSlice = createSlice(
	combineFormState('CHECK_ACCOUNT_EMAIL', {
		name: 'forms.Auth',
		initialState,
		reducers: {
			// reset: (state) => initialState,
			// updateEmail: (state, action) => {
			// 	state.email = action.payload
			// },
		},
	})
)

// Export Form Reducer
export const reducer = rootSlice.reducer

// Export our actions
export const { reset, updateEmail } = rootSlice.actions
