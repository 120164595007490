import useCartMeta from './useCartMeta'
import useEnvironmentMeta from './useEnvironmentMeta'
import useEventMeta from './useEventMeta'
import useUserMeta from './useUserMeta'

export default () => {
	useUserMeta()
	useEnvironmentMeta()
	useEventMeta()
	// useCartMeta()
}
