/* ******************************
 * PROJECT DEPENDENCIES
 ***************************** */

import * as types from '../../constants'
import { makeActionCreator } from '@vidday/utils'

/* ******************************
 * ADD A FLASH MESSAGE
 ***************************** */

export const addFlashMessage = makeActionCreator(types.ADD_FLASH_MESSAGE, 'status', 'message', 'callToAction')

/* ******************************
 * DELETE A FLASH MESSAGE
 ***************************** */

export const deleteFlashMessage = makeActionCreator(types.DELETE_FLASH_MESSAGE, 'id')
