import { useWidgetIsReady } from '@livechat/widget-react'
import { useEffect } from 'react'
import { osName, getUA } from 'react-device-detect'
import { shallowEqual, useSelector } from 'react-redux'

const useEnvironmentMeta = () => {
	const isReady = useWidgetIsReady()

	const { viewport } = useSelector((s) => s.environment, shallowEqual)

	useEffect(() => {
		if (isReady) {
			const displaySize = viewport.width + 'x' + viewport.height || null

			const env = {
				'User Device': osName,
				'User Browser': getUA,
				'Display Size': displaySize,
			}

			LiveChatWidget.call('update_session_variables', env)
		}
	}, [isReady, osName, viewport, getUA])
}

export default useEnvironmentMeta
