import React, { useEffect, useState } from 'react'
import { chakra } from '@chakra-ui/react'
import { baseControlStyles } from './Controls'

const CastButton = ({
	src,
	type = 'video/mp4',
	volume,
	play,
	currentTime,
	castSeek,
	castConnected,
	setCastConnected,
	castAvailable,
	onPlayerPlay,
	onPlayerPause,
	onPlayerPlaying,
	onPlayerTimeUpdate,
}) => {
	const [remotePlayer, setRemotePlayer] = useState(null)
	const [remotePlayerController, setRemotePlayerController] = useState(null)

	// Let's initialize the cast API
	useEffect(() => {
		castAvailable && cast && initializeCastPlayer()
	}, [])

	// sync vidday-player-volume
	useEffect(() => {
		castConnected && handleRemotePlayerVolume(volume)
	}, [volume])

	// sync vidday-player-play/pause-action
	useEffect(() => {
		handleRemotePlayerPlayPause(play)
	}, [play])

	// sync vidday-player-seek-action
	useEffect(() => {
		if (remotePlayer) {
			remotePlayer.currentTime = currentTime
			remotePlayerController.seek()
		}
	}, [castSeek])

	const initializeCastPlayer = () => {
		const options = {
			receiverApplicationId: chrome?.cast?.media?.DEFAULT_MEDIA_RECEIVER_APP_ID,
			autoJoinPolicy: chrome?.cast?.AutoJoinPolicy?.ORIGIN_SCOPED,
			androidReceiverCompatible: true,
		}

		cast.framework.CastContext.getInstance().setOptions(options)

		var player = new cast.framework.RemotePlayer()
		var playerController = new cast.framework.RemotePlayerController(player)
		// set default Chromecast volume to 50%
		player.currentTime = 0.5
		playerController.setVolumeLevel()
		setRemotePlayer(player)
		setRemotePlayerController(playerController)

		playerController.addEventListener(cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED, function (e) {
			var isConnected = e.value
			setCastConnected(isConnected)
			console.log('cast-isConnected -', isConnected)
			if (isConnected) {
				handleLoadMedia()
			}
			if (!isConnected) {
				onPlayerPause()
				onPlayerPlaying(false)
				var castSession = cast.framework.CastContext.getInstance().getCurrentSession()
				playerController.stop()
				console.log(`remote player is stopped`)
				castSession && cast.framework.CastContext.getInstance().endCurrentSession()
				playerController.removeEventListener()
			}
		})

		playerController.addEventListener(cast.framework.RemotePlayerEventType.PLAYER_STATE_CHANGED, function (e) {
			if (e.value === 'PLAYING') {
				// console.log('Chromecast is ' + e.value)
				onPlayerPlay()
				onPlayerPlaying(true)
			} else if (e.value === 'PAUSED') {
				// console.log('Chromecast is ' + e.value)
				onPlayerPause()
				onPlayerPlaying(false)
			} else if (e.value === 'BUFFERING') {
				// console.log('Chromecast is ' + e.value)
			} else if (e.value === 'IDLE') {
				// console.log('Chromecast is ' + e.value)
				var castSession = cast.framework.CastContext.getInstance().getCurrentSession()
				castSession && onPlayerTimeUpdate(0)
				castSession && handleLoadMedia()
			} else {
				// console.log('Chromecast is ' + e.value)
			}
		})
		playerController.addEventListener(cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED, (e) => {
			e.value != 0 && onPlayerTimeUpdate(e.value)
		})
	}

	const handleLoadMedia = () => {
		var castSession = cast.framework.CastContext.getInstance().getCurrentSession()
		let mediaInfo = new chrome.cast.media.MediaInfo(src, type)
		// mediaInfo.metadata = new chrome.cast.media.MovieMediaMetadata()
		// mediaInfo.metadata.title = 'Sample Title'
		let request = new chrome.cast.media.LoadRequest(mediaInfo)
		request.autoplay = false
		castSession &&
			castSession
				.loadMedia(request)
				.then(() => {
					console.log('Media is loaded')
				})
				.catch((e) => {
					console.log(e)
				})
	}

	const handleRemotePlayerVolume = (newVolume) => {
		if (remotePlayer) {
			remotePlayer.volumeLevel = newVolume / 100
			remotePlayerController.setVolumeLevel()
			console.log(`Volume is set to ${newVolume}`)
		}
	}

	const handleRemotePlayerPlayPause = (play) => {
		if (remotePlayer && remotePlayer.playerState != 'BUFFERING') {
			if (remotePlayer.currentTime != currentTime) {
				remotePlayer.currentTime = currentTime
				remotePlayerController.seek()
			}
			play && remotePlayer.isPaused && remotePlayerController.playOrPause()
			!play && !remotePlayer.isPaused && remotePlayerController.playOrPause()
		}
	}

	return (
		<chakra.button
			sx={baseControlStyles}
			title={castConnected ? `Exit video-cast` : `Cast video on big screen`}
			float="right">
			<google-cast-launcher
				id="castbutton"
				type="button"
				style={{
					width: '20px',
					height: '20px',
					paddingLeft: '5px',
				}}></google-cast-launcher>
		</chakra.button>
		// <StyledBaseControl
		// 	style={{ float: 'right' }}
		// 	title={castConnected ? `Exit video-cast` : `Cast video on big screen`}>
		// 	<google-cast-launcher
		// 		id="castbutton"
		// 		type="button"
		// 		style={{
		// 			width: '20px',
		// 			height: '20px',
		// 			paddingLeft: '5px',
		// 		}}></google-cast-launcher>
		// </StyledBaseControl>
	)
}

export default CastButton
