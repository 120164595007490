/* **************************************
 *  PROJECT DEPENDENCIES
 * ************************************ */

import * as types from '../../constants'

/* **************************************
 *  INITIAL STATE
 * ************************************ */

const initialState = {
	isPending: false,
	data: null,
}

export default function temporaryEvent(state = initialState, action = {}) {
	switch (action.type) {
		/* **************************************
		EVENTS
		************************************** */

		case types.ADD_TEMPORARY_EVENT:
			return Object.assign({}, state, {
				data: action.payload.data,
				isPending: true,
			})
		case types.CLEAR_TEMPORARY_EVENT:
			return Object.assign({}, state, {
				data: null,
				isPending: false,
			})

		default:
			return state
	}
}
