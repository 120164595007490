import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuthContext } from '../contexts/AuthProvider'
import { auth as authConfig } from '../../../client/config.client'
import { Flex, Spinner } from '@chakra-ui/react'
import redirectToNextIfWindow from '../utils/redirectToNextIfWindow'

const ProtectedRoute = ({ children, ...rest }) => {
	const { isAuthenticated, isAuthenticating } = useAuthContext()

	const location = useLocation()
	/** Build redirect link */
	const redirectLink = location.search ? `${location.search}&next=${location.pathname}` : `?next=${location.pathname}`

	/** Build full login path to redirect user to */
	const loginPath = `${authConfig.loginURL}${redirectLink}`

	useEffect(() => {
		if (!isAuthenticated && !isAuthenticating) {
			redirectToNextIfWindow(loginPath)
			// NEXTJS
			// window.location.href = window.location.protocol+'//'+window.location.hostname+':'+'3001'+ loginPath
			// window.location.href = loginPath
			// return <Navigate to={loginPath} state={{ from: location }} replace={true} />
		}
	}, [isAuthenticated, isAuthenticating, loginPath])

	// if (!isAuthenticated && !isAuthenticating) {
	// 	// NEXTJS
	// 	window.location.href = loginPath
	// 	// return <Navigate to={loginPath} state={{ from: location }} replace={true} />
	// } else
	if (isAuthenticated) {
		return children
	}

	return (
		<Flex w="full" justifyContent="center" py="2rem">
			<Spinner color="link" />
		</Flex>
	)
}

export default ProtectedRoute
