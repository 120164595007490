import { useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { setTemporaryUser, updateRSVP } from '../../../../../../api/actions'
import { useEventContext } from '../../../../../contexts/EventProvider'
import loadable from '@loadable/component'

const ModalUpdateRsvpConfirm = loadable(() =>
	import(/* webpackPrefetch: true */ '../../../../organisms/ModalUpdateRsvpConfirm')
)

const HandleTemporaryUserParams = () => {
	/** Retrieve current event global state */
	const { event } = useEventContext()

	const dispatch = useDispatch()
	const [search] = useSearchParams()
	const [status, setStatus] = useState(search.get('status'))

	/** Manage Modal state */
	const updatedRsvpModal = useDisclosure()

	useEffect(() => {
		let emailFromParam = null
		let account = {}
		let rsvp = null
		if (search.has('email') || search.has('user_id') || search.has('sms') || search.has('rsvp')) {
			if (search.has('status') && search.has('rsvp') && event.id) {
				setStatus(search.get('status'))
				// && search.has('userId')
				// Update this user's rsvp status for this event rsvp
				dispatch(
					updateRSVP({
						id: search.get('rsvp'),
						status: search.get('status'),
					})
				)
				updatedRsvpModal.onOpen()
			} else {
				// Set Temporary user from url Params
				if (search.has('email')) {
					emailFromParam = search.get('email').replace(' ', '+')
					account.email = emailFromParam
				}
				if (search.has('rsvp')) {
					rsvp = search.get('rsvp') // should be stored in local with atom
				}
				if (search.has('user_id')) {
					// We don't want to save a userId otherwise it will by-pass the contributor form.
					// account.id = search.get('userId')
				}
				if (search.has('first_name') || search.has('last_name') || search.has('sms')) {
					account.profile = {}
					account.profile.firstName = search.get('first_name')
					account.profile.lastName = search.get('last_name')
					account.profile.sms = search.get('sms')
				}
				dispatch(
					setTemporaryUser({
						email: emailFromParam,
						rsvp: rsvp, // should be stored in local with atom
						data: account,
					})
				)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<ModalUpdateRsvpConfirm isOpen={updatedRsvpModal.isOpen} onClose={updatedRsvpModal.onClose} status={status} />
	)
}

export default HandleTemporaryUserParams
