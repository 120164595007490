import React, { useRef, useEffect, useState } from 'react'
import { noop } from '../libs/utils'
import { forwardRef, Spinner } from '@chakra-ui/react'
import { Box, keyframes, Icon } from '@chakra-ui/react'
import { PlayArrowRounded } from '../../../atoms/Icon'
/** COMPONENTS */
import fullscreen, { fsListeners } from '../libs/fullscreen'
import { PlayPauseControl, VolumeControl, VideoRepeatControl, DurationLabel, FullScreenControl } from './Controls'
import Status from './Status'
import VolumeSliderChakra from './VolumeSlider.chakra'
import Trimmer from './Trimmer'

/**HOOK */
import useEventListener from '../../../../hooks/useEventListener'
import CastButton from './CastControl'
import { useLocation } from 'react-router-dom'
import { isAndroid, isBrowser } from 'react-device-detect'

const fadeIn = keyframes`
	0% {
		opacity: 0;
		visibility: hidden;
	}
	100% {
		opacity: 1;
		visibility: visible;
	}
`

const playIconStyles = {
	display: 'inline-flex',
	justifyContent: 'center',
	alignItems: 'center',
	pos: 'absolute',
	right: '0',
	top: '50%',
	w: '40px',
	h: '40px',
	left: '50%',
	border: '2px solid white',
	transform: 'translateY(-50%)',
	marginLeft: '-30px',
	backgroundColor: 'link',
	borderRadius: '50%',
	zIndex: 2,
	boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.5)',
	animation: `${fadeIn} 1000ms ease-in-out`,
}

// /* Styles of the video Wrapper component allowing us to rotate the video using html5 and css3 */
// const PlayerOverlay = styled.div`
// 	position: absolute;
// 	overflow: visible;
// 	width: 100%;
// 	height: 40px;
// 	background-color: ${(props) => `${props.baseColor}`};
// 	padding-left: 4px;
// 	padding-right: 4px;
// 	transition: all 0.2s ease;
// 	opacity: ${(props) => (props.hide ? `0` : `unset`)};
// 	transition: opacity 1s;
// `
/* Styles of the video Wrapper component allowing us to rotate the video using html5 and css3 */
const getPlayerOverlayStyles = ({ baseColor, hide }) => {
	return {
		pos: 'absolute',
		overflow: 'visible',
		width: '100%',
		height: '40px',
		backgroundColor: baseColor,
		pl: '4px',
		pr: '4px',
		transition: 'all 0.2s ease',
		opacity: hide ? '0' : 'unset',
		transition: 'opacity 1s',
	}
}

const PlayerOverlay = ({ baseColor, hide, ...rest }) => {
	const styles = getPlayerOverlayStyles({ baseColor, hide })
	return <Box sx={styles} {...rest} />
}

// const RotateComponentStyling = styled.div`
// 	display: block;
// 	overflow: hidden;
// 	position: relative;
// 	width: 100%;
// 	height: ${(props) => `${props.height}px`};

// 	border-radius: 0;

// 	${breakpoint.tablet`border-radius: var(--global-s2);`}

// 	&.rotate0 video,
// 	&.rotatenull video {
// 		transform: rotate(0deg);
// 	}

// 	&.rotate90 {
// 		video {
// 			height: ${(props) => `${props.width}px`};
// 			width: ${(props) => `${props.height}px`};
// 			// transform: rotate(90deg);
// 			left: 50%;
// 			top: 0%;
// 			position: absolute;
// 			transform: translate(-50%, -22.5%) rotate(90deg);
// 		}
// 	}

// 	&.rotate180 video {
// 		transform: rotate(180deg);
// 	}

// 	&.rotate270 {
// 		video {
// 			height: ${(props) => `${props.width}px`};
// 			width: ${(props) => `${props.height}px`};
// 			// transform: rotate(270deg);
// 			left: 50%;
// 			top: 0%;
// 			position: absolute;
// 			transform: translate(-50%, -22.5%) rotate(270deg);
// 		}
// 	}
// `
const getRotateComponentStyles = ({ height, width }) => {
	return {
		'd': 'block',
		'overflow': 'hidden',
		'pos': 'relative',
		'width': '100%',
		'h': `${height}px`,
		'borderRadius': ['0', '0.5rem'],
		'&.rotate0 video': {
			transform: 'rotate(0deg)',
		},
		'&.rotatenull video': {
			transform: 'rotate(0deg)',
		},
		'&.rotate90 video': {
			height: `${width}px`,
			width: `${height}px`,
			left: '50%',
			top: '0%',
			pos: 'absolute',
			transform: 'translate(-50%, -22.5%) rotate(90deg)',
		},
		'&.rotate180 video': {
			transform: 'rotate(180deg)',
		},
		'&.rotate270 video': {
			height: `${width}px`,
			width: `${height}px`,
			left: '50%',
			top: '0%',
			pos: 'absolute',
			transform: 'translate(-50%, -22.5%) rotate(270deg)',
		},
	}
}

const RotateComponentStyling = forwardRef(({ width, height, ...rest }, ref) => {
	const styles = getRotateComponentStyles({ width, height })
	return <Box sx={styles} ref={ref} {...rest} />
})

// const fadeInAnimation = css`
// 	animation: ${fadeIn} 750ms ease-in-out;
// `

// const PlayerWrapper = styled.div`
// 	height: -webkit-fill-available;
// 	position: relative;
// 	display: flex;
// 	cursor: pointer;
// 	flex-direction: column;
// 	justify-content: flex-end;
// 	${(p) => (p.removeFadeIn ? '' : fadeInAnimation)}

// 	img {
// 		position: absolute;
// 		z-index: 1;
// 	}
// 	video {
// 		margin: auto;
// 		height: ${(props) => `${props.height}px`};
// 	}
// `
const getPlayerWrapperStyles = ({ removeFadeIn, height }) => {
	const fadeInStyles = removeFadeIn ? { animation: `${fadeIn} 750ms ease-in-out` } : {}
	return {
		height: '-webkit-fill-available',
		pos: 'relative',
		display: 'flex',
		cursor: 'pointer',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		...fadeInStyles,
		img: {
			pos: 'absolute',
			zIndex: '1',
		},
		video: {
			margin: 'auto',
			height: `${height}px`,
		},
	}
}

const PlayerWrapper = ({ removeFadeIn, height, ...rest }) => {
	const styles = getPlayerWrapperStyles({ removeFadeIn, height })
	return <Box sx={styles} {...rest} />
}

const Player = (props) => {
	var player = props.videoRef
	const frame = useRef()

	const location = useLocation()

	const [ready, setReady] = useState(false)
	const [inRange, setInRange] = useState(false)
	const [castAvailable, setCastAvailable] = useState(false)
	const [castConnected, setCastConnected] = useState(false)
	const [castSeek, setCastSeek] = useState(false)
	const [accentColor] = useState(props.accentColor || '#E7E9F1') // must be hex
	const [baseColor] = useState(props.baseColor || '#0A2239') // must be hex
	const [playerDataDuration, setPlayerDataDuration] = useState(0)
	const [volume, setVolume] = useState(50)
	const showVolumeSlider = !props.hideVolume && (isBrowser || isAndroid)
	const tryCatch = (tryThis) => {
		console.log('tryThis', tryThis)
		try {
			tryThis
		} catch (e) {
			console.log('error', e)
		}
	}

	// Let's check cast compatible browser to display cast button
	useEffect(() => {
		if (!props.hideVolume) {
			window['__onGCastApiAvailable'] = (isAvailable) => {
				if (isAvailable && window.chrome && window.cast) {
					setCastAvailable(true)
					// console.log(`cast-available`, isAvailable)
				} else {
					setCastAvailable(false)
					// console.log('Was not able to initialize CastPlayer')
				}
			}
		}
	}, [])

	// notify remote player to seek
	const handleCastSeek = () => {
		setCastSeek(!castSeek)
	}
	const updateTimeRange = (duration) => {
		// console.log(`timeRange is different than db`)
		props.setTimeRange({ start: props.timeRange.start, end: duration })
		props.setDuration(duration)
	}
	const keepInRange = (time, range) => {
		let timeInRange = time
		// sanitize these numbers a bit
		let t = time || 0
		let s = range.start || 0
		let e = range.end
		if (t < s) {
			timeInRange = s + 1
		} else if (t > e) {
			timeInRange = e
		}
		return timeInRange
	}

	const isInRange = (time, range) => {
		let t = Math.ceil(time * 1000) / 1000
		let s = Math.floor(range.start * 10) / 10
		let e = Math.ceil(range.end * 10) / 10
		// console.log("TIMES:",t >= s && t <= e,t,s,e)
		setInRange(t > s - 0.5 && t < e)
	}

	const useAnimationFrame = (counting) => {
		if (counting) {
			frame.current = requestAnimationFrame(() => {
				// update currentTime to parent
				props.onPlayerTimeUpdate(Math.round(player.current?.currentTime * 1000) / 1000)
				frame.current = requestAnimationFrame(useAnimationFrame)
			})
		} else {
			cancelAnimationFrame(frame.current)
		}
	}

	//** IS PLAYER PLAYING OR NOT */
	var isPlaying =
		player.current &&
		player.current.currentTime > 0 &&
		!player.current.paused &&
		!player.current.ended &&
		player.current.readyState > 2

	// Load initial currentTime
	useEffect(() => {
		// load file as blob
		// handleSetStaticFile()
		player.current.currentTime = keepInRange(props.currentTime || 0, props.timeRange)
		// Kill any timers on close
		return () => {
			cancelAnimationFrame(frame.current)
		}
	}, [])

	useEffect(() => {
		// return that we playing or not
		props.onPlayerPlaying(isPlaying)
		if (isPlaying == false) {
			props.onPlayerPause()
		}
	}, [isPlaying])

	async function playVideo() {
		try {
			await player.current.play()
		} catch (err) {
			console.log('Player Error:', err)
			// return that we NOT playing
			props.onPlayerPlaying ? props.onPlayerPlaying(false) : noop
			// // set to pause state
			props.onPlayerPause ? props.onPlayerPause() : noop
		}
	}

	async function pauseVideo() {
		try {
			await player.current.pause()
		} catch (err) {
			console.log('Player Error:', err)
			// return that we NOT playing
			props.onPlayerPlaying ? props.onPlayerPlaying(true) : noop
			// // set to pause state
			props.onPlayerPlay ? props.onPlayerPlay() : noop
		}
	}

	useEffect(() => {
		document.getElementById('video').volume = volume / 100
	}, [volume])

	const handleOnMuteUnmuteClick = () => {
		props.muted ? setVolume(50) : setVolume(0)
		props.onMuteUnmuteClick()
	}

	const handleVolumeChange = ({ target: { value } }) => {
		value === 0 ? props.onMuteVideo() : props.onUnmuteVideo()
		setVolume(value)
	}

	/** Handler for chakra implementation */
	const handleVolumeChangeChakra = (value) => {
		value === 0 ? props.onMuteVideo() : props.onUnmuteVideo()
		setVolume(value)
	}

	const handleVideoLoop = () => {
		useAnimationFrame(false)
		// props.onCurrentPositionTimeChange(props.timeRange.start)
		// player.current.currentTime = keepInRange(props.timeRange.start || 0, props.timeRange)
		playVideo().then(() => {
			// Start counting frames
			useAnimationFrame(true)
			// return that we are playing
			props.onPlayerPlaying ? props.onPlayerPlaying(true) : noop
			// set to play state
			props.onPlayerPlay ? props.onPlayerPlay() : noop
		})
	}

	// Play/Pause from props
	useEffect(() => {
		//console.log(`Play call:${props.play}\n isPlaying:${!isPlaying}\n inRange:${inRange}`)
		if (!castConnected && player.current && props.play && !isPlaying && inRange) {
			// 	Set play position to given time within range before play
			player.current.currentTime = keepInRange(props.currentTime || 0, props.timeRange) // make sure we are seeked to the right position
			// Play Player
			playVideo().then(() => {
				// Start counting frames
				useAnimationFrame(true)
				// return that we are playing
				props.onPlayerPlaying ? props.onPlayerPlaying(true) : noop
				// set to play state
				props.onPlayerPlay ? props.onPlayerPlay() : noop
			})
		}
		if (!castConnected && player.current && ((!props.play && isPlaying) || (!inRange && isPlaying))) {
			// check if player has reached to trimmed end time and loop is on
			if (!inRange && isPlaying && props.loop) {
				props.onCurrentPositionTimeChange(props.timeRange.start)
				// if yes, reset player current time to trimmed start time
				handleVideoLoop()
				// props.onCurrentPositionTimeChange(props.timeRange.start)
			} else {
				// console.log("call Pause", inRange, props.currentTime)
				// Stop counting frames
				useAnimationFrame(false)
				// // Pause Player
				pauseVideo().then(() => {
					// // return that we are paused
					props.onPlayerPlaying ? props.onPlayerPlaying(false) : noop
					// // set to pause state
					// props.onPlayerPause ? props.onPlayerPause() : noop
				})
			}
		}
	}, [props.play, inRange])

	// pause video when cast is on
	useEffect(() => {
		if (castConnected) {
			pauseVideo()
			// sync with initial Chromecast volume
			setVolume(50)
			props.muted && props.onMuteUnmuteClick()
		}
	}, [castConnected])

	// on Seeking action seek player to desired time
	useEffect(() => {
		// Scrub is within range
		let a = player.current.currentTime
		let b = props.currentTime
		if (a > b ? a - b > 0.25 : b - a > 0.25) {
			// scrub it difference is greater that .25 it will short loop if less
			player.current.currentTime = props.currentTime
		}
	}, [props.currentTime])

	// Constantly check if we are in range?
	useEffect(() => {
		let currentTime = props.currentTime || 0
		isInRange(currentTime, props.timeRange)
	}, [props.currentTime, props.timeRange, props.play])

	/** Handle the fullscreen toggle */
	const handleFullscreen = () => {
		if (fullscreen.enabled) {
			if (fullscreen.isFullscreen) {
				fullscreen.exit()
				props.onPlayerFullScreenChange(false)
			} else {
				fullscreen.request(player.current)
				props.onPlayerFullScreenChange(true)
			}
		}
	}
	/** Listen for document fullscreen changed */
	const onDocumentFullscreen = () => {
		if (fullscreen.isFullscreen) {
			console.log(`Element: ${document.fullscreenElement} entered full-screen mode.`)
			props.onPlayerFullScreenChange(true)
		} else {
			console.log('Leaving full-screen mode.')
			props.onPlayerFullScreenChange(false)
		}
	}
	/** Add an change listener for each defined api */
	fsListeners.forEach((listener) => {
		useEventListener(listener, onDocumentFullscreen)
	})

	// Let us know when the player is ready to play
	/*
	HAVE_NOTHING		0	No information is available about the media resource.
	HAVE_METADATA		1	Enough of the media resource has been retrieved that the metadata attributes are initialized. Seeking will no longer raise an exception.
	HAVE_CURRENT_DATA	2	Data is available for the current playback position, but not enough to actually play more than one frame.
	HAVE_FUTURE_DATA	3	Data for the current playback position as well as for at least a little bit of time into the future is available (in other words, at least two frames of video, for example).
	HAVE_ENOUGH_DATA	4	Enough data is available—and the download rate is high enough—that the media can be played through to the end without interruption.
	*/
	useEffect(() => {
		if (player.current.readyState >= 1 && player.current.duration) {
			setReady(true)
			props.onPlayerReady ? props.onPlayerReady() : noop
		}
	}, [player.current?.readyState, props.sources])

	// let sources = ''
	// if(props.sources){
	// 	sources = props.sources.forEach((v, i) => {
	// 		return `<source key="${i}" src="${v.src}" type="${v.type || 'video/mp4'}" size="${v.size}" />`
	// 	})
	// }

	/* Allow us to set the default video as the source passed during component initialization */
	// const buildSourceObject = (v,i) => {
	// 	/* Create File from source */
	// 	fetch(v.src)
	// 		.then((response) => response.blob())
	// 		.then((blob) => {
	// 			return ( <source
	// 				key={i}
	// 				src={(URL || webkitURL).createObjectURL(blob)}
	// 				type={v.type || 'video/mp4'}
	// 				size={v.size || '720'} />
	// 			)
	// 		})
	// }

	// const [key, setKey] = useState(null)
	// useEffect(() => {
	// 	// if ( !== props.sources && player.current) {
	// 	// 	console.log(props.sources)
	// 	// 	player.current.load();
	// 	// }
	// 	setKey(new Date().getTime())
	// }, [props.sources]);

	// const getSource = () => {
	// 	if (!Array.isArray(props.sources)) return [];
	// 	var $sources = [];
	// 	for (var ii = 0; ii < props.sources.length; ii++) {
	// 		let src,
	// 			type;
	// 		if (typeof props.sources[ii] === 'object') {
	// 			src = props.sources[ii].src
	// 			type = props.sources[ii].type
	// 		}
	// 		$sources.push(
	// 			<source src={src} type={type} key={ii}/>
	// 		)
	// 	}
	// 	return $sources;
	// }

	/** Get the Element */
	const videoWrapperRef = useRef()
	/** Adjust height of the video element */
	const [videoDimensions, setVideoDimensions] = useState()

	/** Adjust height once Ref becomes available */
	useEffect(() => {
		if (videoWrapperRef.current) {
			const width = videoWrapperRef.current.offsetWidth
			setVideoDimensions({
				height: (width / 16) * 9,
				width: width,
			})
		}
	}, [videoWrapperRef.current?.offsetWidth])

	return (
		<RotateComponentStyling
			className={'rotate' + props.currentRotation}
			height={videoDimensions?.height}
			width={videoDimensions?.width}
			ref={videoWrapperRef}
			onMouseMove={props.onMouseMove}>
			<PlayerWrapper
				height={videoDimensions?.height}
				width={videoDimensions?.width}
				id="player"
				removeFadeIn={true}>
				{!ready && !props.hideLoader && (
					<Status>
						<Spinner thickness="4px" speed="0.45s" emptyColor="gray.200" color={baseColor} size="md" />
					</Status>
				)}
				<video
					ref={player}
					id="video"
					style={castConnected ? { opacity: '0.3' } : noop}
					css={props.css}
					key={props.key}
					preload={'metadata'} // important to keep as 'metadata' to load duration
					// Control
					controls={props.nativeControls || false}
					controlsList={props.controlsList || 'nodownload'}
					autoPlay={false}
					playsInline={true}
					volume={props.volume}
					muted={castConnected ? true : props.muted}
					// Events
					onContextMenu={(e) => {
						// Prevent "right-click" to save-as
						e.preventDefault()
					}}
					onClickCapture={() => {
						if (!props.thankYouVideo) {
							isBrowser ? props.onPlayPauseClick() : props.onHideUnhideControls()
						}
					}}
					onLoadedMetadata={(e) => {
						!props.showTrimmer && props.timeRange.end != e.target.duration
							? updateTimeRange(e.target.duration)
							: noop
						props.timeRange.end > e.target.duration ? updateTimeRange(e.target.duration) : noop
						setPlayerDataDuration(e.target.duration),
							props.onPlayerLoadedMetadata ? props.onPlayerLoadedMetadata() : noop
					}} // MetaData is available
					onLoadedData={() => {
						props.thankYouVideo && props.onPlayPauseClick()
					}}
					onProgress={() => (props.onPlayerProgress ? props.onPlayerProgress() : noop)}
					onDurationChange={() => {
						props.onPlayerDuration ? props.onPlayerDuration(player.current.duration) : noop
					}} // total duration of media has changed from original
					//onEnded={props.onPlayerEnded ? props.onPlayerEnded() : noop} // video completely elapsed
					onTimeUpdate={() => {
						props.onPlayerTimeUpdate ? props.onPlayerTimeUpdate(player.current.currentTime) : noop
					}} // curentTime has been updated
					onEnded={(e) => {
						e.preventDefault()
						props.onCurrentPositionTimeChange(props.timeRange.start)
						if (props.loop) {
							handleVideoLoop()
						} else {
							props.onPlayerPause()
							props.onEnded && props.onEnded()
						}
					}}
					onSeeking={() => (props.onPlayerSeeking ? props.onPlayerSeeking(player.current.currentTime) : noop)} // scrub is in seeking mode // Seeks at restart and causes a play/pause interuption
					onSeeked={() => (props.onPlayerSeeked ? props.onPlayerSeeked() : noop)} // scrub is in seeking mode
					onCanPlay={() => (props.onPlayerCanPlay ? props.onPlayerCanPlay() : noop)} // Media is ready to be played
					onCanPlayThrough={() => (props.onPlayerCanPlayThrough ? props.onPlayerCanPlayThrough() : noop)} // All media has been buffered
					onError={(e) => (props.onPlayerError ? props.onPlayerError(e) : noop)}
					// Styles
					width={'100%'}
					// Source info
					poster={props.poster}
					src={`${props.sources[0].src}#t=${props.timeRange.start + 0.01}`}
					type={props.sources[0].type || 'video/mp4'}
				/>
				{/* {props.sources &&
						props.sources.map((v, i) => {
							return (
								<source key={i} src={`${v.src}`} type={v.type || 'video/mp4'} size={v.size || '720'} />
							)
						})}
				</video> */}

				{(!props.hidePlayIconOnLoad || player.current?.currentTime > 2) &&
					!props.hidePlayIcon &&
					player.current?.paused &&
					(!props.play || !props.playing) && (
						<Box
							sx={playIconStyles}
							color="white"
							width="64px"
							height="64px"
							onClick={props.onPlayPauseClick}>
							<PlayArrowRounded />
							{/* <svg
								xmlns="http://www.w3.org/2000/svg"
								width="40"
								height="40"
								viewBox="0 0 24 24"
								className="rh5v-Overlay_icon"
								fill="var(--vidday-colors-link)">
								<path d="M8.016 5.016L18.985 12 8.016 18.984V5.015z"></path>
							</svg> */}
						</Box>
					)}
				<PlayerOverlay
					id="controls"
					onClick={(e) => e.stopPropagation()}
					hide={props.hide && props.hideOverride != true} //{props.hide && props.hideOverride != true}
					onMouseOver={props.onUnhideControls}
					onMouseOut={props.onHideControls}
					baseColor={baseColor}>
					<PlayPauseControl
						onPlayPauseClick={props.onPlayPauseClick}
						playing={props.playing}
						canPlay={props.canPlay}
					/>
					{!props.hideVolume && (
						<VolumeControl onMuteUnmuteClick={handleOnMuteUnmuteClick} muted={props.muted} />
					)}
					{/* {showVolumeSlider && !location.pathname.includes('v2') && (
						<VolumeSlider
							accentColor={accentColor}
							baseColor={baseColor}
							volume={volume}
							onChange={handleVolumeChange}
						/>
					)} */}
					{/** Temporary workaround to switch slider to chakra instead of MUI */}
					{showVolumeSlider && (
						<VolumeSliderChakra
							accentColor={accentColor}
							baseColor={baseColor}
							volume={volume}
							onChange={handleVolumeChangeChakra}
						/>
					)}
					{!props.hideFullScreen && (
						<FullScreenControl onfullScreenOnOffClick={handleFullscreen} fullScreen={props?.fullScreen} />
					)}
					{!props.hideFullScreen && castAvailable && showVolumeSlider && (
						<CastButton
							src={props.sources[0].src}
							type={props.sources[0].type}
							volume={volume}
							play={props.play}
							currentTime={props.currentTime}
							castSeek={castSeek}
							castConnected={castConnected}
							setCastConnected={setCastConnected}
							castAvailable={castAvailable}
							onPlayerPlay={props.onPlayerPlay}
							onPlayerPause={props.onPlayerPause}
							onPlayerPlaying={props.onPlayerPlaying}
							onPlayerTimeUpdate={props.onPlayerTimeUpdate}
						/>
					)}
					{!props.hideLoop && (
						<VideoRepeatControl
							onRepeatOnOffClick={props.onRepeatOnOffClick}
							loop={props.loop}
							castConnected={castConnected}
						/>
					)}
					<div style={{ position: 'relative' }}>
						<Trimmer
							onPausePlayer={props.onPlayerPause}
							onPlayerPlay={props.onPlayerPlay}
							onCurrentPositionTimeChange={props.onCurrentPositionTimeChange}
							onTrim={props.onTrim}
							toggleCastSeek={handleCastSeek}
							showVolumeSlider={showVolumeSlider}
							showTrimmer={props.showTrimmer}
							castAvailable={castAvailable}
							currentTime={props.currentTime}
							duration={props.duration}
							timeRange={props.timeRange}
							hideVolume={props.hideVolume}
							hideFullScreen={props.hideFullScreen}
							playing={props.playing}
							playerDataDuration={playerDataDuration}
							player={player}
							accentColor={accentColor}
							baseColor={baseColor}
						/>
					</div>
					<DurationLabel
						timeRange={props.timeRange}
						currentTime={props.currentTime}
						playerDataDuration={playerDataDuration}
					/>
				</PlayerOverlay>
			</PlayerWrapper>
		</RotateComponentStyling>
	)
}

export default Player
