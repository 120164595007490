/* **************************************
 *  LIBRARIES
 * ************************************ */

import { createReduxEnhancer } from '@sentry/react'
import { createStore, compose, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import promiseMiddleware from 'redux-promise-middleware'
import { composeWithDevTools } from 'redux-devtools-extension'
import { apiMiddleware } from 'redux-api-middleware'
import modalRefHandler from './modal'
import createSagaMiddleware from 'redux-saga'
import rootSaga from '../sagas'

/* **************************************
 *  PROJECT DEPENDENCIES
 * ************************************ */

import createRootReducer from '../reducers'

/* **************************************
 *  SAGA MIDDLEWARE
 * ************************************ */

const sagaMiddleware = createSagaMiddleware()

/* **************************************
 *  CONFIG STORE
 * ************************************ */

export default function configureStore(initialState = {}) {
	/* Sentry redux integration */
	const sentryReduxEnhancer = createReduxEnhancer({
		// Remove or modify large or sensitive data from state context
		stateTransformer: (state) => {
			// Set large parts of the state to not inclue to null (respect Sentry payload limits)
			const transformedState = {
				...state,
				admin: 'filtered', // this is big and redundant
				entities: 'filtered',
				checkout: {
					...state.checkout,
					location: {
						...state.checkout.location,
						countries: 'filtered', // this is big and redundant
						regions: 'filtered', // this is big and redundant
					},
					currency: {
						...state.checkout.currency,
						currencies: 'filtered', // this is big and redundant
					},
				},
			}

			return transformedState
		},

		// Remove large payloads from action breadcrumbs
		actionTransformer: (action) => {
			// // Filter out payload from FETCH_ALL_COUNTRIES_SUCCEEDED (fairly large)
			// if (action.type === "FETCH_ALL_COUNTRIES_SUCCEEDED") {
			// 	return {
			// 		...action,
			// 		payload: null
			// 	}
			// }
			return {
				...action,
				payload: action.payload ? 'filtered' : null,
			}
		},
	})

	/* Define all middlewares for redux */
	const middlewares = [
		thunkMiddleware,
		promiseMiddleware(),
		apiMiddleware,
		modalRefHandler,
		//LogRocket.reduxMiddleware()
	]

	/* Apply redux middleware */
	const toolChain = [applyMiddleware(...middlewares), applyMiddleware(sagaMiddleware), sentryReduxEnhancer]
	// const toolChain = [applyMiddleware(...middlewares), applyMiddleware(sagaMiddleware, logger), sentryReduxEnhancer]
	// const toolChain = [applyMiddleware(...middlewares)]

	/* compose the store, and pass our root reducer and initial state */
	// const store = compose(composeWithDevTools(...toolChain), sentryReduxEnhancer)(createStore)(rootReducer, initialState)
	const store = compose(composeWithDevTools(...toolChain))(createStore)(createRootReducer(), initialState)

	/** Run sagas */
	sagaMiddleware.run(rootSaga)

	return store
}
