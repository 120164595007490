import React, { useState, useRef, useEffect } from 'react'
import Dragger from './Dragger'
import { forwardRef } from '@chakra-ui/system'
import { Box, chakra } from '@chakra-ui/react'

var format = require('format-duration')

const trimmerContainerStyles = {
	'd': 'block',
	'height': '20px',
	'pos': 'absolute',
	/* Integrated trimmer within the player */
	'marginTop': '10px',
	'width': 'auto',
	'.rvt-thumb': {
		pos: 'relative',
		display: 'inline-block',
	},
}

const getTrimmerContainerStyles = ({
	baseColor,
	accentColor,
	showTrimmer,
	hideVolume,
	showVolumeSlider,
	hideFullScreen,
	castAvailable,
}) => {
	return {
		...trimmerContainerStyles,
		background: baseColor,
		background: `linear-gradient(
			0deg,
			${baseColor} 30%,
			rgba(130, 141, 153, 1) 30%,
			rgba(130, 141, 153, 1) 70%,
			${baseColor} 70%
		)`,
		background: showTrimmer ? accentColor : 'rgba(255,255,255,0.2)',
		left: hideVolume ? '42px' : showVolumeSlider ? '162px' : '72px',
		right: hideFullScreen ? '78px' : castAvailable ? '136px' : '108px',
	}
}

const StyledTrimmerContainer = forwardRef(
	(
		{ baseColor, accentColor, showTrimmer, hideVolume, showVolumeSlider, hideFullScreen, castAvailable, ...rest },
		ref
	) => {
		const styles = getTrimmerContainerStyles({
			baseColor,
			accentColor,
			showTrimmer,
			hideVolume,
			showVolumeSlider,
			hideFullScreen,
			castAvailable,
		})
		return <Box sx={styles} ref={ref} {...rest} />
	}
)

// const StyledTrimmerContainer = styled.div`
// 	display: block;
// 	width: 100%;
// 	height: 20px;
// 	position: relative;
// 	margin-top: 10px;

// 	/* Playback scrub color */
// 	background: ${(props) => `${props.baseColor}`};
// 	background: linear-gradient(
// 		0deg,
// 		${(props) => `${props.baseColor}`} 30%,
// 		rgba(130, 141, 153, 1) 30%,
// 		rgba(130, 141, 153, 1) 70%,
// 		${(props) => `${props.baseColor}`} 70%
// 	);
// 	/* Display taller scrup when trimmer is active */
// 	${(props) => props.showTrimmer && `background: ${props.accentColor};`}
// 	left: ${(props) => (props.hideVolume ? `42px` : props.showVolumeSlider ? `162px` : `72px`)};
// 	right: ${(props) => (props.hideFullScreen ? `78px` : props.castAvailable ? `136px` : `108px`)};

// 	/* Integrated trimmer within the player */
// 	position: absolute;
// 	width: auto;

// 	.rvt-thumb {
// 		position: relative;
// 		display: inline-block;
// 	}
// `

// const StyledTrimmerOverlay = styled.div`
// 	user-select: none;
// 	position: absolute;
// 	top: 0;
// 	right: 0;
// 	width: 0;
// 	height: 100%;
// 	background: ${(props) => `${props.baseColor}`};
// 	background: linear-gradient(
// 		0deg,
// 		${(props) => `${props.baseColor}`} 30%,
// 		rgba(130, 141, 153, 1) 30%,
// 		rgba(130, 141, 153, 1) 70%,
// 		${(props) => `${props.baseColor}`} 70%
// 	);
// `

const trimmerOverlayStyles = {
	userSelect: 'none',
	position: 'absolute',
	top: '0',
	right: '0',
	width: '0',
	height: '100%',
}

const computeTrimmerOverlayStyles = ({ baseColor }) => {
	return {
		background: baseColor,
		background: `linear-gradient(
			0deg,
			${baseColor} 30%,
			rgba(130, 141, 153, 1) 30%,
			rgba(130, 141, 153, 1) 70%,
			${baseColor} 70%
		)`,
	}
}

const TrimmerOverLay = ({ accentColor, baseColor, width, right, rightSide, left, leftSide, ...rest }) => {
	const styles = {
		// Base trimmer styles
		...trimmerOverlayStyles,
		// Computed trimmer styles
		...computeTrimmerOverlayStyles({ baseColor: baseColor }),
		// Width
		width: `${width}px`,
	}

	// If it's left side
	if (leftSide) styles.left = `${left}px` || 0
	// If it's right side
	else if (rightSide) styles.right = `${right}px` || 0

	return <Box sx={styles} />

	// return <StyledTrimmerOverlay {...props} style={{ width: props.width, left: props.left, right: props.right }} />
}

const timestampStyles = {
	display: 'block',
	opacity: 0,
	pos: 'absolute',
	fontSize: '1rem',
	top: '-40px',
	py: '8px',
	px: '12px',
	marginLeft: '1px',
	textAlign: 'center',
	color: 'black',
	transform: 'translate(calc(-50% + 3px)) scale(0.1)',
	background: 'white',
	borderRadius: '6px',
	boxShadow: '0px 0px 6px 3px rgba(0, 0, 0, 0.3)',
	userSelect: 'none',
	transition: 'all 200ms ease-in-out',
	_hover: {
		opacity: 1,
		transform: 'translate(calc(-50% + 3px)) scale(0.8)',
	},
	_after: {
		content: '""',
		position: 'absolute',
		border: '6px solid transparent',
		borderTopColor: 'white',
		bottom: '-10px',
		left: '50%',
		marginLeft: '-6px',
	},
}

// const StyledTimeStamp = styled.div`
// 	opacity: 0;
// 	position: absolute;
// 	font-size: 16px;
// 	top: -40px;
// 	padding: 8px 12px;
// 	margin-left: 1px;
// 	text-align: center;
// 	color: black;
// 	transform: translate(calc(-50% + 3px)) scale(0.1);
// 	background: white;
// 	border-radius: 6px;
// 	box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.3);
// 	user-select: none;
// 	transition: all 200ms ease-in-out;
// 	&:hover {
// 		opacity: 1;
// 		transform: translate(calc(-50% + 3px)) scale(0.8);
// 	}

// 	${is('isCurrent')`
// 		margin-left: -1px;
// 	`}

// 	.currentTime {
// 		font-family: monospace;
// 	}

// 	&:after {
// 		content: '';
// 		position: absolute;
// 		border: 6px solid transparent;
// 		border-top-color: white;
// 		bottom: -10px;
// 		left: 50%;
// 		margin-left: -6px;
// 	}
// 	${is('displayTime')`
// 		opacity: 1;
// 		transform: translate(calc(-50% + 3px)) scale(0.8);
// 	`};
// `

const TimeStamp = ({ isCurrent, displayTime, time }) => {
	let formated = time ? format(Number(time) * 1000) : '0:00'

	let styles = { ...timestampStyles }
	if (isCurrent) styles = { ...styles, marginLeft: '-1px' }
	if (displayTime) styles = { ...styles, opacity: 1, transform: 'translate(calc(-50% + 3px)) scale(0.8)' }

	return (
		<Box sx={styles} marginLeft={isCurrent ? '-1px' : '1px'}>
			<chakra.span fontFamily="monospace" className="currentTime">
				{formated}
			</chakra.span>
		</Box>
	)
}

const Trimmer = (props) => {
	const trimmerContainerRef = useRef()

	// Calculation Functions
	const widthDurationRatio = () => {
		return containerWidth() / props.duration
	}

	const containerWidth = () => {
		if (trimmerContainerRef.current) {
			return trimmerContainerRef.current.getBoundingClientRect().width
		}
	}

	const getTrimmerWidth = (width) => {
		return containerWidth() - width
	}

	const pos2Time = (pos) => {
		return pos / widthDurationRatio()
	}

	const time2pos = (time) => {
		return time * widthDurationRatio()
	}

	const keepInRange = (x) => {
		const width = containerWidth()
		if (x < 0) {
			return 0
		}

		if (x > width) {
			return width
		}

		return x
	}

	const currentPositionWithinTimeRange = (time) => {
		return time > props.startTime && time < props.endTime
	}

	// const withinTimeLimit = (time, isDragEnd = true, isCurrent = false) => {
	// 	const timeLimit = props.timeLimit

	// 	let startTime = props.startTime
	// 	let endTime = time

	// 	if (!isDragEnd) {
	// 		startTime = time
	// 		endTime = props.endTime
	// 	}

	// 	const duration = props.duration
	// 	let timeTillEnd = duration - endTime

	// 	const currentRange = duration - startTime - timeTillEnd
	// 	return timeLimit ? currentRange <= timeLimit : true
	// }

	// const withinTimeRange = (time, isDragEnd = true, isCurrent = false) => {
	// 	const timeRange = props.timeRangeLimit
	// 	let interval = time - props.startTime
	// 	if (!isDragEnd) {
	// 		interval = props.endTime - time
	// 	}
	// 	return timeRange ? interval >= timeRange : true
	// }

	// Convert times into position
	const start = time2pos(props.startTime)
	const end = time2pos(props.endTime)
	// const current = time2pos(props.currentTime)
	const [currentPosition, setCurrentPosition] = useState(time2pos(props.currentTime))
	const [currentTime, setCurrentTime] = useState(props.currentTime)
	useEffect(() => {
		setCurrentTime(props.currentTime)
		setCurrentPosition(time2pos(props.currentTime))
	}, [props.currentTime])

	// Action Functions
	const handleClickToCurrent = (e) => {
		let time =
			(e.pageX -
				(trimmerContainerRef.current.offsetLeft +
					trimmerContainerRef.current.offsetParent.offsetLeft +
					trimmerContainerRef.current.offsetParent?.offsetParent?.offsetParent?.offsetParent?.offsetParent
						?.offsetLeft +
					trimmerContainerRef.current.offsetParent?.offsetParent?.offsetParent?.offsetParent?.offsetParent
						?.offsetParent?.offsetLeft +
					(trimmerContainerRef.current.offsetParent?.offsetParent?.offsetParent?.offsetParent?.offsetParent
						?.offsetParent?.offsetParent?.offsetLeft || 0) +
					(trimmerContainerRef.current.offsetParent?.offsetParent?.offsetParent?.offsetParent?.offsetParent
						?.offsetParent?.offsetParent?.offsetParent?.offsetLeft || 0) +
					(trimmerContainerRef.current.offsetParent?.offsetParent?.offsetParent?.offsetParent?.offsetParent
						?.offsetParent?.offsetParent?.offsetParent?.offsetParent?.offsetLeft || 0))) /
			widthDurationRatio()

		if (currentPositionWithinTimeRange(time)) {
			props.onCurrentTimeChange(time)
		}
	}

	const handleTouchToCurrent = (e) => {
		let time =
			(e.pageX - (trimmerContainerRef.current.offsetLeft + trimmerContainerRef.current.offsetParent.offsetLeft)) /
			widthDurationRatio()
		if (currentPositionWithinTimeRange(time)) {
			props.onCurrentTimeChange(time)
		}
	}

	// Dragger ON functions
	const handleDragCurrentTime = (pos) => {
		let time = pos2Time(keepInRange(pos.x))

		if (!currentPositionWithinTimeRange(time)) {
			// props.onPausePlayer
			if (time < props.startTime) {
				time = props.startTime
				setCurrentTime(time2pos(time))
				setCurrentTime(time)
			}
			if (time > props.endTime) {
				time = props.endTime
				setCurrentTime(time2pos(time))
				setCurrentTime(time)
			}
		} else {
			setCurrentPosition(keepInRange(pos.x))
			setCurrentTime(time)
		}
		props.onCurrentTimeChange(time)
	}

	const handleDragStartTime = (pos) => {
		var time = pos2Time(keepInRange(pos.x))
		const currentTime = props.currentTime || 0
		const endTime = props.endTime
		if (currentTime <= time) {
			// props.onPausePlayer()
			if (time >= endTime) {
				time = endTime - 0.5
				// Change the current time to the endpoint
				props.onCurrentTimeChange(time)
			}
		}
		// Change the current time to the new start time
		props.onStartTimeChange(time)
	}

	const handleDragEndTime = (pos) => {
		var time = pos2Time(keepInRange(pos.x))
		const currentTime = props.currentTime || 0
		const startTime = props.startTime
		if (currentTime >= time) {
			// props.onPausePlayer()
			if (time <= startTime) {
				// props.onPausePlayer
				time = startTime + 0.5
				// Change the current time to the endpoint
				props.onCurrentTimeChange(time)
			}
		}
		// Change the current time to the new start time
		props.onEndTimeChange(time)
	}

	return (
		<StyledTrimmerContainer
			accentColor={props.accentColor}
			baseColor={props.baseColor}
			ref={trimmerContainerRef}
			onMouseDownCapture={handleClickToCurrent}
			onTouchStart={handleTouchToCurrent}
			showTrimmer={props.showTrimmer}
			showVolumeSlider={props.showVolumeSlider}
			hideVolume={props.hideVolume}
			hideFullScreen={props.hideFullScreen}
			castAvailable={props.castAvailable}>
			{props.duration > 0 && (
				<>
					{props.showTrimmer && (
						<>
							<TrimmerOverLay
								left={0}
								width={start}
								leftSide
								accentColor={props.accentColor}
								baseColor={props.baseColor}
							/>
							<Dragger
								x={start}
								onDrag={handleDragStartTime}
								onDragging={props.onTrimming}
								start
								zIndex={1}
								accentColor={props.accentColor}>
								<TimeStamp displayTime={props.trimming} time={props.startTime} />
							</Dragger>
						</>
					)}
					<Dragger
						x={currentPosition}
						onDrag={handleDragCurrentTime}
						onDragging={props.onSeeking}
						playing={props.playing}
						onPausePlayer={props.onPausePlayer}
						onPlayerPlay={props.onPlayerPlay}
						isCurrent
						currentTime={props.currentTime}
						accentColor={props.accentColor}
						baseColor={props.baseColor}>
						<TimeStamp isCurrent displayTime={props.seeking} time={currentTime - props.startTime} />
					</Dragger>

					{props.showTrimmer && (
						<>
							<Dragger
								x={end}
								onDrag={handleDragEndTime}
								onDragging={props.onTrimming}
								end
								zIndex={1}
								accentColor={props.accentColor}>
								<TimeStamp displayTime={props.trimming} time={props.endTime} />
							</Dragger>
							<TrimmerOverLay
								right={0}
								width={getTrimmerWidth(end)}
								rightSide
								accentColor={props.accentColor}
								baseColor={props.baseColor}
							/>
						</>
					)}
				</>
			)}
		</StyledTrimmerContainer>
	)
}

const VideoTrimmer = (props) => {
	const [startTime, setStartTime] = useState(props.timeRange.start)
	const [currentTime, setCurrentTime] = useState(props.currentTime)
	const [endTime, setEndTime] = useState(props.timeRange.end)

	const [trimming, setTrimming] = useState(false)
	const [seeking, setSeeking] = useState(false)

	const handleCurrentTimeChange = (time) => {
		// Show Seeking
		props.onCurrentPositionTimeChange(Math.round(time * 1000) / 1000)
		setCurrentTime(Math.round(time * 1000) / 1000)
		// Inform remote player to update current time
		props.toggleCastSeek()
	}

	const handleStartTimeChange = (time) => {
		if (currentTime <= time) {
			props.onCurrentPositionTimeChange(Math.round(time * 1000) / 1000)
			setCurrentTime(Math.round(time * 1000) / 1000)
		}
		setStartTime(Math.round(time * 1000) / 1000)
	}

	const handleEndTimeChange = (time) => {
		if (currentTime >= time) {
			props.onCurrentPositionTimeChange(Math.round(time * 1000) / 1000)
			setCurrentTime(Math.round(time * 1000) / 1000)
		}
		setEndTime(Math.round(time * 1000) / 1000)
	}

	const handleClickToCurrentTimeChange = (time) => {
		setCurrentTime(Math.round(time * 1000) / 1000)
	}

	// Once trimming is complete send in the new times
	useEffect(() => {
		if (!trimming) {
			// return the trimmed times
			if (props.playerDataDuration > 0) {
				props.onTrim({
					start: startTime,
					end: endTime,
					current: currentTime,
				})
			}
		}
	}, [trimming])

	// Update all positions
	useEffect(() => {
		setStartTime(startTime), setCurrentTime(seeking ? currentTime : props.currentTime), setEndTime(endTime)
	}, [props.timeRange.start, props.currentTime, props.timeRange.end])

	// Update TimeRange if database duration time of video is different from actual video file duration time
	useEffect(() => {
		setEndTime(props.timeRange.end)
	}, [props.timeRange.end])

	// Inform remote player to update current time
	useEffect(() => {
		props.toggleCastSeek()
	}, [seeking])

	return (
		<Trimmer
			accentColor={props.accentColor}
			baseColor={props.baseColor}
			timeLimit={props.timeLimit}
			showTrimmer={props.showTrimmer}
			showVolumeSlider={props.showVolumeSlider}
			hideVolume={props.hideVolume}
			hideFullScreen={props.hideFullScreen}
			castAvailable={props.castAvailable}
			onStartTimeChange={handleStartTimeChange}
			onCurrentTimeChange={handleCurrentTimeChange}
			onClickToCurrentTimeChange={handleClickToCurrentTimeChange}
			onEndTimeChange={handleEndTimeChange}
			onTrimming={setTrimming}
			trimming={trimming}
			onSeeking={setSeeking}
			seeking={seeking}
			startTime={startTime}
			endTime={endTime}
			currentTime={currentTime}
			duration={props.duration}
			onPausePlayer={props.onPausePlayer}
			playing={props.playing}
			onPlayerPlay={props.onPlayerPlay}
		/>
	)
}

export default VideoTrimmer
