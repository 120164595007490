/**
 * Hack function to redirect user to the corresponding URL in NextJS app if needed, as we cannot use navigate() to redirect between vidday-app and nextjs-vidday-app.
 * @param {*} pathname
 */
const redirectToNextIfWindow = (pathname) => {
	if (window && typeof window != 'undefined') {
		console.log('Window Location: ', window.location, ' Redirecting to: ', pathname)
		window.location.href = pathname
	} else {
		console.log("No window available, can't redirect to: ", pathname)
	}
}

export default redirectToNextIfWindow
