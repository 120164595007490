// Should be hosted here any default configuration related
// to this project and used throughout the Frontend components (client side)
// NO SENSITIVE INFORMATION

module.exports = {
	environment: process.env.ENVIRONMENT || process.env.NODE_ENV,

	// general app config
	maxMediaCount: 800,
	maxMediaLength: 9600,
	qualityCheckSlug: 'qualityCheck',
	plantTreeSlug: 'plantTree',
	customThemeCoverSlug: 'customThemeCover',
	baseUrlShort:
		process.env.ENVIRONMENT === 'production'
			? 'https://vid.day'
			: `${process.env.VIDDAY_API_PROTOCOL}://${process.env.VIDDAY_API_HOST}:${process.env.VIDDAY_API_PORT}`,
	baseUrl:
		process.env.ENVIRONMENT === 'development'
			? `${process.env.VIDDAY_API_PROTOCOL}://${process.env.VIDDAY_API_HOST}:${process.env.VIDDAY_API_PORT}`
			: `${process.env.VIDDAY_API_PROTOCOL}://${process.env.VIDDAY_API_HOST}`,

	stripe: {
		publishableKey: process.env.STRIPE_KEY,
		customerPortal:
			process.env.STRIPE_CUSTOMER_PORTAL || 'https://billing.stripe.com/p/login/test_28ocNe4Hjc471ck6oo',
	},

	auth: {
		loginURL: '/auth/login',
		loginRedirect: '/dashboard',
	},

	media: {
		baseUrl: process.env.MEDIA_BASE_URL,
		stockImagesUrlSearch: `${process.env.VIDDAY_API_BASE_URI}/v2/unsplash-search`,
		stockImagesUrlTrack: `${process.env.VIDDAY_API_BASE_URI}/v2/unsplash-track`,
		giphyUrl: `${process.env.VIDDAY_API_BASE_URI}/v2/giphy`,
	},

	uppy: {
		companionUrl: process.env.UPPY_COMPANION_URL || 'https://companion.dev.vidday.com',
		tusEndpoint: process.env.UPPY_TUS_ENDPOINT || 'https://tus.dev.vidday.com/files/',
		tusPrefix: 'tus/',
	},

	geo: {
		defaultCountryCode: 'US',
		defaultRegionCode: 'CA',
	},

	api: {
		protocol: process.env.VIDDAY_API_PROTOCOL,
		host: process.env.VIDDAY_API_HOST,
		port: process.env.VIDDAY_API_PORT,
		baseUri: process.env.VIDDAY_API_BASE_URI,
		baseUrl: `${process.env.VIDDAY_API_PROTOCOL}://${process.env.VIDDAY_API_HOST}:${process.env.VIDDAY_API_PORT}${process.env.VIDDAY_API_BASE_URI}`,
	},

	gapi: {
		clientID:
			process.env.GOOGLE_CLIENT_ID || '226343046934-ikkhlsetttu5bmeg492bdsvmg52n298f.apps.googleusercontent.com', //'226343046934-nseus23ol5cii7rs418meqr4ffbuecnj.apps.googleusercontent.com',
		key: process.env.GOOGLE_API_KEY || 'AIzaSyAbj1ohjoNA7gTRW1QEio_Hr-vMtvJ0sVM',
		tenor: process.env.GIPHY_API_KEY || 'AIzaSyDAvYfSxutzX6rDW1GFg52aC2uEj_1LRKc',
	},

	gift_wrap: {
		baseUrl: process.env.GIFT_WRAP_BASE_URL || 'https://static.vidday.com/giftwrap_intros',
	},

	tremendous: {
		eventCap: process.env.TREMENDOUS_EVENT_CAP || 50000, // max contribution amount per event, in cents
		contributionCap: process.env.TREMENDOUS_CONTRBUTION_CAP || 12500, // max contribution amount, in cents
	},
}
