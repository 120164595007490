import { useState, useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useIsMediaAvailable } from './useIsMediaAvailable'

const useCoverPhoto = () => {
	const coverPhoto = useSelector((s) => s.event.coverPhoto, shallowEqual)
	const [media, setMedia] = useState(coverPhoto)
	const { isAvailable, res } = useIsMediaAvailable(coverPhoto)
	useEffect(() => {
		if (coverPhoto && isAvailable) {
			setMedia(coverPhoto)
		} else {
			if (coverPhoto) {
				setMedia({
					...coverPhoto,
					inGlacierStorage: coverPhoto.hasOwnProperty('inGlacierStorage')
						? coverPhoto.inGlacierStorage
						: true,
				})
			} else {
				setMedia(null)
			}
		}
	}, [coverPhoto, isAvailable])
	return media
}

export default useCoverPhoto
