/* **************************************
 *  LIBRARIES
 * ************************************ */

import { combineReducers } from 'redux'

/* **************************************
 *  PROJECT DEPENDENCIES
 * ************************************ */

import * as types from './constants'

const trimmerState = {
	timeRange: { start: null, end: null },
	duration: null,
}

function trimmer(state = trimmerState, action = {}) {
	switch (action.type) {
		case types.UPDATE_TRIMMING_PERIOD:
			return Object.assign({}, state, {
				timeRange: { start: action.data.start, end: action.data.end },
			})

		case types.UPDATE_TRIMMER_STATE_TO_STORE:
			return Object.assign({}, state, { ...action.data })

		case types.RESET_VIDEO_TRIMMER:
			return Object.assign({}, state, trimmerState)

		default:
			return state
	}
}

// possible values : 0, 90, 180, 270
const rotationState = {
	current: null,
}

function rotation(state = rotationState, action = {}) {
	switch (action.type) {
		case types.UPDATE_VIDEO_ROTATION:
			return Object.assign({}, state, { current: action.data })

		case types.RESET_VIDEO_ROTATION:
			return Object.assign({}, state, rotationState)

		default:
			return state
	}
}

const reducer = combineReducers({ rotation, trimmer })

export default reducer
