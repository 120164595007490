import PropTypes from 'prop-types'

/* **************************************
 *  Event Media
 * ************************************ */

const propTypes = {
	name: PropTypes.string,
	mimeType: PropTypes.string.isRequired,
	medium: PropTypes.string,
	duration: PropTypes.number,
	key: PropTypes.string,
	text: PropTypes.string,
	jobState: PropTypes.string,
	sourceExt: PropTypes.string,
	sourceFilename: PropTypes.string,
	sourceRotation: PropTypes.number,
	sourceHeight: PropTypes.number,
	sourceWidth: PropTypes.number,
	sourceHasAudio: PropTypes.bool,
	sourceHasVideo: PropTypes.bool,
	location: PropTypes.string,
	thumbnail: PropTypes.string,
	occupied: PropTypes.bool,
	mediaUuid: PropTypes.string.isRequired,
	muteBackgroundMusic: PropTypes.bool,
	muteAudio: PropTypes.bool,
	backgroundEligible: PropTypes.bool,
	rotation: PropTypes.string,
	startTime: PropTypes.number,
	endTime: PropTypes.number,
	tags: PropTypes.string,
}

const defaultProps = {
	medium: null,
	duration: 0,
	text: null,
	jobState: null,
	sourceExt: null,
	sourceFilename: null,
	sourceRotation: null,
	sourceHeight: null,
	sourceWidth: null,
	sourceHasAudio: false,
	sourceHasVideo: false,
	location: null,
	thumbnail: null,
	occupied: false,
	muteBackgroundMusic: false,
	muteAudio: false,
	backgroundEligible: true,
	rotation: null,
	startTime: null,
	endTime: null,
	mediaUuid: null,
}

const EventMedia = { propTypes, defaultProps }

export default EventMedia
