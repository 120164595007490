import { extendTheme } from '@chakra-ui/react'

/**
 * GLOBAL STYLES
 */
import styles from './styles'

/**
 * FOUNDATIONS
 */
import breakpoints from './foundations/breakpoints'
import colors from './foundations/colors'
import fonts from './foundations/fonts'
import zIndices from './foundations/zIndices'

/**
 * COMPONENTS
 */
import Card from './components/Card'
import NavBar from './components/NavBar'
import Heading from './components/Heading'
import Button from './components/Button'
import IconButton from './components/IconButton'
import Icon from './components/Icon'
import Divider from './components/Divider'
import Container from './components/Container'
import Text from './components/Text'
import Modal from './components/Modal'
import Element from './components/Element'
import NavCard from './components/NavCard'
import CoverSelector from './components/CoverSelector'
import Link from './components/Link'
import FormLabel from './components/FormLabel'
import Input from './components/Input'
import Select from './components/Select'
import Textarea from './components/Textarea'
import Checkbox from './components/Checkbox'
import Menu from './components/Menu'
import Alert from './components/Alert'
import Notifications from './components/Notifications'
import Notification from './components/Notification'

const theme = extendTheme({
	/**
	 * Theme Configuration
	 */
	config: {
		initialColorMode: 'light',
		useSystemColorMode: false,
		cssVarPrefix: 'vidday',
	},
	/**
	 * Global Styles
	 */
	styles,
	/**
	 * Foundation Styles
	 */
	fonts,
	colors,
	breakpoints,
	zIndices,
	/**
	 * Component Styles
	 */
	components: {
		Heading,
		Button,
		IconButton,
		Icon,
		Card,
		NavBar,
		Divider,
		Container,
		Text,
		Modal,
		Element,
		NavCard,
		CoverSelector,
		Link,
		FormLabel,
		Input,
		Select,
		Textarea,
		Checkbox,
		Menu,
		Alert,
		Notifications,
		Notification,
	},
})

export default theme
