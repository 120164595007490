/* **************************************
 *  LIBRARIES
 * ************************************ */

import { schema } from 'normalizr'

/* ***********************************
 * NORMALIZR SCHEMAS
 * **********************************/

// We use this Normalizr schemas to transform API responses from a nested form
// to a flat form where repos and users are placed in `entities`, and nested
// JSON objects are replaced with their IDs. This is very convenient for
// consumption by reducers, because we can easily build a normalized tree
// and keep it updated as we fetch more data.

/*** START INVOICES SCHEMA ***/
// const invoiceSchema = new schema.Entity('invoice', {}, {
// 	idAttribute: invoice => invoice
// })
// // Many Invoices
// const invoicesSchema = new schema.Entity('invoices', {
// 	invoices: [invoiceSchema]
// }, {
// 	idAttribute: invoice => invoice.id
// })
// const invoiceListSchema = new schema.Array(invoiceSchema);

const invoiceSchema = new schema.Entity(
	'invoices',
	{},
	{
		idAttribute: (invoice) => invoice.id,
	}
)

// const invoiceListSchema = new schema.Entity('invoices', {
// 	invoices: [invoiceSchema]
// }, {
// 	idAttribute: invoice => invoice.id
// })

const invoiceListSchema = new schema.Array(invoiceSchema)

/*** START MEDIA SCHEMA ***/
const eventMediaSchema = new schema.Entity(
	'media',
	{},
	{
		idAttribute: (media) => media,
	}
)
// Many Media
const userMediasSchema = new schema.Entity(
	'medias',
	{
		medias: [eventMediaSchema],
	},
	{
		idAttribute: (media) => media.id,
	}
)

/*** START RECIPIENTS SCHEMA ***/
const recipientSchema = new schema.Entity(
	'recipients',
	{},
	{
		idAttribute: (recipient) => recipient.id,
	}
)

/*** START EVENT SCHEMA ***/
const eventSchema = new schema.Entity(
	'events',
	{
		invoices: [invoiceSchema],
		recipients: [recipientSchema],
		medias: [eventMediaSchema],
	},
	{
		idAttribute: (eventObject) => eventObject.id,
	}
)
const eventListSchema = new schema.Array(eventSchema)
const adminEventListSchema = new schema.Array(eventSchema)
// const eventListSchema = new schema.Array({events:eventSchema, medias: eventMediaSchema});

/*** START CONTRIBUTOR SCHEMA ***/
const contributorSchema = new schema.Entity(
	'contributor',
	{
		events: [eventSchema],
	},
	{
		idAttribute: (contributor) => contributor.id,
	}
)

/*** START USER SCHEMA ***/
const userSchema = new schema.Entity(
	'users',
	{
		invoices: [invoiceSchema],
	},
	{
		idAttribute: (user) => user.id,
	}
)
// Represent all related data coming through the fetch request when loading the user
const userDataSchema = new schema.Array(userSchema)
const adminUserListSchema = new schema.Array(userSchema)

/*** FINAL VIDEO GENERATED BY VIDDAY ***/
const eventVideoSchema = new schema.Entity(
	'videos',
	{
		event: eventSchema,
	},
	{
		idAttribute: (video) => video.id.toString(),
	}
)

/*** PRODUCTS ***/
const productSchema = new schema.Entity(
	'products',
	{},
	{
		idAttribute: (object) => object.id,
	}
)
const productListSchema = new schema.Array(productSchema)

/*** PROMOTIONS ***/
const promotionSchema = new schema.Entity(
	'promotions',
	{},
	{
		idAttribute: (object) => object.id,
	}
)
const promotionListSchema = new schema.Array(promotionSchema)

/*** ANNOUNCEMENTS ***/
const announcementSchema = new schema.Entity(
	'announcements',
	{},
	{
		idAttribute: (object) => object.id,
	}
)
const announcementListSchema = new schema.Array(announcementSchema)

/*** RENDER JOBS ***/
const renderJobSchema = new schema.Entity(
	'event_render_jobs',
	{},
	{
		idAttribute: (object) => object.id,
	}
)
const renderJobListSchema = new schema.Array(renderJobSchema)

export {
	// Super Admin
	adminUserListSchema,
	adminEventListSchema,
	promotionListSchema,
	productListSchema,
	announcementListSchema,
	renderJobListSchema,
}
