const INVOICES = {
	/** Retrieve Event's cart */
	FETCH_REQUESTED: 'FETCH_INVOICES_REQUESTED',
	FETCH_SUCCEEDED: 'FETCH_INVOICES_SUCCEEDED',
	FETCH_FAILED: 'FETCH_INVOICES_FAILED',
}

const CART = {
	/** Retrieve Event's cart */
	FETCH_REQUESTED: 'FETCH_CART_REQUESTED',
	FETCH_SUCCEEDED: 'FETCH_CART_SUCCEEDED',
	FETCH_FAILED: 'FETCH_CART_FAILED',

	/** Update Event's cart */
	UPDATE_REQUESTED: 'UPDATE_CART_REQUESTED',
	UPDATE_SUCCEEDED: 'UPDATE_CART_SUCCEEDED',
	UPDATE_FAILED: 'UPDATE_CART_FAILED',

	/** Add item to the cart */
	ADD_ITEM_REQUESTED: 'CART_ADD_ITEM_REQUESTED',
	ADD_ITEM_SUCCEEDED: 'CART_ADD_ITEM_SUCCEEDED',
	ADD_ITEM_FAILED: 'CART_ADD_ITEM_FAILED',
	/** Remove an item from the cart */
	REMOVE_ITEM_REQUESTED: 'CART_REMOVE_ITEM_REQUESTED',
	REMOVE_ITEM_SUCCEEDED: 'CART_REMOVE_ITEM_SUCCEEDED',
	REMOVE_ITEM_FAILED: 'CART_REMOVE_ITEM_FAILED',

	POST_FREE_REQUESTED: 'CART_POST_FREE_REQUESTED',
	POST_FREE_SUCCEEDED: 'CART_POST_FREE_SUCCEEDED',
	POST_FREE_FAILED: 'CART_POST_FREE_FAILED',

	POST_CHECKOUT_SESSION_REQUESTED: 'CART_POST_CHECKOUT_SESSION_REQUESTED',
	POST_CHECKOUT_SESSION_SUCCEEDED: 'CART_POST_CHECKOUT_SESSION_SUCCEEDED',
	POST_CHECKOUT_SESSION_FAILED: 'CART_POST_CHECKOUT_SESSION_FAILED',
}

const LOCATION = {
	/** Retrieve User's  IP location */
	FETCH_REQUESTED: 'FETCH_LOCATION_REQUESTED',
	FETCH_SUCCEEDED: 'FETCH_LOCATION_SUCCEEDED',
	FETCH_FAILED: 'FETCH_LOCATION_FAILED',

	/** Check postal code validity */
	VERIFY_POSTAL_CODE_REQUESTED: 'VERIFY_POSTAL_CODE_REQUESTED',
	VERIFY_POSTAL_CODE_SUCCEEDED: 'VERIFY_POSTAL_CODE_SUCCEEDED',
	VERIFY_POSTAL_CODE_FAILED: 'VERIFY_POSTAL_CODE_FAILED',
}

const COUNTRY = {
	/** Retrieve a specific country */
	FETCH_REQUESTED: 'FETCH_COUNTRY_REQUESTED',
	FETCH_SUCCEEDED: 'FETCH_COUNTRY_SUCCEEDED',
	FETCH_FAILED: 'FETCH_COUNTRY_FAILED',

	/** Retrieve all countries */
	FETCH_ALL_REQUESTED: 'FETCH_ALL_COUNTRIES_REQUESTED',
	FETCH_ALL_SUCCEEDED: 'FETCH_ALL_COUNTRIES_SUCCEEDED',
	FETCH_ALL_FAILED: 'FETCH_ALL_COUNTRIES_FAILED',
}

const PRODUCT = {
	/** Retrieve products available through the checkout flow */
	FETCH_REQUESTED: 'FETCH_PRODUCTS_REQUESTED',
	FETCH_SUCCEEDED: 'FETCH_PRODUCTS_SUCCEEDED',
	FETCH_FAILED: 'FETCH_PRODUCTS_FAILED',
}

const PAYMENT_INTENT = {
	/** Create payment intent */
	ADD_REQUESTED: 'ADD_PAYMENT_INTENT_REQUESTED',
	ADD_SUCCEEDED: 'ADD_PAYMENT_INTENT_SUCCEEDED',
	ADD_FAILED: 'ADD_PAYMENT_INTENT_FAILED',

	/** Update Payment Intent */
	UPDATE_REQUESTED: 'UPDATE_PAYMENT_INTENT_REQUESTED',
	UPDATE_SUCCEEDED: 'UPDATE_PAYMENT_INTENT_SUCCEEDED',
	UPDATE_FAILED: 'UPDATE_PAYMENT_INTENT_FAILED',
}

export { CART, LOCATION, PRODUCT, COUNTRY, PAYMENT_INTENT, INVOICES }
