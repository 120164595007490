/* eslint-disable no-console */
import { createSlice } from '@reduxjs/toolkit'
import Schema from '../schemas'
import { RSVP } from '../api/constants'

const eventSchema = Schema('Event').defaultProps
/** Define our initial context State */
const defaultState = {
	/** TODO: Review this later on to see if needed or not */
	interface: {
		modalOpen: false,
		showContributorForm: true,
		showContributorView: false,
		viewTab: 'video-maker',
	},
	/** If for instance, the uuid doesn't exist */
	fetchingError: false,
	/** Are we fetching from the server? */
	isFetching: true,
	/** Is the authed user the owner of the event? */
	isOwner: false,
	/** Data of the event */
	event: eventSchema,
	/** RSVP */
	rsvps: [],
	rsvpFilters: [],
	/** Recipient linked to the event */
	recipients: [],
	/** Invoice linked to the event */
	invoice: [],
	/** Media linked to the event */
	media: [],
	/** archived Media linked to the event */
	mediaArchived: [],
	/** Render Jobs linked to the event */
	renderJobs: [],
	/** Cover Photo of event */
	coverPhoto: null,
	/** Theme Cover Photo of event */
	themeCover: null,
	/** Thank You video */
	thankYouVideo: null,
	/** Invite video */
	inviteVideo: null,
	publishedMedia: null,
	/** Attach funders of a cash pot */
	funders: [],
}

const handleRegenerateMediaRequest = (state, action) => {
	// lets check if we haven't added it already
	// NEED to use mediaUuid as it's genorated client side initially
	if (!state.media) {
		// undefined check
		state.media = []
	}

	const index = state.media.findIndex((el) => el?.mediaUuid && el?.mediaUuid == action.payload.data?.mediaUuid)

	if (action.payload.data.occupied && index != -1) {
		/** Otherwise, if the media exist, we need to update it */
		/** If the media already exist, and is not a cover photo nor a thank you video */
		if (
			action.payload.data?.isCoverPhoto != true &&
			action.payload.data?.isThemeCover != true &&
			action.payload.data?.isThankYouVideo != true &&
			action.payload.data?.isInviteVideo != true
		) {
			/** We're merging to collection */
			let newData = action.payload.data
			let existingData = state.media[index]
			state.media[index] = { ...existingData, occupied: true }
		}
	}
}
/**
 * Common handler for POST_EVENT_MEDIA_SUCCESS and SOCKET_ADD_MEDIA
 * @param {*} state
 * @param {*} action
 */
const handlerPostMediaSuccess = (state, action) => {
	// lets check if we haven't added it already
	// NEED to use mediaUuid as it's genorated client side initially
	if (!state.media) {
		// undefined check
		state.media = []
	}

	// Get index of object in media
	const index = state.media.findIndex((el) => el?.mediaUuid && el?.mediaUuid == action.payload?.data?.mediaUuid)

	/** If the media isn't here yet */
	if (index == -1) {
		/** If the media is not a cover photo or theme cover photo or a thank you video */
		if (
			action.payload.data?.isCoverPhoto != true &&
			action.payload.data?.isThemeCover != true &&
			action.payload.data?.isThankYouVideo != true &&
			action.payload.data?.isInviteVideo != true
		) {
			/** We're adding to collection */
			state.media.push(action.payload.data)
		}
	} else if (index != -1) {
		/** Otherwise, if the media exist, we need to update it */
		/** If the media already exist, and is not a cover photo nor a thank you video */
		if (
			action.payload.data?.isCoverPhoto != true &&
			action.payload.data?.isThemeCover != true &&
			action.payload.data?.isThankYouVideo != true &&
			action.payload.data?.isInviteVideo != true
		) {
			/** We're merging to collection */
			let newData = action.payload.data
			let existingData = state.media[index]
			state.media[index] = { ...existingData, ...newData }
		}
	}

	// Get index of object in sortingOrder
	const sortOrder = [...state.event.mediaSortingOrder]
	const sortIndex = sortOrder.findIndex((el) => el == action.payload.data.id || el == action.payload.data.mediaUuid)

	// Adjust Sort Order if we have the media id (skip for media in error state)
	if (action.payload.data.jobState != 'error') {
		if (sortIndex == -1) {
			/** We're adding to collection */
			state.event.mediaSortingOrder.push(action.payload.data.id || action.payload.data.mediaUuid)
		} else {
			/** Otherwise, if the mediaUuid exist, we need to update it with id */
			if (sortIndex >= 0 && sortIndex < sortOrder.length) {
				sortOrder[sortIndex] = action.payload.data.id
			}
			state.event.mediaSortingOrder = sortOrder
		}
	}

	/** ARCHIVED MEDIA - If this is an unarchive lets remove it from that list */
	// Not the cover or thank you, look for it in the gallery
	if (!state.mediaArchived) {
		// undefined check
		state.mediaArchived = []
	}
	const mediaArchived = [...state.mediaArchived] // make a separate copy of the array
	const mediaArchivedIndex = mediaArchived.findIndex(
		(el) => el?.id == action.payload?.data?.id || el.mediaUuid == action.payload?.data?.mediaUuid
	)
	if (mediaArchivedIndex !== -1) {
		// console.log(`Before Removing media at index ${mediaIndex}`, media)
		mediaArchived.splice(mediaArchivedIndex, 1)
		// console.log(`After remove of ${mediaIndex}`, media)
		state.mediaArchived = mediaArchived
	}

	/** Otherwise if it's a cover photo, assign it */
	if (action.payload.data?.isCoverPhoto == true) {
		/** Set the new cover photo */
		state.coverPhoto = action.payload.data
	}

	/** Otherwise if it's a theme cover photo, assign it */
	if (action.payload.data?.isThemeCover == true) {
		/** Set the new theme cover photo */
		state.themeCover = action.payload.data
	}

	/** Otherwise if it's a thank you video, assign it */
	if (action.payload.data?.isThankYouVideo == true) {
		/** Set the new cover photo */
		state.thankYouVideo = action.payload.data
	}

	/** Otherwise if it's an invite video, assign it */
	if (action.payload.data?.isInviteVideo == true) {
		/** Set the new cover photo */
		state.inviteVideo = action.payload.data
	}
}

const handlerRemoveMedia = (state, action) => {
	// Remove from media array
	if (action.payload?.data?.mediaUuid || action.payload?.data?.id) {
		// Not the cover or thank you, look for it in the gallery
		const media = [...state.media] // make a separate copy of the array
		const mediaIndex = media.findIndex(
			(el) => el.id == action.payload.data.id || el.mediaUuid == action.payload.data.mediaUuid
		)
		if (mediaIndex !== -1) {
			// console.log(`Before Removing media at index ${mediaIndex}`, media)
			media.splice(mediaIndex, 1)
			// console.log(`After remove of ${mediaIndex}`, media)
			state.media = media
		}
		// Remove from mediaSortingOrder (which is an array of ids)
		const sortOrder = [...state.event.mediaSortingOrder]
		const sortIndex = sortOrder.findIndex((id) => id == action.payload.data.id)
		// console.log(`Before sorting order at ${sortIndex}`, sortOrder)
		if (sortIndex != -1) {
			sortOrder.splice(sortIndex, 1)
			// console.log(`After sorting order at ${sortIndex}`, sortOrder)
			state.event.mediaSortingOrder = sortOrder
		}

		// Check if this was the cover?
		if (action.payload?.data?.id == state.coverPhoto?.id) {
			state.coverPhoto = null
			state.event.coverPhotoId = null
			return
		}

		// Check if this was the theme cover?
		if (action.payload?.data?.id == state.themeCover?.id) {
			state.themeCover = null
			state.event.themeCoverId = null
			return
		}

		// Check if this was the thankyou?
		if (action.payload?.data?.id == state.thankYouVideo?.id) {
			state.thankYouVideo = null
			state.event.thankYouVideoId = null
			return
		}

		// Check if this was the invite video?
		if (action.payload?.data?.id == state.inviteVideo?.id) {
			state.inviteVideo = null
			state.event.inviteVideoId = null
			return
		}

		/** ARCHIVED MEDIA - If this is an unarchive lets add it to that list */
		// Not the cover or thank you, look for it in the gallery
		if (!state.mediaArchived) {
			// undefined check
			state.mediaArchived = []
		}
		const mediaArchived = [...state.mediaArchived] // make a separate copy of the array
		const mediaArchivedIndex = mediaArchived.findIndex(
			(el) => el.id == action.payload.data.id || el.mediaUuid == action.payload.data.mediaUuid
		)
		if (mediaArchivedIndex == -1) {
			// console.log(`Before Removing media at index ${mediaIndex}`, media)
			state.mediaArchived.push(action.payload.data)
		}
	}
}

const handlerPostRSVPSuccess = (state, action) => {
	if (state.rsvps) {
		// ensures payload has eventId that matches id of currently loaded event
		if (action.payload?.data?.eventId && action.payload.data.eventId === state.event.id) {
			const index = state.rsvps.findIndex((el) =>
				action.payload.data.id
					? action.payload.data.id == el.id
					: el.eventId == action.payload.data.eventId &&
					  (action.payload.data.deliveryEmail == el.deliveryEmail ||
							action.payload.data.deliverySms == el.deliverySms)
			)
			if (index !== -1) {
				state.rsvps[index] = { ...state.rsvps[index], ...action.payload.data }
			} else {
				state.rsvps.push(action.payload.data)
			}
		}
	}
}

const currentEventSlice = createSlice({
	initialState: defaultState,
	name: 'event',
	extraReducers: {
		['LOAD_EVENT_FAILURE']: (state, action) => {
			state.fetchingError = true
			state.isFetching = false
		},
		// Event
		['LOAD_EVENT_REQUEST']: (state, action) => {
			state.isFetching = true
			state.fetchingError = false // important, so we reset
		},
		['LOAD_EVENT_SUCCESS']: (state, action) => {
			// un-join event payload
			var event = { ...action?.payload?.data }
			delete event.recipients
			delete event.rsvps
			delete event.media
			delete event.archivedMedia
			delete event.invoice
			delete event.renderJobs
			delete event.coverPhoto
			delete event.themeCover
			delete event.thankYouVideo
			delete event.inviteVideo
			delete event.publishedMedia
			delete event.funders

			state.fetchingError = false
			state.isFetching = false
			state.event = event
			state.rsvps = action.payload.data?.rsvps
			state.recipients = action.payload.data?.recipients
			state.media = action.payload.data?.media?.filter(
				(el) => el.id !== action.payload.data.coverPhotoId && el.id !== action.payload.data.themeCoverId
			)
			state.mediaArchived = action.payload.data?.archivedMedia?.filter((el) => el.archived)
			state.invoice = action.payload.data?.invoice
			state.renderJobs = action.payload.data?.renderJobs
			state.funders = action.payload.data?.funders

			var cover = action.payload.data?.coverPhoto
			var themeCover = action.payload.data?.themeCover
			if (cover) {
				state.coverPhoto = cover
			}
			if (themeCover) {
				state.themeCover = themeCover
			}
			state.thankYouVideo = action.payload.data?.thankYouVideo
			state.inviteVideo = action.payload.data?.inviteVideo
			state.publishedMedia = action.payload.data?.publishedMedia
		},
		['UPDATE_EVENT_SUCCESS']: (state, action) => {
			state.isFetching = false
			state.event = action.payload.data
		},
		['SET_CURRENT_EVENT_OWNER']: (state, action) => {
			state.isOwner = action.data
		},
		// Media
		['LOAD_EVENT_MEDIA_SUCCESS']: (state, action) => {
			state.isFetching = false
			state.media = action.payload.data?.media?.filter((el) => el.id !== state.event.coverPhotoId)
		},
		['POST_EVENT_MEDIA_REQUEST']: handlerPostMediaSuccess,
		['POST_EVENT_MEDIA_SUCCESS']: handlerPostMediaSuccess,
		['POST_EVENT_MEDIA_FAILURE']: handlerRemoveMedia,
		['PUT_EVENT_MEDIA_SUCCESS']: (state, action) => {
			const index = state.media.findIndex((el) => el.id == action.payload.data.id)
			if (index !== -1) {
				state.media[index] = { ...state.media[index], ...action.payload.data }
			}
		},
		['REGENERATE_MEDIA_REQUEST']: handleRegenerateMediaRequest,
		['REGENERATE_MEDIA_SUCCESS']: handlerPostMediaSuccess,
		['DELETE_MEDIA_SUCCESS']: handlerRemoveMedia,
		// Recipients
		['UPDATE_RECIPIENT_SUCCESS']: (state, action) => {
			const index = state.recipients.findIndex((el) => el.id == action.payload.data.id)
			if (index != -1) {
				state.recipients[index] = action.payload.data
			}
		},

		['ADD_RECIPIENT_SUCCESS']: (state, action) => {
			const index = state.recipients.findIndex((el) => el.id == action.payload.data.id)
			if (index == -1) {
				state.recipients.push(action.payload.data)
			}
		},
		// Delete a recipient
		['DELETE_RECIPIENT_SUCCESS']: (state, action) => {
			console.log(action, 'action recipient')
			if (action.payload.data?.id) {
				state.recipients = [...state.recipients.filter((rec) => rec.id !== action.payload.data.id)]
			}
		},

		//RSVPS
		['POST_RSVP_SUCCEEDED']: handlerPostRSVPSuccess,

		// SOCKETS
		// Event
		['SOCKET_EVENT_SORTING_ORDER']: (state, action) => {
			state.event.mediaSortingOrder = action.payload.data.mediaSortingOrder
		},
		['SOCKET_EVENT_BACKGROUNDMUSIC']: (state, action) => {
			state.event.backgroundMusicIds = action.payload.data.backgroundMusicIds
		},
		['SOCKET_EVENT_THEME']: (state, action) => {
			state.event.themeTemplateId = action.payload.data.themeTemplateId
		},
		// Media
		['POST_DUPLICATE_MEDIA_REQUESTED']: (state, action) => {
			// Maybe a bit hacky but works.
			if (action.payload.data.mediaUuid) state.event.mediaSortingOrder.push(action.payload.data.mediaUuid)
			if (action.payload.data.mediaUuid) state.media.push({ id: action.payload.data.mediaUuid })
			// handlerPostMediaSuccess(state, action)
		},
		['SOCKET_ADD_MEDIA']: handlerPostMediaSuccess,
		['SOCKET_UPDATE_MEDIA']: handlerPostMediaSuccess,
		['DELETE_MEDIA_SUCCESS']: handlerRemoveMedia,
		['SOCKET_DELETE_MEDIA_SUCCESS']: handlerRemoveMedia,
		// Funders
		['SOCKET_ADD_FUNDER']: (state, action) => {
			const index = state.funders.findIndex((el) => el.id == action.payload.data.id)
			if (index !== -1) {
				state.funders[index] = action.payload.data
			} else {
				state.funders.push(action.payload.data)
			}
		},
		// RSVP
		['SOCKET_UPDATE_RSVP']: handlerPostRSVPSuccess,
		['SET_RSVP_FILTER']: (state, action) => {
			state.rsvpFilters = action.payload.filters
		},
		// Previews & Render Jobs
		['SOCKET_UPDATE_RENDERJOB']: (state, action) => {
			if (state.renderJobs) {
				// undefined check
				if (action.payload.data.id) {
					const index = state.renderJobs.findIndex((el) => el.id == action.payload.data.id)
					if (index !== -1) {
						state.renderJobs[index] = action.payload.data
					} else {
						state.renderJobs.push(action.payload.data)
					}
				}
			} else {
				state.renderJobs = []
			}
		},
		// Used this to remove/add jobs from toolbox
		['ADD_ADMIN_RENDER_JOB_SUCCESS']: (state, action) => {
			if (state.renderJobs) {
				// undefined check
				if (action.payload.data.id) {
					const index = state.renderJobs.findIndex((el) => el.id == action.payload.data.id)
					if (index !== -1) {
						state.renderJobs[index] = action.payload.data
					} else {
						state.renderJobs.push(action.payload.data)
					}
				}
			} else {
				state.renderJobs = []
			}
		},
		['DELETE_ADMIN_RENDER_JOB_SUCCESS']: (state, action) => {
			if (state.renderJobs) {
				// undefined check
				if (action.payload.id) {
					state.renderJobs = [...state.renderJobs.filter((job) => job.id !== action.payload.id)]
				}
			} else {
				state.renderJobs = []
			}
		},

		/** Update individual rsvps upon updates (merge with original object to not loose relationships) */
		[RSVP.UPDATE_SUCCEEDED]: (state, action) => {
			if (state.rsvps && state.rsvps.length > 0) {
				let itemIndex = state.rsvps.findIndex((el) => el.id == action.payload.data.id)
				// console.log(itemIndex, action.payload.data)
				if (itemIndex) {
					let item = { ...state.rsvps[itemIndex], ...action.payload.data }
					state.rsvps[itemIndex] = item
				}
			}
		},
	},
	reducers: {
		clearEvent: (state, action) => {
			state.interface = defaultState.interface
			state.isFetching = defaultState.isFetching
			state.fetchingError = defaultState.fetchingError
			state.isOwner = defaultState.isOwner
			state.event = defaultState.event
			state.recipients = defaultState.recipients
			state.invoice = defaultState.invoice
			state.media = defaultState.media
			state.renderJobs = defaultState.renderJobs
			state.coverPhoto = defaultState.coverPhoto
			state.themeCover = defaultState.themeCover
			state.publishedMedia = defaultState.publishedMedia
		},
		setModalOpen: (state, action) => {
			state.interface.modalOpen = action.payload
		},
		showContributorView: (state, action) => {
			state.interface.showContributorView = action.payload
		},
		setViewTab: (state, action) => {
			state.interface.viewTab = action.payload
		},
	},
})

export default currentEventSlice.reducer
export const currentEventState = defaultState
// Export our actions
export const { clearEvent, setModalOpen, showContributorView, setViewTab } = currentEventSlice.actions
