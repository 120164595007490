import { select, call, put, take, takeEvery, takeLatest, all } from 'redux-saga/effects'
// import { normalize } from 'normalizr'
// import { eventListSchema, eventSchema } from './normalizr'
import requests from './requests'
import { NOTIFICATIONS } from './constants'

/**
 * Fetch user'notifications
 * @param {*} action
 */
function* handleFetchNotifications(action) {
	try {
		const response = yield call(requests.fetchNotifications, action.payload)
		yield put({ type: NOTIFICATIONS.FETCH_SUCCEEDED, payload: response.data })
	} catch (e) {
		yield put({ type: NOTIFICATIONS.FETCH_FAILED, payload: e })
	}
}

/**
 * Theme Versions Saga Watcher
 */
export function* watchFetchNotifications() {
	yield takeLatest(NOTIFICATIONS.FETCH_REQUESTED, handleFetchNotifications)
}

/**
 * Mark a notification as seen
 * @param {*} action
 */
function* handleMarkNotificationAsSeen(action) {
	try {
		const response = yield call(requests.markNotificationAsSeen, action.payload)
		yield put({ type: NOTIFICATIONS.MARK_AS_SEEN_SUCCEEDED, payload: response.data })
	} catch (e) {
		yield put({ type: NOTIFICATIONS.MARK_AS_SEEN_FAILED, payload: e })
	}
}

export function* watchMarkNotificationAsSeen() {
	yield takeEvery(NOTIFICATIONS.MARK_AS_SEEN_REQUESTED, handleMarkNotificationAsSeen)
}

/**
 * Mark a notification as read
 * @param {*} action
 */
function* handleMarkNotificationAsRead(action) {
	try {
		const response = yield call(requests.markNotificationAsRead, action.payload)
		yield put({ type: NOTIFICATIONS.MARK_AS_READ_SUCCEEDED, payload: response.data })
	} catch (e) {
		yield put({ type: NOTIFICATIONS.MARK_AS_READ_FAILED, payload: e })
	}
}

export function* watchMarkNotificationAsRead() {
	yield takeLatest(NOTIFICATIONS.MARK_AS_READ_REQUESTED, handleMarkNotificationAsRead)
}

/**
 * Mark a notification as unread
 * @param {*} action
 */
function* handleMarkNotificationAsUnread(action) {
	try {
		const response = yield call(requests.markNotificationAsUnread, action.payload)
		yield put({ type: NOTIFICATIONS.MARK_AS_UNREAD_SUCCEEDED, payload: response.data })
	} catch (e) {
		yield put({ type: NOTIFICATIONS.MARK_AS_UNREAD_FAILED, payload: e })
	}
}

export function* watchMarkNotificationAsUnread() {
	yield takeLatest(NOTIFICATIONS.MARK_AS_UNREAD_REQUESTED, handleMarkNotificationAsUnread)
}

/**
 * Mark a notification as dismissed
 * @param {*} action
 */
function* handleMarkNotificationAsDismissed(action) {
	try {
		const response = yield call(requests.markNotificationAsDismissed, action.payload)
		yield put({ type: NOTIFICATIONS.MARK_AS_DISMISSED_SUCCEEDED, payload: response.data })
	} catch (e) {
		yield put({ type: NOTIFICATIONS.MARK_AS_DISMISSED_FAILED, payload: e })
	}
}

export function* watchMarkNotificationAsDismissed() {
	yield takeLatest(NOTIFICATIONS.MARK_AS_DISMISSED_REQUESTED, handleMarkNotificationAsDismissed)
}
