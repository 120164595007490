import { NOTIFICATIONS } from './constants'

const fetchNotifications = (params) => ({
	type: NOTIFICATIONS.FETCH_REQUESTED,
	payload: params,
})

const markNotificationAsSeen = (id) => ({
	type: NOTIFICATIONS.MARK_AS_SEEN_REQUESTED,
	payload: id,
})

const markNotificationAsRead = (id) => ({
	type: NOTIFICATIONS.MARK_AS_READ_REQUESTED,
	payload: id,
})

const markNotificationAsUnread = (id) => ({
	type: NOTIFICATIONS.MARK_AS_UNREAD_REQUESTED,
	payload: id,
})

const markNotificationAsDismissed = (id) => ({
	type: NOTIFICATIONS.MARK_AS_DISMISSED_REQUESTED,
	payload: id,
})

export {
	fetchNotifications,
	markNotificationAsSeen,
	markNotificationAsRead,
	markNotificationAsUnread,
	markNotificationAsDismissed,
}
