// A really common need is to get the current size of the browser window.
// This hook returns an object containing the window's width and height. If executed server-side (no window object) the value of width and height will be undefined.
import { useState, useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

function useWindowSize() {
	const dispatch = useDispatch()
	// Initialize state with undefined width/height so server and client renders match
	const [windowSize, setWindowSize] = useState({
		width: undefined,
		height: undefined,
		orientation: undefined,
	})

	const viewport = useSelector((s) => s.environment.viewport, shallowEqual)

	useEffect(() => {
		// Handler to call on window resize
		function handleResize() {
			let orient = undefined
			switch (window?.screen?.orientation?.angle) {
				case -90:
				case 90:
					orient = 'landscape'
					break
				default:
					orient = window?.innerWidth > window?.innerHeight ? 'landscape' : 'portrait'
					break
			}
			const obj = {
				width: window?.innerWidth,
				height: window?.innerHeight,
				orientation: orient,
			}
			// Set window width/height to state
			setWindowSize(obj)
			/** If it has changed, send to redux for update */
			if (window.innerWidth != viewport.width || window.innerHeight != viewport.height) {
				dispatch({ type: 'SET_VIEWPORT', payload: obj })
			}
		}

		// Add event listener
		window.addEventListener('orientationchange', handleResize)
		window.addEventListener('resize', handleResize)

		// Call handler right away so state gets updated with initial window size
		handleResize()

		// Remove event listener on cleanup
		return () => {
			window.addEventListener('orientationchange', handleResize)
			window.removeEventListener('resize', handleResize)
		}
	}, []) // Empty array ensures that effect is only run on mount

	return windowSize
}

export default useWindowSize
