/* **************************************
 *  CONSTANTS
 * ************************************ */

// Send new start and end time for the current video
export const UPDATE_TRIMMING_PERIOD = 'UPDATE_TRIMMING_PERIOD'

// Update the store to match the current state of the video trimmer
export const UPDATE_TRIMMER_STATE_TO_STORE = 'UPDATE_TRIMMER_STATE_TO_STORE'

// Update video rotation on the reducer
export const UPDATE_VIDEO_ROTATION = 'UPDATE_VIDEO_ROTATION'

// Reset the video trimmer to default (this will clear the file)
export const RESET_VIDEO_TRIMMER = 'RESET_VIDEO_TRIMMER'

// Reset the video rotation to default
export const RESET_VIDEO_ROTATION = 'RESET_VIDEO_ROTATION'
