class Fullscreen {
	request(element) {
		var elm = element || document
		if (elm.requestFullscreen) {
			elm.requestFullscreen()
		} else if (elm.webkitRequestFullscreen) {
			elm.webkitRequestFullscreen()
		} else if (elm.mozRequestFullScreen) {
			elm.mozRequestFullScreen()
		} else if (elm.msRequestFullscreen) {
			elm.msRequestFullscreen()
		}
	}

	exit(element) {
		var elm = element || document
		if (elm?.exitFullscreen) {
			elm.exitFullscreen()
		} else if (elm?.webkitExitFullscreen) {
			elm.webkitExitFullscreen()
		} else if (elm?.mozCancelFullScreen) {
			elm.mozCancelFullScreen()
		} else if (elm?.msExitFullscreen) {
			elm.msExitFullscreen()
		}
	}

	get isFullscreen() {
		return (
			document?.fullscreenElement ||
			document?.webkitFullscreenElement ||
			document?.webkitCurrentFullScreenElement ||
			document?.mozFullScreenElement ||
			document?.msFullscreenElement
		)
	}

	get enabled() {
		return (
			document?.fullscreenEnabled ||
			document?.webkitFullscreenEnabled ||
			document?.mozFullScreenEnabled ||
			document?.msFullscreenEnabled
		)
	}
}

const fsListeners = ['fullscreenchange', 'webkitfullscreenchange', 'mozfullscreenchange', 'MSFullscreenChange']

export { fsListeners }
export default new Fullscreen()
