import React, { createContext, useContext, useState, useEffect } from 'react'
import { useSelector, shallowEqual, useStore as useReduxStore } from 'react-redux'

import { useSocketContext, forceConnect } from './SocketProvider'

/** Build Current Event Context */
const AuthContext = createContext()

/** Define Current Event Context Provider  */
const AuthProvider = ({ children }) => {
	/** Set initial state rendered by the context */
	// Get the redux store (important for SSR)
	const store = useReduxStore()

	/** Retrieve auth information */
	const auth = useSelector((s) => s.auth, shallowEqual)

	/** Is the authed user an admin */
	const [isAdmin, setIsAdmin] = useState(auth.role == 'admin')
	/** Is the authed user an editor */
	const [isEditor, setIsEditor] = useState(auth.role == 'editor')

	/** Do we have admin privilege (the user is either an admin, an editor, or is impersonated) */
	const [hasAdminPrivilege, setAdminPrivilege] = useState(
		auth.isImpersonated || auth.role == 'admin' || auth.role == 'editor'
	)

	/** Get private users socket from SocketProvider */
	const {
		users: { socket },
	} = useSocketContext()

	/** TODO: Review role of editor */
	useEffect(() => {
		const adminRole = auth.role == 'admin'
		const editorRole = auth.role == 'editor'
		const isImpersonated = auth.isImpersonated
		const isAuthenticated = auth.isAuthenticated

		/**
		 * Check if user is an admin only
		 */
		if (adminRole) {
			setIsAdmin(true)
		} else if (isAdmin != true) {
			/**
			 * If the previous value was true,
			 * we want to set it to false since predicate returned false.
			 * */
			setIsAdmin(false)
		}
		/**
		 * Check if the user has admin privileges (admin, editor, impersonated)
		 */
		if (adminRole || editorRole || isImpersonated) {
			setAdminPrivilege(true)
		} else {
			setAdminPrivilege(false)
		}

		/**
		 * Force a socket connection attempt to private namespace, only when authenticated
		 */
		if (isAuthenticated) {
			forceConnect(socket)
		}
	}, [auth])

	const s = {
		...auth,
		isAdmin,
		isEditor,
		hasAdminPrivilege,
		hasSubscription: auth.hasSubscription,
	}
	return <AuthContext.Provider value={s}>{children}</AuthContext.Provider>
}

export default AuthProvider

export const useAuthContext = () => useContext(AuthContext)
