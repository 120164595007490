import React, { useEffect } from 'react'
import { useColorMode } from '@chakra-ui/react'

const LightMode = ({ children }) => {
	const { colorMode, setColorMode } = useColorMode()

	useEffect(() => {
		if (colorMode == 'dark') {
			setColorMode('light')
		}
	}, [colorMode])

	return <>{children}</>
}

export default LightMode
