import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import requests from './requests'
import { TRACKS, MEDIA, PUBLISHED_MEDIA } from './constants'

/** Saga to handle duplicate media item */
function* handleDuplicateMedia(action) {
	try {
		const response = yield call(requests.duplicateMedia, action.payload.data)
		yield put({ type: MEDIA.POST_DUPLICATE_MEDIA_SUCCEEDED, payload: { data: response.data.data } })
	} catch (e) {
		yield put({ type: MEDIA.POST_DUPLICATE_MEDIA_FAILED, payload: e })
	}
}

/** Saga to handle restore media request for a media item */
function* handleRestoreArchivedMedia(action) {
	try {
		const response = yield call(requests.unArchiveMedia, action.payload)
		yield put({ type: MEDIA.POST_UNARCHIVE_MEDIA_SUCCEEDED, payload: { data: response.data.data } })
	} catch (e) {
		yield put({ type: MEDIA.POST_UNARCHIVE_MEDIA_FAILED, payload: e })
	}
}

/** Saga to handle restore All s3 objects media request for an event */
function* handleRestoreAllArchivedMedia(action) {
	try {
		const response = yield call(requests.restoreAllMedia, action.payload)
		yield put({ type: MEDIA.POST_RESTORE_ALL_MEDIA_SUCCEEDED, payload: { data: response.data.data } })
	} catch (e) {
		yield put({ type: MEDIA.POST_RESTORE_ALL_MEDIA_FAILED, payload: e })
	}
}

/** Saga to handle image upscaling request */
function* handleUpscaleImage(action) {
	try {
		const response = yield call(requests.upscaleImage, action.payload)
		yield put({ type: MEDIA.POST_UPSCALE_MEDIA_SUCCEEDED, payload: { data: response.data.data } })
	} catch (e) {
		yield put({ type: MEDIA.POST_UPSCALE_MEDIA_FAILED, payload: e })
	}
}

/** PUBLISHED MEDIA */

/** Saga to handle restore All media request for an event */
function* handleRestorePublishedMedia(action) {
	try {
		const response = yield call(requests.restorePublishedMedia, action.payload)
		yield put({
			type: PUBLISHED_MEDIA.PUT_RESTORE_PUBLISHED_MEDIA_SUCCEEDED,
			payload: { data: response.data.data },
		})
	} catch (e) {
		yield put({ type: PUBLISHED_MEDIA.PUT_RESTORE_PUBLISHED_MEDIA_FAILED, payload: e })
	}
}

/**
 * Fetch user tracks
 * @param {*} action
 */
function* handleFetchTracks(action) {
	try {
		const response = yield call(requests.fetchTracks, action.payload)
		let res = { ...response.data }
		delete res.data
		yield put({ type: TRACKS.FETCH_SUCCEEDED, payload: { ...res, entities: response.data.data } })
	} catch (e) {
		yield put({ type: TRACKS.FETCH_FAILED, payload: e })
	}
}

/** Saga to handle creation of a track uploaded by the user */
function* handlePostTrack(action) {
	// let muteAlert = action.payload.muteAlert || false
	// delete action.payload.muteAlert
	try {
		// var user = {
		// 	email: action.payload.email,
		// 	profile: {
		// 		firstName: action.payload.firstName,
		// 	},
		// }
		const response = yield call(requests.postTrack, action.payload)

		console.log(response, 'response from adding track')
		yield put({ type: TRACKS.POST_SUCCEEDED, payload: response.data.data })
		yield put({
			type: 'ADD_FLASH_MESSAGE',
			status: 'success',
			message: 'Background music added.',
		})
	} catch (e) {
		yield put({ type: TRACKS.POST_FAILED, payload: e })
		yield put({
			type: 'ADD_FLASH_MESSAGE',
			status: 'error',
			message: `An error occurred.`,
		})
	}
}

/** Saga to handle creation of a track uploaded by the user */
function* handleDeleteTrack(action) {
	try {
		const response = yield call(requests.deleteTrack, action.payload)
		yield put({ type: TRACKS.DELETE_SUCCEEDED, payload: response.data.data })
	} catch (e) {
		yield put({ type: TRACKS.DELETE_FAILED, payload: e })
	}
}

/**
 * Watch tracks
 */
export function* watch() {
	yield takeLatest(TRACKS.FETCH_REQUESTED, handleFetchTracks)
	yield takeEvery(TRACKS.POST_REQUESTED, handlePostTrack)
	yield takeEvery(TRACKS.DELETE_REQUESTED, handleDeleteTrack)
	yield takeEvery(MEDIA.POST_DUPLICATE_MEDIA_REQUESTED, handleDuplicateMedia)
	yield takeEvery(MEDIA.POST_UNARCHIVE_MEDIA_REQUESTED, handleRestoreArchivedMedia)
	yield takeEvery(MEDIA.POST_RESTORE_ALL_MEDIA_REQUESTED, handleRestoreAllArchivedMedia)
	yield takeEvery(MEDIA.POST_UPSCALE_MEDIA_REQUESTED, handleUpscaleImage)
	yield takeEvery(PUBLISHED_MEDIA.PUT_RESTORE_PUBLISHED_MEDIA_REQUESTED, handleRestorePublishedMedia)
}
