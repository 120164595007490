import { useEffect } from 'react'

const useViddayDevtag = () => {
	useEffect(() => {
		// A brutish way of dealing with FB redirect - however this violates react router
		// since it is not aware that a location change has undergone and it still holds
		// this hash in the redux state .
		if (window && window.location.hash && window.location.hash == '#_=_') {
			history.replaceState(null, null, ' ')
		}

		// (componentDidMount only fires in browser/DOM environment)
		if (navigator.onLine && window) {
			console.log(`
				___   ___     __ ___
				| |  / (_)___/ / __ |_____ __  __
				| | / / / __  / / / / __  / / / /
				| |/ / / /_/ / /_/ / /_/ / /_/ /
				|___/_/___,_/_____/___,_/___, /
										/____/
				`)
		}
	}, [])
}

export default useViddayDevtag
