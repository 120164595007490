/**
 * detect if a file is an video.
 * @param {File} file
 */
export const isVideo = (file) => file.type.indexOf('video') > -1

/**
 * create range [min .. max]
 */
export const range = (min, max) => Array.apply(null, { length: max - min + 1 }).map((v, i) => i + min)

/**
 * FileReader via promise
 * @param {File} file
 * @param {string} dataType
 * @return {Promise<ArrayBuffer | string>}
 */
export const readFile = (file, dataType = 'ArrayBuffer') =>
	new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader['readAs' + dataType](file)
		reader.onload = () => resolve(reader.result)
		reader.onerror = (err) => reject(err)
	})

/**
 * Read File/Blob to ArrayBuffer
 * @param {File} file
 * @return {Promise<ArrayBuffer>}
 */
export const readArrayBuffer = (file) => readFile(file, 'ArrayBuffer')

/**
 * Read File/Blob to Base64
 * @param {File} file
 * @return {Promise<string>}
 */
export const readDataURL = (file) => readFile(file, 'DataURL')

export const readBlobURL = (file) => URL.createObjectURL(file)

export const rename = (filename, ext, stamp) => `${filename.replace(/\.\w+$/, '')}${stamp || ''}.${ext}`

/**
 * format seconds to [minutes, integer, decimal(2)]
 * @param {number} seconds
 */
export const formatSeconds = (seconds) => [
	Math.floor(seconds / 60 / 60),
	Math.floor(seconds / 60),
	Math.floor(seconds % 60),
	Math.round((seconds % 1) * 100),
]

export const noop = () => {}

export const arrayBufferToBlob = (buffer) =>
	new Blob([new Uint8Array(buffer, 0, buffer.byteLength)], {
		type: 'video/webm',
		name: 'video.webm',
	})
