import { createSlice } from '@reduxjs/toolkit'

/**
 * This reducer correspond to the products available throughout the checkout module.
 * These are not the products contained in the cart, but rather all available options.
 */

const initialState = {
	viewport: {
		width: undefined,
		height: undefined,
		orientation: undefined,
	},
	webpSupport: true,
	browserAgent: null,
}

const EnvironmentSlice = createSlice({
	initialState: initialState,
	name: 'environment',
	extraReducers: {
		['SET_VIEWPORT']: (state, action) => {
			state.viewport = action.payload
		},
		['SET_WEBP_SUPPORT']: (state, action) => {
			state.webpSupport = action.payload
		},
		['SET_BROWSER_AGENT']: (state, action) => {
			state.browserAgent = action.payload
		},
	},
	reducers: {},
})

export default EnvironmentSlice.reducer
