import { combineReducers } from 'redux'
import { reducer as NewVidday } from './start-a-vidday'
import { reducer as ForgotPassword } from './forgot-password'
import { reducer as UserEmail } from './user-email'
import { reducer as EventDetail } from './event-detail'
import { reducer as Auth } from './login'
import { reducer as Register } from './register'
import { reducer as UserProfile } from './user-profile'
import { reducer as UserPassword } from './user-password'
import { reducer as UserNotifications } from './user-notifications'
import { reducer as ResetPassword } from './reset-password'
import { reducer as AddMediaContributor } from './add-media-contributor'
import { reducer as PromoCode } from './promo-code'
import { reducer as RSVP } from './rsvp'
import { reducer as BroadcastMessage } from './broadcast-message'
import { reducer as AddThankYouRecipient } from './add-thank-you-recipient'
import { reducer as RedeemCash } from './redeem-form'

/** Export forms reducers */
const formReducers = combineReducers({
	NewVidday,
	ForgotPassword,
	UserEmail,
	EventDetail,
	Auth,
	Register,
	UserProfile,
	UserPassword,
	UserNotifications,
	ResetPassword,
	AddMediaContributor,
	AddThankYouRecipient,
	PromoCode,
	RSVP,
	BroadcastMessage,
	RedeemCash,
})

export default formReducers
