import React, { useEffect } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { useEventContext } from '../contexts/EventProvider'
import HandleArbitrary from '../ui/pages/utils/HandleArbitrary'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import qs from 'qs'
import { loadEvent } from '../../api/app/events'
import { clearEvent } from '../../reducers/event'
import FancyLoader from '../ui/atoms/FancyLoader'

const IfEventExist = ({ children }) => {
	/** Retrieve the event from the state */
	const { event, isFetching } = useEventContext()

	const params = useParams()
	const { uuid } = params

	/** Do we have an error while fetching ? */
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const location = useLocation()
	const { search } = location
	// console.log('props ProtectedEventRoute # ', props, location)
	// console.log('search key # ', search)

	const fetchingError = useSelector((s) => s.event.fetchingError, shallowEqual)
	const privateKey = qs.parse(search, { ignoreQueryPrefix: true }).v || null

	/** Determine if we're entering v2 endpoints */
	// const hasMatch = matchPath(location.pathname, {
	// 	path: '/v2/event/:uuid([a-z]{0,1}-?[a-zA-Z0-9]{6,})',
	// 	exact: true,
	// 	strict: false,
	// })

	useEffect(() => {
		if (uuid && !fetchingError) {
			if (uuid === event?.uuid) {
				// when uuid is correct and matches uuid of EventContext, this is a no-op
				console.log('Event is already loaded in EventContext. Skipping event fetch.')
			} else if (uuid.indexOf('-') == 1 && uuid.length > 8) {
				// to fix format error "b-123456extra" -> "b-123456"
				let fixedUuid = uuid.substring(0, 8) // trim things down to 8 chars and try the search.
				navigate(`/event/${fixedUuid}`)
			} else if (uuid.indexOf('-') == -1 && uuid.length > 6) {
				// to fix format error "123456extra" -> "123456"
				// (support for older 6 char uuid)
				let fixedUuid = uuid.substring(0, 6) // trim things down to 8 chars and try the search.
				navigate(`/event/${fixedUuid}`)
			} else {
				// valid uuid but not loaded in redux yet, so fetch it!
				dispatch(loadEvent(uuid, { privateKey: privateKey }))
			}
		} else {
			console.error("we didn't find a valid uuid, skippig event fetch...")
		}
	}, [uuid, privateKey, isFetching, fetchingError])

	useEffect(() => {
		/** Clear event on unmount */
		return () => {
			dispatch(clearEvent())
		}
	}, [])

	/** If we have a fetching error, render 404 */
	if (fetchingError) {
		return <HandleArbitrary layout="event-404" />
	}

	/** If we have an event, render child route */
	if (event && event.id) {
		return children
	}

	return <FancyLoader />
}

export default IfEventExist
