import { select, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import requests from './requests'
import { INVOICES, COUNTRY, CART, LOCATION, PRODUCT, PAYMENT_INTENT, PROMOTION } from './constants'
import { countryListSchema, invoiceListSchema } from './normalizr'
import { normalize } from 'normalizr'

/**
 * Users Saga worker
 * Fetch users
 * @param {*} action
 */
function* handleFetchInvoices(action) {
	try {
		let userId = yield select((s) => s.auth.userId)
		let payload = { ...action.payload, userId: userId }
		const response = yield call(requests.fetchInvoices, payload)
		const normalizedData = normalize(response.data.data, invoiceListSchema)
		const data = { ...response.data, ...normalizedData }
		delete data.data
		yield put({ type: INVOICES.FETCH_SUCCEEDED, payload: data })
	} catch (e) {
		yield put({ type: INVOICES.FETCH_FAILED, payload: e })
	}
}

/**
 * Quick Util : Selector to retrieve the cart
 */
const getCartFromStore = (s) => s.checkout.cart.data

/**
 * Retrieve available products
 * @param {*} action
 */
function* handleGetProducts(action) {
	try {
		const response = yield call(requests.getProducts, action.payload)
		yield put({ type: PRODUCT.FETCH_SUCCEEDED, payload: { data: response.data.data } })
	} catch (e) {
		yield put({ type: PRODUCT.FETCH_FAILED, payload: e })
	}
}

/**
 * Retrieve Event's Cart
 * @param {*} action
 */
function* handleGetCart(action) {
	try {
		const response = yield call(requests.getCart, action.payload)
		yield put({ type: CART.FETCH_SUCCEEDED, payload: { data: response.data.data } })
	} catch (e) {
		console.error('handleGetCart failed !', e)
		yield put({ type: CART.FETCH_FAILED, payload: e, message: `Cart failed to load: ${e.message}` })
	}
}

/**
 * Saga worker
 * @param {*} action
 */
function* handleUpdateCart(action) {
	try {
		// temporary attach current cart id if not specified...
		let cartData = yield select(getCartFromStore)
		// Retrieve the payload
		let payload = action.payload
		// If we didn't specify an id for the cart to be updated, pass in the existing cart id
		if (!payload.id) {
			payload = { ...payload, id: cartData.id }
		}
		const response = yield call(requests.updateCart, payload)
		yield put({ type: CART.UPDATE_SUCCEEDED, payload: { data: response.data.data } })
	} catch (e) {
		yield put({ type: CART.UPDATE_FAILED, payload: e })
	}
}

/**
 * Add Item to cart. Takes the id of the item in the payload.
 * Retrieves the existing cart, then add the id to the cart items list.
 * @param {*} action
 */
function* handleAddItemToCart(action) {
	try {
		// Retrieve current cart
		let cartData = yield select(getCartFromStore)
		// Build our new list of items
		let newData = { ...cartData, items: [...cartData.items, action.payload] }
		// Make call
		const response = yield call(requests.addItemToCart, newData)
		// Return our new cart
		yield put({ type: CART.ADD_ITEM_SUCCEEDED, payload: { data: response.data.data } })
	} catch (e) {
		yield put({ type: CART.ADD_ITEM_FAILED, payload: e })
	}
}
/**
 * Remove Item From cart. Takes the id of the item in the payload.
 * Retrieves the existing cart, then remove the id of the item from the cart item list.
 * @param {*} action
 */
function* handleRemoveItemFromCart(action) {
	try {
		// Retrieve current cart
		let cartData = yield select(getCartFromStore)
		// Build our new list of item by removing the item id.
		let newData = { ...cartData, items: cartData.items.filter((e) => e !== action.payload) }
		// Make call
		const response = yield call(requests.removeItemFromCart, newData)
		// Return our new cart
		yield put({ type: CART.REMOVE_ITEM_SUCCEEDED, payload: { data: response.data.data } })
	} catch (e) {
		yield put({ type: CART.REMOVE_ITEM_FAILED, payload: e })
	}
}

/**
 * Post a checkout session
 * @param {*} action
 */
function* handlePostCartCheckoutSession(action) {
	try {
		// Get cart id directly from store (instead of action payload)
		let cartData = yield select(getCartFromStore)

		// Success and cancel urls (for Checkout redirects)
		let payload = {
			cartId: cartData.id,
			bundleMonthlyMembership: action.payload.bundleMonthlyMembership || false,
			successUrl: action.payload.successUrl,
			cancelUrl: action.payload.cancelUrl,
			analyticsClientId: action.payload.analyticsClientId || undefined,
		}

		const response = yield call(requests.postCartCheckoutSession, payload)
		yield put({ type: CART.POST_CHECKOUT_SESSION_SUCCEEDED, payload: response.data })
	} catch (e) {
		yield put({ type: CART.POST_FREE_FAILED, payload: e })
	}
}

/**
 * Post a free cart
 * @param {*} action
 */
function* handlePostFreeCart(action) {
	try {
		let cartData = yield select(getCartFromStore)
		let payload = cartData.id

		const response = yield call(requests.postFreeCart, payload)
		yield put({ type: CART.POST_FREE_SUCCEEDED, payload: response.data })
		yield put({ type: INVOICES.FETCH_REQUESTED })
	} catch (e) {
		yield put({ type: CART.POST_FREE_FAILED, payload: e })
	}
}

/**
 * Create Payment Intent.
 * @param {*} action
 */
function* handlePostPaymentIntent(action) {
	try {
		const response = yield call(requests.postPaymentIntent, action.payload)
		yield put({ type: PAYMENT_INTENT.ADD_SUCCEEDED, payload: response.data })
	} catch (e) {
		yield put({ type: PAYMENT_INTENT.ADD_FAILED, payload: e })
	}
}

/**
 * Update existing Payment Intent
 * @param {*} action
 */
function* handleUpdatePaymentIntent(action) {
	try {
		const response = yield call(requests.updatePaymentIntent, action.payload)
		yield put({ type: PAYMENT_INTENT.UPDATE_SUCCEEDED, payload: response.data })
	} catch (e) {
		yield put({ type: PAYMENT_INTENT.UPDATE_FAILED, payload: e })
	}
}

/**
 * Retrieve the existing IP location of the user.
 * @param {*} action
 */
function* handleGetLocation(action) {
	try {
		const response = yield call(requests.getLocation, action.payload)
		yield put({ type: LOCATION.FETCH_SUCCEEDED, payload: { data: response.data } })
	} catch (e) {
		yield put({ type: LOCATION.FETCH_FAILED, payload: e })
	}
}

/**
 * Retrieve a given country provided a country code.
 * @param {*} action
 */
function* handleGetCountry(action) {
	try {
		const response = yield call(requests.getCountry, action.payload)
		yield put({ type: COUNTRY.FETCH_SUCCEEDED, payload: { data: response.data } })
	} catch (e) {
		yield put({ type: COUNTRY.FETCH_FAILED, payload: e })
	}
}

/**
 * Retrieve all countries
 * @param {*} action
 */
function* handleGetAllCountries(action) {
	try {
		const response = yield call(requests.getAllCountries, action.payload)
		let normalizedData = normalize(response.data.data, countryListSchema)
		yield put({ type: COUNTRY.FETCH_ALL_SUCCEEDED, payload: { data: normalizedData } })
	} catch (e) {
		yield put({ type: COUNTRY.FETCH_ALL_FAILED, payload: e })
	}
}

/**
 * Verify the validity of a postal code
 * @param {*} action
 */
function* handleVerifyPostalCode(action) {
	try {
		const response = yield call(requests.verifyPostalCode, action.payload)
		yield put({ type: LOCATION.VERIFY_POSTAL_CODE_SUCCEEDED, payload: { data: response.data } })
	} catch (e) {
		yield put({ type: LOCATION.VERIFY_POSTAL_CODE_FAILED, payload: e })
	}
}

/**
 * Save promotion on the cart object when a valid promotion has been entered
 * @param {*} action
 */
function* handleSavePromotionOnCart(action) {
	try {
		// Retrieve the cart from the store
		let cartData = yield select(getCartFromStore)
		// Retrieve the code of the promotion from the LOAD_PROMOTION_SUCCESS
		if (action.payload?.code && action.payload.code.code && cartData.promoCode !== action.payload.code.code) {
			// Add promoCode to the cart
			let newData = { ...cartData, promoCode: action.payload.code.code }
			// Make call to update cart
			const response = yield call(requests.updateCart, newData)
			// Trigger a successful cart update.
			yield put({ type: CART.UPDATE_SUCCEEDED, payload: { data: response.data.data } })
		}
	} catch (e) {
		yield put({ type: 'SavingPromotionOnCartFailed', payload: e })
	}
}

function* handleUpdateCountryBasedOnCart(action) {
	try {
		let cartData = yield select(getCartFromStore)
		if (cartData && cartData.countryCode != null) {
			const response = yield call(requests.getCountry, cartData.countryCode)

			yield put({ type: COUNTRY.FETCH_SUCCEEDED, payload: { data: response.data.data } })
		}
	} catch (e) {
		yield put({ type: COUNTRY.FETCH_FAILED, payload: e })
	}
}

/**
 * Watch invoices
 */
export function* watchInvoicesFetch() {
	yield takeLatest(INVOICES.FETCH_REQUESTED, handleFetchInvoices)
}

export function* watchValidPromotionEntered() {
	yield takeEvery('LOAD_PROMOTION_SUCCESS', handleSavePromotionOnCart)
}

// export function* watchUpdateCountryBasedOnCart() {
// 	yield takeEvery(CART.FETCH_SUCCEEDED, handleUpdateCountryBasedOnCart)
// 	yield takeEvery(CART.UPDATE_SUCCEEDED, handleUpdateCountryBasedOnCart)
// }

export function* watchGetProducts() {
	// while(true){
	yield takeEvery(PRODUCT.FETCH_REQUESTED, handleGetProducts)
	// }
}

export function* watchGetCart() {
	// while(true){
	yield takeEvery(CART.FETCH_REQUESTED, handleGetCart)
	// }
}

export function* watchAddItemToCart() {
	// while(true){
	yield takeEvery(CART.ADD_ITEM_REQUESTED, handleAddItemToCart)
	// }
}

export function* watchRemoveItemFromCart() {
	// while(true){
	yield takeEvery(CART.REMOVE_ITEM_REQUESTED, handleRemoveItemFromCart)
	// }
}

export function* watchUpdateCart() {
	// while(true){
	yield takeEvery(CART.UPDATE_REQUESTED, handleUpdateCart)
	// }
}

export function* watchPostFreeCart() {
	// while(true){
	yield takeEvery(CART.POST_FREE_REQUESTED, handlePostFreeCart)
	// }
}

export function* watchPostCartCheckoutSession() {
	// while(true){
	yield takeEvery(CART.POST_CHECKOUT_SESSION_REQUESTED, handlePostCartCheckoutSession)
	// }
}

export function* watchGetLocation() {
	// while(true){
	yield takeEvery(LOCATION.FETCH_REQUESTED, handleGetLocation)
	// }
}

export function* watchPostPaymentIntent() {
	// while(true){
	yield takeEvery(PAYMENT_INTENT.ADD_REQUESTED, handlePostPaymentIntent)
	// }
}

export function* watchUpdatePaymentIntent() {
	// while(true){
	yield takeEvery(PAYMENT_INTENT.UPDATE_REQUESTED, handleUpdatePaymentIntent)
	// }
}

export function* watchVerifyPostalCode() {
	// while(true){
	yield takeLatest(LOCATION.VERIFY_POSTAL_CODE_REQUESTED, handleVerifyPostalCode)
	// }
}

export function* watchGetCountry() {
	// while(true){
	yield takeEvery(COUNTRY.FETCH_REQUESTED, handleGetCountry)
	// }
}

export function* watchGetAllCountries() {
	// while(true){
	yield takeEvery(COUNTRY.FETCH_ALL_REQUESTED, handleGetAllCountries)
	// }
}
