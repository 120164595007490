import React, { useState, createContext, useContext, useEffect } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import useAuthHandlers from '../ui/pages/Auth/hooks/useAuthHandlers'
import { useAuthContext } from './AuthProvider'
import { history } from '../../history'
import { shallowEqual, useSelector } from 'react-redux'
import isEqual from 'lodash.isequal'

/** Build Temporary User Context */
const TemporaryUserContext = createContext()

const useStructuredAuthAccount = () => {
	/**
	 * Only retrieve fields we need for this
	 */
	const id = useSelector((s) => s.auth.userId || null, shallowEqual)
	const email = useSelector((s) => s.auth.email || null, shallowEqual)
	const isActive = useSelector((s) => s.auth.isActive || null, shallowEqual)
	const isRegistered = useSelector((s) => s.auth.isRegistered || null, shallowEqual)
	const profile = useSelector((s) => s.auth.profile || null, shallowEqual)

	/**
	 * Ideally we want to always set the auth Account
	 * to match the temporary Account structure.
	 * There may be a better way of doing this, but fields are
	 */
	const [authAccount, setAuthAccount] = useState({ id, email, isActive, isRegistered, profile })

	useEffect(() => {
		/**
		 * If all values are null, then we're not authenticated,
		 * as we couldn't retrieve any of the auth values.
		 */
		if (id == null && email == null && isActive == null && isRegistered == null && profile == null) {
			setAuthAccount(null)
		} else if (
			/**
			 * Otherwise, if we have a current auth account and any of the info
			 * is different that the current user account being returned, save new account information.
			 */
			authAccount &&
			(id != authAccount.id ||
				email != authAccount.email ||
				isActive != authAccount.isActive ||
				isRegistered != authAccount.isRegistered ||
				profile != authAccount.profile)
		) {
			setAuthAccount({ id, email, isActive, isRegistered, profile })
		}
	}, [id, email, isActive, isRegistered, profile])

	return authAccount
}

const defaultAccount = {
	id: null,
	email: null,
	isActive: null,
	isRegistered: null,
	profile: null,
	rsvp: null,
}

/** Temporary User Provider  */
const TemporaryUserProvider = ({ children }) => {
	const location = useLocation()

	/**
	 * Handler to trigger an account check
	 */
	const { handleCheckAccount } = useAuthHandlers()

	/**
	 * Retrieve user from the authentication
	 * */
	const { isAuthenticated } = useAuthContext()
	const authAccount = useStructuredAuthAccount()

	/**
	 * Retrieve user from the temporary User object (redux values)
	 */

	const reduxAccount = useSelector((s) => s.temporaryUser.account, shallowEqual)
	const reduxEmail = useSelector((s) => s.temporaryUser.email, shallowEqual)
	const reduxRsvp = useSelector((s) => s.temporaryUser.rsvp, shallowEqual)

	/**
	 * Retrieve a temporary user from the URL Search Params
	 */
	const [query] = useSearchParams()

	/**
	 * Save account information
	 */
	const [account, setAccount] = useState(null)

	/**
	 * Check if we have a temporary user on mount, through search params.
	 */
	useEffect(() => {
		// If we have at least email or sms and either first or last name
		if (query.has('email') || query.has('sms') || query.has('rsvp')) {
			// Check user account
			handleCheckAccount(query.get('email'))

			setAccount({
				...defaultAccount,
				email: query.get('email'),
				rsvp: query.get('rsvp'),
				profile: {
					firstName: query.get('first_name'),
					lastName: query.get('last_name'),
					sms: query.get('sms'),
				},
			})

			// Clear params from the URL
			history.push(location.pathname, { replace: true })
		}
	}, [query])

	/**
	 * Handle changes when the `account` variable changes from redux
	 */
	useEffect(() => {
		/**
		 * If the user is not authenticated
		 */
		if (!isAuthenticated) {
			/**
			 * Do we have an account supplied by Redux? and are they different than params
			 */
			if (reduxAccount) {
				/**
				 * If the current account is different than the reduxAccount,
				 * we want to replace
				 */
				if (
					!isEqual(account, reduxAccount) // Also ensure that the values will be different before proceeding with replacement.
				) {
					/**
					 * If the emails are the same, merge the existing firstName and lastName,
					 * as it may come from the search params.
					 * We want to merge accounts
					 */
					if (
						account && // If we have an account
						(reduxAccount.email == account.email || // If redux account's email is same as the account email OR
							reduxAccount?.profile?.sms == account?.profile?.sms || // If redux account's sms is same as the account sms
							reduxRsvp == account.rsvp) // If redux rsvpId is same as the account rsvpId
					) {
						let newAccount = {
							...account,
							...reduxAccount,
							profile: {
								...account.profile,
								...reduxAccount?.profile,
							},
							rsvp: reduxRsvp,
						}
						/** Make sure the value isn't the same already */
						if (!isEqual(account, newAccount)) {
							setAccount(newAccount)
						}
					} else {
						/**
						 * Otherwise, if we don't have an account,
						 * as it may come from the search params.
						 */
						let newAccount = {
							...reduxAccount,
							rsvp: reduxRsvp,
							email: reduxAccount.email || reduxEmail, // For users that are not yet registered, the email won't come from the temporary account.
						}
						/** Make sure the value isn't the same already */
						if (!isEqual(account, newAccount)) {
							setAccount(newAccount)
						}
					}
				}
			} else if (!reduxAccount && reduxEmail) {
				/**
				 * If we don't have a redux account, but we have an email
				 */
				const isSame = isEqual(account, { ...defaultAccount, email: reduxEmail })
				if (!isSame) {
					setAccount({ ...defaultAccount, email: reduxEmail })
				}
			} else if (account != null) {
				/**
				 * Otherwise, if no redux account and account is currently not null,
				 * reset the account
				 */
				// setAccount(null)
			}
		} else {
			/**
			 * If we don't have a redux account
			 */
			/**
			 * Otherwise, save new authenticated user account information if it differs
			 */
			if (!isEqual(account, authAccount)) {
				setAccount(authAccount)
			}
		}
	}, [account, reduxAccount, reduxEmail, authAccount, isAuthenticated])

	return <TemporaryUserContext.Provider value={account}>{children}</TemporaryUserContext.Provider>
}

export default TemporaryUserProvider

export const useTemporaryUserContext = () => useContext(TemporaryUserContext)
