import { useState, useEffect } from 'react'
import useSSR from 'use-ssr'

/**
 * Hook that returns the user's displayMode.
 */
const useStandaloneDetect = () => {
	let [appInstalled, setAppInstalled] = useState()
	const { isBrowser } = useSSR()

	useEffect(() => {
		const mqStandAlone = '(display-mode: standalone)'
		if (isBrowser && (navigator.standalone || window.matchMedia(mqStandAlone).matches)) {
			setAppInstalled(true)
		} else {
			setAppInstalled(false)
		}
	}, [appInstalled])

	return appInstalled
}

export default useStandaloneDetect
