import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSocketContext } from './SocketProvider'
import { useEventContext } from './EventProvider'
import { loadEvent } from '../../api/app/events'

/**
 *	Contains PUBLIC event page socket.io listeners
 */

const PublicSocketListeners = ({ children }) => {
	const dispatch = useDispatch()
	const { event } = useEventContext()
	const {
		public: { socket: publicSocket },
	} = useSocketContext()

	/** define handlers */
	const handleLoadEvent = (payload) => {
		if (payload.data?.id == event.id || payload.data?.uuid == event.uuid) {
			dispatch(loadEvent(event.uuid))
		}
	}

	/** join public namespace room for this specific event */
	useEffect(() => {
		publicSocket.emit('join', `events:${event.id}`)

		/** leave room on unmount */
		return () => {
			publicSocket.emit('leave', `events:${event.id}`)
		}
	}, [])

	/** register listeners for users namespace (on mount only) */
	useEffect(() => {
		publicSocket.on('finalizeSuccess', handleLoadEvent)
		publicSocket.on('reloadCurrentEvent', handleLoadEvent)

		/** deregister listeners on unmount */
		return () => {
			publicSocket.off('finalizeSuccess')
			publicSocket.off('reloadCurrentEvent')
		}
	}, [])

	return children
}

export default PublicSocketListeners
