/* ******************************
 * PROJECT DEPENDENCIES
 ***************************** */

import * as types from '../constants'

function announcements(state = {}, action = {}) {
	switch (action.type) {
		case types.LOAD_ADMIN_ANNOUNCEMENTS_SUCCESS:
			if (action.payload && action.payload.entities && action.payload.entities.announcements) {
				return Object.assign({}, state, action.payload.entities.announcements)
			}

		case types.UPDATE_ADMIN_ANNOUNCEMENT_SUCCESS:
			// console.log("UPDATE PAYLOAD", action.payload)
			if (action.payload && action.payload.data) {
				let announcementId = action.payload.data.id
				state[announcementId] = action.payload.data
				return Object.assign({}, state)
			}

		case types.DELETE_ADMIN_ANNOUNCEMENT_SUCCESS:
			// console.log("ACTION", action, "STATE", state)
			// let newState = state
			return Object.assign({}, state)
		default:
			return state
	}
}

export default announcements
