// eslint-disable-next-line no-unused-vars
import { all, fork } from 'redux-saga/effects'
/** Import our users saga */
import * as checkoutSagas from '../api/checkout/sagas'
import * as publicEventSagas from '../api/sagas'
import * as notificationsSagas from '../api/notifications/sagas'
import * as mediaSagas from '../api/app/media/sagas'

// console.log(publicEventSagas, 'public event saga')
export default function* rootSaga() {
	yield all(
		[
			...Object.values(checkoutSagas),
			...Object.values(publicEventSagas),
			...Object.values(notificationsSagas),
			...Object.values(mediaSagas),
		].map(fork)
	)
}
