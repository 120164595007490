import { createSlice } from '@reduxjs/toolkit'
import { NOTIFICATIONS } from './constants'
import { LOGOUT_SUCCESS } from '../app/constants'

const initialState = {
	isFetching: false,
	data: [],
	success: null,
	count: 0,
	message: null,
}

const COMMON_UPDATE = (state, action) => {
	var index = state.data.findIndex((el, i) => el.id == action.payload.data.id)
	if (index != -1) {
		state.data[index] = action.payload.data
	} else {
		state.data[state.data.length] = action.payload.data
	}
}

const notificationsSlice = createSlice({
	initialState: initialState,
	name: 'environment',
	extraReducers: {
		[NOTIFICATIONS.FETCH_REQUESTED]: (state, action) => {
			state.isFetching = true
		},
		[NOTIFICATIONS.FETCH_SUCCEEDED]: (state, action) => {
			state.data = action.payload.data
			state.isFetching = false
			state.success = action.payload.success
			state.count = action.payload.count
			state.message = action.payload.message
		},
		[NOTIFICATIONS.FETCH_FAILED]: (state, action) => {
			state.data = []
			state.isFetching = false
			state.success = false
			state.count = 0
			state.message = action.payload.message || 'An error occured. Please try again.'
		},

		[NOTIFICATIONS.MARK_AS_SEEN_SUCCEEDED]: COMMON_UPDATE,
		[NOTIFICATIONS.MARK_AS_READ_SUCCEEDED]: COMMON_UPDATE,
		[NOTIFICATIONS.MARK_AS_UNREAD_SUCCEEDED]: COMMON_UPDATE,
		[NOTIFICATIONS.MARK_AS_DISMISSED_SUCCEEDED]: COMMON_UPDATE,

		// On LOGOUT_SUCCESS, reset slice to its initial state! Notifs are for authed users only
		[LOGOUT_SUCCESS]: (state, action) => {
			return initialState
		},
	},
	reducers: {},
})

export default notificationsSlice.reducer
