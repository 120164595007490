import React, { memo } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import IfEventExist from '../routes/EnsureEventExist'
import ProtectedEventRoute from '../routes/ProtectedEventRoute'

const EventRouter = memo((props) => {
	return (
		<Routes>
			<Route index element={<Navigate to="/dashboard" />} />
			<Route
				path=":uuid/*"
				element={
					/**
					 * HOC Component to ensure event exist or
					 * handle appropriate redirects or 404.
					 *
					 * If an event has been found, check for ownership priviledges.
					 */
					<IfEventExist>
						<ProtectedEventRoute />
					</IfEventExist>
				}
			/>
		</Routes>
	)
})

export default EventRouter
