import PropTypes from 'prop-types'

/* **************************************
 *  Event Published Media
 * ************************************ */

const propTypes = {
	name: PropTypes.string,
	mimeType: PropTypes.string.isRequired,
	duration: PropTypes.number,
	key: PropTypes.string.isRequired,
	sourceExt: PropTypes.string.isRequired,
	location: PropTypes.string.isRequired,
	thumbnail: PropTypes.string.isRequired,
}

const defaultProps = {
	duration: 0,
}

const EventPublishedMedia = { propTypes, defaultProps }

export default EventPublishedMedia
