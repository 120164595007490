/* *******************************
Calculate Charge total for payment
@props: pricingMatrix object, promocode object
******************************* */
import { eventTypes } from '@vidday/data'
import sortBy from 'lodash/sortBy'

/**
 * Pick util
 * Pass in an object and an array of accepted keys
 * Returns a new object with only keys contained in array
 */
export const pick = (obj, keys) => {
	return keys.map((k) => (k in obj ? { [k]: obj[k] } : {})).reduce((res, o) => Object.assign(res, o), {})
}

/**
 * Reject util
 * Pass in an object and an array of rejected keys
 * Returns a new object with only keys not contained in array
 */
export const reject = (obj, keys) => {
	return Object.keys(obj)
		.filter((k) => !keys.includes(k))
		.map((k) => Object.assign({}, { [k]: obj[k] }))
		.reduce((res, o) => Object.assign(res, o), {})
}

/**
 * Check if an event should be free given the category
 * */
export const shouldVideoBeFree = (occasion) => {
	const elem = eventTypes.findIndex((el) => el.type === occasion)
	/** If we have an item,  */
	if (elem != -1) {
		return eventTypes[elem].isFree || false
	}
	return
}

/**
 * Tiers prices breakdown. price expressed in cents.
 */
const tiers = [
	{ duration: 180, price: 500, label: 'under 3' },
	{ duration: 600, price: 1200, label: '3-10' },
	{ duration: 1200, price: 2400, label: '10-20' },
	{ duration: 1800, price: 3600, label: '20-30' },
	{ duration: 2400, price: 4800, label: '30-40' },
	{ duration: 3000, price: 6000, label: '40-50' },
	{ duration: 3600, price: 7200, label: '50+' },
	{ duration: 4200, price: 8400, label: '60+' },
	{ duration: 4800, price: 9600, label: '70+' },
	{ duration: 5400, price: 10800, label: '80+' },
	{ duration: 6000, price: 12000, label: '90+' },
	{ duration: 6600, price: 13200, label: '100+' },
	{ duration: 7200, price: 14400, label: '110+' },
	{ duration: 7800, price: 15600, label: '120+' },
	{ duration: 8400, price: 16800, label: '130+' },
]

export const formatToDecimalAmount = (amountInCent = 0, fiatSymbol = '$', fiatCode) => {
	if (amountInCent == 0 && !fiatCode) return amountInCent.toFixed(2)
	if (fiatSymbol && fiatCode) {
		return `${fiatSymbol}${(amountInCent / 100).toFixed(2)} ${fiatCode}`
	}
	if (fiatSymbol) {
		return `${fiatSymbol}${(amountInCent / 100).toFixed(2)}`
	}
	return (amountInCent / 100).toFixed(2)
}

/**
 * retrieve the proper cost of a video based on the tier plan given a video duration.
 * @param {number} duration
 */
export const retrieveDurationPrice = (duration) => {
	var currentTier = 0
	tiers.forEach(function (tier, i) {
		if (duration > tier.duration) {
			currentTier++
		}
	})
	if (duration > 8400) {
		return 20000
	} else {
		return tiers[currentTier].price
	}
}

/**
 * Retrieve the corresponding video product based on the duration
 * @param {array} products
 * @param {string} duration
 */
export const retrieveVideoProduct = (products, duration) => {
	/** Sort all products based on their duration */
	const sortedProducts = sortBy(products, ['duration'])
	var currentTier = 0
	sortedProducts.forEach(function (p, i) {
		if (duration > p.duration) {
			currentTier++
		}
	})
	return sortedProducts[currentTier]
}

/**
 * Calculate the total of the cart.
 * @param {array} products
 * @param {object} promotion
 * @param {boolean} isRepub
 */
export const calculateTotal = (products = [], promotion) => {
	/********************
	 * Initialize
	 ***************** */
	/** Let's hold the full amount */
	let amount = 0.0 //= basePrice //pricingMatrix.discount > 0 ? basePrice - pricingMatrix.discount : basePrice
	var saved = 0.0
	var total = 0.0

	var basePrice = 0.0
	/********************
	 * Calculate products cost
	 ***************** */

	/** Loop through each products to adjust total amount  */
	products.forEach((el, i) => {
		/** First, let's find the video item to calculate duration cost */
		if (el.id == 'video') {
			/** Check if video item has occasion set, and if it's a free occasion. defaults to false */
			// TODO : eventTypes should be passed into this function keep it pure? that way it's future-proof for pulling from db
			let isFree = el.occasion ? eventTypes.filter((o) => o.type === el.occasion)[0].isFree : false

			/** Retrieve tier price based on duration. Set price to 0 for FREE events */
			let durationPrice = retrieveDurationPrice(el.duration)
			el.amount = durationPrice // attach item amount to cart item

			/** Add amounts to cart total and cart amount */
			basePrice += isFree ? 0 : durationPrice // 0$ when free event, otherwise duration price

			/********************
			 * Handle republish discount 70%
			 ***************** */
			if (el.republish && basePrice > 0) {
				let reduction = (basePrice * 70) / 100
				basePrice = Math.round(basePrice - reduction)
				saved = Math.round(amount - reduction)
			}

			amount += basePrice
		} else if (el.quantity) {
			/** Otherwise, let's check if the product supports quantity */
			amount += el.quantity * el.amount
		} else {
			/** If quantity isn't needed, simply add the amount */
			amount += el.amount
		}
	})

	/** set the total amount as per calculated */
	total = amount
	/********************
	 * Calculate Promotion
	 ***************** */

	let percentOff = 0,
		amountOff = 0

	if (promotion) {
		const {
			code: { type, value },
		} = promotion
		/** Percentage (%) discount */
		if (type) {
			if (type === 'percent') {
				percentOff = value
				saved = Math.round((amount * percentOff) / 100)
				total = Math.round(amount - (amount * percentOff) / 100)
			} else if (type === 'amount') {
				/** amount ($) discount */
				amountOff = value
				saved = Math.round(amountOff * 100)
				total = Math.round(amount - amountOff * 100)
			} else if (type === 'gratuit') {
				// // Was this before, but this would only apply to the last 'video' item processed above
				// saved = basePrice
				// total = amount - basePrice

				// New logic to include all items in cart :
				saved = total
				total = 0
			}
		}
	}

	/********************
	 * Prevent negative amount
	 ***************** */

	if (total <= 0) {
		total = 0
	}

	return { total: total, amount: amount, saved: saved } // discount: pricingMatrix.discount (discount no longer used)
}
