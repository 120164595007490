import { generateUID } from '@vidday/utils'

/* **************************************
 *  STORE ENHANCER FOR MODALS
 * ************************************ */

export const modalRefStore = {}

export default function modalRefHandler({ getState }) {
	return (next) => (action) => {
		switch (action.type) {
			/** Add a modal refs from the registry */
			case 'modal_ADD_MODAL':
				const id = action.id || generateUID()
				action.id = id

				modalRefStore[id] = action.refs || {}
				// Delete refs from the action
				delete action.refs

				break

			/** Remove a modal refs from the registry */
			case 'modal_REMOVE_MODAL':
				var modal = modalRefStore[action.id]
				modal && delete modalRefStore[action.id]
				break

			default:
				break
		}
		const returnValue = next(action)
		return returnValue
	}
}
