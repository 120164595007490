import { mode } from '@chakra-ui/theme-tools'

const NavBar = {
	// The styles all NavBars have in common
	baseStyle: (props) => ({
		boxShadow: 'base',
		bg: mode('light', 'gray.900')(props),
		h: '80px',
		w: 'full',
		zIndex: '2147483638',
		position: 'absolute',
		top: '0',
		left: '0',
		right: '0',
		borderBottom: '1px',
		borderBottomColor: mode('transparent', 'rgba(255,255,255,0.1)')(props),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	}),
	// Two variants: rounded and smooth
	variants: {
		marketplace: () => {
			return {
				bg: 'transparent',
				boxShadow: 'none',
			}
		},
	},
	// The default variant value
	defaultProps: {},
}

export default NavBar
