import PropTypes from 'prop-types'

/* **************************************
 *  Event
 * ************************************ */

const propTypes = {
	uuid: PropTypes.string.isRequired,
	occupied: PropTypes.bool,
	occasion: PropTypes.string,
	occasionOther: PropTypes.string,
	deadline: PropTypes.string, //instanceOf(Date),
	years: PropTypes.number,
	paid: PropTypes.bool,
	status: PropTypes.oneOf(['active', 'inactive', 'publishing', 'published', 'archived']).isRequired,
	isPrivate: PropTypes.bool,
	allowComments: PropTypes.bool,
	timezone: PropTypes.string,
	notes: PropTypes.string,
	viewLayout: PropTypes.oneOf(['grid', 'list', 'block']).isRequired,
	gridSize: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge']).isRequired,
	inviteMessage: PropTypes.string,
	package: PropTypes.string,
	publishedDate: PropTypes.string, //instanceOf(Date),
	autoRender: PropTypes.bool,
	contributorsNotified: PropTypes.bool,
	lastModified: PropTypes.string, //instanceOf(Date),
	updatedAt: PropTypes.string, //instanceOf(Date),
	mediaSortingOrder: PropTypes.array,
	utmData: PropTypes.shape({
		source: PropTypes.string,
		medium: PropTypes.string,
		campaign: PropTypes.string,
		content: PropTypes.string,
	}),
}

const defaultProps = {
	uuid: -1,
	occupied: false,
	occasion: '',
	occasionOther: null,
	deadline: null,
	years: null,
	paid: false,
	status: 'active',
	isPrivate: true,
	allowComments: true,
	notes: null,
	viewLayout: 'grid',
	gridSize: 'medium',
	inviteMessage: null,
	package: null,
	publishedDate: null,
	autoRender: false,
	contributorsNotified: false,
	lastModified: null,
	updatedAt: null,
	mediaSortingOrder: [],
	utmData: {
		source: null,
		medium: null,
		campaign: null,
		content: null,
	},
}

const Event = { propTypes, defaultProps }

export default Event
